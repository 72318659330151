import * as yup from 'yup';
import moment from 'moment';

export const activeAnalysisUnpaidInitialValues = {
  start_date: moment(new Date()).format('MM/DD/YYYY'),
  end_date: moment(new Date()).format('MM/DD/YYYY'),
  filter_by: 'entity',
  entity: [],
  provider: [],
  payroll_group: [],
  include_unit_overage_bonus: false,
  unit_overage_start_date: moment(new Date()).format('MM/DD/YYYY'),
  unit_overage_close_date: moment(new Date()).format('MM/DD/YYYY'),
};

export const activeAnalysUnpaidisValidationSchema = yup.object().shape({
  start_date: yup.date().required('Required *'),
  end_date: yup.date().required('Required *'),
  unit_overage_start_date: yup.date(),
  unit_overage_close_date: yup.date(),
  include_unit_overage_bonus: yup.boolean(),
  end_date: yup.date().required('Required *'),
  filter_by: yup.string(),

  entity: yup.array().when('filter_by', {
    is: 'entity',
    then: yup.array().min(1, 'Required *').required('Required *'),
    otherwise: yup.array(),
  }),
  provider: yup.array().when('filter_by', {
    is: 'provider',
    then: yup.array().min(1, 'Required *').required('Required *'),
    otherwise: yup.array(),
  }),
  payroll_group: yup.array().when('filter_by', {
    is: 'payroll_group',
    then: yup.array().min(1, 'Required *').required('Required *'),
    otherwise: yup.array(),
  }),
});
