import CustomToast from 'components/common/custom-toast';

import {get, put, post, deleteApi} from 'services/network';
import {FACILITIES_ENDPOINT, FACILITIES_TYPES_ENDPOINT, PROVIDERS_ENDPOINT} from 'constants/endpoints';
import {alertTypes} from 'constants/common';
import {toast} from 'react-toastify';

export const getFacilitiesList = async obj => {
  const params = {
    sort: obj?.sort ? obj.sort : '',
    order: obj?.order ? obj.order : 'asc',
    page: obj?.page ? obj.page : 1,
    perPage: obj?.perPage ? obj.perPage : 10,
    search: obj?.search ? obj.search : '',
    status: obj?.status || '',
    entity_id: obj?.entity_id || '',
  };
  try {
    const result = await get(
      `${FACILITIES_ENDPOINT}?page=${params.page}&sort=${params.sort}&order=${params.order}&per=${
        params.perPage
      }&query=${params.search}${params.status ? `&filter[status]=${params.status}` : ''}${
        params.entity_id ? `&filter[entity_id]=${params.entity_id}` : ''
      }`,
      true,
    );
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const deleteFacility = async id => {
  try {
    const response = await deleteApi(`${FACILITIES_ENDPOINT}/${id}/`, true);
    toast(<CustomToast variant={alertTypes.SUCCESS} message={response.message} />);
    return response;
  } catch (error) {
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={error?.response?.data?.message || error?.response?.statusText}
      />,
    );
    console.error(error);
  }
};

export const createFacility = async (data, setErrors) => {
  try {
    const result = await post(`${FACILITIES_ENDPOINT}`, data, true);
    toast(<CustomToast variant={alertTypes.SUCCESS} message="Facility created successfully!" />);
    return result;
  } catch (error) {
    if (error?.response?.data) {
      setErrors(error?.response?.data.errors);
    }
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={error?.response?.data?.message || error?.response?.statusText}
      />,
    );
    console.log('error');
  }
};

export const getFacilitiesTypes = async (status = '', search = '') => {
  try {
    const result = await get(`${FACILITIES_TYPES_ENDPOINT}?filter[status]=${status}&query=${search}`, true);
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const getSingleFacility = async id => {
  try {
    const result = await get(`${FACILITIES_ENDPOINT}/${id}`, true);
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const updateFacility = async (data, id, setErrors) => {
  try {
    const result = await put(`${FACILITIES_ENDPOINT}/${id}`, data, true);
    toast(<CustomToast variant={alertTypes.SUCCESS} message="Facility updated successfully!" />);
    return result;
  } catch (error) {
    if (error?.response?.data) {
      setErrors(error?.response?.data.errors);
    }
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={error?.response?.data?.message || error?.response?.statusText}
      />,
    );
    console.log('error');
  }
};

export const getFacilityByProvider = async id => {
  try {
    const result = await get(`${PROVIDERS_ENDPOINT}/${id}/facilities`, true);
    return result;
  } catch (e) {
    console.log(e);
  }
};
