import {DASHBOARD_PATH} from 'constants/routePaths';
import {CircleWavyWarning} from 'phosphor-react';
import {Link} from 'react-router-dom';

const NotFound = () => (
  <div className="text-center p-4">
    <CircleWavyWarning size={64} />
    <h4 className="mt-4 mb-1">No matching path exists.</h4>
    <p>
      <Link to={DASHBOARD_PATH} className="btn btn-transparent">
        View dashboard
      </Link>
    </p>
  </div>
);

export default NotFound;
