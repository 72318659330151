import React, {useEffect, useState} from 'react';
import SelectComponent from 'components/common/select';
import debounce from 'debounce-promise';
import {Row, Col} from 'react-bootstrap';
import {getEntitesList} from 'containers/backend-tables/api';
import {getProvidersList} from 'containers/providers/api';
import Input from 'components/common/input';
import {DEBOUNCE_DELAY} from 'utils/common';
import AsyncSelectComponent from 'components/common/async-select';
import CustomDatePicker from 'components/common/custom-datepicker';
import moment from 'moment';

const Filter = ({handleSubmit}) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [entities, setEntities] = useState([]);
  const [providers, setProviders] = useState([]);
  const [selectedEntities, setSelectedEntities] = useState([]);
  const [selectedProviders, setSelectedProviders] = useState([]);
  const [dropdownLoading, setDropdownLoading] = useState({
    entities: false,
    providers: false,
  });

  async function fetchEntities() {
    try {
      const result = await getEntitesList('', 'active');
      if (result?.entities) {
        setEntities(result.entities.map(item => ({value: item.id, label: item.name})));
      }
    } catch (e) {
      console.log(e);
    }
  }

  async function fetchProviders() {
    try {
      const result = await getProvidersList({status: 'active'});
      if (result?.providers) {
        setProviders(
          result.providers.map(item => ({value: item.id, label: `${item.first_name} ${item.last_name}` || item.name})),
        );
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    fetchEntities();
    fetchProviders();
  }, []);

  const fetchEntitiesData = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, entities: true});
    try {
      const result = await getEntitesList(search, 'active');
      if (result['entities']) {
        const data = result?.entities.map(item => ({label: item.name, value: item.id}));
        setEntities(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, entities: false});
    }
  }, DEBOUNCE_DELAY);

  const fetchProvidersData = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, providers: true});
    try {
      const result = await getProvidersList({search: search, status: 'active'});
      if (result['providers']) {
        const data = result?.providers.map(item => ({
          value: item.id,
          label: `${item.first_name} ${item.last_name}` || item.name,
        }));
        setProviders(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, providers: false});
    }
  }, DEBOUNCE_DELAY);
  const loadEntitiesDropdownOptions = search => fetchEntitiesData(search);
  const loadProvidersDropdownOptions = search => fetchProvidersData(search);

  const handleEntityChange = arr => {
    setSelectedEntities(arr);
  };
  const handleProviderChange = arr => {
    setSelectedProviders(arr);
  };

  const handleStartDate = date => {
    setStartDate(date);
  };

  const handleEndDate = date => {
    setEndDate(date);
  };

  return (
    <div className="looker-filter" style={{width: '100%'}}>
      <h5>Filters</h5>
      <Row>
        <Col md={6} className="mt-4">
          <CustomDatePicker
            name="start_date"
            label="From"
            selected={startDate ? new Date(moment(startDate)) : ''}
            onDateChange={date => handleStartDate(date)}
          />
        </Col>
        <Col md={6} className="mt-4">
          <CustomDatePicker
            name="end_date"
            label="To"
            selected={endDate ? new Date(moment(endDate)) : ''}
            onDateChange={date => handleEndDate(date)}
          />
        </Col>
        <Col md={6} className="mt-4">
          <p style={{margin: '10px 0px'}}>Entities</p>
          <AsyncSelectComponent
            isLoading={dropdownLoading.entities}
            selectedValue={selectedEntities}
            defaultOptions={entities}
            loadOptions={loadEntitiesDropdownOptions}
            onChange={handleEntityChange}
            placeholder="Search and select"
            closeMenuOnSelect={false}
            isMulti
          />
        </Col>
        <Col md={6} className="mt-4">
          <p style={{margin: '10px 0px'}}>Providers</p>
          <AsyncSelectComponent
            isLoading={dropdownLoading.providers}
            selectedValue={selectedProviders}
            defaultOptions={providers}
            loadOptions={loadProvidersDropdownOptions}
            onChange={handleProviderChange}
            placeholder="Search and select"
            closeMenuOnSelect={false}
            isMulti
          />
        </Col>
      </Row>
      <div className="filter-search-otr mt-4" style={{width: '90px'}}>
        <div
          className={`action ${
            (selectedEntities.length || selectedProviders.length) && startDate && endDate ? '' : 'disabled'
          }`}
          onClick={() => {
            handleSubmit({
              entities: selectedEntities.map(ent => ent.value),
              providers: selectedProviders.map(pro => pro.value),
              startDate,
              endDate,
            });
          }}
        >
          <button className="primary-btn-small record-btn">Apply</button>
        </div>
      </div>
    </div>
  );
};

export default Filter;
