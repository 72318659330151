import React, {useEffect, useState} from 'react';
import SelectComponent from 'components/common/select';
import debounce from 'debounce-promise';
import {Row, Col} from 'react-bootstrap';
import {getUsers} from 'containers/system-audit/api';
import {DEBOUNCE_DELAY} from 'utils/common';
import AsyncSelectComponent from 'components/common/async-select';
import CustomDatePicker from 'components/common/custom-datepicker';
import moment from 'moment';

const Filter = ({handleSubmit}) => {
  const [list, setList] = useState([]);
  const [value, setValue] = useState('');
  const [to, setTo] = useState('');
  const [from, setFrom] = useState('');
  const [dropdownLoading, setDropdownLoading] = useState({
    users: false,
  });
  useEffect(() => {
    const fetchUsers = async () => {
      const result = await getUsers();
      if (result?.users) {
        const data = result.users.map(item => ({value: item.id, label: item.full_name}));
        setList(data);
      }
    };
    fetchUsers();
  }, []);
  const handleChangeOnDropdown = item => {
    setValue(item.value);
  };

  const fetchUsersData = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, users: true});
    try {
      const result = await getUsers(search);
      if (result['users']) {
        const data = result?.users.map(item => ({value: item.id, label: item.full_name}));
        setList(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, users: false});
    }
  }, DEBOUNCE_DELAY);

  const loadUsersDropdownOptions = search => fetchUsersData(search);

  return (
    <div className="system-audit-filter">
      <h5>Filters</h5>
      <Row>
        <Col md={6}>
          <CustomDatePicker
            name="from"
            label="From"
            selected={from ? new Date(moment(from)) : ''}
            onDateChange={date => setFrom(date)}
          />
        </Col>
        <Col md={6}>
          <CustomDatePicker
            name="to"
            label="To"
            selected={to ? new Date(moment(to)) : ''}
            onDateChange={date => setTo(date)}
          />
        </Col>
        <Col md={12} className="mt-4">
          <p>User</p>
          <AsyncSelectComponent
            isLoading={dropdownLoading.users}
            selectedValue={value}
            defaultOptions={list}
            loadOptions={loadUsersDropdownOptions}
            onChange={handleChangeOnDropdown}
            placeholder="Search and select"
          />
        </Col>
      </Row>
      <div className="filter-search-otr">
        {/* <SearchInput /> */}
        <div className={`action ${value || to || from ? '' : 'disabled'}`}>
          <button
            className="primary-btn-small record-btn"
            onClick={() => {
              handleSubmit(from, to, value);
            }}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default Filter;
