import React from 'react';

import BreadcrumbTrail from 'components/common/breadcrumb-trail';
import RecordsListing from 'components/gross-billing-report/Listing';

const GrossBillingReportDetail = () => {
  return (
    <>
      <div className="provider-assignment">
        <div className="container-fluid">
          <div className="heading-path-otr">
            <h3 className="heading heading-h3">Gross Billing Report</h3>
            <BreadcrumbTrail />
          </div>

          <RecordsListing />
        </div>
      </div>
    </>
  );
};

export default GrossBillingReportDetail;
