import {LOADING} from '../action-types/users';

const users = (state = {}, action = {}) => {
  const {type, payload} = action;
  switch (type) {
    case LOADING:
      return {...state, loading: payload};

    default:
      return {...state};
  }
};

export default users;
