import React, {useEffect, useState} from 'react';
import SelectComponent from 'components/common/select';
import {Row, Col} from 'react-bootstrap';
import {statusOptions} from 'constants/common';
import {getProvidersList} from 'containers/providers/api';
import {getEarningCodes} from 'containers/earning-codes/api';
import debounce from 'debounce-promise';
import {DEBOUNCE_DELAY} from 'utils/common';
import AsyncSelectComponent from 'components/common/async-select';

const ProviderRatesFilter = ({handleSubmit, filters}) => {
  const [providers, setProviders] = useState([]);
  const [earningCodes, setEarningCodes] = useState([]);
  const [filterState, setFilterState] = useState(filters);
  const [dropdownLoading, setDropdownLoading] = useState({
    providers: false,
    entities: false,
  });

  async function fetchProviders() {
    const result = await getProvidersList({status: 'active'});
    if (result?.providers?.length) {
      setProviders(result?.providers.map(item => ({value: item.id, label: item.first_name + ' ' + item.last_name})));
    }
  }

  async function fetchEarningCodes() {
    try {
      const result = await getEarningCodes({page: 1, perPage: 10,status:'active'});
      if (result['earning_codes']) {
        const codes = result?.earning_codes.map(item => ({value: item.id, label: item.code}));
        setEarningCodes(codes);
      }
    } catch (e) {
      console.log(e);
    } finally {
    }
  }
  useEffect(() => {
    fetchProviders();
    fetchEarningCodes();
  }, []);

  const fetchProvidersData = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, providers: true});
    try {
      const result = await getProvidersList({page: 1, perPage: 10, search: search, status: 'active'});
      if (result['providers']) {
        const data = result?.providers.map(item => ({value: item.id, label: item.first_name + ' ' + item.last_name}));
        setProviders(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, providers: false});
    }
  }, DEBOUNCE_DELAY);

  const fetchEntitiesData = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, entities: true});
    try {
      const result = await getEarningCodes({page: 1, perPage: 10, search,status:'active'});
      if (result['earning_codes']) {
        const data = result?.earning_codes.map(item => ({value: item.id, label: item.code}));
        setEarningCodes(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, entities: false});
    }
  }, DEBOUNCE_DELAY);
  const loadProvidersDropdownOptions = search => fetchProvidersData(search);
  const loadEntitiesDropdownOptions = search => fetchEntitiesData(search);

  return (
    <div className="providers-filter">
      <h5>Filters</h5>
      <Row>
        <Col md={6}>
          <p>Provider</p>
          <AsyncSelectComponent
            isLoading={dropdownLoading.providers}
            selectedValue={filterState.provider_id}
            defaultOptions={providers}
            loadOptions={loadProvidersDropdownOptions}
            onChange={obj => {
              setFilterState(pre => ({...pre, provider_id: obj.value}));
            }}
            placeholder="Search and select"
          />
        </Col>
        <Col md={6}>
          <p>Earning Code</p>
          <AsyncSelectComponent
            isLoading={dropdownLoading.entities}
            selectedValue={filterState.earning_code_id}
            defaultOptions={earningCodes}
            loadOptions={loadEntitiesDropdownOptions}
            onChange={obj => {
              setFilterState(pre => ({...pre, earning_code_id: obj.value}));
            }}
            placeholder="Search and select"
          />
        </Col>
      </Row>
      <div className="filter-search-otr">
        {/* <SearchInput /> */}
        <div
          className={`action`}
          onClick={() => {
            handleSubmit(filterState);
          }}
        >
          <button className="primary-btn-small record-btn">Apply</button>
        </div>
      </div>
    </div>
  );
};

export default ProviderRatesFilter;
