import Sidebar from 'components/layout/sidebar';
import Header from 'components/layout/header';
import {revokeAuthToken} from 'containers/login/api';
import {isEmpty} from 'lodash';
import {removeAccessToken, removeUserInfoFromStorage} from 'utils/common';
import {useNavigate} from 'react-router-dom';
import {LOGIN_PATH} from 'constants/routePaths';
import {useState} from 'react';
import classNames from 'classnames';

const Layout = ({children}) => {
  const navigate = useNavigate();
  const [isSidebarVisible, setSidebarVisibility] = useState(true);

  const toggleSidebarHandler = () => {
    setSidebarVisibility(!isSidebarVisible);
  };

  const logoutHandler = async () => {
    try {
      const res = await revokeAuthToken();
      if (isEmpty(res)) {
        removeAccessToken();
        removeUserInfoFromStorage();
        navigate(LOGIN_PATH);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {isSidebarVisible && <Sidebar toggleSidebarHandler={toggleSidebarHandler} logoutHandler={logoutHandler} />}
      <main className={classNames('body-content', {'has-sidebar': isSidebarVisible})}>
        <Header toggleSidebarHandler={toggleSidebarHandler} />
        {children}
      </main>
    </div>
  );
};

export default Layout;
