import '../mail.scss';
import React, {useEffect, useState} from 'react';
import Input from 'components/common/input';
import SelectComponent from 'components/common/select';
import {ErrorMessage} from 'formik';
import {Row, Col} from 'react-bootstrap';
import FileUpload from 'components/common/file-upload';
import FilterWrapper from 'components/common/filter-wrapper';
import CustomToast from 'components/common/custom-toast';
import {alertTypes} from 'constants/common';
import {toast} from 'react-toastify';
import {getTemplatesList} from 'containers/mail/api';

const EmailForm = ({values, handleChange, setFieldValue}) => {
  const [refresh, setRefresh] = useState(false);
  const [templateList, setTemplateList] = useState([]);

  const handleRefreshPage = () => {
    setRefresh(pre => !pre);
  };

  const handleFileUpload = async file => {
    const extension = file.file.name.split('.').pop().toLowerCase();
    const isSupported = ['xlsx', 'csv'].includes(extension);
    if (isSupported) {
      let formData = new FormData();
      formData.append('document', file.file);
      //   const result = await createLookerImport(formData);
      handleRefreshPage();
    } else {
      toast(<CustomToast variant={alertTypes.DANGER} message={'Please select xlsx or csv file.'} />);
    }
  };

  useEffect(() => {
    async function getTemplates() {
      const result = await getTemplatesList();
      if (result['email_templates']) {
        const data = result.email_templates.map(item => {
          return {label: item.name, value: item.id};
        });
        setTemplateList(data);
      }
    }
    getTemplates();
  }, []);

  return (
    <div className="w-100 send-mails">
      <Row>
        <Col md={6}>
          <div className=" input-otr" style={{alignItems: 'inherit'}}>
            <label htmlFor="email" className="input-label">
              Template
            </label>
            <SelectComponent
              name="templateId"
              options={templateList}
              selectedValue={values.templateId}
              placeholder="Select Email Template"
              onChange={v => setFieldValue('templateId', v.value)}
            />
            <ErrorMessage className="error-text" component="p" name="templateId" />
          </div>
        </Col>
        <Col md={6}>
          <Input
            handleChange={handleChange}
            name="subject"
            value={values.subject}
            placeholder={'e.g. Uh-oh, your prescription is expiring'}
            label="Subject"
            type="text"
          />
          <ErrorMessage className="error-text" component="p" name="subject" />
        </Col>

        <Col md={12}>
          <Input
            handleChange={handleChange}
            name="body"
            value={values.body}
            placeholder={'Please Type Message'}
            label={'Message'}
            type="textarea"
            className="message"
            rows={8}
          />
          <ErrorMessage className="error-text" component="p" name="body" />
        </Col>
        {/* <div className="browse-file">
          <FilterWrapper>
            <FileUpload type="drag" accept={['.xlsx', '.csv']} customRequest={handleFileUpload} />
          </FilterWrapper>
        </div> */}
      </Row>
    </div>
  );
};

export default EmailForm;
