import React from 'react';
import CustomModal from 'components/common/modal';
import {Col, Row} from 'react-bootstrap';

const ShowProvider = ({selectedRow, handleCloseModal, isModalVisible}) => {
  return (
    <CustomModal size="lg" show={isModalVisible} onHide={handleCloseModal} heading={`View Provider`}>
      <Row>
        <Col md={3} className="mb-3">
          <h5>NPI Code</h5>
          <p>{selectedRow?.npi_code || '-'}</p>
        </Col>
        <Col md={3} className="mb-3">
          <h5>Provider Name</h5>
          <p>{selectedRow?.first_name + ' ' + selectedRow.last_name || '-'}</p>
        </Col>
        <Col md={3} className="mb-3">
          <h5>Phone</h5>
          <p>{selectedRow?.phone_no || '-'}</p>
        </Col>
        <Col md={3} className="mb-3">
          <h5>Cell</h5>
          <p>{selectedRow?.phone_no || '-'}</p>
        </Col>
        <Col md={3} className="mb-3">
          <h5>Email</h5>
          <p>{selectedRow?.personal_email || '-'}</p>
        </Col>
        <Col md={3} className="mb-3">
          <h5>Start Date</h5>
          <p>{selectedRow?.provider_title?.updated_at || '-'}</p>
        </Col>
        <Col md={3} className="mb-3">
          <h5>Address</h5>
          <p>{selectedRow?.address?.street || '-'}</p>
        </Col>
      </Row>
    </CustomModal>
  );
};

export default ShowProvider;
