import CustomDatePicker from 'components/common/custom-datepicker';
import Input from 'components/common/input';
import CustomModal from 'components/common/modal';
import SelectComponent from 'components/common/select';
import {adjustmentsInitialValues, adjustmentsValidationSchema as validationSchema} from 'constants/adjustments';
import {PAID_TYPES} from 'constants/common';
import {getEarningCodes, getEarningCodesByProvider} from 'containers/earning-codes/api';
import {getFacilitiesList, getFacilityByProvider} from 'containers/facilities/api';
import {getProvidersList} from 'containers/providers/api';
import {ErrorMessage, Formik} from 'formik';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {appendObjectOnEditDropdown, isPaid} from 'utils/common';

const EditModal = ({handleCloseModal, selectedRow, handleSubmit}) => {
  console.log('selected  value', selectedRow);
  const [initialValues, setInitialValues] = useState(
    selectedRow !== null
      ? {
          ...adjustmentsInitialValues,
          ...selectedRow,
          effective_date: moment(selectedRow.effective_date).format('YYYY-MM-DD'),
          documentation_received_date: moment(selectedRow.documentation_received_date).format('YYYY-MM-DD'),
          service_date: moment(selectedRow.service_date).format('YYYY-MM-DD'),
        }
      : adjustmentsInitialValues,
  );

  const [providers, setProviders] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [earningCodes, setEarningCodes] = useState([]);
  const isEditMode = selectedRow !== null;
  async function fetchProviders() {
    const result = await getProvidersList();
    if (result['providers']) {
      setProviders(
        appendObjectOnEditDropdown(
          result['providers'].map(item => ({value: item.id, label: item.first_name + ' ' + item.last_name})),
          {
            value: selectedRow?.provider_id,
            label: selectedRow?.provider?.first_name + ' ' + selectedRow?.provider?.last_name,
          },
        ),
      );
    }
  }

  const getFacilityById = async id => {
    const result = await getFacilityByProvider(id);
    if (result.length) {
      setFacilities(
        appendObjectOnEditDropdown(
          result.map(item => ({label: item.name, value: item.id})),
          {value: selectedRow?.facility?.id, label: selectedRow?.facility?.name},
        ),
      );
    } else {
      setFacilities([]);
    }
  };
  const getEarningCodeById = async id => {
    const result = await getEarningCodesByProvider(id);
    console.log('earningCode by provider ', result);
    if (result.length) {
      setEarningCodes(
        appendObjectOnEditDropdown(
          result.map(item => ({label: item.code, value: item.id})),
          {value: selectedRow?.earning_code?.id, label: selectedRow?.earning_code?.code},
        ),
      );
    } else {
      setEarningCodes([]);
    }
  };

  useEffect(() => {
    fetchProviders();
    getFacilityById(selectedRow?.provider_id);
    getEarningCodeById(selectedRow?.provider_id);
  }, []);
  console.log('initialValues', initialValues);
  return (
    <CustomModal show={true} onHide={() => handleCloseModal()} heading={`Edit Timesheet Adjustments`}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={values => handleSubmit(values)}
      >
        {formik => {
          console.log('errors', formik.errors);
          return (
            <form className={'w-100'}>
              <Row>
                <Col md={6}>
                  <CustomDatePicker
                    name="service_date"
                    label="Date of Service"
                    selected={formik.values.service_date ? new Date(moment(formik.values.service_date)) : ''}
                    onDateChange={date => formik.setFieldValue('service_date', date)}
                  />
                  <ErrorMessage className="error-text" component="p" name="service_date" />
                </Col>
                <Col md={6}>
                  <CustomDatePicker
                    name="effective_date"
                    label="Effective Date"
                    selected={formik.values.effective_date ? new Date(moment(formik.values.effective_date)) : ''}
                    onDateChange={date => formik.setFieldValue('effective_date', date)}
                    disabled={isEditMode ? true : false}
                  />
                  <ErrorMessage className="error-text" component="p" name="effective_date" />
                </Col>
                <Col md={6}>
                  <div className=" input-otr" style={{alignItems: 'inherit'}}>
                    <label htmlFor="email" className="input-label">
                      Provider Name
                    </label>
                    <SelectComponent
                      name="provider_id"
                      options={providers}
                      selectedValue={formik.values.provider_id}
                      handleChange={obj => {
                        formik.setFieldValue('provider_id', obj.value);
                      }}
                      isDisabled
                    />
                    <ErrorMessage className="error-text" component="p" name="provider_id" />
                  </div>
                </Col>
                <Col md={6}>
                  <div className=" input-otr" style={{alignItems: 'inherit'}}>
                    <label htmlFor="email" className="input-label">
                      Facility
                    </label>
                    <SelectComponent
                      name="facility_id"
                      options={facilities}
                      selectedValue={formik.values.facility_id}
                      handleChange={obj => {
                        formik.setFieldValue('facility_id', obj.value);
                      }}
                    />
                    <ErrorMessage className="error-text" component="p" name="facility_id" />
                  </div>
                </Col>
                <Col md={6}>
                  <div className=" input-otr" style={{alignItems: 'inherit'}}>
                    <label htmlFor="email" className="input-label">
                      Earning Code
                    </label>
                    <SelectComponent
                      name="earning_code_id"
                      options={earningCodes}
                      selectedValue={formik.values.earning_code_id}
                      handleChange={obj => {
                        formik.setFieldValue('earning_code_id', obj.value);
                      }}
                    />
                    <ErrorMessage className="error-text" component="p" name="earning_code_id" />
                  </div>
                </Col>

                <Col md={6}>
                  <Input
                    handleChange={formik.handleChange}
                    name={'note_id'}
                    value={formik.values.note_id}
                    placeholder={'Select'}
                    label={'Note Id'}
                    type="text"
                  />

                  <ErrorMessage className="error-text" component="p" name="note_id" />
                </Col>

                <Col md={6}>
                  <Input
                    handleChange={formik.handleChange}
                    name={'serviced_unit'}
                    value={formik.values.serviced_unit}
                    placeholder={'Select'}
                    label={'Service Unit'}
                    type="text"
                  />

                  <ErrorMessage className="error-text" component="p" name="serviced_unit" />
                </Col>
                {/* <Col md={6}>
                  <CustomDatePicker
                    name="documentation_received_date"
                    label="Documentation Reveived Date"
                    selected={
                      formik.values.documentation_received_date
                        ? new Date(moment(formik.values.documentation_received_date))
                        : ''
                    }
                    onDateChange={date => formik.setFieldValue('documentation_received_date', date)}
                  />
                  <ErrorMessage className="error-text" component="p" name="documentation_received_date" />
                </Col> */}
                <Col md={6}>
                  <Input
                    handleChange={formik.handleChange}
                    name={'unit_in_minutes'}
                    value={formik.values.unit_in_minutes}
                    placeholder={'Select'}
                    label={'Units in Minutes'}
                    type="number"
                  />

                  <ErrorMessage className="error-text" component="p" name="unit_in_minutes" />
                </Col>
                <Col md={6}>
                  <Input
                    handleChange={formik.handleChange}
                    name={'patient_seen'}
                    value={formik.values.patient_seen}
                    placeholder={'Select'}
                    label={'Patient Seen'}
                    type="number"
                  />

                  <ErrorMessage className="error-text" component="p" name="patient_seen" />
                </Col>
                <Col md={6}>
                  <Input
                    handleChange={formik.handleChange}
                    name={'initial_unit_charge_amount'}
                    value={formik.values.initial_unit_charge_amount}
                    placeholder={'Select'}
                    label={'Use Initial Unit Charge Amount'}
                    type="number"
                  />

                  <ErrorMessage className="error-text" component="p" name="initial_unit_charge_amount" />
                </Col>
                <Col md={6}>
                  <div className=" input-otr" style={{alignItems: 'inherit'}}>
                    <label htmlFor="email" className="input-label">
                      Paid Type
                    </label>
                    <SelectComponent
                      name="paid_type"
                      options={PAID_TYPES}
                      selectedValue={formik.values.paid_type}
                      handleChange={obj => {
                        formik.setFieldValue('paid_type', obj.value);
                      }}
                      isDisabled={isPaid(selectedRow.paid_type)}
                    />

                    <ErrorMessage className="error-text" component="p" name="paid_type" />
                  </div>
                </Col>
                <Col md={6}>
                  <Input
                    handleChange={e => {
                      formik.setFieldValue('note.message', e.target.value);
                    }}
                    name={'note'}
                    value={formik.values.note?.message}
                    placeholder={'Select'}
                    label={'Note'}
                    type="textarea"
                  />
                  <ErrorMessage className="error-text" component="p" name="note" />
                </Col>
              </Row>

              <div className="mt-3" onClick={formik.handleSubmit}>
                <button type="button" className={`primary-btn-small record-btn mt-3`}>
                  Save
                </button>
              </div>
            </form>
          );
        }}
      </Formik>
    </CustomModal>
  );
};

export default EditModal;
