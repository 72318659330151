import DashboardComponent from 'components/dashboard';
import React from 'react';

const Dashboard = () => {
  return (
    <>
      <div className="system-audit-main">
        <div className="container-fluid">
          <div className="heading-path-otr">
            <h3 className="heading heading-h3">Home</h3>
          </div>
          <DashboardComponent />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
