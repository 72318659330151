import React, {useEffect, useState} from 'react';

import Tabs from 'components/common/tabs';
import PayMethod from 'components/backend-tables/pay-method';
import ProviderCategory from 'components/backend-tables/provider-category';
import ProviderTitle from 'components/backend-tables/provider-title';
import FacilityType from 'components/backend-tables/facility-type';
import PayrollGroups from 'components/backend-tables/payroll-groups';
import {useLocation} from 'react-router-dom';
import 'containers/backend-tables/backendTable.scss';

import {tabsList as list} from 'constants/backend-tables';
import BreadcrumbTrail from 'components/common/breadcrumb-trail';

const tabMaping = {
  0: <PayMethod />,
  1: <ProviderCategory />,
  2: <ProviderTitle />,
  3: <FacilityType />,
  4: <PayrollGroups />,
};

const BackendTables = () => {
  const {pathname} = useLocation();
  const [currentTab, setCurrentTab] = useState(list.findIndex(item => pathname.includes(item.path)));
  useEffect(() => {
    setCurrentTab(list.findIndex(item => pathname.includes(item.path)));
  }, [pathname]);
  const handleSetCurrentTab = item => {
    setCurrentTab(item.value);
  };
  const renderCurrentTab = () => {
    return tabMaping[currentTab];
  };
  return (
    <div className="backend-tables">
      <div className="container-fluid">
        <div className="heading-path-otr">
          <h3 className="heading heading-h3">{list[currentTab].title}</h3>
          <BreadcrumbTrail />
        </div>
        <Tabs list={list} handleClick={handleSetCurrentTab} activeTab={currentTab} />
        <>{renderCurrentTab()}</>
      </div>
    </div>
  );
};

export default BackendTables;
