import React from 'react';
import Input from 'components/common/input';
import SelectComponent from 'components/common/select';
import {ErrorMessage} from 'formik';
import {Row, Col} from 'react-bootstrap';
import {statusList, isProvider} from 'utils/common';
import {normalizeInput} from 'utils/common';
import StateField from 'components/common/state-field';
import ZipCodeField from 'components/common/zip-code-field';
import CityField from 'components/common/city-field';
import AsyncSelectComponent from 'components/common/async-select';

const Form = ({
  formik,
  providerTitles,
  users,
  loadUsersDropdownOptions,
  dropdownLoading,
  loadProvidersTitleDropdownOptions,
}) => {
  const isFormDisabled = isProvider();

  const setCompanyFieldValue = (formik, e) => {
    const {value} = e.target;
    // const [companyMailIdentifier] = value.split('@');
    formik.setFieldValue('company_email', value);
  };
  console.log('formik.touched', formik.touched);
  return (
    <form onSubmit={formik.handleSubmit} className={'w-100'}>
      <Row>
        <Col md={4}>
          <Input
            handleChange={e => {
              formik.setFieldTouched('npi_code');
              if (e.target.value.length <= 10) {
                formik.setFieldValue('npi_code', e.target.value);
              }
            }}
            name={'npi_code'}
            value={formik.values.npi_code}
            placeholder={'NPI Code'}
            label={'NPI Code'}
            type="number"
            disabled={isFormDisabled}
          />
          <ErrorMessage className="error-text" component="p" name="npi_code" />
        </Col>
        <Col md={8}>
          <div className=" input-otr" style={{alignItems: 'inherit'}}>
            <label htmlFor="email" className="input-label">
              Regional Director
            </label>
            <AsyncSelectComponent
              isLoading={dropdownLoading.users}
              selectedValue={formik.values.regional_directors_attributes}
              defaultOptions={users}
              loadOptions={loadUsersDropdownOptions}
              onChange={arr => {
                formik.setFieldValue('regional_directors_attributes', arr);
                formik.setFieldTouched('regional_directors_attributes');
              }}
              placeholder="Search and select"
              isDisabled={isFormDisabled}
              isMulti
            />
            <ErrorMessage className="error-text" component="p" name="regional_directors_attributes" />
          </div>
        </Col>

        <Col md={4}>
          <Input
            handleChange={e => {
              formik.setFieldValue('first_name', e.target.value);
              formik.setFieldTouched('first_name');
            }}
            name={'first_name'}
            value={formik.values.first_name}
            placeholder={'First Name'}
            label={'First Name'}
            disabled={isFormDisabled}
          />
          <ErrorMessage className="error-text" component="p" name="first_name" />
        </Col>
        <Col md={4}>
          <Input
            handleChange={e => {
              formik.setFieldValue('last_name', e.target.value);
              formik.setFieldTouched('last_name');
            }}
            name={'last_name'}
            value={formik.values.last_name}
            placeholder={'Last Name'}
            label={'Last Name'}
            disabled={isFormDisabled}
          />
          <ErrorMessage className="error-text" component="p" name="last_name" />
        </Col>
        <Col md={4}>
          <div className=" input-otr" style={{alignItems: 'inherit'}}>
            <label htmlFor="email" className="input-label">
              Provider Title
            </label>
            <AsyncSelectComponent
              isLoading={dropdownLoading.providerTitles}
              selectedValue={formik.values.provider_and_titles_attributes}
              defaultOptions={providerTitles}
              loadOptions={loadProvidersTitleDropdownOptions}
              onChange={obj => {
                formik.setFieldValue('provider_and_titles_attributes', obj);
                formik.setFieldTouched('provider_and_titles_attributes');
              }}
              placeholder="Search and select"
              isDisabled={isFormDisabled}
              isMulti
            />
            <ErrorMessage className="error-text" component="p" name="provider_title" />
          </div>
        </Col>
        {/* <Col md={4}>
          <div className=" input-otr" style={{alignItems: 'inherit'}}>
            <label htmlFor="email" className="input-label">
              Entity
            </label>
            <SelectComponent
              name="entity"
              options={entities}
              selectedValue={formik.values.entity.value}
              handleChange={obj => {
                formik.setFieldValue('entity', obj);
              }}
              disabled={isFormDisabled}
            />
            <ErrorMessage className="error-text" component="p" name="entity" />
          </div>
        </Col> */}
        <Col md={4}>
          <div className=" input-otr" style={{alignItems: 'inherit'}}>
            <label htmlFor="email" className="input-label">
              Employment Status
            </label>
            <SelectComponent
              name="status"
              options={statusList}
              selectedValue={formik.values.status}
              handleChange={obj => {
                formik.setFieldValue('status', obj.value);
                formik.setFieldTouched('status');
              }}
              isDisabled={isFormDisabled}
            />
            <ErrorMessage className="error-text" component="p" name="status" />
          </div>
        </Col>
        <Col md={4} className="position-relative">
          <Input
            handleChange={e => setCompanyFieldValue(formik, e)}
            name={'company_email'}
            value={formik.values.company_email}
            placeholder={'Company Email'}
            label={'Company Email'}
            // className="company-email"
          />
          <ErrorMessage className="error-text" component="p" name="company_email" />
        </Col>
        <Col md={4}>
          <Input
            handleChange={e => {
              formik.setFieldValue('personal_email', e.target.value);
              formik.setFieldTouched('personal_email');
            }}
            name={'personal_email'}
            value={formik.values.personal_email}
            placeholder={'Personal Email'}
            label={'Personal Email'}
            disabled={isFormDisabled}
          />
          <ErrorMessage className="error-text" component="p" name="personal_email" />
        </Col>
        <Col md={4}>
          <Input
            handleChange={e => {
              formik.setFieldValue('phone_no', normalizeInput(e.target.value, formik.values.phone_no));
              formik.setFieldTouched('phone_no');
            }}
            name={'phone_no'}
            value={normalizeInput(formik.values.phone_no, 0)}
            placeholder={'Phone Number'}
            label={'Phone Number'}
            type="text"
            disabled={isFormDisabled}
          />
          <ErrorMessage className="error-text" component="p" name="phone_no" />
        </Col>
        <Col md={4}>
          <Input
            handleChange={e => {
              formik.setFieldValue('street', e.target.value);
              formik.setFieldTouched('street');
            }}
            name={'street'}
            value={formik.values.street}
            placeholder={'e.g 100 Main Road'}
            label={'Street *'}
            disabled={isFormDisabled}
          />
          <ErrorMessage className="error-text" component="p" name="street" />
        </Col>
        <CityField
          name="city"
          value={formik.values.city}
          handleChange={e => {
            formik.setFieldValue('city', e.target.value);
            formik.setFieldTouched('city');
          }}
          isDisabled={isFormDisabled}
        />
        <StateField
          name="state"
          selectedValue={formik.values.state}
          setFieldValue={formik.setFieldValue}
          onBlur={formik.handleBlur}
          isDisabled={isFormDisabled}
        />
        <ZipCodeField
          name="zip_code"
          value={formik.values.zip_code}
          handleChange={e => {
            if (e.target.value.length <= 5) {
              formik.setFieldValue('zip_code', e.target.value);
            }
          }}
          // handleChange={e => {
          //   if (formik.values.zip_code.length > e.target.value.length) {
          //     formik.setFieldTouched('zip_code');

          //     formik.setFieldValue('zip_code', e.target.value);
          //   } else if (e.target.value.length == 5) {
          //     formik.setFieldTouched('zip_code');

          //     formik.setFieldValue('zip_code', e.target.value + '-');
          //   } else if (formik.values.zip_code.toString().length == 5) {
          //     formik.setFieldTouched('zip_code');
          //     formik.setFieldValue('zip_code', formik.values.zip_code + '-');
          //   } else if (e.target.value.length <= 10) {
          //     formik.setFieldTouched('zip_code');

          //     formik.setFieldValue('zip_code', e.target.value);
          //   }
          // }}
          onBlur={formik.handleBlur}
        />
      </Row>

      {isFormDisabled ? (
        <div />
      ) : (
        <button
          type="submit"
          className={`primary-btn-small record-btn mt-3 ${
            Object.keys(formik.touched || {})?.length <= 0 ? 'disabled' : ''
          }`}
        >
          Update
        </button>
      )}
    </form>
  );
};

export default Form;
