import React from 'react';

const Card = ({icon, title, subTitle}) => {
  return (
    <div className="dashbord-score-card">
      <div className="avatr">
        <img src={icon} width="50px" height="50px" alt="icon" className="icon" />
        <h3 className="heading heading-h3 ms-3">{title}</h3>
      </div>
      <p className="mt-3 heading-smb">{subTitle}</p>
    </div>
  );
};

export default Card;
