import CustomToast from 'components/common/custom-toast';

import {get, post, put, deleteApi} from 'services/network';
import {EMAIL_QUEUE_ENDPOINT, SEND_MAIL_ENDPOINT, EMAIL_TEMPLATES_ENDPOINT} from 'constants/endpoints';
import {alertTypes} from 'constants/common';
import {toast} from 'react-toastify';

export const getEmailQueueList = async obj => {
  const params = {
    page: obj?.page ? obj.page : 1,
    perPage: obj?.perPage ? obj.perPage : 10,
    search: obj?.search ? obj.search : '',
  };
  try {
    const result = await get(
      `${EMAIL_QUEUE_ENDPOINT}?page=${params.page}&per=${params.perPage}&query=${params.search}`,
      true,
    );
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const getSingleEmail = async id => {
  try {
    const result = await get(`${EMAIL_QUEUE_ENDPOINT}/${id}`, true);
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const sendMail = async (data, setErrors) => {
  try {
    const result = await post(SEND_MAIL_ENDPOINT, data, true);
    toast(<CustomToast variant={alertTypes.SUCCESS} message="Email sent successfully!" />);
    return result;
  } catch (error) {
    if (error?.response?.data) {
      setErrors(error?.response?.data.errors);
    }
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={error?.response?.data?.message || error?.response?.statusText}
      />,
    );
    console.log('error');
  }
};

export const getTemplatesList = async obj => {
  const params = {
    page: obj?.page ? obj.page : 1,
    perPage: obj?.perPage ? obj.perPage : 20,
    search: obj?.search ? obj.search : '',
  };

  try {
    const result = await get(
      `${EMAIL_TEMPLATES_ENDPOINT}?page=${params.page}&per=${params.perPage}&query=${params.search}`,
      true,
    );
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const createTemplate = async (data, setErrors) => {
  try {
    const result = await post(EMAIL_TEMPLATES_ENDPOINT, data, true);
    if (result.meta.status === 200)
      toast(<CustomToast variant={alertTypes.SUCCESS} message="Template created successfully!" />);
    else {
      toast(<CustomToast variant={alertTypes.DANGER} message={result?.meta?.status} />);
    }
    return result;
  } catch (error) {
    if (error?.response?.data) {
      setErrors(error?.response?.data.errors);
    }
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={error?.response?.data?.message || error?.response?.statusText}
      />,
    );
    console.log('error', error);
  }
};
export const updateTemplate = async (data, setErrors) => {
  const id = data.id;
  delete data.id;
  try {
    const result = await put(EMAIL_TEMPLATES_ENDPOINT + `/${id}`, data, true);
    if (result.meta.status === 200)
      toast(<CustomToast variant={alertTypes.SUCCESS} message="Template updated successfully!" />);
    else {
      toast(<CustomToast variant={alertTypes.DANGER} message={result?.meta?.status} />);
    }
    return result;
  } catch (error) {
    if (error?.response?.data) {
      setErrors(error?.response?.data.errors);
    }
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={error?.response?.data?.message || error?.response?.statusText}
      />,
    );
    console.log('error', error);
  }
};

export const deleteTemplate = async id => {
  try {
    const response = await deleteApi(`${EMAIL_TEMPLATES_ENDPOINT}/${id}/`, true);
    toast(<CustomToast variant={alertTypes.SUCCESS} message={response.message} />);
    return response;
  } catch (error) {
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={error?.response?.data?.message || error?.response?.statusText}
      />,
    );
    console.error(error);
  }
};
