import {post, deleteApi} from 'services/network';
import {PROVIDERS_FACILITIES_ENDPOINT, PROVIDER_RATES_ENDPOINT} from 'constants/endpoints';
import CustomToast from 'components/common/custom-toast';
import {alertTypes} from 'constants/common';
import {toast} from 'react-toastify';
export const createFacilityProvider = async (data, succesMsg) => {
  try {
    const result = await post(`${PROVIDERS_FACILITIES_ENDPOINT}`, data, true);
    toast(<CustomToast variant={alertTypes.SUCCESS} message={succesMsg} />);
    return result;
  } catch (error) {
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={error?.response?.data?.message || error?.response?.statusText}
      />,
    );
    console.log('error');
  }
};

export const deleteFacilityProvider = async (id, succesMsg) => {
  try {
    const result = await deleteApi(`${PROVIDERS_FACILITIES_ENDPOINT}/${id}`, true);
    toast(<CustomToast variant={alertTypes.SUCCESS} message={succesMsg} />);
    return result;
  } catch (error) {
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={error?.response?.data?.message || error?.response?.statusText}
      />,
    );
    console.log('error');
  }
};
export const createProviderRate = async (data, succesMsg) => {
  try {
    const result = await post(`${PROVIDER_RATES_ENDPOINT}`, data, true);
    toast(<CustomToast variant={alertTypes.SUCCESS} message={succesMsg} />);
    return result;
  } catch (error) {
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={error?.response?.data?.message || error?.response?.statusText}
      />,
    );
    console.log('error');
  }
};
export const deleteProviderRate = async (id, succesMsg) => {
  try {
    const result = await deleteApi(`${PROVIDER_RATES_ENDPOINT}/${id}`, true);
    toast(<CustomToast variant={alertTypes.SUCCESS} message={succesMsg} />);
    return result;
  } catch (error) {
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={error?.response?.data?.message || error?.response?.statusText}
      />,
    );
    console.log('error');
  }
};
