import React from 'react';
import {Accordion} from 'react-bootstrap';
import {ChartLine, Circle} from 'phosphor-react';
import {NavLink} from 'react-router-dom';
import {
  ACTIVITY_ANALYSIS_PAID_PATH,
  ACTIVITY_ANALYSIS_UNPAID_PATH,
  BILLER_ASSIGNMENT_PATH,
  FACILITY_ASSIGNMENT_PATH,
  FACILITY_UNITS_PATH,
  GROSS_BILLING_REPORT_PATH,
  PROVIDER_ASSIGNMENT_PATH,
  SERVICE_ANALYSIS_PATH,
  RAW_DATA_PATH,
  ADP_MISSING_PATH,
} from 'constants/routePaths';

const ReportsAccordion = () => (
  <Accordion.Item eventKey="2">
    <Accordion.Header>
      <ChartLine size={24} /> <span className="heading-smb">Reports</span>
    </Accordion.Header>
    <Accordion.Body>
      <ul className="menu-ul">
        {/* <li className="menu-li">
          <NavLink to={BILLER_ASSIGNMENT_PATH}>
            <p className="menu-a heading-smb">
              <Circle weight="fill" size={8} />
              Biller Assignment
            </p>
          </NavLink>
        </li> */}
        <li className="menu-li">
          <NavLink to={ADP_MISSING_PATH}>
            <p className="menu-a heading-smb">
              <Circle weight="fill" size={8} />
              ADP Missing
            </p>
          </NavLink>
        </li>
        {/* <li className="menu-li">
          <NavLink to={PROVIDER_ASSIGNMENT_PATH}>
            <p className="menu-a heading-smb">
              <Circle weight="fill" size={8} />
              Provider Assignment
            </p>
          </NavLink>
        </li>
        <li className="menu-li">
          <NavLink to={FACILITY_ASSIGNMENT_PATH}>
            <p className="menu-a heading-smb">
              <Circle weight="fill" size={8} />
              Facility Assignment
            </p>
          </NavLink>
        </li> */}
        <li className="menu-li">
          <NavLink to={ACTIVITY_ANALYSIS_UNPAID_PATH}>
            <p className="menu-a heading-smb">
              <Circle weight="fill" size={8} />
              Activity Analysis Unpaid
            </p>
          </NavLink>
        </li>
        <li className="menu-li">
          <NavLink to={ACTIVITY_ANALYSIS_PAID_PATH}>
            <p className="menu-a heading-smb">
              <Circle weight="fill" size={8} />
              Activity Analysis Paid
            </p>
          </NavLink>
        </li>
        {/* <li className="menu-li">
          <NavLink to={SERVICE_ANALYSIS_PATH}>
            <p className="menu-a heading-smb">
              <Circle weight="fill" size={8} />
              Service Analysis
            </p>
          </NavLink>
        </li>
        <li className="menu-li">
          <NavLink to={GROSS_BILLING_REPORT_PATH}>
            <p className="menu-a heading-smb">
              <Circle weight="fill" size={8} />
              Gross Billing Reports
            </p>
          </NavLink>
        </li>
        <li className="menu-li">
          <NavLink to={FACILITY_UNITS_PATH}>
            <p className="menu-a heading-smb">
              <Circle weight="fill" size={8} />
              Facility Units
            </p>
          </NavLink>
        </li> */}
        <li className="menu-li">
          <NavLink to={RAW_DATA_PATH}>
            <p className="menu-a heading-smb">
              <Circle weight="fill" size={8} />
              TSM Raw Data Reports
            </p>
          </NavLink>
        </li>
      </ul>
    </Accordion.Body>
  </Accordion.Item>
);

export default ReportsAccordion;
