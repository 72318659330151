import React from 'react';

import FilterWrapper from 'components/common/filter-wrapper';
import Filter from 'components/system-audit/Filter';
import 'containers/system-audit/system-audit.scss';
import RecordsListing from 'components/system-audit/RecordListing';
import BreadcrumbTrail from 'components/common/breadcrumb-trail';
import './system-audit.scss';
import {useState} from 'react';

const SystemAudit = () => {
  const [meta, setMeta] = useState({
    startDate: '',
    endDate: '',
    user: '',
  });
  const handleSetFilter = (startDate = '', endDate = '', user = '') => {
    setMeta({...meta, startDate, endDate, user});
  };
  return (
    <>
      <div className="system-audit-main">
        <div className="container-fluid">
          <div className="heading-path-otr">
            <h3 className="heading heading-h3">System Audit</h3>
            <BreadcrumbTrail />
          </div>
          {/* <TableComponent /> */}
          <FilterWrapper>
            <Filter handleSubmit={handleSetFilter} />
          </FilterWrapper>
          <RecordsListing filters={meta} />
        </div>
      </div>
    </>
  );
};

export default SystemAudit;
