import React from 'react';
import './toggle.scss';

function ToggleButton({label, handleChange, value, name, ...rest}) {
  return (
    <>
      <div className="form-check form-switch toggle-main">
        <input
          className="form-check-input"
          type="checkbox"
          role="switch"
          name={name}
          id={name}
          onChange={e => handleChange(name, e.target.checked ? 1 : 0)}
          checked={value === 1 ? true : false}
          {...rest}
        />
        <label className="form-check-label heading-sm" htmlFor={name}>
          {label}
        </label>
      </div>
    </>
  );
}

export default ToggleButton;
