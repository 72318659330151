import {roleTypes} from 'constants/common';
import * as yup from 'yup';

const userValidationSchema = {
  first_name: yup.string().required('Required *'),
  last_name: yup.string().required('Required *'),
  email: yup.string().email('Email must be a valid email *').required('Required *'),
  comment: yup.string(),
  role_id: yup.string().required('Required *'),
  status: yup.string().required('Required *'),
};

const passwordSchema = yup.string().required('Required *').min(6, 'Password must be at least 6 characters');

export const passwordValidationSchema = yup.object().shape({password: passwordSchema});

export const userValidationSchemaForCreate = yup.object().shape({
  ...userValidationSchema,
  password: passwordSchema,
});

export const userValidationSchemaForEdit = yup.object().shape(userValidationSchema);

export const userFormInitialValues = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  password_confirmation: '',
  comment: '',
  role_id: roleTypes.ADMINISTRATOR,
  status: 1,
};
