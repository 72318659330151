import Input from 'components/common/input';
import {ErrorMessage} from 'formik';
import {Col} from 'react-bootstrap';

const CityField = ({column = 4, name, value, handleChange, isDisabled = false, ...rest}) => {
  return (
    <Col md={column}>
      <Input
        type="text"
        value={value}
        label="City *"
        placeholder="e.g New York"
        isDisabled={isDisabled}
        handleChange={handleChange}
        name={name}
        {...rest}
      />
      <ErrorMessage className="error-text" component="p" name={name} />
    </Col>
  );
};

export default CityField;
