import React, {useEffect, useState} from 'react';
import SelectComponent from 'components/common/select';
import debounce from 'debounce-promise';

import {Row, Col} from 'react-bootstrap';
import {filterOptions} from 'constants/common';
import {DEBOUNCE_DELAY} from 'utils/common';
import {getEntitiesList} from 'containers/providers/api';
import AsyncSelectComponent from 'components/common/async-select';

const FacilityFilter = ({handleSubmit, filters}) => {
  const [filterState, setFilterState] = useState(filters);
  const [entities, setEntities] = useState([]);
  const [dropdownLoading, setDropdownLoading] = useState({
    entities: false,
  });

  async function fetchEntities() {
    const result = await getEntitiesList();
    if (result?.entities) {
      setEntities(result['entities']?.map(item => ({value: item.id, label: item.name})));
    }
  }

  const fetchEntitiessData = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, entities: true});
    try {
      const result = await getEntitiesList('', search);
      if (result['entities']) {
        const data = result?.entities.map(item => ({value: item.id, label: item.name}));
        setEntities(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, entities: false});
    }
  }, DEBOUNCE_DELAY);
  const loadEntitiesDropdownOptions = search => fetchEntitiessData(search);
  useEffect(() => {
    fetchEntities();
  }, []);

  return (
    <div className="providers-filter">
      <h5>Filters</h5>
      <Row>
        <Col md={6}>
          <p>Status</p>
          <SelectComponent
            options={filterOptions}
            selectedValue={filterState.status}
            handleChange={obj => {
              setFilterState(pre => ({...pre, status: obj.value}));
            }}
            placeholder="Select Status"
          />
        </Col>
        <Col md={6}>
          <p>Entity</p>
          <AsyncSelectComponent
            isLoading={dropdownLoading.entities}
            selectedValue={filterState.entity_id}
            defaultOptions={entities}
            loadOptions={loadEntitiesDropdownOptions}
            onChange={obj => {
              setFilterState(pre => ({...pre, entity_id: obj.value}));
            }}
            placeholder="Search and select"
          />
        </Col>
      </Row>
      <div className="filter-search-otr">
        {/* <SearchInput /> */}
        <div
          className={`action`}
          onClick={() => {
            handleSubmit(filterState);
          }}
        >
          <button className="primary-btn-small record-btn">Apply</button>
        </div>
      </div>
    </div>
  );
};

export default FacilityFilter;
