import * as yup from 'yup';
import moment from 'moment';

export const initialValues = {
  service_date: moment(new Date()).format('YYYY-MM-DD'),
  // effective_date: moment(new Date()).format('YYYY-MM-DD'),
  provider_id: '',
  facility_id: '',
  provider_data: [
    {
      earning_code_id: '',
      // patient_seen: '',
      note_id: '',
      serviced_unit: '',
      note: {message: ''},
      effective_date: '',
    },
  ],
};

export const validationSchemaForArray = yup.object().shape({
  // patient_seen: yup.number().required('Required *'),
  serviced_unit: yup.number().required('Required *'),
  earning_code_id: yup.string().required('Required *').typeError('Required *'),
  effective_date: yup.string().required('Required *'),
  // note: yup.object().shape({message: yup.string().required('Required *')}),
});
export const validationSchema = yup.object().shape({
  service_date: yup.date().required('Required *'),
  // effective_date: yup.date().required('Required *'),
  provider_id: yup.string().required('Required *'),
  provider_data: yup.array().of(validationSchemaForArray),
  facility_id: yup.string().required('Required *'),
});
export const classNameWithKeys = {
  adjustment_paid_provider_data: 'dollar-green text-red',
  adjustment_unpaid_provider_data: 'dollar-red text-red',
  adjustment_erroneous_provider_data: 'dollar-gray text-gray',
  paid_provider_data: 'dollar-green',
  unpaid_provider_data: 'dollar-red',
  erroneous_provider_data: 'dollar-gray',
};
export const matchingWithKeys = [
  'adjustment_paid_provider_data',
  'adjustment_unpaid_provider_data',
  'adjustment_erroneous_provider_data',
  'paid_provider_data',
  'unpaid_provider_data',
  'erroneous_provider_data',
];
