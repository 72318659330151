import React from 'react';
import {Accordion} from 'react-bootstrap';
import {FirstAidKit, Circle} from 'phosphor-react';
import {PROVIDERS_PATH, PROVIDER_ADJUSTMENTS_PATH, PROVIDER_RATES_PATH, CALENDAR_PATH} from 'constants/routePaths';
import {NavLink} from 'react-router-dom';

const ProviderAccordion = () => (
  <Accordion.Item eventKey="0">
    <Accordion.Header>
      <FirstAidKit size={24} /> <span className="heading-smb">Providers</span>
    </Accordion.Header>
    <Accordion.Body>
      <ul className="menu-ul">
        <li className="menu-li">
          <NavLink to={PROVIDERS_PATH}>
            <p className="menu-a heading-smb">
              <Circle weight="fill" size={8} />
              Providers
            </p>
          </NavLink>
        </li>
        <li className="menu-li">
          <NavLink to={PROVIDER_RATES_PATH}>
            <p className="menu-a heading-smb">
              <Circle weight="fill" size={8} />
              Provider Rates
            </p>
          </NavLink>
        </li>
        <li className="menu-li">
          <NavLink to={PROVIDER_ADJUSTMENTS_PATH}>
            <p className="menu-a heading-smb">
              <Circle weight="fill" size={8} />
              Session History
            </p>
          </NavLink>
        </li>
        <li className="menu-li">
          <NavLink to={CALENDAR_PATH}>
            <p className="menu-a heading-smb">
              <Circle weight="fill" size={8} />
              Calendar
            </p>
          </NavLink>
        </li>
      </ul>
    </Accordion.Body>
  </Accordion.Item>
);

export default ProviderAccordion;
