import React, {useEffect, useState} from 'react';
import SelectComponent from 'components/common/select';
import {Row, Col} from 'react-bootstrap';
import {statusOptions} from 'constants/common';
import {getProvidersList} from 'containers/providers/api';
import Input from 'components/common/input';
import moment from 'moment';
import CustomDatePicker from 'components/common/custom-datepicker';
import {PAID_TYPES} from 'constants/common';
import AsyncSelectComponent from 'components/common/async-select';
import {getEarningCodes} from 'containers/earning-codes/api';
import debounce from 'debounce-promise';
import {DEBOUNCE_DELAY} from 'utils/common';

const AdjustmentHistoryFilter = ({handleSubmit, startDate, endDate, changeFilter}) => {
  const [list, setList] = useState([]);
  const [selectedPaidStatus, setSelectedPaidStatus] = useState('all');
  const [selectedEarningCode, setSelectedEarningCode] = useState('');
  const [loadingEarningCodes, setLoadingEarningCodes] = useState(false);
  const [earningCodes, setEarningCodes] = useState([]);
  async function fetchEarningCodes() {
    try {
      const result = await getEarningCodes({page: 1, perPage: 10, status: 'active'});
      if (result['earning_codes']) {
        const codes = result?.earning_codes.map(item => ({value: item.id, label: item.code}));
        setEarningCodes(codes);
      }
    } catch (e) {
      console.log(e);
    } finally {
    }
  }
  useEffect(() => {
    const fetchUsers = async () => {
      setList(PAID_TYPES);
    };
    fetchUsers();
    fetchEarningCodes();
  }, []);

  const fetchEarningCodesData = debounce(async (search = '') => {
    setLoadingEarningCodes(true);
    try {
      const result = await getEarningCodes({page: 1, perPage: 10, search, status: 'active'});
      if (result['earning_codes']) {
        const data = result?.earning_codes.map(item => ({value: item.id, label: item.code}));
        setEarningCodes(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingEarningCodes(false);
    }
  }, DEBOUNCE_DELAY);

  const loadEarningCodesDropdownOptions = search => fetchEarningCodesData(search);

  const handleChangePaidStatus = item => {
    setSelectedPaidStatus(item.value);
    changeFilter(null, null, item.value, selectedEarningCode);
  };
  const handleChangeEarningCode = item => {
    setSelectedEarningCode(item.value);
    changeFilter(null, null, selectedPaidStatus, item.value);
  };
  return (
    <div className="providers-filter">
      <h5>Filters</h5>
      <Row>
        <Col sm={12} lg={3}>
          <p>Paid Status</p>
          <SelectComponent
            options={list}
            selectedValue={selectedPaidStatus}
            handleChange={handleChangePaidStatus}
            placeholder="Select "
          />
        </Col>
        <Col sm={12} lg={3}>
          <p>Earning Code</p>
          <AsyncSelectComponent
            isLoading={loadingEarningCodes}
            selectedValue={selectedEarningCode}
            defaultOptions={earningCodes}
            loadOptions={loadEarningCodesDropdownOptions}
            onChange={handleChangeEarningCode}
            placeholder="Search and select"
          />
        </Col>
        <Col sm={6} lg={3} style={{marginTop: '-9px'}}>
          <CustomDatePicker
            name="start_date"
            label="From"
            selected={startDate ? new Date(moment(startDate)) : ''}
            onDateChange={date => changeFilter(date, null, selectedPaidStatus, selectedEarningCode)}
          />
        </Col>
        <Col sm={6} lg={3} style={{marginTop: '-9px'}}>
          <CustomDatePicker
            name="end_date"
            label="To"
            selected={endDate ? new Date(moment(endDate)) : ''}
            onDateChange={date => changeFilter(null, date, selectedPaidStatus, selectedEarningCode)}
          />
        </Col>
      </Row>
      <div className="filter-search-otr">
        <div className={`action`} onClick={handleSubmit}>
          <p className="primary-btn-small record-btn">Apply</p>
        </div>
      </div>
    </div>
  );
};

export default AdjustmentHistoryFilter;
