import React from 'react';
import {Accordion} from 'react-bootstrap';
import {EnvelopeSimple, Circle} from 'phosphor-react';
import {NavLink} from 'react-router-dom';
import {SEND_MAIL_PATH, TEMPLATES_MAIL_PATH, EMAIL_QUEUE_MAIL_PATH} from 'constants/routePaths';

const MailAccordion = () => (
  <Accordion.Item eventKey="3">
    <Accordion.Header>
      <EnvelopeSimple size={24} /> <span className="heading-smb">Mail</span>
    </Accordion.Header>
    <Accordion.Body>
      <ul className="menu-ul">
        <li className="menu-li">
          <NavLink to={SEND_MAIL_PATH}>
            <p className="menu-a heading-smb">
              <Circle weight="fill" size={8} />
              Send Mail
            </p>
          </NavLink>
        </li>
        <li className="menu-li">
          <NavLink to={TEMPLATES_MAIL_PATH}>
            <p className="menu-a heading-smb">
              <Circle weight="fill" size={8} />
              Templates
            </p>
          </NavLink>
        </li>
        <li className="menu-li">
          <NavLink to={EMAIL_QUEUE_MAIL_PATH}>
            <p className="menu-a heading-smb">
              <Circle weight="fill" size={8} />
              Email Queue
            </p>
          </NavLink>
        </li>
      </ul>
    </Accordion.Body>
  </Accordion.Item>
);

export default MailAccordion;
