import React, {useState, useEffect} from 'react';
import ProviderRatesForm from 'components/provider-rates/provider-rates-form';
import debounce from 'debounce-promise';

import 'components/facilities/facilities.scss';

import {Formik} from 'formik';
import {
  createProviderRatesInitialValues as initialValues,
  ProviderRatesValidationSchema as validationSchema,
} from 'constants/provider-rates';
import {useLocation, useNavigate} from 'react-router-dom';
import {createProviderRates} from './api';
import {PROVIDER_RATES_PATH} from 'constants/routePaths';
import {getProvidersList} from 'containers/providers/api';
import {getEarningCodes} from 'containers/earning-codes/api';

import {Spinner} from 'react-bootstrap';
import BreadcrumbTrail from 'components/common/breadcrumb-trail';
import {DEBOUNCE_DELAY} from 'utils/common';
import moment from 'moment';

const AddProviderRates = ({loading}) => {
  const {state} = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [providersList, setProvidersList] = useState([]);
  const [earningCodes, setEarningCodes] = useState([]);
  const [earningCodesNames, setEarningCodesNames] = useState([]);
  const [dropdownLoading, setDropdownLoading] = useState({
    providerName: false,
    earningName: false,
    earningCode: false,
  });

  const navigate = useNavigate();
  const handleCreateProviderRatesHandler = async values => {
    const data = {
      provider_rate: {
        ...values,
        active: values.active ? true : false,
      },
    };
    try {
      setIsLoading(true);
      const result = await createProviderRates(data);
      if (result.meta.status === 200) {
        setIsLoading(false);
        navigate(-1);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateProviderRates = values => {
    handleCreateProviderRatesHandler(values);
  };

  async function fetchProvides() {
    try {
      const result = await getProvidersList({page: 1, perPage: 10, status: 'active'});
      if (result['providers']) {
        const data = result?.providers.map(item => ({value: item.id, label: item.name}));
        setProvidersList(data);
      }
    } catch (e) {
      console.log(e);
    } finally {
    }
  }

  async function fetchEarningCodes() {
    try {
      const result = await getEarningCodes({page: 1, perPage: 10, status: 'active'});
      if (result['earning_codes']) {
        const names = result?.earning_codes.map(item => ({value: item.id, label: item.name}));
        const codes = result?.earning_codes.map(item => ({value: item.id, label: item.code}));
        setEarningCodes(codes);
        setEarningCodesNames(names);
      }
    } catch (e) {
      console.log(e);
    } finally {
    }
  }

  const fetchProvidersData = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, providerName: true});
    try {
      const result = await getProvidersList({page: 1, perPage: 10, status: 'active', search});
      if (result['providers']) {
        const data = result?.providers.map(item => ({value: item.id, label: item.name}));
        setProvidersList(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, providerName: false});
    }
  }, DEBOUNCE_DELAY);

  const fetchEarningNamesData = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, earningName: true});
    try {
      const result = await getEarningCodes({page: 1, perPage: 10, status: 'active', search});
      if (result['earning_codes']) {
        const data = result?.earning_codes.map(item => ({value: item.id, label: item.name}));
        setEarningCodesNames(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, earningName: false});
    }
  }, DEBOUNCE_DELAY);

  const fetchEarningCodesData = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, earningCode: true});
    try {
      const result = await getEarningCodes({page: 1, perPage: 10, status: 'active', search});
      if (result['earning_codes']) {
        const data = result?.earning_codes.map(item => ({value: item.id, label: item.code}));
        const dataForName = result?.earning_codes.map(item => ({value: item.id, label: item.name}));
        setEarningCodesNames(dataForName);
        setEarningCodes(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, earningCode: false});
    }
  }, DEBOUNCE_DELAY);

  const loadProviderDropdownOptions = search => fetchProvidersData(search);
  const loadEarningNamesDropdownOptions = search => fetchEarningNamesData(search);
  const loadEarningCodesDropdownOptions = search => fetchEarningCodesData(search);

  useEffect(() => {
    fetchProvides();
    fetchEarningCodes();
  }, []);

  return (
    <div className="container-fluid">
      <div className="heading-path-otr">
        <h3 className="heading heading-h3">Provider Rates</h3>
        <BreadcrumbTrail />
      </div>
      <div className="card-wrapper facility-padding">
        <Formik
          initialValues={
            state?.provider_id
              ? {
                  ...initialValues,
                  provider_id: state.provider_id,
                  // effective_date: moment().format('YYYY-MM-DD'),
                }
              : {...initialValues}
          }
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={values => {
            handleCreateProviderRates(values);
          }}
        >
          {formik => {
            return (
              <>
                <ProviderRatesForm
                  formik={formik}
                  providers={providersList}
                  earningCodesNames={earningCodesNames}
                  earningCodes={earningCodes}
                  dropdownLoading={dropdownLoading}
                  loadProviderDropdownOptions={loadProviderDropdownOptions}
                  loadEarningNamesDropdownOptions={loadEarningNamesDropdownOptions}
                  loadEarningCodesDropdownOptions={loadEarningCodesDropdownOptions}
                  isCreating
                />

                {loading ? (
                  <button className={`primary-btn-small record-btn mt-3`}>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                  </button>
                ) : (
                  <button
                    type="button"
                    className={`primary-btn-small record-btn mt-3 `}
                    onClick={formik.handleSubmit}
                    disabled={isLoading}
                  >
                    Save
                  </button>
                )}
              </>
            );
          }}
        </Formik>
      </div>
    </div>
    // </CustomModal>
  );
};

export default AddProviderRates;
