import {LOADING, LOGIN_USER} from 'store/action-types/login';
import {getAuthToken} from 'containers/login/api';
import {toast} from 'react-toastify';
import {alertTypes} from 'constants/common';
import CustomToast from 'components/common/custom-toast';

export const loginAction = (data, setErrors, setSubmitting) => async dispatch => {
  setSubmitting(true);
  dispatch({type: LOADING, payload: true});
  try {
    const response = await getAuthToken(data);
    if (response?.status_code === 200) {
      toast(
        <CustomToast
          variant={alertTypes.SUCCESS}
          message={response?.message ? response.message : 'Login successfully!'}
        />,
      );
    } else {
      toast(<CustomToast variant={alertTypes.DANGER} message={'Invalid Credentials'} />);
    }
    dispatch({type: LOGIN_USER, payload: response});
    return response;
  } catch (error) {
    if (error?.response?.data) {
      setErrors(error?.response?.data.errors);
    }
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={error?.response?.data?.message || error?.response?.statusText}
      />,
    );
    console.error(error);
  } finally {
    dispatch({type: LOADING, payload: false});
    setSubmitting(false);
  }
};
