import React, {useEffect, useState} from 'react';
import {ErrorMessage, Formik} from 'formik';
import Input from 'components/common/input';
import Radio from 'components/common/radio';
import {useNavigate, useParams} from 'react-router-dom';
import Toggle from 'components/common/togglebutton';
import {getSingleUser, createUser, updateUser} from 'containers/users/api';
import {USERS_PATH} from 'constants/routePaths';
import BreadcrumbTrail from 'components/common/breadcrumb-trail';
import {userValidationSchemaForCreate, userValidationSchemaForEdit, userFormInitialValues} from 'constants/users';
import {roleNamesByType, roleTypes} from 'constants/common';
import {map} from 'lodash';
import {isAdministrator} from 'utils/common';
import {Col, Row} from 'react-bootstrap';
import ChangePasswordModal from 'components/users/ChangePasswordModal';

const UserForm = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isPasswordModalVisibile, setPasswordModalVisibility] = useState(false);
  const [initialValues, setInitialValues] = useState(userFormInitialValues);

  useEffect(() => {
    if (!isAdministrator()) {
      navigate(USERS_PATH);
    }

    if (params?.id) {
      const fetchEditUser = async id => {
        setLoading(true);
        const result = await getSingleUser(id);
        if (result?.user) {
          result.user.status = result.user.active ? 1 : 0;
          setInitialValues(result.user);
        }
        setLoading(false);
      };
      fetchEditUser(params?.id);
    }
  }, []);

  const submitHandler = async (values, setErrors) => {
    values.password_confirmation = values.password;

    if (params?.id) {
      delete values.id;
      delete values.active;
      delete values.created_at;
      delete values.created_by;
      delete values.updated_at;
      delete values.updated_by;
      delete values.last_login;
      delete values.full_name;

      await updateUser(params?.id, values, setErrors);
      navigate(USERS_PATH);
    } else {
      await createUser(values, setErrors);
      navigate(USERS_PATH);
    }
  };

  const togglePasswordModal = () => {
    setPasswordModalVisibility(!isPasswordModalVisibile);
  };

  const userFlow = params?.id ? 'Update' : 'Create';
  const colSize = params?.id ? 4 : 3;

  return (
    <>
      {loading ? (
        <p style={{textAlign: 'center'}}>Loading</p>
      ) : (
        <div className="user-main">
          <div className="container-fluid">
            <div className="heading-path-otr">
              <h3 className="heading heading-h3">{userFlow}</h3>
              <BreadcrumbTrail />
            </div>

            <div className="table-main">
              <div className="table-head-content">
                <Formik
                  initialValues={initialValues}
                  enableReinitialize={true}
                  validationSchema={params?.id ? userValidationSchemaForEdit : userValidationSchemaForCreate}
                  onSubmit={(values, {setErrors}) => submitHandler(values, setErrors)}
                >
                  {({values, errors, touched, handleChange, setFieldValue, handleSubmit, setErrors}) => {
                    return (
                      <form onSubmit={handleSubmit} className={'w-100'}>
                        <h3 className="heading heading-h3 mb-3">User</h3>
                        <Toggle label="Active User" name="status" value={values.status} handleChange={setFieldValue} />
                        {errors.status && touched.status && errors.status}
                        <Row>
                          <Col lg={colSize} md={12}>
                            <div className="mb-2">
                              <Input
                                handleChange={handleChange}
                                name={'first_name'}
                                value={values.first_name}
                                placeholder={'First Name'}
                                label={'First Name'}
                              />
                              <ErrorMessage className="error-text" component="p" name="first_name" />
                            </div>
                          </Col>
                          <Col lg={colSize} md={12}>
                            <div className="mb-2">
                              <Input
                                handleChange={handleChange}
                                name={'last_name'}
                                value={values.last_name}
                                placeholder={'Last Name'}
                                label={'Last Name'}
                              />
                              <ErrorMessage className="error-text" component="p" name="last_name" />
                            </div>
                          </Col>
                          <Col lg={colSize} md={12}>
                            <div className="mb-2">
                              <Input
                                handleChange={handleChange}
                                name={'email'}
                                value={values.email}
                                placeholder={'Email'}
                                label={'Email'}
                              />
                              <ErrorMessage className="error-text" component="p" name="email" />
                            </div>
                          </Col>
                          {!params?.id && (
                            <Col lg={colSize} md={4}>
                              <div className="mb-2">
                                <Input
                                  handleChange={handleChange}
                                  type={'password'}
                                  name={'password'}
                                  value={values.password}
                                  placeholder={'Password'}
                                  label={'Password'}
                                />
                                <ErrorMessage className="error-text" component="p" name="password" />
                              </div>
                            </Col>
                          )}
                          <Col md={12}>
                            <div className="mb-1 input-label">Roles:</div>
                            <Row>
                              {map(roleTypes, (role, ind) => (
                                <Col md={4} className="mb-2" key={ind}>
                                  <Radio
                                    key={ind}
                                    handleChange={setFieldValue}
                                    label={roleNamesByType[role]}
                                    value={values.role_id}
                                    fieldValue={role}
                                    name="role_id"
                                  />
                                </Col>
                              ))}
                            </Row>
                          </Col>
                          <Col md={12}>
                            <div className="mb-4">
                              <Input
                                handleChange={handleChange}
                                placeholder="Comment"
                                type="text"
                                label="Comment"
                                value={values.comment}
                                name="comment"
                              />
                              <ErrorMessage className="error-text" component="p" name="comment" />
                            </div>
                          </Col>
                          <Col md={12}>
                            <Row>
                              <Col md={12} lg={5} className="p-0">
                                <button type="submit" className="ms-3 my-2 primary-btn-small record-btn">
                                  {userFlow} User
                                </button>
                                {params?.id && (
                                  <>
                                    <button
                                      onClick={togglePasswordModal}
                                      type="button"
                                      className="ms-3 my-2 secondary-btn-small record-btn"
                                    >
                                      Change Password
                                    </button>
                                    <ChangePasswordModal
                                      open={isPasswordModalVisibile}
                                      closeModalHandler={togglePasswordModal}
                                      updatePasswordHandler={submitHandler}
                                      setErrors={setErrors}
                                    />
                                  </>
                                )}
                              </Col>
                              {params?.id && (
                                <Col md={12} lg={7} className="text-start text-sm-end fw-light">
                                  <p className="page-linkk">
                                    Created by {values.update_by || '_'} on {values.created_at}{' '}
                                  </p>
                                  <p className="page-linkk">Last Login on {values.last_login}</p>
                                </Col>
                              )}
                            </Row>
                          </Col>
                        </Row>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserForm;
