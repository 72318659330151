import React, {useState, useEffect} from 'react';

import BreadcrumbTrail from 'components/common/breadcrumb-trail';
import Tabs from 'components/common/tabs';
import ProviderInformation from './tabs/ProviderInformation';
import PayrollInformation from './tabs/PayrollInformation';

import {tabsList as list} from 'constants/providers';
import {getEditProvider} from 'containers/providers/api';
import {useNavigate, useParams} from 'react-router-dom';
import AssignedFacilities from './tabs/AssignedFacilities';
import ProviderRate from './tabs/ProviderRates';
import ProviderCalender from './tabs/ProviderCalender';
import {PROVIDER_TABS} from 'constants/routePaths';
import {isProvider, useQuery} from 'utils/common';
import AdjustmentHistory from './tabs/AdjustmentHistory';
import {isEmpty} from 'lodash';

const EditProvider = () => {
  const {id} = useParams();
  const query = useQuery();
  const tab = query.get('tab');
  const navigate = useNavigate();
  const [provider, setProvider] = useState(null);

  const fetchEditUser = async id => {
    const result = await getEditProvider(id);
    if (result?.provider) {
      setProvider(result?.provider);
    }
  };

  useEffect(() => {
    fetchEditUser(id);
  }, []);
  useEffect(() => {
    if (!tab) {
      navigate(`/providers/${id}/edit?tab=provider-information`);
    }
  }, [tab]);
  console.log('tab is ', tab);
  const tabMapping = {
    [list[PROVIDER_TABS.INFORMATION].value]: <ProviderInformation provider={provider} />,
    [list[PROVIDER_TABS.PAYROLL].value]: <PayrollInformation provider={provider} />,
    [list[PROVIDER_TABS.RATES].value]: <ProviderRate />,
    [list[PROVIDER_TABS.FACILITIES].value]: <AssignedFacilities />,
    [list[PROVIDER_TABS.ADJUSTMENT].value]: <AdjustmentHistory />,
    [list[PROVIDER_TABS.CALENDAR].value]: <ProviderCalender />,
  };
  const handleSetCurrentTab = item => {
    navigate(`/providers/${id}/edit?tab=${item.value}`);
  };
  const renderCurrentTab = () => {
    return tabMapping[tab];
  };

  return (
    <div className="backend-tables">
      <div className="container-fluid">
        <div className="heading-path-otr">
          <h3 className="heading heading-h3">
            {!isEmpty(provider)
              ? `${provider?.first_name} ${provider?.last_name}, ${provider?.provider_title} | `
              : '(- -)'}
            {list[tab]?.title}
          </h3>
          {!isProvider() ? <div /> : <BreadcrumbTrail />}
        </div>
        {isProvider() ? <div /> : <Tabs list={Object.values(list)} handleClick={handleSetCurrentTab} activeTab={tab} />}
        <>{renderCurrentTab()}</>
      </div>
    </div>
  );
};

export default EditProvider;
