import React, {useState} from 'react';
import Tabs from 'components/common/tabs';

import BreadcrumbTrail from 'components/common/breadcrumb-trail';
import RecordsListing from 'components/payroll-period/RecordsListing';
import {useNavigate, useParams, useLocation} from 'react-router-dom';

import {PAYROLL_PERIODS_TABS} from 'constants/routePaths';

const tabsList = {
  [PAYROLL_PERIODS_TABS.OPEN]: {
    title: 'Open Periods',
    value: 'open',
  },
  [PAYROLL_PERIODS_TABS.CLOSE]: {
    title: 'Closed Periods',
    value: 'close',
  },
  [PAYROLL_PERIODS_TABS.FUTURE]: {
    title: 'Future Periods',
    value: 'future',
  },
};

const PayrollPeriods = () => {
  const [tab, setTab] = useState(PAYROLL_PERIODS_TABS.OPEN);

  const handleSetCurrentTab = item => {
    setTab(item.value);
  };
  return (
    <>
      <div className="payroll-period">
        <div className="container-fluid">
          <div className="heading-path-otr">
            <h3 className="heading heading-h3">Payroll Periods</h3>
            <BreadcrumbTrail />
          </div>
          <Tabs list={Object.values(tabsList)} handleClick={handleSetCurrentTab} activeTab={tab} />
          <RecordsListing tab={tab} />
        </div>
      </div>
    </>
  );
};

export default PayrollPeriods;
