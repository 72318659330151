import React, {useEffect, useRef, useState} from 'react';

import TableWraper from 'components/common/table-wrapper';

import {getActionButtonProps, isAdministrator, isPayRoll} from 'utils/common';
import {ArrowsDownUp, PencilSimple, Trash} from 'phosphor-react';
import {DEBOUNCE_DELAY} from 'utils/common';
import {alertTypes, initialMetaForTable, orderStratergy} from 'constants/common';
import {EARNING_CODES_CREATE_PATH, EARNING_CODES_PATH, PROVIDERS_PATH} from 'constants/routePaths';
import {useNavigate, useLocation} from 'react-router-dom';
import {deactivateEarningCode, getEarningCodes} from 'containers/earning-codes/api';
import {startCase} from 'lodash';
import {CSVLink} from 'react-csv';
import {toast} from 'react-toastify';
import CustomToast from 'components/common/custom-toast';

let timeout;
const headers = [
  {label: 'Earning Code', key: 'code'},
  {label: 'Earning Name', key: 'name'},
  {label: 'Billable', key: 'billable'},
  {label: 'Requires Medicaid', key: 'required_medical_patient'},
  {label: 'Requires Patient', key: 'required_patient'},
];

const EarningCodesListing = () => {
  const exportRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const routerStateMeta = location.state?.meta || initialMetaForTable;
  const [allListSelected, setAllListSelected] = useState(false);
  const [providersList, setProvidersList] = useState([]);
  const [refreshPage, setRefreshPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState({...initialMetaForTable, page: routerStateMeta.page});
  const [totalCount, setTotalCount] = useState(0);
  const [csvData, setCsvData] = useState([]);

  async function fetchData() {
    try {
      setLoading(true);
      const result = await getEarningCodes({
        ...meta,
        status: 'active',
      });
      if (result['earning_codes']) {
        const data = result?.earning_codes.map(item => ({...item, isSelected: allListSelected}));
        setProvidersList(data);
        setTotalCount(result?.meta.total_count);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage]);
  useEffect(() => {
    setCsvData(providersList.filter(item => item.isSelected));
  }, [providersList]);

  const handleRefreshPage = () => {
    setRefreshPage(pre => !pre);
  };
  const handleSetPerPage = obj => {
    setMeta(pre => ({...pre, perPage: obj.value}));
    handleRefreshPage();
  };
  const debounceFn = (callback, delay) => {
    clearTimeout(timeout);
    timeout = setTimeout(callback, delay);
  };
  const handleSetSearchQuery = value => {
    setMeta(pre => ({...pre, search: value}));
    debounceFn(handleRefreshPage, DEBOUNCE_DELAY);
  };
  const handlePageChange = value => {
    setMeta(pre => ({...pre, page: value}));
    handleRefreshPage();
  };

  const setAllListCheck = value => {
    setProvidersList(pre => pre.map(item => ({...item, isSelected: value})));
  };
  const setSingleItemCheck = (value, id) => {
    setProvidersList(pre => pre.map(item => (item.id === id ? {...item, isSelected: value} : item)));
  };
  const handleCreateEarningCode = () => {
    navigate(EARNING_CODES_CREATE_PATH);
  };
  const deleteSingleEarningCode = async id => {
    const result = await deactivateEarningCode(id);
    if (result?.status === 200) {
      handleRefreshPage();
    }
  };
  const handleExport = done => {
    exportRef.current.link.click();
    if (csvData.length) {
      done(true);
    } else {
      toast(<CustomToast variant={alertTypes.DANGER} message={'Please Select Record!'} />);
      done(false);
    }
  };
  const sortedDataByColumn = columnName => {
    if (providersList.length > 0) {
      setMeta(pre => ({...pre, sort: columnName, order: orderStratergy[pre.order]}));
      handleRefreshPage();
    }
  };
  return (
    <>
      <TableWraper
        setPerPage={handleSetPerPage}
        setSearchQuery={handleSetSearchQuery}
        searchValue={meta.search}
        totalListCount={totalCount}
        pageSize={meta.perPage}
        currentPage={meta.page}
        onPageChange={handlePageChange}
        actionButtons={
          (isAdministrator() || isPayRoll()) && [
            ...getActionButtonProps('Create Earning Code', handleCreateEarningCode),
            ...getActionButtonProps('Export', () => {
              exportRef.current.link.click();
            }),
          ]
        }
      >
        {loading ? (
          <h3 style={{textAlign: 'center'}}>Loading</h3>
        ) : (
          <table className="table">
            <thead>
              <tr>
                {/* <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">
                      <input
                        className="form-check-input"
                        onChange={e => {
                          setAllListSelected(e.target.checked);
                          setAllListCheck(e.target.checked);
                        }}
                        type="checkbox"
                        checked={allListSelected}
                      />
                    </p>
                  </div>
                </th> */}
                <th scope="col">
                  <div className="header-text-otr">
                    <p
                      className="table-name heading-xsb cursor-pointer c-hand"
                      onClick={() => sortedDataByColumn('code')}
                    >
                      Earning Code 
                    </p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p
                      className="table-name heading-xsb cursor-pointer c-hand"
                      onClick={() => sortedDataByColumn('name')}
                    >
                      Earning Name 
                    </p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Billable</p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Requires Medicaid</p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Requires Patient</p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Status</p>
                  </div>
                </th>
                {(isAdministrator() || isPayRoll()) && (
                  <th scope="col" className="action-column">
                    <div className="header-text-otr">
                      <p className="table-name heading-xsb">Actions</p>
                    </div>
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {providersList.map(item => {
                return (
                  <tr key={item.id}>
                    {/* <th scope="col">
                      <div className="header-text-otr">
                        <p className="table-name heading-xsb">
                          <input
                            className="form-check-input"
                            value={item.isSelected}
                            onChange={e => {
                              setSingleItemCheck(e.target.checked, item.id);
                            }}
                            type="checkbox"
                            checked={item.isSelected}
                          />
                        </p>
                      </div>
                    </th> */}

                    <th scope="col">
                      <div className="table-text-otr">
                        <p className="table-text ">{item?.code || ''}</p>
                      </div>
                    </th>
                    <td>
                      <div className="table-text-otr w-75">
                        <p className="table-text w-100">{item?.name || ''}</p>
                      </div>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <p className={`table-text heading-xsb ${item?.billable ? 'active' : 'in-active'}`}>
                          {startCase(item.billable ? 'true' : 'false')}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <p
                          className={`table-text heading-xsb ${
                            item?.required_medical_patient ? 'active' : 'in-active'
                          }`}
                        >
                          {startCase(item.required_medical_patient ? 'true' : 'false')}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <p className={`table-text heading-xsb ${item?.required_patient ? 'active' : 'in-active'}`}>
                          {startCase(item.required_patient ? 'true' : 'false')}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <p className={`table-text heading-xsb ${item?.status === 'active' ? 'active' : 'in-active'}`}>
                          {startCase(item.status)}
                        </p>
                      </div>
                    </td>
                    {(isAdministrator() || isPayRoll()) && (
                      <td className="action-column">
                        <div className="table-icon-otr">
                          <div className="icon-otr">
                            <PencilSimple
                              size={24}
                              onClick={() => {
                                navigate(EARNING_CODES_PATH + `/${item.id}/edit`, {
                                  state: {meta},
                                });
                              }}
                            />
                          </div>
                          <div className="icon-otr" onClick={() => deleteSingleEarningCode(item.id)}>
                            <Trash size={24} />
                          </div>
                        </div>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </TableWraper>
      <CSVLink
        ref={exportRef}
        data={csvData}
        headers={headers}
        asyncOnClick={true}
        onClick={(event, done) => {
          handleExport(done);
        }}
        filename={'my-file.csv'}
        className="btn btn-primary opacity-0 w-0 h-0 position-absolute top-0"
        target="_blank"
      />
    </>
  );
};

export default EarningCodesListing;
