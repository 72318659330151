import React, {useState, useEffect} from 'react';
import 'components/facilities/facilities.scss';

import {Formik} from 'formik';
import {
  createProviderRatesInitialValues as initial,
  ProviderRatesValidationSchema as validationSchema,
} from 'constants/provider-rates';
import {Navigate, useNavigate, useParams} from 'react-router-dom';
import 'components/facilities/facilities.scss';
import {getProvidersList} from 'containers/providers/api';
import {getEarningCodes} from 'containers/earning-codes/api';
import {getSingleProviderRate, updateProviderRate} from './api';

import {Spinner} from 'react-bootstrap';

import ProviderRatesForm from 'components/provider-rates/provider-rates-form';
import BreadcrumbTrail from 'components/common/breadcrumb-trail';
import {appendObjectOnEditDropdown} from 'utils/common';
import {DEBOUNCE_DELAY} from 'utils/common';
import debounce from 'debounce-promise';
import moment from 'moment';

const EditProviderRates = () => {
  const Navigate = useNavigate();
  const [providerRate, setProviderRate] = useState({});
  const [initialValues, setInitialValues] = useState(initial);
  const [isLoading, setIsLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(true);
  const {id} = useParams();
  const [providersList, setProvidersList] = useState([]);
  const [refetch, setRefetch] = useState(true);
  const [earningCodes, setEarningCodes] = useState([]);
  const [earningCodesNames, setEarningCodesNames] = useState([]);
  const [dropdownLoading, setDropdownLoading] = useState({
    providerName: false,
    earningName: false,
    earningCode: false,
  });

  const fetchProviderRate = async id => {
    try {
      const result = await getSingleProviderRate(id);

      setProviderRate(result.provider_rate);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (Object.keys(providerRate).length > 0) {
      setInitialValues({
        ...initialValues,

        active: providerRate?.active === true ? 1 : 0,
        provider_id: providerRate?.provider?.id || '',
        earning_code_id: providerRate?.earning_code?.id || '',
        initial_unit_charge_amount: providerRate?.initial_unit_charge_amount || '',
        unit_cov_by_initial_charge: providerRate?.unit_cov_by_initial_charge || '',
        unit_multiplier: providerRate?.unit_multiplier || '',
        effective_date: providerRate?.effective_date || moment(new Date()).format('YYYY-MM-DD'),
      });
      fetchProvides();
      fetchEarningCodes();
      setFormLoading(false);
    }
  }, [providerRate]);

  const providerRateUpdateHandler = async values => {
    const data = {
      provider_rate: {
        ...values,
        active: values.active ? true : false,
      },
    };
    try {
      setIsLoading(true);
      const result = await updateProviderRate(data, id);
      if (result.meta.status === 200) {
        setIsLoading(false);
        Navigate(-1);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const providerRateHandler = values => {
    providerRateUpdateHandler(values);
  };

  async function fetchEarningCodes() {
    try {
      const result = await getEarningCodes({page: 1, perPage: 10, status: 'active'});
      if (result['earning_codes']) {
        const names = result?.earning_codes.map(item => ({value: item.id, label: item.name}));
        const codes = result?.earning_codes.map(item => ({value: item.id, label: item.code}));
        setEarningCodes(
          Object.keys(providerRate).length > 0
            ? appendObjectOnEditDropdown(codes, {
                value: providerRate?.earning_code?.id,
                label: providerRate?.earning_code?.code,
              })
            : codes,
        );
        setEarningCodesNames(
          Object.keys(providerRate).length > 0
            ? appendObjectOnEditDropdown(names, {
                value: providerRate?.earning_code?.id,
                label: providerRate?.earning_code?.name,
              })
            : names,
        );
      }
    } catch (e) {
      console.log(e);
    } finally {
    }
  }

  useEffect(() => {
    fetchProviderRate(id);
  }, []);

  // useEffect(() => {
  //   if (refetch) {
  //     fetchProviderRate(id);

  //     setRefetch(false);
  //   }
  // }, [refetch]);

  // eslint-disable-next-line react-hooks/exhaustive-deps

  async function fetchProvides() {
    try {
      const result = await getProvidersList({page: 1, perPage: 10, status: 'active'});
      if (result['providers']) {
        const data = result?.providers.map(item => ({value: item.id, label: item.name}));
        setProvidersList(
          Object.keys(providerRate).length > 0
            ? appendObjectOnEditDropdown(data, {
                value: providerRate?.provider?.id,
                label: providerRate?.provider?.first_name + ' ' + providerRate?.provider?.last_name,
              })
            : data,
        );
      }
    } catch (e) {
      console.log(e);
    } finally {
    }
  }

  const fetchProvidersData = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, providerName: true});
    try {
      const result = await getProvidersList({page: 1, perPage: 10, status: 'active', search});
      if (result['providers']) {
        const data = result?.providers.map(item => ({value: item.id, label: item.name}));
        setProvidersList(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, providerName: false});
    }
  }, DEBOUNCE_DELAY);

  const fetchEarningNamesData = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, earningName: true});
    try {
      const result = await getEarningCodes({page: 1, perPage: 10, status: 'active', search});
      if (result['earning_codes']) {
        const data = result?.earning_codes.map(item => ({value: item.id, label: item.name}));
        setEarningCodes(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, earningName: false});
    }
  }, DEBOUNCE_DELAY);

  const fetchEarningCodesData = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, earningCode: true});
    try {
      const result = await getEarningCodes({page: 1, perPage: 10, status: 'active', search});
      if (result['earning_codes']) {
        const data = result?.earning_codes.map(item => ({value: item.id, label: item.code}));
        setEarningCodesNames(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, earningCode: false});
    }
  }, DEBOUNCE_DELAY);

  const loadProviderDropdownOptions = search => fetchProvidersData(search);
  const loadEarningNamesDropdownOptions = search => fetchEarningNamesData(search);
  const loadEarningCodesDropdownOptions = search => fetchEarningCodesData(search);

  return (
    <div className="container-fluid">
      <div className="heading-path-otr">
        <h3 className="heading heading-h3">Provider Rates</h3>
        <BreadcrumbTrail />
      </div>

      <div className="card-wrapper facility-padding">
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={values => {
            providerRateHandler(values);
          }}
        >
          {formik => {
            return (
              <>
                <h4>Provider Rate</h4>
                {formLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="margin-auto"
                  />
                ) : (
                  <ProviderRatesForm
                    formik={formik}
                    providers={providersList}
                    earningCodes={earningCodes}
                    earningCodesNames={earningCodesNames}
                    isUpdateFlow
                    dropdownLoading={dropdownLoading}
                    loadProviderDropdownOptions={loadProviderDropdownOptions}
                    loadEarningNamesDropdownOptions={loadEarningNamesDropdownOptions}
                    loadEarningCodesDropdownOptions={loadEarningCodesDropdownOptions}
                  />
                )}

                {isLoading ? (
                  <button className={`primary-btn-small record-btn mt-3 m-auto`}>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                  </button>
                ) : (
                  <>
                    {!formLoading && (
                      <button
                        type="button"
                        className={`primary-btn-small record-btn mt-3 `}
                        onClick={formik.handleSubmit}
                        disabled={isLoading}
                      >
                        Save
                      </button>
                    )}
                  </>
                )}
              </>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default EditProviderRates;
