import React from 'react';

import CustomModal from 'components/common/modal';
import Input from 'components/common/input';
import {Formik, ErrorMessage} from 'formik';
import {passwordValidationSchema} from 'constants/users';

const ChangePasswordModal = ({open, setErrors, closeModalHandler, updatePasswordHandler}) => {
  const changePasswordHandler = values => {
    closeModalHandler();
    updatePasswordHandler(values, setErrors);
  };

  return (
    <CustomModal size="sm" show={open} onHide={closeModalHandler} heading="Change Password">
      <Formik
        initialValues={{password: ''}}
        validationSchema={passwordValidationSchema}
        onSubmit={values => {
          changePasswordHandler(values);
        }}
      >
        {({handleChange, values, handleSubmit}) => {
          return (
            <form className="form-main" onSubmit={handleSubmit}>
              <div className="wrapper">
                <Input
                  handleChange={handleChange}
                  placeholder="Enter new password"
                  type="text"
                  label="New Password"
                  value={values.password}
                  name="password"
                />
                <ErrorMessage className="error-text" component="p" name="password" />
              </div>
              <div className="action">
                <button type="submit" className=" primary-btn-small record-btn">
                  Update
                </button>
              </div>
            </form>
          );
        }}
      </Formik>
    </CustomModal>
  );
};

export default ChangePasswordModal;
