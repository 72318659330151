import React, {useEffect, useState} from 'react';

import FilterWrapper from 'components/common/filter-wrapper';
import BreadcrumbTrail from 'components/common/breadcrumb-trail';
import CalendarFilter from 'components/payroll-calendar/Filter';
import moment from 'moment';
import MyCalendar from 'components/common/calendar';
import {getProviderData} from 'containers/providers/api';

const PayrollCalendar = () => {
  const [refresh, setRefresh] = useState(false);
  const [filters, setFilters] = useState(null);
  const [dataEvents, setDataEvents] = useState([]);
  const [date, setDate] = useState({
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').format('YYYY-MM-DD'),
  });

  async function fetchData() {
    if (filters !== null) {
      const result = await getProviderData({
        id: filters?.providerId,
        start_date: moment(date.startDate).format('YYYY-MM-DD'),
        end_date: moment(date.endDate).format('YYYY-MM-DD'),
        facility_id: filters?.facilityId || '',
      });
      if (result?.length) {
        setDataEvents(result);
      }
    }
  }
  useEffect(() => {
    if (filters !== null) fetchData();
  }, [filters, refresh]);

  const handleChangeDate = (changedDate, type) => {
    setDate({
      startDate: moment(changedDate).startOf(type).format('YYYY-MM-DD'),
      endDate: moment(changedDate).endOf(type).format('YYYY-MM-DD'),
    });
    handleRefreshPage();
  };

  const handleRefreshPage = () => {
    setRefresh(pre => !pre);
  };
  const handleClickOnFilter = values => {
    setFilters(values);
    handleRefreshPage();
  };
  return (
    <>
      <div className="adjustments-main">
        <div className="container-fluid">
          <div className="heading-path-otr">
            <h3 className="heading heading-h3">Calendar</h3>
            <BreadcrumbTrail />
          </div>
          <FilterWrapper>
            <CalendarFilter filters={filters} handleSubmit={handleClickOnFilter} />
          </FilterWrapper>
          <MyCalendar
            eventsData={dataEvents}
            handleChangeDate={handleChangeDate}
            providerId={filters?.providerId}
            handleRefresh={handleRefreshPage}
          />
          ;
        </div>
      </div>
    </>
  );
};

export default PayrollCalendar;
