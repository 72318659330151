import React from 'react';

import CustomModal from 'components/common/modal';
import Input from 'components/common/input';
import {Formik, ErrorMessage} from 'formik';
import {initialValues, validationSchema} from 'constants/roles';

const CreateRoleModal = ({open, close, handleSubmit}) => {
  return (
    <CustomModal size="sm" show={open} onHide={() => close()} heading="Create Role">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, {setErrors}) => {
          handleSubmit(values, setErrors);
        }}
      >
        {({handleChange, values, handleSubmit}) => {
          return (
            <div className="col-login-inr roles-modal">
              <form className="form-main" onSubmit={handleSubmit}>
                <div className="wrapper">
                  <Input
                    handleChange={handleChange}
                    placeholder="Enter Name"
                    type="text"
                    label="Role Name"
                    value={values.name}
                    name="name"
                  />
                  <ErrorMessage component="p" name="name" />
                </div>
                <div className="wrapper">
                  <Input
                    handleChange={handleChange}
                    placeholder="Enter Description"
                    type="text"
                    label="Role Description"
                    value={values.description}
                    name="description"
                  />
                  <ErrorMessage component="p" name="description" />
                </div>
                <div className="action">
                  <input className="primary-btn login-btn" type="submit" name="submit" value={'Create Role'} />
                </div>
              </form>
            </div>
          );
        }}
      </Formik>
    </CustomModal>
  );
};

export default CreateRoleModal;
