import {post} from 'services/network';
import {RAW_DATA_ENDPOINT} from 'constants/endpoints';
import moment from 'moment';

export const getTSMRawDataReport = async (filters, meta) => {
  try {
    const entities = filters.entities.split(',');
    const providers = filters.providers.split(',');

    const result = await post(
      RAW_DATA_ENDPOINT,
      {
        query: meta.search,
        per: meta.perPage,
        page: meta.page,
        start_date: moment(filters.startDate).format('YYYY-MM-DD'),
        end_date: moment(filters.endDate).format('YYYY-MM-DD'),
        entities,
        providers,
      },
      true,
    );
    return result;
  } catch (e) {
    console.log(e);
  }
};
