import React, {useCallback, useEffect, useRef, useState} from 'react';
import ConfirmationModal from './ConfirmationModal';
import {ArrowsDownUp, PencilSimple, Trash} from 'phosphor-react';
import {useNavigate} from 'react-router-dom';
import TableWraper from 'components/common/table-wrapper';
import {getUsers, deactivateUser} from 'containers/users/api';
import {USERS_PATH, USER_CREATE_PATH} from 'constants/routePaths';
import BreadcrumbTrail from 'components/common/breadcrumb-trail';
import {alertTypes, initialMetaForTable, orderStratergy, roleNamesByType} from 'constants/common';
import {createdAtFormat, getActionButtonProps, getNameInitials, isAdministrator} from 'utils/common';
import {startCase} from 'lodash';

import {CSVLink} from 'react-csv';
import {toast} from 'react-toastify';
import CustomToast from 'components/common/custom-toast';

const headers = [
  {label: 'Name', key: 'full_name'},
  {label: 'Email', key: 'email'},
  {label: 'Creation', key: 'created_at'},
  {label: 'Active', key: 'active'},
  {label: 'Role', key: 'role_id'},
];

function UserListing() {
  const exportRef = useRef(null);
  const navigate = useNavigate();
  const [usersList, setUsersList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [allListSelected, setAllListSelected] = useState(false);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [userToDeactivate, setUserToDeactivate] = useState({id: null, name: ''});
  const [meta, setMeta] = useState({
    ...initialMetaForTable,
    totalCount: 0,
    totalPages: 0,
  });
  const [csvData, setCsvData] = useState([]);

  const promptDeactivateUser = (userId, userName) => {
    setUserToDeactivate({id: userId, name: userName});
    setConfirmationModalOpen(true);
  };

  const handleDeactivateUser = async () => {
    if (userToDeactivate.id) {
      await deactivateUser(userToDeactivate.id);
      setLoading(true);
    }
    setConfirmationModalOpen(false);
  };

  const handleCloseModal = () => {
    setConfirmationModalOpen(false);
  };

  const handleSetPerPage = obj => {
    setLoading(true);
    setMeta(pre => ({...pre, perPage: obj.value}));
  };

  const handleSetSearchQuery = value => {
    setLoading(true);
    setMeta(pre => ({...pre, search: value}));
  };
  const handlePageChange = value => {
    setLoading(true);
    setMeta(pre => ({...pre, page: value}));
  };
  const setAllListCheck = value => {
    setUsersList(pre => pre.map(item => ({...item, isSelected: value})));
  };
  const setSingleItemCheck = (value, id) => {
    setUsersList(pre => pre.map(item => (item.id === id ? {...item, isSelected: value} : item)));
  };
  const fetchUsers = useCallback(async () => {
    const result = await getUsers(meta.page, meta.perPage, meta.search, meta.sort, meta.order);
    if (result?.meta) {
      const resultMeta = result?.meta;
      setMeta(pre => ({
        ...pre,
        totalCount: resultMeta.total_count,
        totalPages: resultMeta.total_pages,
      }));
    }
    if (result?.users) {
      const data = result.users.map(item => ({...item, isSelected: allListSelected}));
      setUsersList(data);
    }
    setLoading(false);
  }, [meta.page, meta.perPage, meta.search]);

  useEffect(() => {
    if (loading) {
      fetchUsers();
    }
  }, [fetchUsers, loading]);
  useEffect(() => {
    setCsvData(usersList.filter(item => item.isSelected));
  }, [usersList]);

  // const deleteSingleUser = async id => {
  //   await deactivateUser(id);
  //   setLoading(true);
  // };

  const handleExport = done => {
    exportRef.current.link.click();
    if (csvData.length) {
      done(true);
    } else {
      toast(<CustomToast variant={alertTypes.DANGER} message={'Please Select Record!'} />);
      done(false);
    }
  };
  const sortedDataByColumn = columnName => {
    if (usersList.length > 0) {
      setMeta(pre => ({...pre, sort: columnName, order: orderStratergy[pre.order]}));
      setLoading(true);
    }
  };
  return (
    <>
      <div className="user-main">
        <div className="container-fluid">
          <div className="heading-path-otr">
            <h3 className="heading heading-h3">Users</h3>
            <BreadcrumbTrail />
          </div>

          <TableWraper
            setPerPage={handleSetPerPage}
            setSearchQuery={handleSetSearchQuery}
            totalListCount={meta.totalCount}
            pageSize={meta.perPage}
            currentPage={meta.page}
            onPageChange={handlePageChange}
            actionButtons={
              isAdministrator() && [
                ...getActionButtonProps('Add User', () => navigate(USER_CREATE_PATH)),
                ...getActionButtonProps('Export', () => {
                  exportRef.current.link.click();
                }),
              ]
            }
          >
            {loading ? (
              <p style={{textAlign: 'center'}}>Loading</p>
            ) : (
              <table className="table">
                <thead>
                  <tr>
                    {/* <th scope="col">
                      <div className="header-text-otr">
                        <p className="table-name heading-xsb">
                          <input
                            className="form-check-input"
                            onChange={e => {
                              setAllListSelected(e.target.checked);
                              setAllListCheck(e.target.checked);
                            }}
                            type="checkbox"
                            checked={allListSelected}
                          />
                        </p>
                      </div>
                    </th> */}
                    <th scope="col">
                      <div className="header-text-otr">
                        <p
                          className="table-name heading-xsb cursor-pointer c-hand"
                          onClick={() => sortedDataByColumn('name')}
                        >
                          Name 
                        </p>
                      </div>
                    </th>
                    <th scope="col">
                      <div className="header-text-otr">
                        <p
                          className="table-name heading-xsb cursor-pointer c-hand"
                          onClick={() => sortedDataByColumn('email')}
                        >
                          Email 
                        </p>
                      </div>
                    </th>
                    <th scope="col">
                      <div className="header-text-otr">
                        <p
                          className="table-name heading-xsb cursor-pointer c-hand"
                          onClick={() => sortedDataByColumn('created_at')}
                        >
                          Creation 
                        </p>
                      </div>
                    </th>
                    <th scope="col">
                      <div className="header-text-otr">
                        <p className="table-name heading-xsb">Active</p>
                      </div>
                    </th>
                    <th scope="col">
                      <div className="header-text-otr">
                        <p className="table-name heading-xsb">Role</p>
                      </div>
                    </th>
                    {isAdministrator() && (
                      <th scope="col" className="action-column">
                        <div className="header-text-otr">
                          <p className="table-name heading-xsb">Actions</p>
                        </div>
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {usersList?.map(item => (
                    <tr key={item.id}>
                      {/* <th scope="col">
                        <div className="header-text-otr">
                          <p className="table-name heading-xsb">
                            <input
                              className="form-check-input"
                              value={item.isSelected}
                              onChange={e => {
                                setSingleItemCheck(e.target.checked, item.id);
                              }}
                              type="checkbox"
                              checked={item.isSelected}
                            />
                          </p>
                        </div>
                      </th> */}

                      <th scope="row">
                        <div className="profile-otr" title={item.full_name}>
                          <div className="named-avatar">{getNameInitials(item.full_name)}</div>
                          {item.full_name}
                        </div>
                      </th>
                      <td>
                        <div className="">
                          <p className="table-text" title={item.email}>
                            {item.email}
                          </p>
                        </div>
                      </td>
                      <td>
                        <div className="table-text-otr">
                          <p className="table-text-black" title={item.created_at}>
                            <>{createdAtFormat(item.created_at)}</>
                          </p>
                        </div>
                      </td>
                      <td>
                        <div className="table-text-otr">
                          <p className={`table-text heading-xsb ${item?.active ? 'active' : 'in-active'}`}>
                            {startCase(item.active ? 'true' : 'false')}
                          </p>
                        </div>
                      </td>
                      <td>
                        <div className="table-text-otr">
                          <p className="table-text" title={roleNamesByType[item.role_id]}>
                            {roleNamesByType[item.role_id]}
                          </p>
                        </div>
                      </td>
                      {isAdministrator() && (
                        <td className="action-column">
                          <div className="table-icon-otr">
                            <div className="icon-otr" onClick={() => navigate(`${USERS_PATH}/${item.id}/edit`)}>
                              <PencilSimple size={24} />
                            </div>
                            <div className="icon-otr" onClick={() => promptDeactivateUser(item.id, item.full_name)}>
                              <Trash size={24} />
                            </div>
                          </div>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </TableWraper>
        </div>
      </div>
      <CSVLink
        ref={exportRef}
        data={csvData}
        headers={headers}
        asyncOnClick={true}
        onClick={(event, done) => {
          handleExport(done);
        }}
        filename={'my-file.csv'}
        className="btn btn-primary opacity-0 w-0 h-0 position-absolute top-0"
        target="_blank"
      />
      <ConfirmationModal
        open={isConfirmationModalOpen}
        userName={userToDeactivate.name}
        onClose={handleCloseModal}
        onConfirm={handleDeactivateUser}
      />
    </>
  );
}

export default UserListing;
