import CustomModal from 'components/common/modal';

const ConfirmationModal = ({isDeleting, isModalVisible, handleCloseModal, handleConfirmClose}) => (
  <CustomModal size="sm" show={isModalVisible} onHide={handleCloseModal} heading="Confirm">
    <h5 className="mb-4">Are you sure you want to delete it?</h5>

    <div className="d-flex">
      <button className="secondary-btn-small record-btn" onClick={handleCloseModal}>
        Cancel
      </button>
      <button disabled={isDeleting} className="ms-3 danger-btn" onClick={handleConfirmClose}>
        {isDeleting ? (
          <>
            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
            <span className="visually-hidden">Deleting...</span>
          </>
        ) : (
          <span>Yes, Delete</span>
        )}
      </button>
    </div>
  </CustomModal>
);

export default ConfirmationModal;
