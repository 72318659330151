import React, {useEffect, useState} from 'react';

import TableWraper from 'components/common/table-wrapper';
import CustomModal from 'components/common/modal';
import Input from 'components/common/input';

import {Formik, ErrorMessage} from 'formik';
import {ArrowsDownUp, PencilSimple, Trash} from 'phosphor-react';
import {getProviderCategoryList, createProviderCategory, updateProviderCategory} from 'containers/backend-tables/api';
import {
  providerCategoryValidationSchema as validationSchema,
  getProviderCategoryInitialValues as getInitialValues,
} from 'constants//backend-tables';
import {getNameInitials, DEBOUNCE_DELAY, getActionButtonProps, isAdministrator} from 'utils/common';
import {initialMetaForTable, orderStratergy} from 'constants/common';
import ToggleButton from 'components/common/togglebutton';

let timeout;

const ProviderCategory = () => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [providerCategoryList, setProviderCategoryList] = useState([]);
  const [isModalVisible, setModalVisibility] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState(initialMetaForTable);

  async function fetchData() {
    setLoading(true);
    try {
      const result = await getProviderCategoryList(meta);
      if (result?.provider_categories) {
        setProviderCategoryList(result.provider_categories);
        setTotalCount(result?.meta.total_count);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage]);

  const handleRefreshPage = () => {
    setRefreshPage(pre => !pre);
  };
  const handleSetPerPage = obj => {
    setMeta(pre => ({...pre, perPage: obj.value}));
    handleRefreshPage();
  };
  const debounceFn = (callback, delay) => {
    clearTimeout(timeout);
    timeout = setTimeout(callback, delay);
  };
  const handleSetSearchQuery = value => {
    setMeta(pre => ({...pre, search: value}));
    debounceFn(handleRefreshPage, DEBOUNCE_DELAY);
  };
  const handlePageChange = value => {
    setMeta(pre => ({...pre, page: value}));
    handleRefreshPage();
  };
  const handleClickOnCreate = () => {
    handleOpenModal();
  };
  const handleCloseModal = () => {
    setModalVisibility(false);
    setSelectedRow(null);
  };
  const handleOpenModal = () => {
    setModalVisibility(true);
  };
  const handleCreateProviderCategory = async (data, setErrors) => {
    try {
      const result = await createProviderCategory(data, setErrors);
      if (result?.meta?.status === 200) {
        handleCloseModal();
        handleRefreshPage();
      }
    } catch (e) {
      console.log(e);
    }
  };
  const handleUpdateProviderCategory = async (data, setErrors) => {
    try {
      const result = await updateProviderCategory(data, setErrors);
      if (result?.meta?.status === 200) {
        handleCloseModal();
        handleRefreshPage();
      }
    } catch (e) {
      console.log(e);
    }
  };
  const sortedDataByColumn = columnName => {
    if (providerCategoryList.length > 0) {
      setMeta(pre => ({...pre, sort: columnName, order: orderStratergy[pre.order]}));
      handleRefreshPage();
    }
  };
  return (
    <>
      <TableWraper
        setPerPage={handleSetPerPage}
        setSearchQuery={handleSetSearchQuery}
        searchValue={meta.search}
        totalListCount={totalCount}
        pageSize={meta.perPage}
        currentPage={meta.page}
        onPageChange={handlePageChange}
        actionButtons={isAdministrator() && getActionButtonProps('Add Provider Category', handleClickOnCreate)}
      >
        {loading ? (
          <p style={{textAlign: 'center'}}>Loading</p>
        ) : (
          <table className="table">
            <thead>
              <tr>
                <th scope="col">
                  <div className="header-text-otr">
                    <p
                      className="table-name heading-xsb cursor-pointer c-hand"
                      onClick={() => sortedDataByColumn('code')}
                    >
                      Category Code 
                    </p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p
                      className="table-name heading-xsb cursor-pointer c-hand"
                      onClick={() => sortedDataByColumn('name')}
                    >
                      Category Name 
                    </p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Active</p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p
                      className="table-name heading-xsb cursor-pointer c-hand"
                      onClick={() => sortedDataByColumn('updated_at')}
                    >
                      Updated Date 
                    </p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p
                      className="table-name heading-xsb cursor-pointer c-hand"
                      onClick={() => sortedDataByColumn('update_by')}
                    >
                      Updated By 
                    </p>
                  </div>
                </th>
                {isAdministrator() && (
                  <th scope="col" className="action-column">
                    <div className="header-text-otr">
                      <p className="table-name heading-xsb">Actions</p>
                    </div>
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {providerCategoryList.map(item => {
                return (
                  <tr key={item.id}>
                    <th scope="row">
                      <div className="table-text-otr ">
                        <span className="table-text">{item?.code || ''}</span>
                      </div>
                    </th>
                    <td>
                      <div className="">
                        <p className="table-text">{item?.name || ''}</p>
                      </div>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <p className={`table-text ${item?.active ? 'active' : 'in-active'}`} title="Title here ">
                          {item?.active ? 'true' : 'false'}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <p className="table-text">{item?.updated_at || ''}</p>
                      </div>
                    </td>
                    <td>
                      <div className="profile-otr">
                        <div className="named-avatar">{getNameInitials(item?.update_by)}</div>
                        <p className="user-name heading-xs" title={getNameInitials(item?.update_by)}>
                          {item?.update_by || ''}
                        </p>
                      </div>
                    </td>
                    {isAdministrator() && (
                      <td className="action-column">
                        <div className="table-icon-otr">
                          <div className="icon-otr">
                            <PencilSimple
                              size={24}
                              onClick={() => {
                                handleOpenModal();
                                setSelectedRow({...item});
                              }}
                            />
                          </div>
                        </div>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </TableWraper>

      {isModalVisible && (
        <CustomModal
          size="sm"
          show={isModalVisible}
          onHide={() => handleCloseModal()}
          heading={`${selectedRow === null ? 'Create' : 'Edit'} Provider Category`}
        >
          <Formik
            initialValues={getInitialValues(selectedRow)}
            validationSchema={validationSchema}
            onSubmit={(values, {setErrors}) => {
              if (selectedRow === null) {
                const payload = {
                  provider_category: {
                    ...values,
                    status: values.status ? 'active' : 'inactive',
                  },
                };
                handleCreateProviderCategory(payload, setErrors);
              } else {
                const payload = {
                  id: selectedRow.id,
                  provider_category: {
                    ...values,
                    status: values.status ? 'active' : 'inactive',
                  },
                };
                handleUpdateProviderCategory(payload, setErrors);
              }
            }}
          >
            {({handleChange, values, handleSubmit, setFieldValue}) => {
              return (
                <div className="col-login-inr backend-tables-modal">
                  <form className="form-main" onSubmit={handleSubmit}>
                    <div className="wrapper">
                      <Input
                        handleChange={handleChange}
                        placeholder="Name"
                        type="text"
                        label="Name"
                        value={values.name}
                        name="name"
                        maxLength={50}
                      />
                      <ErrorMessage component="p" name="name" />
                    </div>
                    <div className="wrapper">
                      <Input
                        handleChange={handleChange}
                        placeholder="Enter Code"
                        type="text"
                        label="Code"
                        value={values.code}
                        name="code"
                        maxLength={10}
                      />
                      <ErrorMessage component="p" name="code" />
                    </div>
                    <div className="wrapper">
                      <ToggleButton
                        label="Active Provider Category"
                        handleChange={(name, value) => {
                          setFieldValue(name, value);
                        }}
                        value={values.status}
                        name="status"
                      />
                    </div>
                    <div className="action">
                      <input
                        className="primary-btn login-btn"
                        type="submit"
                        name="submit"
                        value={`${selectedRow === null ? 'Create' : 'Edit'} Provider Category`}
                      />
                    </div>
                  </form>
                </div>
              );
            }}
          </Formik>
        </CustomModal>
      )}
    </>
  );
};

export default ProviderCategory;
