import {Formik, ErrorMessage} from 'formik';
import {validationSchema} from 'constants/login';
import Check from 'components/common/check';
import Input from 'components/common/input';
import {Link} from 'react-router-dom';
const environment = process.env.REACT_APP_ENVIRONMENT || 'production';

const LoginForm = ({checked, handleCheckChange, formSubmitHandler}) => (
  <Formik
    initialValues={{
      email: '',
      password: '',
    }}
    enableReinitialize={true}
    validationSchema={validationSchema}
    onSubmit={(values, {setErrors, setSubmitting}) => {
      formSubmitHandler(values, setErrors, setSubmitting);
    }}
  >
    {formik => {
      return (
        <form className="form-main" onSubmit={formik.handleSubmit}>
          <div className="wrapper">
            {/* <h3 className="heading heading-h3">Login to CareVue Portal</h3> */}
            {environment === 'staging' && <p className='error-text' style={{fontSize:'18px', fontWeight:'bold'}}>Demo</p>}
            <p className="desc heading-sm">Enter the following details to access the admin portal.</p>
          </div>
          <Input
            handleChange={formik.handleChange}
            placeholder="Enter Email"
            type="email"
            label="Email"
            value={formik.values.email}
            name="email"
            className="mb-1"
          />
          <ErrorMessage className="error-text" component="p" name="email" />
          <Input
            handleChange={formik.handleChange}
            placeholder="Enter Password"
            type="password"
            label="Password"
            value={formik.values.password}
            name="password"
            className="mb-1"
          />
          <ErrorMessage className="error-text" component="p" name="password" />
          <div className="remember-otr">
            <Check label={'Remember Me'} handleChange={handleCheckChange} value={checked} name="check" />
            <Link to="/?reset=1" className="Forget-text heading-xs">
              Forgot Password?
            </Link>
          </div>
          <div className="action">
            <button type="submit" disabled={formik.isSubmitting} className="primary-btn login-btn">
              {formik.isSubmitting ? (
                <>
                  <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                  <span className="visually-hidden">Loading...</span>
                </>
              ) : (
                <span>Login</span>
              )}
            </button>
          </div>
        </form>
      );
    }}
  </Formik>
);

export default LoginForm;
