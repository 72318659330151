import React, {useEffect, useState} from 'react';

import TableWraper from 'components/common/table-wrapper';

import {DEBOUNCE_DELAY} from 'utils/common';
import {initialMetaForPayrollPeriodTable, orderStratergy} from 'constants/common';
import {getPayrollPeriods} from 'containers/payroll-period/api';
import {updateClosePeriod, updateOpenPeriod} from 'containers/dashboard/api';
import moment from 'moment';
import {ArrowsDownUp} from 'phosphor-react';

let timeout;

const RecordsListing = ({tab}) => {
  const [list, setList] = useState([]);
  const [refreshPage, setRefreshPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState({
    initialMetaForPayrollPeriodTable,
    filter: tab,
    sort: 'start_date',
    order: 'asc',
  });
  const [totalCount, setTotalCount] = useState(0);
  const currentDate = moment();
  const dateDifference = date => Math.abs(moment(date, 'MM/DD/YYYY') - currentDate);

  const setDefaultColumnSorting = () => {
    if (tab === 'close') {
      setMeta(pre => ({...pre, sort: 'marked_closed_at', order: 'desc', filter: tab}));
    } else {
      setMeta(pre => ({...pre, sort: 'start_date', order: 'asc', filter: tab}));
    }
  };

  async function fetchData() {
    try {
      setLoading(true);
      const result = await getPayrollPeriods({
        ...meta,
        filter: tab,
        // sort: tab === 'close' ? 'marked_closed_at' : 'start_date',
        // order: tab === 'close' ? (meta?.order ? meta.order : 'desc') : 'asc',
      });
      if (result['payroll_periods']) {
        // setList(result.payroll_periods.sort((a, b) => dateDifference(a.start_date) - dateDifference(b.start_date)));
        setList(result.payroll_periods);
        setTotalCount(result?.meta.total_count);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    setDefaultColumnSorting();
    handleRefreshPage();
  }, [tab]);
  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage]);

  const handleRefreshPage = () => {
    setRefreshPage(pre => !pre);
  };
  const handleSetPerPage = obj => {
    setMeta(pre => ({...pre, perPage: obj.value}));
    handleRefreshPage();
  };
  const debounceFn = (callback, delay) => {
    clearTimeout(timeout);
    timeout = setTimeout(callback, delay);
  };
  const handleSetSearchQuery = value => {
    setMeta(pre => ({...pre, search: value}));
    debounceFn(handleRefreshPage, DEBOUNCE_DELAY);
  };
  const handlePageChange = value => {
    setMeta(pre => ({...pre, page: value}));
    handleRefreshPage();
  };

  const getClassName = status => {
    let className = 'table-text ';
    switch (status) {
      case 'open':
        return (className = className + 'active clickable');
      case 'close':
        return (className = className + 'in-active disabled');
      case 'future':
        return (className = className + 'in-process clickable');
    }
  };

  const handleUpdateClosePeriod = async id => {
    const response = await updateClosePeriod(id);
    if (response) {
      setRefreshPage(pre => !pre);
    }
  };
  const handleUpdateOpenPeriod = async id => {
    const response = await updateOpenPeriod(id);
    if (response) {
      setRefreshPage(pre => !pre);
    }
  };
  const clickHandler = item => {
    if (item?.period_status === 'future') {
      handleUpdateOpenPeriod(item.id);
    } else if (item?.period_status === 'open') {
      handleUpdateClosePeriod(item.id);
    }
  };
  const sortedDataByColumn = columnName => {
    if (list.length > 0) {
      setMeta(pre => ({...pre, sort: columnName, order: orderStratergy[pre.order]}));
      handleRefreshPage();
    }
  };
  return (
    <>
      <TableWraper
        setPerPage={handleSetPerPage}
        setSearchQuery={handleSetSearchQuery}
        searchValue={meta.search}
        totalListCount={totalCount}
        pageSize={meta.perPage}
        currentPage={meta.page}
        onPageChange={handlePageChange}
      >
        {loading ? (
          <p style={{textAlign: 'center'}}>Loading</p>
        ) : (
          <table className="table fixed-layout">
            <thead>
              <tr>
                <th scope="col">
                  <div className="header-text-otr">
                    <p
                      className="table-name heading-xsb cursor-pointer c-hand"
                      onClick={() => sortedDataByColumn('start_date')}
                    >
                      Start Date 
                    </p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p
                      className="table-name heading-xsb cursor-pointer c-hand"
                      onClick={() => sortedDataByColumn('close_date')}
                    >
                      End Date 
                    </p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p
                      className="table-name heading-xsb cursor-pointer c-hand"
                      onClick={() => sortedDataByColumn('marked_closed_at')}
                    >
                      Close Date 
                    </p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Period Status</p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Updated By</p>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {list.map(item => {
                return (
                  <tr key={item.id}>
                    <th scope="row">
                      <p className="table-text ">{item?.start_date || ''}</p>
                    </th>
                    <td>
                      <div className="table-text-otr">
                        <p className="table-text ">{item?.close_date || ''}</p>
                      </div>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <p className="table-text ">{item?.marked_closed_at || ''}</p>
                      </div>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <p className={getClassName(item?.period_status)} onClick={() => clickHandler(item)}>
                          {item?.period_status || ''}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <p className="table-text">{item?.updated_by_jsonb?.name || ''}</p>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </TableWraper>
    </>
  );
};

export default RecordsListing;
