import * as yup from 'yup';

export const entitySchema = yup.object().shape({
  name: yup.string().required('Required *'),
  adp_w2: yup
    .string()
    .test('len', 'ADP code For W2 length must be 3 characters *', val => !val || val?.toString()?.length === 3),
  email: yup.string(),
  adp_1099: yup
    .string()
    .test('len', 'ADP code For 1099 length must be 3 characters *', val => !val || val?.toString()?.length === 3),
  time_sheet_email: yup.string().email('Email must be a valid email *').required('Required *'),
  folder_path: yup.string(),
  payroll_provider: yup.string(),
  status: yup.string(),
});

export const entityFormInitialValues = {
  name: '',
  adp_w2: '',
  adp_1099: '',
  time_sheet_email: '',
  status: 0,
};
