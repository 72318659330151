import 'react-toastify/dist/ReactToastify.css';
import CustomAlert from 'components/common/custom-alert';
import './toast.scss';

const CustomToast = ({closeToast, toastProps, variant, message}) => (
  <CustomAlert variant={variant} toastProps={toastProps} closeAlert={closeToast}>
    {message}
  </CustomAlert>
);

export default CustomToast;
