import React, {useState, useEffect} from 'react';

import BreadcrumbTrail from 'components/common/breadcrumb-trail';
import Tabs from 'components/common/tabs';

import {tabsList as list} from 'constants/adp-export';
import {getEditProvider} from 'containers/providers/api';
import {useParams} from 'react-router-dom';
import AdpExportSerach from './adp-export-search';
import AdpExportHistory from 'components/adp-export/adp-export-history';

const AdpExport = () => {
  const {id} = useParams();
  const [currentTab, setCurrentTab] = useState(list[0].value);
  const [provider, setProvider] = useState();
  const fetchEditUser = async id => {
    const result = await getEditProvider(id);
    if (result?.provider) {
      setProvider(result);
    }
  };
  useEffect(() => {
    if (id) fetchEditUser(id);
  }, []);

  const tabMaping = {
    0: <AdpExportSerach />,
    1: <AdpExportHistory />,
  };
  const handleSetCurrentTab = item => {
    setCurrentTab(item.value);
  };
  const renderCurrentTab = () => {
    return tabMaping[currentTab];
  };

  return (
    <div className="backend-tables">
      <div className="container-fluid">
        <div className="heading-path-otr">
          <h3 className="heading heading-h3">ADP Export</h3>
          <BreadcrumbTrail />
        </div>
        <Tabs list={list} handleClick={handleSetCurrentTab} activeTab={currentTab} />
        <>{renderCurrentTab()}</>
      </div>
    </div>
  );
};

export default AdpExport;
