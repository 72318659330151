import {post} from 'services/network';
import {GROSS_BILLING_REPORTS_ENDPOINT} from 'constants/endpoints';
import moment from 'moment';

export const getGrossBillingReport = async (filters, query = '') => {
  try {
    const result = await post(
      GROSS_BILLING_REPORTS_ENDPOINT,
      {
        query,
        start_date: moment(filters.startDate).format('YYYY-MM-DD'),
        end_date: moment(filters.endDate).format('YYYY-MM-DD'),
        filter_by: filters.aggregate,
      },
      true,
    );
    return result;
  } catch (e) {
    console.log(e);
  }
};
