import {ENTITIES_ENDPOINT} from 'constants/endpoints';
import {get, post, put, deleteApi} from 'services/network';

export const getEntities = async (page, perPage, search, sort, order) => {
  try {
    const response = await get(
      `${ENTITIES_ENDPOINT}?per=${perPage}&sort=${sort}&order=${order}&page=${page}&query=${search}`,
      true,
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const getSingleEntity = async id => {
  try {
    const response = await get(`${ENTITIES_ENDPOINT}/${id}`, true);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const createEntity = async (values, setErrors) => {
  try {
    const response = await post(ENTITIES_ENDPOINT, {entity: values}, true);
    return response;
  } catch (error) {
    if (error?.response?.data) {
      setErrors(error?.response?.data.errors);
    }
    console.error(error);
  }
};
export const updateEntity = async (id, values, setErrors) => {
  try {
    const response = await put(`${ENTITIES_ENDPOINT}/${id}/`, {entity: values}, true);
    return response;
  } catch (error) {
    if (error?.response?.data) {
      setErrors(error?.response?.data.errors);
    }
    console.error(error);
  }
};

export const deleteEntity = async id => {
  try {
    const response = await deleteApi(`${ENTITIES_ENDPOINT}/${id}/`, true);
    return response;
  } catch (error) {
    console.error(error);
  }
};
