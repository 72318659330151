import React, {useEffect, useState, useCallback} from 'react';
import '../mail.scss';
import {Row, Col} from 'react-bootstrap';
import {getNameInitials, useQuery} from 'utils/common';
import {getSingleEmail} from 'containers/mail/api';
import {isEmpty, startCase} from 'lodash';

const EmailDetailView = () => {
  const query = useQuery();
  const id = query.get('id');
  const [loading, setLoading] = useState(true);
  const [emailData, setEmailData] = useState(null);

  const fetchEmailDetail = useCallback(async () => {
    setLoading(true);
    const result = await getSingleEmail(id);
    if (result?.email) {
      setEmailData(result.email);
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchEmailDetail();
  }, [fetchEmailDetail]);

  return (
    <div className="table-main facility-padding email-detail">
      <h5>{loading ? 'Loading...' : 'Email Details'}</h5>
      {!isEmpty(emailData) && !loading && (
        <Row>
          <Col md={4} className="mt-4">
            <label className="label mb-1">From Email</label>
            <p className="text">{emailData.from_email}</p>
          </Col>
          <Col md={4} className="mt-4">
            <label className="label mb-1">Reply-to Email</label>
            <p className="text">{emailData.reply_email}</p>
          </Col>
          <Col md={4} className="mt-4">
            <label className="label mb-1">To Email</label>
            <p className="text">{emailData.to_email}</p>
          </Col>
          <Col md={12} className="mt-4">
            <label className="label mb-1">Subject</label>
            <p className="text">{emailData.subject || '-'}</p>
          </Col>
          <Col md={12} className="mt-4 content">
            <p className="text p-3">{emailData.template_body || '-'}</p>
          </Col>

          <Col md={4} className="mt-4">
            <label className="label mb-1">Email Status Code</label>
            <p className="table-text active">{startCase(emailData.status)}</p>
          </Col>
          <Col md={4} className="mt-4">
            <label className="label mb-1">Sent Date</label>
            <p className="text">{emailData.send_date}</p>
          </Col>
          <Col md={4} className="mt-4">
            <label className="label mb-1">Insert by</label>
            <div className="profile-otr d-flex" title={emailData.insert_by}>
              <div className="named-avatar">{getNameInitials(emailData.insert_by)}</div>
              <span className="line-height">{emailData.insert_by}</span>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default EmailDetailView;
