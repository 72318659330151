import React, {useEffect, useState} from 'react';

import TableWraper from 'components/common/table-wrapper';

// import {isAdministrator, isBiller, isPayRoll} from 'utils/common';
import {Trash, Eye, ArrowsDownUp, Lock, LockOpen} from 'phosphor-react';
import {DEBOUNCE_DELAY, isAdministrator, isPayRoll} from 'utils/common';
import {initialMetaForTable, orderStratergy, textStatus} from 'constants/common';
import {getLookerList, lockTheLookerImport} from 'containers/looker-import/api';
import {useNavigate} from 'react-router-dom';
import {Tooltip, OverlayTrigger} from 'components/common/tooltip';
let timeout;
const renderTooltip = props => (
  <Tooltip id="button-tooltip" {...props}>
    Lock the Looker Import File
  </Tooltip>
);
const LookerListing = ({refreshByParent, filters}) => {
  const navigate = useNavigate();
  const [allListSelected, setAllListSelected] = useState(false);
  const [lookerList, setLookerList] = useState([]);
  const [refreshPage, setRefreshPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState(initialMetaForTable);
  const [totalCount, setTotalCount] = useState(0);

  async function fetchData() {
    let filterPayload = {status: 'error'};
    if (filters !== null) {
      filterPayload = {...filters};
    }
    try {
      setLoading(true);
      const result = await getLookerList({...meta, ...filterPayload});
      if (result['looker_imports']) {
        const data = result?.looker_imports.map(item => ({...item, isSelected: allListSelected}));
        setLookerList(data);
        setTotalCount(result?.meta.total_count);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }
  async function addLockToTheLookerImport(id) {
    let data = {id, locked: true};

    try {
      await lockTheLookerImport(data);
      handleRefreshPage();
    } catch (e) {
      console.log(e);
    } finally {
    }
  }
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage, refreshByParent]);

  const handleRefreshPage = () => {
    setRefreshPage(pre => !pre);
  };
  const handleSetPerPage = obj => {
    setMeta(pre => ({...pre, perPage: obj.value}));
    handleRefreshPage();
  };
  const debounceFn = (callback, delay) => {
    clearTimeout(timeout);
    timeout = setTimeout(callback, delay);
  };
  const handleSetSearchQuery = value => {
    setMeta(pre => ({...pre, search: value}));
    debounceFn(handleRefreshPage, DEBOUNCE_DELAY);
  };
  const handlePageChange = value => {
    setMeta(pre => ({...pre, page: value}));
    handleRefreshPage();
  };

  const setAllListCheck = value => {
    setLookerList(pre => pre.map(item => ({...item, isSelected: value})));
  };
  const setSingleItemCheck = (value, id) => {
    setLookerList(pre => pre.map(item => (item.id === id ? {...item, isSelected: value} : item)));
  };
  const sortedDataByColumn = columnName => {
    if (lookerList.length > 0) {
      setMeta(pre => ({...pre, sort: columnName, order: orderStratergy[pre.order]}));
      handleRefreshPage();
    }
  };
  return (
    <>
      <TableWraper
        setPerPage={handleSetPerPage}
        setSearchQuery={handleSetSearchQuery}
        searchValue={meta.search}
        totalListCount={totalCount}
        pageSize={meta.perPage}
        currentPage={meta.page}
        onPageChange={handlePageChange}
      >
        {loading ? (
          <h3 style={{textAlign: 'center'}}>Loading</h3>
        ) : (
          <table className="table">
            <thead>
              <tr>
                {/* <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">
                      <input
                        className="form-check-input"
                        onChange={e => {
                          setAllListSelected(e.target.checked);
                          setAllListCheck(e.target.checked);
                        }}
                        type="checkbox"
                        checked={allListSelected}
                      />
                    </p>
                  </div>
                </th> */}
                <th scope="col">
                  <div className="header-text-otr">
                    <p
                      className="table-name heading-xsb cursor-pointer c-hand"
                      onClick={() => sortedDataByColumn('updated_at')}
                    >
                      Filename
                    </p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p
                      className="table-name heading-xsb cursor-pointer c-hand"
                      onClick={() => sortedDataByColumn('name')}
                    >
                      Upload Date
                    </p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p
                      className="table-name heading-xsb cursor-pointer c-hand"
                      onClick={() => sortedDataByColumn('name')}
                    >
                      Import Date
                    </p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p
                      className="table-name heading-xsb cursor-pointer c-hand"
                      onClick={() => sortedDataByColumn('status')}
                    >
                      Status
                    </p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb"> Error Message </p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Results</p>
                  </div>
                </th>
                {(isAdministrator() || isPayRoll()) && (
                  <th scope="col" className="action-column">
                    <div className="header-text-otr">
                      <p className="table-name heading-xsb">Actions</p>
                    </div>
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {lookerList.map(item => {
                return (
                  <tr key={item.id}>
                    {/* <th scope="col">
                      <div className="header-text-otr">
                        <p className="table-name heading-xsb">
                          <input
                            className="form-check-input"
                            value={item.isSelected}
                            onChange={e => {
                              setSingleItemCheck(e.target.checked, item.id);
                            }}
                            type="checkbox"
                            checked={item.isSelected}
                          />
                        </p>
                      </div>
                    </th> */}

                    <th scope="col">
                      <div className="table-text-otr">
                        <p className="table-text ">{item?.name || ''}</p>
                      </div>
                    </th>
                    <td>
                      <div>
                        <p className="table-text ">{item?.created_at || ''}</p>
                      </div>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <p className="table-text ">{item?.updated_at || ''}</p>
                      </div>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <p className={`table-text ${textStatus[item.status]}`} title="Title here ">
                          {item?.status === 'error'
                            ? 'Failed'
                            : item?.status === 'in_process'
                            ? 'In Progress'
                            : 'Completed'}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <p className="table-text ">{item?.message || ''}</p>
                      </div>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <div className="table-text ">
                          <span className="mx-1 total-count">{item?.total_count || 0}</span>
                          <span className="mx-1 total-matched">{item?.record_matched_count || 0}</span>
                          <span className="not-matched">{item?.record_not_found_count || 0}</span>
                        </div>
                      </div>
                    </td>
                    {(isAdministrator() || isPayRoll()) && (
                      <td className="action-column">
                        <div className="table-icon-otr">
                          <div className="icon-otr">
                            <Eye
                              size={24}
                              onClick={() => {
                                navigate(`${item.id}/provider-data`);
                              }}
                              className="me-2"
                            />
                            {item.locked ? (
                              <Lock size={24} />
                            ) : (
                              <OverlayTrigger placement="bottom" delay={{show: 250, hide: 400}} overlay={renderTooltip}>
                                <LockOpen
                                  size={24}
                                  onClick={() => {
                                    addLockToTheLookerImport(item.id);
                                  }}
                                />
                              </OverlayTrigger>
                            )}
                          </div>
                          {/* <div className="icon-otr">
                            <Trash size={24} onClick={() => {}} />
                          </div> */}
                        </div>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </TableWraper>
    </>
  );
};

export default LookerListing;
