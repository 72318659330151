import * as yup from 'yup';
import {
  BACKEND_TABLES_PATH,
  BACKEND_TABLES_PROVIDER_CATEGORY_PATH,
  BACKEND_TABLES_PROVIDER_TITLE_PATH,
  BACKEND_TABLES_FACILITY_TYPE_PATH,
  BACKEND_TABLES_PAYROLL_GROUPS_PATH,
} from './routePaths';
const tabsList = [
  {
    title: 'Pay Method',
    value: 0,
    path: BACKEND_TABLES_PATH,
  },
  {
    title: 'Provider Category',
    value: 1,
    path: BACKEND_TABLES_PROVIDER_CATEGORY_PATH,
  },
  {
    title: 'Provider Title',
    value: 2,
    path: BACKEND_TABLES_PROVIDER_TITLE_PATH,
  },
  {
    title: 'Facility Type',
    value: 3,
    path: BACKEND_TABLES_FACILITY_TYPE_PATH,
  },
  {
    title: 'Payroll Groups',
    value: 4,
    path: BACKEND_TABLES_PAYROLL_GROUPS_PATH,
  },
];

const payMethodValidationSchema = yup.object().shape({
  name: yup.string().required('Required *'),
  code: yup.string().matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed!'),
});
const getPayMethodInitialValues = selectedRow => {
  if (selectedRow === null)
    return {
      status: 0,
      name: '',
      code: '',
    };
  return {name: selectedRow.name, code: selectedRow.code, status: selectedRow.active ? 1 : 0};
};
const facilityTypeValidationSchema = yup.object().shape({
  name: yup.string().required('Required *'),
  description: yup.string().required('Required *'),
});

const getFacilityTyepInitialValues = selectedRow => {
  if (selectedRow === null)
    return {
      status: 0,
      name: '',
      description: '',
    };
  return {name: selectedRow.name, description: selectedRow.description, status: selectedRow.active ? 1 : 0};
};

const providerCategoryValidationSchema = yup.object().shape({
  name: yup.string().required('Required *'),
  code: yup.string().matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed!'),
});
const getProviderCategoryInitialValues = selectedRow => {
  if (selectedRow === null)
    return {
      status: 0,
      name: '',
      code: '',
    };
  return {name: selectedRow.name, code: selectedRow.code, status: selectedRow.active ? 1 : 0};
};

const providerTitleValidationSchema = yup.object().shape({
  name: yup.string().required('Required *'),
  description: yup.string().required('Required *'),
});

const getProviderTitleInitialValues = selectedRow => {
  if (selectedRow === null)
    return {
      status: 0,
      name: '',
      description: '',
    };
  return {
    description: selectedRow.description,
    name: selectedRow.name,
    status: selectedRow.active ? 1 : 0,
  };
};

const payrollGroupValidationSchema = yup.object().shape({
  name: yup.string().required('Required *'),
  payroll_entities_attributes: yup.array().test({
    message: 'Required *',
    test: arr => arr.length >= 1,
  }),
});

export {
  tabsList,
  payMethodValidationSchema,
  getPayMethodInitialValues,
  facilityTypeValidationSchema,
  getFacilityTyepInitialValues,
  providerCategoryValidationSchema,
  getProviderCategoryInitialValues,
  providerTitleValidationSchema,
  getProviderTitleInitialValues,
  payrollGroupValidationSchema,
};
