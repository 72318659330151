import {isAdministrator} from 'utils/common';
import * as yup from 'yup';

const initialValues = {
  name: '',
  description: '',
};
const validationSchema = yup.object().shape({
  name: yup.string().required('Required *'),
  description: yup.string().required('Required *'),
});

export {initialValues, validationSchema};
