import React from 'react';
import {List, MagnifyingGlass} from 'phosphor-react';
import {getNameInitials, getUserInfoFromStorage} from 'utils/common';
import './header.scss';

function Header({toggleSidebarHandler}) {
  const user = getUserInfoFromStorage();
  const environment = process.env.REACT_APP_ENVIRONMENT || 'production';

  return (
    <div className="header-main">
      <div className="container-fluid">
        <div className="wrapper">
          <div className="header-input">
            <List size={24} onClick={toggleSidebarHandler} />
            {environment === 'staging' && <p className='error-text' style={{fontSize:'16px', fontWeight:'bold'}}>Demo</p>}
            <MagnifyingGlass size={24} />
            <input className="search-input heading-sm" type="text" name="search" placeholder="Search Admin Portal" />
          </div>
          <div className="profile-otr" title={user.full_name}>
            <div className="named-avatar">{getNameInitials(user.full_name)}</div>
            <p className="user-name heading-smb">{user.full_name}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
