import React, {useState, useEffect} from 'react';
import AssignedFacilityProvider from 'components/common/assigne-facilities-providers';
import {createFacilityProvider, deleteFacilityProvider} from 'components/common/assigne-facilities-providers/api';
import {useParams} from 'react-router-dom';
import {getEditProvider} from 'containers/providers/api';

const AssignedFacilities = () => {
  const [facilityId, setFacilityId] = useState('');
  const [facilities, setFacilities] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const {id} = useParams();

  const fetchProvider = async () => {
    try {
      const result = await getEditProvider(id);
      if (result?.provider?.provider_facilities.length > 0) {
        const data = [];
        result?.provider?.provider_facilities.map(item => data.push(item));

        setFacilities(data);
      } else {
        setFacilities([]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchProvider();
  }, []);

  useEffect(() => {
    if (refetch) {
      fetchProvider();
      setRefetch(false);
    }
  }, [refetch]);

  useEffect(() => {
    if (facilityId) {
      setIsDisabled(false);
    }
    if (!facilityId) {
      setIsDisabled(true);
    }
  }, [facilityId]);

  const facilityProviderClickHandler = async () => {
    try {
      await createFacilityProvider(
        {facility_id: facilityId, provider_id: id},
        'Facility has been assigned successfully!',
      );
      setRefetch(true);
    } catch (e) {
      console.log(e);
    }
  };

  const facilityProviderDeleteHandler = async id => {
    try {
      await deleteFacilityProvider(id, 'Facilty has been removed successfully!');
      setRefetch(true);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <AssignedFacilityProvider
      btnText="Add Facility"
      data={facilities}
      setDropDownId={setFacilityId}
      btnClickHandler={facilityProviderClickHandler}
      facilityProviderDeleteHandler={facilityProviderDeleteHandler}
      isDisabled={isDisabled}
      dropDownId={facilityId}
      isProviderView={true}
    />
  );
};
export default AssignedFacilities;
