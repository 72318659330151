import React from 'react';
import SelectComponent from 'components/common/select';
import SearchInput from 'components/common/table-wrapper/search-input';
import Pagination from 'components/common/table-wrapper/pagination';
import {entriesOptions} from 'constants/common';
import './table.scss';

const TableWraper = ({
  setPerPage,
  setSearchQuery,
  searchValue,
  totalListCount,
  pageSize,
  currentPage,
  onPageChange,
  actionButtons,
  children,
  showSearch = true,
  customActionButton = null,
}) => {
  return (
    <>
      <div className="table-main">
        <div className="table-head-content">
          <div className="search-otr">
            {showSearch && <SearchInput handleChange={setSearchQuery} value={searchValue} />}
            {customActionButton && customActionButton()}
            {actionButtons?.length > 0 &&
              actionButtons.map((item, index) => {
                return (
                  <button key={item.label + index} index={index} className={item.classes} onClick={item.handleClick}>
                    {item.label}
                  </button>
                );
              })}
          </div>
        </div>
        <div className="table-otr">
          {children}
          <div className="footer-table">
            <div className="d-flex align-items-center">
              <div className="select-main d-flex align-items-center">
                <p className="select-text heading-xs">Show</p>
                <SelectComponent
                  className="mx-2"
                  options={entriesOptions}
                  selectedValue={pageSize}
                  handleChange={setPerPage}
                  menuPlacement="top"
                />
                <p className="select-text heading-xs">Entries</p>
              </div>
              <div className="text-otr mx-3">
                <p className="entity-text heading-xs">
                  {`Showing ${(currentPage - 1) * pageSize + 1} to ${
                    (currentPage - 1) * pageSize > totalListCount ? totalListCount : currentPage * pageSize
                  } of ${totalListCount} Entries`}
                </p>
              </div>
            </div>
            <Pagination
              itemsCount={totalListCount}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={onPageChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TableWraper;
