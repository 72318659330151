import React from 'react';
import {useNavigate} from 'react-router-dom';

import './tabs.scss';

const Tabs = ({list, handleClick, activeTab = 0}) => {
  const navigate = useNavigate();
  return (
    <div className="tabs d-flex pb-3 gap-3 flex-wrap">
      {list.map(item => {
        return (
          <div key={item.value} className="tab">
            <p
              className={`text-truncate ${activeTab === item.value ? 'tab-btn-active' : 'tab-btn'}`}
              onClick={() => {
                handleClick(item);
                if (item?.path) navigate(item.path);
              }}
            >
              {item.title}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default Tabs;
