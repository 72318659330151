import CustomToast from 'components/common/custom-toast';

import {deleteApi, get, post, put} from 'services/network';
import {toast} from 'react-toastify';
import {alertTypes} from 'constants/common';

import {
  BACKEND_TABLES_PAY_METHOD,
  BACKEND_TABLES_PROVIDER_CATEGORY,
  BACKEND_TABLES_PROVIDER_TITLE,
  BACKEND_TABLES_FACILITY_TYPES,
  BACKEND_TABLES_PAYROLL_GROUPS,
  ENTITIES_ENDPOINT,
  PAYROLL_GROUP_ENTITIES,
} from 'constants/endpoints';

//PAY METHOD API'S
export const getPayMethodList = async obj => {
  const params = {
    sort: obj?.sort ? obj.sort : '',
    order: obj?.order ? obj.order : 'asc',
    page: obj?.page ? obj.page : 1,
    perPage: obj?.perPage ? obj.perPage : 10,
    search: obj?.search ? obj.search : '',
  };
  try {
    const result = await get(
      `${BACKEND_TABLES_PAY_METHOD}?page=${params.page}&sort=${params.sort}&order=${params.order}&per=${params.perPage}&query=${params.search}`,
      true,
    );
    return result;
  } catch (e) {
    console.log(e);
  }
};
export const createPayMethod = async (data, setErrors) => {
  try {
    const result = await post(BACKEND_TABLES_PAY_METHOD, data, true);
    if (result.meta.status === 200)
      toast(<CustomToast variant={alertTypes.SUCCESS} message="YeeHaw! Pay Method created successfully!" />);
    else {
      toast(<CustomToast variant={alertTypes.DANGER} message={result?.meta?.status} />);
    }
    return result;
  } catch (error) {
    if (error?.response?.data) {
      console.log('ali');
      setErrors(error?.response?.data.errors);
    }
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={error?.response?.data?.message || error?.response?.statusText}
      />,
    );
    console.log('error', error);
  }
};
export const updatePayMethod = async (data, setErrors) => {
  const id = data.id;
  delete data.id;
  try {
    const result = await put(BACKEND_TABLES_PAY_METHOD + `/${id}`, data, true);
    if (result.meta.status === 200)
      toast(<CustomToast variant={alertTypes.SUCCESS} message="Payment Method updated successfully!" />);
    else {
      toast(<CustomToast variant={alertTypes.DANGER} message={result?.meta?.status} />);
    }
    return result;
  } catch (error) {
    if (error?.response?.data) {
      setErrors(error?.response?.data.errors);
    }
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={error?.response?.data?.message || error?.response?.statusText}
      />,
    );
    console.log('error', error);
  }
};

//PROVIDER CATEGORY API'S
export const getProviderCategoryList = async obj => {
  const params = {
    sort: obj?.sort ? obj.sort : '',
    order: obj?.order ? obj.order : 'asc',
    page: obj?.page ? obj.page : 1,
    perPage: obj?.perPage ? obj.perPage : 10,
    search: obj?.search ? obj.search : '',
  };
  try {
    const result = await get(
      `${BACKEND_TABLES_PROVIDER_CATEGORY}?page=${params.page}&sort=${params.sort}&order=${params.order}&per=${params.perPage}&query=${params.search}`,
      true,
    );
    return result;
  } catch (e) {
    console.log(e);
  }
};
export const createProviderCategory = async (data, setErrors) => {
  try {
    const result = await post(BACKEND_TABLES_PROVIDER_CATEGORY, data, true);
    if (result.meta.status === 200)
      toast(<CustomToast variant={alertTypes.SUCCESS} message="Provider Category created successfully!" />);
    else {
      toast(<CustomToast variant={alertTypes.DANGER} message={result?.meta?.status} />);
    }
    return result;
  } catch (error) {
    if (error?.response?.data) {
      setErrors(error?.response?.data.errors);
    }
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={error?.response?.data?.message || error?.response?.statusText}
      />,
    );
    console.log('error');
  }
};
export const updateProviderCategory = async (data, setErrors) => {
  const id = data.id;
  delete data.id;
  try {
    const result = await put(BACKEND_TABLES_PROVIDER_CATEGORY + `/${id}`, data, true);
    if (result.meta.status === 200)
      toast(<CustomToast variant={alertTypes.SUCCESS} message="Great job! Provider Category updated successfully." />);
    else {
      toast(<CustomToast variant={alertTypes.DANGER} message={result?.meta?.status} />);
    }
    return result;
  } catch (error) {
    if (error?.response?.data) {
      setErrors(error?.response?.data.errors);
    }
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={error?.response?.data?.message || error?.response?.statusText}
      />,
    );
    console.log('error');
  }
};

// PROVIDER TITLES API'S

export const getProviderTitleList = async obj => {
  const params = {
    sort: obj?.sort ? obj.sort : '',
    order: obj?.order ? obj.order : 'asc',
    page: obj?.page ? obj.page : 1,
    perPage: obj?.perPage ? obj.perPage : 10,
    search: obj?.search ? obj.search : '',
  };
  try {
    const result = await get(
      `${BACKEND_TABLES_PROVIDER_TITLE}?page=${params.page}&sort=${params.sort}&order=${params.order}&per=${params.perPage}&query=${params.search}`,
      true,
    );
    return result;
  } catch (e) {
    console.log(e);
  }
};
export const createProviderTitle = async (data, setErrors) => {
  try {
    const result = await post(BACKEND_TABLES_PROVIDER_TITLE, data, true);
    if (result.meta.status === 200)
      toast(<CustomToast variant={alertTypes.SUCCESS} message="Provider Title created successfully!" />);
    else {
      toast(<CustomToast variant={alertTypes.DANGER} message={result?.meta?.status} />);
    }
    return result;
  } catch (error) {
    if (error?.response?.data) {
      setErrors(error?.response?.data.errors);
    }
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={error?.response?.data?.message || error?.response?.statusText}
      />,
    );
    console.log('error');
  }
};
export const updateProviderTitle = async (data, setErrors) => {
  const id = data.id;
  delete data.id;
  try {
    const result = await put(BACKEND_TABLES_PROVIDER_TITLE + `/${id}`, data, true);
    if (result.meta.status === 200)
      toast(<CustomToast variant={alertTypes.SUCCESS} message="Provider Title updated successfully!" />);
    else {
      toast(<CustomToast variant={alertTypes.DANGER} message={result?.meta?.status} />);
    }
    return result;
  } catch (error) {
    if (error?.response?.data) {
      setErrors(error?.response?.data.errors);
    }
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={error?.response?.data?.message || error?.response?.statusText}
      />,
    );
    console.log('error');
  }
};

// FACILITY TYPE API'S

export const getFacilityTypeList = async obj => {
  const params = {
    sort: obj?.sort ? obj.sort : '',
    order: obj?.order ? obj.order : 'asc',
    page: obj?.page ? obj.page : 1,
    perPage: obj?.perPage ? obj.perPage : 10,
    search: obj?.search ? obj.search : '',
  };
  try {
    const result = await get(
      `${BACKEND_TABLES_FACILITY_TYPES}?page=${params.page}&sort=${params.sort}&order=${params.order}&per=${params.perPage}&query=${params.search}`,
      true,
    );
    return result;
  } catch (e) {
    console.log(e);
  }
};
export const createFacilityType = async (data, setErrors) => {
  try {
    const result = await post(BACKEND_TABLES_FACILITY_TYPES, data, true);
    if (result.meta.status === 200)
      toast(
        <CustomToast variant={alertTypes.SUCCESS} message="Work hard, play hard! Facility created successfully!" />,
      );
    else {
      toast(<CustomToast variant={alertTypes.DANGER} message={result?.meta?.status} />);
    }
    return result;
  } catch (error) {
    if (error?.response?.data) {
      setErrors(error?.response?.data.errors);
    }
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={error?.response?.data?.message || error?.response?.statusText}
      />,
    );
    console.log('error');
  }
};
export const updateFacilityType = async (data, setErrors) => {
  const id = data.id;
  delete data.id;
  try {
    const result = await put(BACKEND_TABLES_FACILITY_TYPES + `/${id}`, data, true);
    if (result.meta.status === 200)
      toast(<CustomToast variant={alertTypes.SUCCESS} message="Facility updated successfully!" />);
    else {
      toast(<CustomToast variant={alertTypes.DANGER} message={result?.meta?.status} />);
    }
    return result;
  } catch (error) {
    if (error?.response?.data) {
      setErrors(error?.response?.data.errors);
    }
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={error?.response?.data?.message || error?.response?.statusText}
      />,
    );
    console.log('error');
  }
};
// PAYROLL GROUPS API'S

export const getPayrollGroupsList = async obj => {
  const params = {
    page: obj?.page ? obj.page : 1,
    perPage: obj?.perPage ? obj.perPage : 10,
    search: obj?.search ? obj.search : '',
  };
  try {
    const result = await get(
      `${BACKEND_TABLES_PAYROLL_GROUPS}?page=${params.page}&per=${params.perPage}&query=${params.search}`,
      true,
    );
    return result;
  } catch (e) {
    console.log(e);
  }
};
export const createPayrollGroup = async (data, setErrors) => {
  try {
    const result = await post(BACKEND_TABLES_PAYROLL_GROUPS, data, true);
    if (result.meta.status === 200)
      toast(<CustomToast variant={alertTypes.SUCCESS} message="Payroll Group created successfully!" />);
    else {
      toast(<CustomToast variant={alertTypes.DANGER} message={result?.meta?.status} />);
    }
    return result;
  } catch (error) {
    if (error?.response?.data) {
      setErrors(error?.response?.data.errors);
    }
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={error?.response?.data?.message || error?.response?.statusText}
      />,
    );
    console.log('error');
  }
};
export const updatePayrollGroups = async (data, setErrors) => {
  const id = data.id;
  delete data.id;
  try {
    const result = await put(BACKEND_TABLES_PAYROLL_GROUPS + `/${id}`, data, true);
    if (result.meta.status === 200)
      toast(
        <CustomToast variant={alertTypes.SUCCESS} message="Payroll Group updated successfully! You deserve a break!" />,
      );
    else {
      toast(<CustomToast variant={alertTypes.DANGER} message={result?.meta?.status} />);
    }
    return result;
  } catch (error) {
    if (error?.response?.data) {
      setErrors(error?.response?.data.errors);
    }
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={error?.response?.data?.message || error?.response?.statusText}
      />,
    );
    console.log('error');
  }
};
export const deletePayrollGroup = async id => {
  try {
    const response = await deleteApi(`${BACKEND_TABLES_PAYROLL_GROUPS}/${id}/`, true);
    if (response?.status === 200)
      toast(<CustomToast variant={alertTypes.SUCCESS} message="Payroll Group deactivated successfully!" />);
    else {
      toast(<CustomToast variant={alertTypes.DANGER} message={response?.status} />);
    }
    return response;
  } catch (error) {
    console.error(error);
  }
};
export const getEntitesList = async (query = '', status, sort, order) => {
  try {
    const result = await get(
      `${ENTITIES_ENDPOINT}?filter[status]=${status}&sort=${sort}&order=${order}&query=${query}`,
      true,
    );
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const deletePayrollGroupEntity = async id => {
  try {
    const response = await deleteApi(`${PAYROLL_GROUP_ENTITIES}/${id}/`, true);
    if (response?.status === 200)
      toast(<CustomToast variant={alertTypes.SUCCESS} message="Payroll Entity deactivated successfully!" />);
    else {
      toast(<CustomToast variant={alertTypes.DANGER} message={response?.status} />);
    }
    return response;
  } catch (error) {
    console.error(error);
  }
};
