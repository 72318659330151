import React, {useState, useEffect} from 'react';
import CustomModal from 'components/common/modal';
import {Formik} from 'formik';

import {
  createFacilityInitialValues as initialValues,
  FacilityValidationSchema as validationSchema,
} from 'constants/facilities';
import {Spinner} from 'react-bootstrap';
import {getEntitiesList, getUsersByFilter} from 'containers/providers/api';
import {createFacility, getFacilitiesTypes} from 'containers/facilities/api';
import {DEBOUNCE_DELAY} from 'utils/common';
import FacilityForm from 'components/facilities/facility-form';
import debounce from 'debounce-promise';

const CreateFacilityModal = ({open, close, refetchFacilitites}) => {
  const [entities, setEntities] = useState([]);
  const [users, setUsers] = useState([]);
  const [facilitiesTypes, setFacilitiesTypes] = useState([]);
  const [dropdownLoading, setDropdownLoading] = useState({
    entities: false,
    users: false,
    facilitiesType: false,
  });

  const handleCreateFacility = async (values, setErrors, setSubmitting) => {
    const data = {
      facility: {
        ...values,
        phone_no: values.phone_no.replaceAll(' ', ''),

        address_attributes: {
          state: values.state,
          county: values.county,
          zip_code: values.zip_code,
          country: 'America',
          street: values.street,
          city: values.city,
        },
      },
    };
    try {
      setSubmitting(true);
      const result = await createFacility(data, setErrors);
      if (result.meta.status === 200) {
        setSubmitting(false);
        close();
        refetchFacilitites();
      }
    } catch (e) {
      console.log(e);
    } finally {
      setSubmitting(false);
    }
  };

  async function fetchEntities() {
    const result = await getEntitiesList('active');
    if (result['entities']) {
      setEntities(result['entities'].map(item => ({value: item.id, label: item.name})));
    }
  }

  async function fetchFacilitiesTypes() {
    const result = await getFacilitiesTypes('active');
    if (result['facility_types']) {
      setFacilitiesTypes(result['facility_types'].map(item => ({value: item.id, label: item.name})));
    }
  }

  async function fetchUsers() {
    const result = await getUsersByFilter('director');
    if (result['users']) {
      setUsers(result['users'].map(item => ({value: item.id, user_id: item.id, label: item.full_name})));
    }
  }

  const fetchEntitiessData = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, entities: true});
    try {
      const result = await getEntitiesList('active', search);
      if (result['entities']) {
        const data = result?.entities.map(item => ({value: item.id, label: item.name}));
        setEntities(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, entities: false});
    }
  }, DEBOUNCE_DELAY);

  const fetchUsersData = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, users: true});
    try {
      const result = await getUsersByFilter('director', null, search);
      if (result['users']) {
        const data = result?.users.map(item => ({value: item.id, user_id: item.id, label: item.full_name}));
        setUsers(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, entities: false});
    }
  }, DEBOUNCE_DELAY);

  const fetchFacilitiesTypeData = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, facilitiesType: true});
    try {
      const result = await getFacilitiesTypes('active');
      if (result['facility_types']) {
        const data = result?.facility_types.map(item => ({value: item.id, label: item.name}));
        setFacilitiesTypes(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, facilitiesType: false});
    }
  }, DEBOUNCE_DELAY);
  const loadEntitiesDropdownOptions = search => fetchEntitiessData(search);
  const loadUsersDropdownOptions = search => fetchUsersData(search);
  const loadFacilitiesTypeDropdownOptions = search => fetchFacilitiesTypeData(search);

  useEffect(() => {
    fetchEntities();
    fetchUsers();
    fetchFacilitiesTypes();
  }, []);

  return (
    <CustomModal show={open} onHide={close} heading="Create Facility">
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values, {setErrors, setSubmitting}) => handleCreateFacility(values, setErrors, setSubmitting)}
      >
        {formik => {
          return (
            <>
              <h4>Create Facility</h4>
              <FacilityForm
                formik={formik}
                entities={entities}
                users={users}
                facilitiesTypes={facilitiesTypes}
                loadEntitiesDropdownOptions={loadEntitiesDropdownOptions}
                dropdownLoading={dropdownLoading}
                loadUsersDropdownOptions={loadUsersDropdownOptions}
                loadFacilitiesTypeDropdownOptions={loadFacilitiesTypeDropdownOptions}
              />

              <button
                type="button"
                className={`primary-btn-small record-btn mt-3 `}
                onClick={formik.handleSubmit}
                disabled={formik.isSubmitting}
              >
                {formik.isSubmitting ? (
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                ) : (
                  'Save'
                )}
              </button>
            </>
          );
        }}
      </Formik>
    </CustomModal>
  );
};

export default CreateFacilityModal;
