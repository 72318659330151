import {post} from 'services/network';
import {FACILITY_UNITS_REPORTS_ENDPOINT} from 'constants/endpoints';
import moment from 'moment';

export const getFacilityUnitsReport = async (filters, query = '') => {
  try {
    const entities = filters.entities.split(',');
    const result = await post(
      FACILITY_UNITS_REPORTS_ENDPOINT,
      {
        query,
        start_date: moment(filters.startDate).format('YYYY-MM-DD'),
        end_date: moment(filters.endDate).format('YYYY-MM-DD'),
        entities,
      },
      true,
    );
    return result;
  } catch (e) {
    console.log(e);
  }
};
