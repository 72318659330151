import React, {useState} from 'react';

import FilterWrapper from 'components/common/filter-wrapper';
import Filter from 'components/providers/Filter';
import BreadcrumbTrail from 'components/common/breadcrumb-trail';
import 'containers/providers/provider.scss';
import ProvidersListing from 'components/providers/ProvidersListing';

const Providers = () => {
  const [refresh, setRefresh] = useState(false);
  const [filters, setFilters] = useState({status: 'active', users: '', states: '', entity_id: ''});

  const handleRefreshPage = () => {
    setRefresh(pre => !pre);
  };
  const handleClickOnFilter = values => {
    setFilters(values);
    handleRefreshPage();
  };

  return (
    <>
      <div className="providers-main">
        <div className="container-fluid">
          <div className="heading-path-otr">
            <h3 className="heading heading-h3">Providers</h3>
            <BreadcrumbTrail />
          </div>
          <FilterWrapper>
            <Filter handleSubmit={handleClickOnFilter} />
          </FilterWrapper>
          <ProvidersListing refreshByParent={refresh} filters={filters} />
        </div>
      </div>
    </>
  );
};

export default Providers;
