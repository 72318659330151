import React, {useState} from 'react';

import FilterWrapper from 'components/common/filter-wrapper';
import BreadcrumbTrail from 'components/common/breadcrumb-trail';
import Filter from 'components/looker-import-provider-data/Filter';
import RecordsListing from 'components/looker-import-provider-data/RecordListing';

const ProviderDataView = () => {
  const [refresh, setRefresh] = useState(false);
  const [filters, setFilters] = useState('error');
  const handleRefreshPage = () => {
    setRefresh(pre => !pre);
  };
  const handleClickOnFilter = values => {
    setFilters(values);
    handleRefreshPage();
  };
  return (
    <>
      <div className="looker-main">
        <div className="container-fluid">
          <div className="heading-path-otr">
            <h3 className="heading heading-h3">Records</h3>
            <BreadcrumbTrail />
          </div>

          <FilterWrapper>
            <Filter handleSubmit={handleClickOnFilter} filters={filters} />
          </FilterWrapper>
          <RecordsListing refreshByParent={refresh} filters={filters} />
        </div>
      </div>
    </>
  );
};

export default ProviderDataView;
