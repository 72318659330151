import React from 'react';
import './filter.scss';

const FilterWrapper = ({children}) => {
  return (
    <div className="filter-wrapper">
      <div className="wrapper">{children}</div>
    </div>
  );
};

export default FilterWrapper;
