import {classNameWithKeys, matchingWithKeys} from 'constants/calendar';

const getCommonEventValue = (event, key = null) => {
  return key !== null
    ? `${event?.earning_code} ${event[key][0]?.total_price_and_unit?.total_units?.toFixed(2)}U ${
        event[key][0]?.total_price_and_unit?.total_amount
      }`
    : '';
};

const MonthsEventView = ({events}) => {
  if (events?.length) {
    const slicedEvents = events.length > 1 ? events.slice(0, 2) : events;
    return slicedEvents.map((event, eventIndex) => {
      let objectHasvalue = null;
      Object.keys(event).forEach(keyName => {
        const index = matchingWithKeys.findIndex(item => item === keyName);
        if (index !== -1) {
          if (event[matchingWithKeys[index]][0]?.provider_data?.length) {
            objectHasvalue = matchingWithKeys[index];
          }
        }
      });

      return objectHasvalue === 'erroneous_provider_data' ? null : (
        <p
          key={event.earning_code + eventIndex}
          className={objectHasvalue !== null ? classNameWithKeys[objectHasvalue] : ''}
        >
          {getCommonEventValue(event, objectHasvalue)}
        </p>
      );
    });
  }
  return <p>-</p>;
};

export default MonthsEventView;
