import TableWraper from 'components/common/table-wrapper';
import {Trash} from 'phosphor-react';

const PaymentEntitiesListing = ({
  loading,
  handleSetPerPage,
  paymentEntitiesList,
  handleSetSearchQuery,
  meta,
  totalCount,
  handlePageChange,
  handleDeletePaymentEntity,
}) => {
  return (
    <div style={{marginTop: '-30px'}}>
      {/* <div className="heading-path-otr">
        <h6 className="heading heading-h6">Payment Entities</h6>
      </div> */}
      <TableWraper
        setPerPage={handleSetPerPage}
        setSearchQuery={handleSetSearchQuery}
        searchValue={meta.search}
        totalListCount={totalCount}
        pageSize={meta.perPage}
        currentPage={meta.page}
        onPageChange={handlePageChange}
        actionButtons={[]}
        showSearch={false}
      >
        {loading ? (
          <h3 style={{textAlign: 'center'}}>Loading</h3>
        ) : (
          <table className="table">
            <thead>
              <tr>
                <th scope="col">
                  <div>
                    <p className="table-name heading-xsb">Entity Name</p>
                  </div>
                </th>
                <th scope="col">
                  <div>
                    <p className="table-name heading-xsb">ADP File Number</p>
                  </div>
                </th>
                <th scope="col">
                  <div>
                    <p className="table-name heading-xsb">Action</p>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {paymentEntitiesList.map(item => {
                return (
                  <tr key={item.id}>
                    <th scope="col">
                      <div>
                        <p className="table-name heading-xsb">{item.entity.name}</p>
                      </div>
                    </th>
                    <td>
                      <div>
                        <p className="table-text">{item.adp_number}</p>
                      </div>
                    </td>
                    <td className="action-column">
                      <div className="table-icon-otr">
                        <div className="icon-otr" onClick={() => handleDeletePaymentEntity(item.id)}>
                          <Trash size={24} />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </TableWraper>
    </div>
  );
};

export default PaymentEntitiesListing;
