import React, {useEffect, useState} from 'react';
import SelectComponent from 'components/common/select';
import debounce from 'debounce-promise';
import {Row, Col} from 'react-bootstrap';
import {getProvidersList} from 'containers/providers/api';
import {getFacilityByProvider} from 'containers/facilities/api';
import AsyncSelectComponent from 'components/common/async-select';
import {DEBOUNCE_DELAY} from 'utils/common';

const CalendarFilter = ({handleSubmit, filters}) => {
  const [filterState, setFilterState] = useState(filters);
  const [providers, setProviders] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [providerId, setProviderId] = useState('');
  const [facilityId, setFacilityId] = useState('');
  const [dropdownLoading, setDropdownLoading] = useState({
    facilities: false,
    providers: false,
  });
  async function fetchProviders() {
    const result = await getProvidersList({status: 'active'});
    if (result['providers']) {
      setProviders(result['providers'].map(item => ({value: item.id, label: item.first_name + ' ' + item.last_name})));
    }
  }
  useEffect(() => {
    fetchProviders();
  }, []);

  const fetchProvidersData = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, providers: true});
    try {
      const result = await getProvidersList({status: 'active', search});
      if (result['providers']) {
        const data = result['providers'].map(item => ({value: item.id, label: item.name}));
        setProviders(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, providers: false});
    }
  }, DEBOUNCE_DELAY);
  const getFacilityById = async id => {
    const result = await getFacilityByProvider(id);
    if (result?.length) {
      setFacilities(result.map(item => ({value: item.id, label: item.name})));
    } else {
      setFacilities([]);
    }
  };
  const loadProvidersDropdownOptions = search => fetchProvidersData(search);

  return (
    <div className="adjustments-filter">
      <h5>Filters</h5>
      <Row>
        <Col md={6} className="mt-4">
          <p className="mb-2">Provider</p>
          {/* <SelectComponent
            options={providers}
            selectedValue={providerId}
            handleChange={obj => {
              setProviderId(obj.value);
              setFacilityId('');
              getFacilityById(obj.value);
            }}
            placeholder="Select Provider"
          /> */}
          <AsyncSelectComponent
            isLoading={dropdownLoading.providers}
            selectedValue={filterState?.providerId || ''}
            defaultOptions={providers}
            loadOptions={loadProvidersDropdownOptions}
            onChange={obj => {
              setProviderId(obj.value);
              setFilterState(pre => ({...pre, providerId: obj.value, facilityId: ''}));
              setFacilityId('');
              getFacilityById(obj.value);
            }}
            placeholder="Search and select"
          />
        </Col>
        <Col md={6} className="mt-4">
          <p className="mb-2">Facility</p>
          <SelectComponent
            options={facilities}
            selectedValue={facilityId}
            handleChange={obj => {
              setFacilityId(obj.value);
              setFilterState(pre => ({...pre, facilityId: obj.value}));
            }}
            placeholder="Select Status"
          />
        </Col>
      </Row>
      <div className="filter-search-otr mt-3">
        {/* <SearchInput /> */}
        <div
          className={`action ${facilityId || providerId ? '' : 'disabled'}`}
          onClick={() => {
            handleSubmit({providerId, facilityId});
          }}
        >
          <p className="primary-btn-small record-btn">Apply</p>
        </div>
      </div>
    </div>
  );
};

export default CalendarFilter;
