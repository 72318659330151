import React from 'react';
import {CaretRight, CaretLeft} from 'phosphor-react';
import PaginationComponent from 'rc-pagination';

const Pagination = ({itemsCount, currentPage, onPageChange, pageSize}) => {
  return (
    <div className="pagination-main">
      <nav aria-label="...">
        <ul className="pagination">
          <PaginationComponent
            className="pagination"
            onChange={onPageChange}
            current={currentPage}
            total={itemsCount}
            pageSize={pageSize || 0}
            showTitle={false}
            hideOnSinglePage
            showLessItems
            showSizeChanger={false}
            itemRender={(current, type, element) => (
              <p className="page-link page-linkk-otr">
                <span className="page-linkk heading-xsb">{element}</span>
              </p>
            )}
            prevIcon={
              <p className="page-link icon-otr">
                <CaretLeft size={20} />
              </p>
            }
            nextIcon={
              <p className="page-link icon-otr">
                <CaretRight size={20} />
              </p>
            }
          />
        </ul>
      </nav>
    </div>
  );
};

export default Pagination;
