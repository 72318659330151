import React, {useEffect, useState} from 'react';

import CustomModal from 'components/common/modal';

import {getUsersByRoleId} from 'containers/roles/api';

const UserCountModal = ({open, id, close}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const result = await getUsersByRoleId(id);
      if (result?.users) {
        setData(result.users);
      }
    }
    fetchData();
  }, [id]);

  return (
    <CustomModal show={open} onHide={() => close()} heading="User Count">
      <div className="table-otr">
        <table className="table secondary-table">
          <thead className="secondary-head">
            <tr>
              <th scope="col">
                <div className="header-text-otr">
                  <p className="table-name heading-xsb">First Name</p>
                </div>
              </th>
              <th scope="col">
                <div className="header-text-otr">
                  <p className="table-name heading-xsb">Last Name</p>
                </div>
              </th>
              <th scope="col">
                <div className="header-text-otr">
                  <p className="table-name heading-xsb">Email</p>
                </div>
              </th>
              <th scope="col">
                <div className="header-text-otr">
                  <p className="table-name heading-xsb">Last Login</p>
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="secondary-body">
            {data?.map(item => {
              return (
                <tr key={item.id}>
                  <th scope="row">
                    <p className="table-text">{item?.first_name || ''}</p>
                  </th>
                  <td>
                    <p className="table-text">{item?.last_name || ''}</p>
                  </td>
                  <td>
                    <p className="table-text">{item?.email || ''}</p>
                  </td>
                  <td>
                    <p className="table-text">{item?.last_login || ''}</p>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </CustomModal>
  );
};

export default UserCountModal;
