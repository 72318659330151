import {appendForMail, roleTypes} from 'constants/common';
import {DASHBOARD_PATH} from 'constants/routePaths';
import {isEmpty, startCase, isArray, isPlainObject, forEach, drop} from 'lodash';
import Papa from 'papaparse';
import React from 'react';
import {useLocation} from 'react-router-dom';
import * as XLSX from 'xlsx';
export const getParsedJsonResults = data => {
  const {results} = JSON.parse(data);
  return results;
};

export const getAccessToken = () => {
  return localStorage.getItem('access_token');
};

export const getUserInfoFromStorage = () => {
  return JSON.parse(localStorage.getItem('uuInfo'));
};

export const setAccessToken = accessToken => {
  return localStorage.setItem('access_token', accessToken);
};

export const setUserInfoInStorage = (userInfo = {}) => {
  return localStorage.setItem('uuInfo', JSON.stringify(userInfo));
};

export const removeAccessToken = () => {
  localStorage.removeItem('access_token');
};

export const removeUserInfoFromStorage = () => {
  localStorage.removeItem('uuInfo');
};

export const getLoggedInUserRole = () => {
  const user = getUserInfoFromStorage();
  if (!isEmpty(user)) {
    return user.role_id;
  }

  return -1;
};

export const isAdministrator = () => {
  const role = getLoggedInUserRole();
  return role === roleTypes.ADMINISTRATOR;
};

export const isPayRoll = () => {
  const role = getLoggedInUserRole();
  return role === roleTypes.PAYROLL;
};
export const isBiller = () => {
  const role = getLoggedInUserRole();
  return role === roleTypes.BILLER;
};

export const isProvider = () => {
  const role = getLoggedInUserRole();
  return role === roleTypes.PROVIDER;
};

export const checkUserLoggedInStatus = loginData => {
  if (loginData?.status_code === 200 || getAccessToken()) {
    return true;
  }

  return false;
};
export const getNameInitials = nameString => {
  const fullName = nameString?.split(' ');
  if (fullName?.length > 0) {
    let initials = fullName.shift().charAt(0);
    if (fullName.length) initials += fullName.pop()?.charAt(0);
    return initials.toUpperCase();
  }
  return nameString ? nameString[0] : '';
};

export const DEBOUNCE_DELAY = 700;

export const getBreadcrumbs = pathname => {
  const urlPath = pathname.split('/');
  const [, ...usablePath] = urlPath;

  let breadCrumbs = [
    {
      name: 'Home',
      route: DASHBOARD_PATH,
    },
  ];
  let fullTrailPath = '/';

  forEach(usablePath, (path, i) => {
    fullTrailPath = fullTrailPath + `${path + (i !== usablePath.length - 1 ? '/' : '')}`;
    breadCrumbs.push({
      name: startCase(path),
      route: fullTrailPath,
    });
  });

  // In case of edit path remove the id before edit
  if (pathname.includes('edit')) {
    breadCrumbs.splice(breadCrumbs.length - 2, 1);
  }
  if (pathname.includes('provider-rate-history')) {
    breadCrumbs.splice(breadCrumbs.length - 2, 1);
  }

  return breadCrumbs;
};

export const getActionButtonProps = (label, handleClick) => {
  return [
    {
      label,
      handleClick,
      classes: 'primary-btn-small record-btn',
    },
  ];
};
export const getActionButtonObj = (label, handleClick) => {
  return {
    label,
    handleClick,
    classes: 'primary-btn-small record-btn',
  };
};
export const statusList = [
  {value: 'inactive', label: 'Inactive'},
  {value: 'active', label: 'active'},
  {value: 'pending', label: 'Pending'},
  {value: 'currently_working', label: 'Currently Working'},
  {value: 'transitioning_out', label: 'Transitioning Out'},
  {value: 'leave_of_absence', label: 'Leave Of Absense'},
];
export const regionalDirectories = [
  {value: 'USA', label: 'USA'},
  {value: 'UAE', label: 'UAE'},
];

export const normalizeInput = (value, previousValue) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 4) return currentValue;
    if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
  }
};

export const checkCsvFormate = async (file, formate, extension) => {
  let check = false;

  const parseFile = file => {
    if (extension === 'xlsx') {
      return new Promise(resolve => {
        const reader = new FileReader();
        reader.onload = function (e) {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, {type: 'array'});
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          const jsonData = XLSX.utils.sheet_to_json(worksheet, {header: 1});
          const rowsArray = jsonData.map(row => Object.values(row));

          if (rowsArray && rowsArray[0].length >= formate.length) {
            const check = formate.every(element => {
              return rowsArray[0].includes(element);
            });
            resolve(check);
          }
        };
        reader.readAsArrayBuffer(file.file);
      });
    }

    return new Promise(resolve => {
      Papa.parse(file.file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          const rowsArray = [];

          if (results?.data[0]) {
            rowsArray.push(...Object.keys(results.data[0]).map(item => item.toLowerCase()));

            if (rowsArray.length >= formate.length) {
              check = formate.every(element => {
                return rowsArray.includes(element.toLowerCase());
              });
            }
          }
          resolve(check);
        },
      });
    });
  };
  let parsedCheck = await parseFile(file);
  return parsedCheck;
};

export const createdAtFormat = text => {
  let formate = text.split('at');
  if (formate?.length > 1) {
    return (
      <>
        {formate[0]}
        <br />
        {`at ${formate[1]}`}
      </>
    );
  }
  return text;
};
export function getPaginatedItems(items, page, pageSize) {
  let pg = page || 1,
    pgSize = pageSize || 100,
    offset = (pg - 1) * pgSize,
    pagedItems = drop(items, offset).slice(0, pgSize);
  return {
    page: pg,
    pageSize: pgSize,
    total: items.length,
    total_pages: Math.ceil(items.length / pgSize),
    data: pagedItems,
  };
}

export function useQuery() {
  const {search} = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const getCompanyMailUser = (companyMail = '') => {
  if (companyMail.length > 0) {
    const [user] = companyMail.split(appendForMail);
    return user;
  }
  return '';
};

export function appendObjectOnEditDropdown(list, payload) {
  if (isArray(payload)) {
    const arr = payload.filter(item => list.findIndex(item2 => item2.value === item.value) <= 0) || [];
    if (arr.length > 0) {
      return [...list, ...arr];
    }
  }

  if (isPlainObject(payload) && payload?.label && payload?.value) {
    const listIndex = list.findIndex(item => item.value === payload.value);
    if (listIndex <= 0) {
      return [...list, payload];
    }
  }

  return list;
}

export const getCurrencyAndFormattedValue = amount => {
  if (amount.length > 0) {
    let [symbol, value] = amount.split('$');
    const formattedValue = new Intl.NumberFormat('en-US', {currency: 'USD'}).format(value);
    symbol = '$';

    return symbol + formattedValue;
  }

  return '';
};

export const isPaid = paidType => paidType === 'paid';
