import React, {useEffect, useState, useCallback, useRef} from 'react';
import TableWraper from 'components/common/table-wrapper';
import debounce from 'lodash/debounce';

import {getActionButtonProps, isAdministrator, isBiller, isPayRoll} from 'utils/common';
import {PencilSimple, Trash, Eye, ClockCounterClockwise, ArrowsDownUp} from 'phosphor-react';
import {getProviderRatesList, deleteProviderRates} from 'containers/provider-rates/api';
import {DEBOUNCE_DELAY} from 'utils/common';
import {alertTypes, initialMetaForTable, orderStratergy} from 'constants/common';
import {PROVIDER_RATES_PATH} from 'constants/routePaths';
import {useNavigate} from 'react-router-dom';
import {CREATE_PROVIDER_RATES_PATH} from 'constants/routePaths';
import ShowProviderRates from './show-provider-rates';
import ConfirmationModal from 'components/common/confirmation-modal';
import {startCase} from 'lodash';
import {Dropdown} from 'react-bootstrap';
import {CSVLink} from 'react-csv';
import {toast} from 'react-toastify';
import CustomToast from 'components/common/custom-toast';

const headers = [
  {label: 'Provider Name', key: 'provider.first_name'},
  {label: 'Unit in minutes', key: 'earning_code.unit_in_minutes'},
  {label: 'Initial charge amount', key: 'initial_unit_charge_amount'},
  {label: 'Earning Code', key: 'earning_code.code'},
  {label: 'Status', key: 'active'},
];

const ProviderRatesListing = ({filters, refreshByParent, setRefreshByParent}) => {
  const navigate = useNavigate();
  const exportRef = useRef(null);
  const [allListSelected, setAllListSelected] = useState(false);
  const [providerRatesList, setProviderRatesList] = useState([]);
  const [refreshPage, setRefreshPage] = useState(true);
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState(initialMetaForTable);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [providerInformation, setProviderInformation] = useState(false);
  const [deletableItem, setDeleteableItem] = useState(null);
  const [isDeleting, setDeleting] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [isSorted, setSorted] = useState(false);

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await getProviderRatesList({...meta, ...filters, active: true});

      if (result['provider_rates']) {
        const data = result?.provider_rates.map(item => ({...item, isSelected: allListSelected}));
        setProviderRatesList(data);
        setTotalCount(result?.meta.total_count);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
      setRefreshPage(false);
      setRefreshByParent(false);
    }
  };

  useEffect(() => {
    if (refreshPage || refreshByParent) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage, refreshByParent]);
  useEffect(() => {
    setCsvData(providerRatesList.filter(item => item.isSelected));
  }, [providerRatesList]);

  const handleConfirmModalToggle = ({itemId = null}) => {
    setDeleteableItem(itemId);
  };

  const deleteProviderRate = async () => {
    setDeleting(true);
    const res = await deleteProviderRates(deletableItem);
    if (res) {
      setDeleteableItem(null);
      setDeleting(false);
      handleRefreshPage();
    }
  };

  const handleRefreshPage = () => {
    setRefreshPage(true);
  };
  const handleSetPerPage = obj => {
    setMeta(pre => ({...pre, perPage: obj.value}));
    handleRefreshPage();
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(debounce(handleRefreshPage, DEBOUNCE_DELAY), [meta.search]);
  const handleSetSearchQuery = value => {
    setMeta(pre => ({...pre, search: value}));
    debounceFn();
  };
  const handlePageChange = value => {
    setMeta(pre => ({...pre, page: value}));
    handleRefreshPage();
    handleRefreshPage();
  };

  const setAllListCheck = value => {
    setProviderRatesList(pre => pre.map(item => ({...item, isSelected: value})));
  };
  const setSingleItemCheck = (value, id) => {
    setProviderRatesList(pre => pre.map(item => (item.id === id ? {...item, isSelected: value} : item)));
  };
  const handleClickAddProvider = () => {
    navigate(CREATE_PROVIDER_RATES_PATH);
  };

  const handleCloseProviderInformation = () => {
    setProviderInformation(false);
    setSelectedRow(null);
  };
  const handleExport = done => {
    exportRef.current.link.click();
    if (csvData.length) {
      done(true);
    } else {
      toast(<CustomToast variant={alertTypes.DANGER} message={'Please Select Record!'} />);
      done(false);
    }
  };
  const sortedDataByColumn = (columnName, isAlreadySorted) => {
    if (providerRatesList.length > 0) {
      setMeta(pre => ({...pre, sort: columnName, order: orderStratergy[pre.order]}));
      handleRefreshPage();
    }
  };
  return (
    <>
      <TableWraper
        setPerPage={handleSetPerPage}
        setSearchQuery={handleSetSearchQuery}
        searchValue={meta.search}
        totalListCount={totalCount}
        pageSize={meta.perPage}
        currentPage={meta.page}
        onPageChange={handlePageChange}
        actionButtons={[
          ...getActionButtonProps('Add Rate', handleClickAddProvider),
          ...getActionButtonProps('Export', () => {
            exportRef.current.link.click();
          }),
        ]}
      >
        {loading || refreshPage ? (
          <h3 style={{textAlign: 'center'}}>Loading</h3>
        ) : (
          <table className="table provider-rate">
            <thead>
              <tr>
                {/* <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">
                      <input
                        className="form-check-input"
                        onChange={e => {
                          setAllListSelected(e.target.checked);
                          setAllListCheck(e.target.checked);
                        }}
                        type="checkbox"
                        checked={allListSelected}
                      />
                    </p>
                  </div>
                </th> */}
                <th scope="col">
                  <div className="header-text-otr">
                    <p
                      className="table-name heading-xsb cursor-pointer c-hand"
                      onClick={() => sortedDataByColumn('name', isSorted)}
                    >
                      Provider Name
                      
                    </p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p
                      className="table-name heading-xsb cursor-pointer c-hand"
                    >
                      Provider Category
                    </p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p
                      className="table-name heading-xsb cursor-pointer c-hand"
                    >
                      Entities
                    </p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p
                      className="table-name heading-xsb"
                      onClick={() => sortedDataByColumn('unit_in_minutes', isSorted)}
                    >
                      Unit in minutes 
                    </p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p
                      className="table-name heading-xsb"
                      onClick={() => sortedDataByColumn('initial_unit_charge_amount', isSorted)}
                    >
                      Initial Charge Amount 
                    </p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb" onClick={() => sortedDataByColumn('code', isSorted)}>
                      Earning Code 
                    </p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Status</p>
                  </div>
                </th>
                {(isAdministrator() || isPayRoll() || isBiller()) && (
                  <th scope="col">
                    <div className="header-text-otr">
                      <p className="table-name heading-xsb">Actions</p>
                    </div>
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {providerRatesList.map(item => {
                return (
                  <tr key={item.id}>
                    {/* <th scope="col">
                      <div className="header-text-otr">
                        <p className="table-name heading-xsb">
                          <input
                            className="form-check-input"
                            value={item.isSelected}
                            onChange={e => {
                              setSingleItemCheck(e.target.checked, item.id);
                            }}
                            type="checkbox"
                            checked={item.isSelected}
                          />
                        </p>
                      </div>
                    </th> */}

                    <th scope="col">
                      <div className="">
                        <p className="table-text ">
                          {`${item?.provider?.last_name}, ${item?.provider?.first_name}` || ''}
                        </p>
                      </div>
                    </th>
                    <th scope="col">
                      <div className="">
                        <p className="table-text ">
                          {item?.provider_category?.code || ''}
                        </p>
                      </div>
                    </th>
                    <th scope="col">
                      <div className="">
                        <p className="table-text ">
                          {item?.entities}
                        </p>
                      </div>
                    </th>
                    <td>
                      <div className="table-text-otr">
                        <p className="table-text ">{item?.earning_code?.unit_in_minutes || ''}</p>
                      </div>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <p className="table-text ">{item?.initial_unit_charge_amount || ''}</p>
                      </div>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <p className="table-text ">{item?.earning_code?.code || ''}</p>
                      </div>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <p className={`table-text heading-xsb ${item?.active ? 'active' : 'in-active'}`}>
                          {startCase(item.active ? 'Active' : 'Inactive')}
                        </p>
                      </div>
                    </td>
                    {(isAdministrator() || isPayRoll() || isBiller()) && (
                      <td className="action-column">
                        <div className="table-icon-otr">
                          <Dropdown>
                            <Dropdown.Toggle variant="white" id="dropdown-basic">
                              ...
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => {
                                  setSelectedRow(item);
                                  setProviderInformation(true);
                                }}
                              >
                                <div className="icon-otr">
                                  <span className="me-2">
                                    <Eye size={24} />
                                  </span>
                                  <span>Details</span>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => {
                                  navigate(PROVIDER_RATES_PATH + `/${item.id}/edit`);
                                }}
                              >
                                <div className="icon-otr">
                                  <span className="me-2">
                                    <PencilSimple size={24} />
                                  </span>
                                  <span>Edit</span>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => {
                                  navigate(PROVIDER_RATES_PATH + `/${item.id}/provider-rate-history`);
                                }}
                              >
                                <div className="icon-otr">
                                  <span className="me-2">
                                    <ClockCounterClockwise size={24} />
                                  </span>
                                  <span>History</span>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item onClick={e => handleConfirmModalToggle({...e, itemId: item.id})}>
                                <div className="icon-otr">
                                  <span className="me-2">
                                    <Trash size={24} />
                                  </span>
                                  <span>Delete</span>
                                </div>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </TableWraper>
      <CSVLink
        ref={exportRef}
        data={csvData}
        headers={headers}
        asyncOnClick={true}
        onClick={(event, done) => {
          handleExport(done);
        }}
        filename={'my-file.csv'}
        className="btn btn-primary opacity-0 w-0 h-0 position-absolute top-0"
        target="_blank"
      />
      {providerInformation && (
        <ShowProviderRates
          isModalVisible={true}
          handleCloseModal={handleCloseProviderInformation}
          selectedRow={selectedRow}
        />
      )}
      <ConfirmationModal
        isDeleting={isDeleting}
        isModalVisible={deletableItem}
        handleCloseModal={handleConfirmModalToggle}
        handleConfirmClose={deleteProviderRate}
      />
    </>
  );
};

export default ProviderRatesListing;
