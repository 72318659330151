import React from 'react';
import CustomModal from 'components/common/modal';
import {Col, Row} from 'react-bootstrap';

const ShowProviderRates = ({selectedRow, handleCloseModal, isModalVisible}) => {
  return (
    <CustomModal size="lg" show={isModalVisible} onHide={handleCloseModal} heading={`View Provider`}>
      <Row className="gap-3">
        <Col md={3}>
          <h5>Provider Name</h5>
          <p>{`${selectedRow?.provider?.last_name}, ${selectedRow?.provider?.first_name}` || '-'}</p>
        </Col>
        <Col md={3}>
          <h5>Provider Category</h5>
          <p>{selectedRow?.name || '-'}</p>
        </Col>
        <Col md={5}>
          <h5>Entity</h5>
          <p>{selectedRow?.email || '-'}</p>
        </Col>
        <Col md={3}>
          <h5>Earning Code</h5>
          <p>{selectedRow?.earning_code?.code || '-'}</p>
        </Col>
        <Col md={3}>
          <h5>Units In Minutes</h5>
          <p>{selectedRow?.earning_code?.unit_in_minutes || '-'}</p>
        </Col>
        <Col md={3}>
          <h5>Unit Charge Amount</h5>
          <p>{selectedRow?.initial_unit_charge_amount || '-'}</p>
        </Col>
      </Row>
    </CustomModal>
  );
};

export default ShowProviderRates;
