import React, {useState, useEffect} from 'react';
import FacilityForm from 'components/facilities/facility-form';
import debounce from 'debounce-promise';

import {DEBOUNCE_DELAY} from 'utils/common';

import 'components/facilities/facilities.scss';

import {Formik} from 'formik';
import {
  createFacilityInitialValues as initial,
  FacilityValidationSchema as validationSchema,
} from 'constants/facilities';
import {getEntitiesList, getUsersByFilter} from 'containers/providers/api';
import {updateFacility, getFacilitiesTypes, getSingleFacility} from 'containers/facilities/api';
import {useParams} from 'react-router-dom';
import 'components/facilities/facilities.scss';
import {createFacilityProvider, deleteFacilityProvider} from 'components/common/assigne-facilities-providers/api';

import {Spinner} from 'react-bootstrap';
import AssignedFacilityProvider from 'components/common/assigne-facilities-providers';
import NotesListing from 'components/common/notes-listing';
import {notableTypes} from 'constants/common';
import BreadcrumbTrail from 'components/common/breadcrumb-trail';
import moment from 'moment';

const EditFacility = () => {
  const [entities, setEntities] = useState([]);
  const [facility, setFacility] = useState({});
  const [initialValues, setInitialValues] = useState(initial);
  const [users, setUsers] = useState([]);
  const [facilitiesTypes, setFacilitiesTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(true);
  const {id} = useParams();
  const [providers, setProviders] = useState([]);
  const [providerId, setProviderId] = useState('');
  const [refetch, setRefetch] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const [dropdownLoading, setDropdownLoading] = useState({
    entities: false,
    users: false,
    facilitiesType: false,
  });

  const fetchFacility = async id => {
    try {
      const result = await getSingleFacility(id);

      setFacility(result.facility);
      if (result?.facility?.provider_facilities.length > 0) {
        const data = [];
        result?.facility?.provider_facilities.map(item => data.push(item));

        setProviders(data);
      } else {
        setProviders([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (Object.keys(facility).length > 0) {
      let list = [];
      facility.regional_managers.forEach(item =>
        list.push({
          id: item.id,
          user_id: item.user.id,
          value: item.user.id,
          label: item.user.first_name + ' ' + item.user.last_name,
        }),
      );
      setInitialValues({
        ...initialValues,
        status: facility?.active === true ? 1 : 0,
        name: facility?.name,
        npi: facility?.npi,
        facility_id: facility?.facility_id,
        regional_managers_attributes: list,
        entity_id: facility.entity.id,
        document_delivery_type: facility?.document_delivery_type,
        email: facility?.email,
        fax: facility?.fax,
        phone_no: facility?.phone_no,
        ads_name: facility?.ads_name || '',
        state: facility?.address?.state || '',
        zip_code: facility?.address?.zip_code || '',
        country: facility?.address?.country || '',
        county: facility?.address?.county || '',
        street: facility?.address?.street || '',
        city: facility?.address?.city || '',
        address_id: facility?.address?.id || '',

        facility_type_id: facility.facility_type.id,
        number_of_bed: facility?.number_of_bed,
        patient_estimate: facility?.patient_estimate,
        visit_estimate: facility?.visit_estimate,
        start_date: facility?.start_date,
        end_date: facility?.end_date,
      });
      setFormLoading(false);
    }
  }, [facility]);

  useEffect(() => {
    if (providerId) {
      setIsDisabled(false);
    }
    if (!providerId) {
      setIsDisabled(true);
    }
  }, [providerId]);

  const handleCreateFacility = async (values, setErrors) => {
    const checkDeletedDirectors = facility.regional_managers
      .filter(val => !values.regional_managers_attributes.find(arr1Obj => arr1Obj.id === val.id))
      .map(item => ({
        id: item.id,
        user_id: item.user.id,
        value: item.user.id,
        label: item.user.first_name + ' ' + item.user.last_name,
        _destroy: true,
      }));

    const data = {
      facility: {
        ...values,
        phone_no: values.phone_no.replaceAll(' ', ''),
        address_attributes: {
          id: values.address_id,
          state: values.state,
          zip_code: values.zip_code,
          county: values.county,
          country: values.country,
          street: values.street,
          city: values.city,
        },
        regional_managers_attributes: [...values.regional_managers_attributes, ...checkDeletedDirectors],
        start_date: moment(values.start_date).format('YYYY-MM-DD'),
        end_date: moment(values.end_date).format('YYYY-MM-DD'),
      },
    };
    delete data.facility.state;
    delete data.facility.zip_code;
    delete data.facility.county;
    delete data.facility.country;
    delete data.facility.street;
    delete data.facility.city;
    delete data.facility.address_id;

    try {
      setIsLoading(true);
      const result = await updateFacility(data, id, setErrors);
      if (result.meta.status === 200) {
        setIsLoading(false);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  async function fetchEntities() {
    const result = await getEntitiesList('active');
    if (result['entities']) {
      setEntities(result['entities'].map(item => ({value: item.id, label: item.name})));
    }
  }

  async function fetchFacilitiesTypes() {
    const result = await getFacilitiesTypes('active');
    if (result['facility_types']) {
      setFacilitiesTypes(result['facility_types'].map(item => ({value: item.id, label: item.name})));
    }
  }

  async function fetchUsers() {
    const result = await getUsersByFilter('director');
    if (result['users']) {
      const users = result['users'].map(item => ({
        value: item.id,
        user_id: item.id,
        label: item.first_name + ' ' + item.last_name,
      }));

      setUsers(users);
    }
  }

  useEffect(() => {
    fetchFacility(id);
    fetchEntities();
    fetchUsers();
    fetchFacilitiesTypes();
  }, []);

  useEffect(() => {
    if (refetch) {
      fetchFacility(id);

      setRefetch(false);
    }
  }, [refetch]);

  // eslint-disable-next-line react-hooks/exhaustive-deps

  const facilityProviderClickHandler = async () => {
    try {
      const res = await createFacilityProvider(
        {facility_id: id, provider_id: providerId},
        'Provider has been assigned successfully!',
      );
      setProviderId(null);
      if (res['provider_facility']) {
        setRefetch(true);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const facilityProviderDeleteHandler = async id => {
    try {
      await deleteFacilityProvider(id, 'Provider has been removed successfully!');
      setRefetch(true);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchEntitiessData = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, entities: true});
    try {
      const result = await getEntitiesList('active', search);
      if (result['entities']) {
        const data = result?.entities.map(item => ({value: item.id, label: item.name}));
        setEntities(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, entities: false});
    }
  }, DEBOUNCE_DELAY);

  const fetchUsersData = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, users: true});
    try {
      const result = await getUsersByFilter('director', null, search);
      if (result['users']) {
        const data = result?.users.map(item => ({
          value: item.id,
          user_id: item.id,
          label: item.first_name + ' ' + item.last_name,
        }));
        setUsers(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, entities: false});
    }
  }, DEBOUNCE_DELAY);

  const fetchFacilitiesTypeData = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, facilitiesType: true});
    try {
      const result = await getFacilitiesTypes('active');
      if (result['facility_types']) {
        const data = result?.facility_types.map(item => ({value: item.id, label: item.name}));
        setFacilitiesTypes(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, facilitiesType: false});
    }
  }, DEBOUNCE_DELAY);
  const loadEntitiesDropdownOptions = search => fetchEntitiessData(search);
  const loadUsersDropdownOptions = search => fetchUsersData(search);
  const loadFacilitiesTypeDropdownOptions = search => fetchFacilitiesTypeData(search);

  return (
    <div className="container-fluid">
      <div className="heading-path-otr">
        <h3 className="heading heading-h3">{initialValues?.name} - Facilities</h3>
        <BreadcrumbTrail />
      </div>
      <div className="table-main facility-padding">
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={(values, {setErrors}) => handleCreateFacility(values, setErrors)}
        >
          {formik => {
            return (
              <>
                <h4>Facility</h4>
                {formLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="margin-auto"
                  />
                ) : (
                  <FacilityForm
                    formik={formik}
                    entities={entities}
                    users={users}
                    facilitiesTypes={facilitiesTypes}
                    isUpdateFlow
                    loadEntitiesDropdownOptions={loadEntitiesDropdownOptions}
                    dropdownLoading={dropdownLoading}
                    loadUsersDropdownOptions={loadUsersDropdownOptions}
                    loadFacilitiesTypeDropdownOptions={loadFacilitiesTypeDropdownOptions}
                  />
                )}
                {isLoading ? (
                  <button className={`primary-btn-small record-btn mt-3 m-auto`}>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                  </button>
                ) : (
                  !formLoading && (
                    <button
                      type="button"
                      className={`primary-btn-small record-btn mt-3 `}
                      onClick={formik.handleSubmit}
                      disabled={isLoading}
                    >
                      Save
                    </button>
                  )
                )}
              </>
            );
          }}
        </Formik>
      </div>
      <div className="mt-5">
        <div className="card-wrapper px-4">
          <div className="heading-path-otr">
            <h4 className="heading heading-h4 text-dark">Assigned Providers</h4>
          </div>
          <AssignedFacilityProvider
            btnText="Add Provider"
            data={providers}
            setDropDownId={setProviderId}
            btnClickHandler={facilityProviderClickHandler}
            facilityProviderDeleteHandler={facilityProviderDeleteHandler}
            isDisabled={isDisabled}
            dropDownId={providerId}
          />
        </div>
      </div>

      <div className="card-wrapper mt-5 ">
        <div className="heading-path-otr px-4">
          <h4 className="heading heading-h4 text-dark">Notes</h4>
        </div>
        <NotesListing noteableId={id} notableType={notableTypes.FACILITY} />
      </div>
    </div>
  );
};

export default EditFacility;
