import React, {useEffect, useState} from 'react';

import TableWraper from 'components/common/table-wrapper';

import {DEBOUNCE_DELAY, getPaginatedItems, useQuery} from 'utils/common';
import {initialMetaForTable} from 'constants/common';
import {getGrossBillingReport} from 'containers/gross-billing-report/api';

let timeout;

const GrossBillingsListing = () => {
  const query = useQuery();
  const startDate = query.get('startDate');
  const endDate = query.get('endDate');
  const aggregate = query.get('aggregate');

  const [grossBillingsList, setGrossBillingsList] = useState([]);
  const [refreshPage, setRefreshPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState(initialMetaForTable);
  const [totalCount, setTotalCount] = useState(0);

  async function fetchData() {
    try {
      setLoading(true);
      const result = await getGrossBillingReport({startDate, endDate, aggregate}, meta.search);
      if (result) {
        setTotalCount(result.length);
        setGrossBillingsList(
          getPaginatedItems(result, meta.page, meta.perPage)?.data?.map((item, index) => ({
            ...item,
            id: index,
          })),
        );
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage]);

  const handleRefreshPage = () => {
    setRefreshPage(pre => !pre);
  };
  const handleSetPerPage = obj => {
    setMeta(pre => ({...pre, perPage: obj.value}));
    handleRefreshPage();
  };
  const debounceFn = (callback, delay) => {
    clearTimeout(timeout);
    timeout = setTimeout(callback, delay);
  };
  const handleSetSearchQuery = value => {
    setMeta(pre => ({...pre, search: value}));
    debounceFn(handleRefreshPage, DEBOUNCE_DELAY);
  };
  const handlePageChange = value => {
    setMeta(pre => ({...pre, page: value}));
    handleRefreshPage();
  };

  return (
    <>
      <TableWraper
        setPerPage={handleSetPerPage}
        setSearchQuery={handleSetSearchQuery}
        searchValue={meta.search}
        totalListCount={totalCount}
        pageSize={meta.perPage}
        currentPage={meta.page}
        onPageChange={handlePageChange}
      >
        {loading ? (
          <h3 style={{textAlign: 'center'}}>Loading</h3>
        ) : (
          <table className="table">
            <thead>
              <tr>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Provider</p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Total</p>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {grossBillingsList.map(item => {
                return (
                  <tr key={item.name}>
                    <th scope="row">
                      <div className="table-text-otr ">
                        <span className="table-text">{item.name || '-'}</span>
                      </div>
                    </th>
                    <td>
                      <div className="table-text-otr">
                        <p className="table-text">${item.total || '-'}</p>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </TableWraper>
    </>
  );
};

export default GrossBillingsListing;
