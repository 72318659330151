import React, {useEffect, useState} from 'react';
import SelectComponent from 'components/common/select';
import {Row, Col} from 'react-bootstrap';
import {providerStatus} from 'constants/providers';
import {states} from 'constants/common';
import debounce from 'debounce-promise';
import {getEntitiesList, getUsersByFilter} from 'containers/providers/api';
import {DEBOUNCE_DELAY} from 'utils/common';
import AsyncSelectComponent from 'components/common/async-select';

const Filter = ({handleSubmit, filters = {}}) => {
  const [filterState, setFilterState] = useState(filters);
  const [users, setUsers] = useState([]);
  const [entities, setEntities] = useState([]);
  const [dropdownLoading, setDropdownLoading] = useState({
    entities: false,
    users: false,
  });
  async function fetchEntities() {
    const result = await getEntitiesList();
    if (result['entities']) {
      setEntities(
        result['entities'].map(item => ({value: item.id, label: item.name, id: item.id, entity_id: item.id})),
      );
    }
  }

  async function fetchUsers() {
    const result = await getUsersByFilter('director');
    if (result['users']) {
      setUsers(result['users'].map(item => ({user_id: item.id, value: item.id, label: item.full_name})));
    }
  }
  const fetchEntitiessData = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, entities: true});
    try {
      const result = await getEntitiesList('', search);
      if (result['entities']) {
        const data = result?.entities.map(item => ({
          value: item.id,
          label: item.name,
          id: item.id,
          entity_id: item.id,
        }));
        setEntities(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, entities: false});
    }
  }, DEBOUNCE_DELAY);
  useEffect(() => {
    fetchEntities();
    fetchUsers();
  }, []);
  const fetchUsersData = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, users: true});
    try {
      const result = await getUsersByFilter('director', 'active', search);
      if (result['users']) {
        const data = result?.users.map(item => ({
          id: item.id,
          user_id: item.id,
          value: item.id,
          label: item.full_name,
        }));
        setUsers(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, users: false});
    }
  }, DEBOUNCE_DELAY);

  const loadEntitiesDropdownOptions = search => fetchEntitiessData(search);
  const loadUsersDropdownOptions = search => fetchUsersData(search);

  const handleChangeOnDropdown = item => {
    setFilterState(pre => ({...pre, status: item.value}));
  };


  return (
    <div className="providers-filter">
      <h5>Filters</h5>
      <Row>
        <Col sm={6} lg={3}>
          <div className=" input-otr" style={{alignItems: 'inherit'}}>
            <label htmlFor="state" className="input-label">
              Status
            </label>
            <AsyncSelectComponent
              defaultOptions={providerStatus}
              isLoading={false}
              placeholder="Select status"
              selectedValue={filterState.status}
              onChange={obj => setFilterState(pre => ({...pre, status: obj}))}
              isMulti
            />
          </div>
        </Col>
        <Col sm={6} lg={3}>
          <div className=" input-otr" style={{alignItems: 'inherit'}}>
            <label htmlFor="state" className="input-label">
              State
            </label>
            <AsyncSelectComponent
              isLoading={false}
              defaultOptions={states}
              placeholder="Select state"
              selectedValue={filterState.states}
              onChange={obj => setFilterState(pre => ({...pre, states: obj}))}
              isMulti
            />
          </div>
        </Col>
        <Col sm={6} lg={3} style={{marginTop: '8px'}}>
          <p>Entity</p>

          <AsyncSelectComponent
            isLoading={dropdownLoading.entities}
            selectedValue={filterState.entity_id}
            defaultOptions={entities}
            loadOptions={loadEntitiesDropdownOptions}
            onChange={obj => {
              setFilterState(pre => ({...pre, entity_id: obj}));
            }}
            isMulti
            placeholder="Search and select"
          />
        </Col>
        <Col sm={6} lg={3}>
          <div className=" input-otr" style={{alignItems: 'inherit', marginTop: '8px'}}>
            <label htmlFor="email" className="input-label">
              Regional Director
            </label>
            <AsyncSelectComponent
              isLoading={dropdownLoading.users}
              selectedValue={filterState.users}
              defaultOptions={users}
              loadOptions={loadUsersDropdownOptions}
              onChange={usr => {
                setFilterState(pre => ({...pre, users: usr}));
              }}
              placeholder="Search and select"
              isMulti
            />
          </div>
        </Col>
      </Row>
      <div className="filter-search-otr">
        {/* <SearchInput /> */}
        <div
          onClick={() => {
            handleSubmit(filterState);
          }}
        >
          <button className="primary-btn-small record-btn">Apply</button>
        </div>
      </div>
    </div>
  );
};

export default Filter;
