import {get, put, post} from 'services/network';
import {PROVIDER_RATES_ENDPOINT} from 'constants/endpoints';
import CustomToast from 'components/common/custom-toast';
import {deleteApi} from 'services/network';
import {alertTypes} from 'constants/common';
import {toast} from 'react-toastify';

export const getProviderRatesList = async obj => {
  try {
    const result = await get(
      `${PROVIDER_RATES_ENDPOINT}?page=${obj.page}&sort=${obj?.sort ? obj.sort : ''}&order=${
        obj?.order ? obj.order : 'asc'
      }&active=${obj?.active ? obj.active : false}&per=${obj.perPage}&query=${obj.search}${
        obj?.provider_id ? `&filter[provider_id]=${obj.provider_id}` : ''
      }${obj?.earning_code_id ? `&filter[earning_code_id]=${obj.earning_code_id}` : ''}`,
      true,
    );
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const createProviderRates = async data => {
  try {
    const result = await post(`${PROVIDER_RATES_ENDPOINT}`, data, true);
    toast(<CustomToast variant={alertTypes.SUCCESS} message="Provider Rate created successfully!" />);
    return result;
  } catch (error) {
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={error?.response?.data?.message || error?.response?.statusText}
      />,
    );
    console.log('error');
  }
};

export const deleteProviderRates = async id => {
  try {
    const response = await deleteApi(`${PROVIDER_RATES_ENDPOINT}/${id}/`, true);
    toast(<CustomToast variant={alertTypes.SUCCESS} message={response.message} />);
    return response;
  } catch (error) {
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={error?.response?.data?.message || error?.response?.statusText}
      />,
    );
    console.error(error);
  }
};

export const getSingleProviderRate = async id => {
  try {
    const result = await get(`${PROVIDER_RATES_ENDPOINT}/${id}`, true);
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const updateProviderRate = async (data, id) => {
  try {
    const result = await put(`${PROVIDER_RATES_ENDPOINT}/${id}`, data, true);
    toast(<CustomToast variant={alertTypes.SUCCESS} message="Provider Rate updated successfully!" />);
    return result;
  } catch (error) {
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={error?.response?.data?.message || error?.response?.statusText}
      />,
    );
    console.log('error');
  }
};

export const getProviderHistory = async id => {
  try {
    const result = await get(`${PROVIDER_RATES_ENDPOINT}/${id}/versions_history`, true);
    return result;
  } catch (e) {
    console.log(e);
  }
};
