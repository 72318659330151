import React from 'react';
import {Accordion} from 'react-bootstrap';
import {CurrencyDollar, Circle} from 'phosphor-react';
import {NavLink} from 'react-router-dom';
import {
  EARNING_CODES_PATH,
  LOOKER_IMPORT_PATH,
  PAYROLL_CALENDAR_PATH,
  ADP_EXPORT_PATH,
  PAYROLL_PERIODS_PATH,
} from 'constants/routePaths';

const PayrollAccordion = () => (
  <Accordion.Item eventKey="1">
    <Accordion.Header>
      <CurrencyDollar size={24} /> <span className="heading-smb">Payroll</span>
    </Accordion.Header>
    <Accordion.Body>
      <ul className="menu-ul">
        {/* <li className="menu-li">
          <NavLink to={PAYROLL_CALENDAR_PATH}>
            <p className="menu-a heading-smb">
              <Circle weight="fill" size={8} />
              Calendar
            </p>
          </NavLink>
        </li> */}
        {/* <li className="menu-li">
          <p className="menu-a heading-smb">
            <Circle weight="fill" size={8} />
            Monthly / Daily
          </p>
        </li> */}
        <li className="menu-li">
          <NavLink to={ADP_EXPORT_PATH}>
            <p className="menu-a heading-smb">
              <Circle weight="fill" size={8} />
              ADP Export
            </p>
          </NavLink>
        </li>
        <li className="menu-li">
          <NavLink to={LOOKER_IMPORT_PATH}>
            <p className="menu-a heading-smb">
              <Circle weight="fill" size={8} />
              Looker Import
            </p>
          </NavLink>
        </li>
        <li className="menu-li">
          <NavLink to={PAYROLL_PERIODS_PATH}>
            <p className="menu-a heading-smb">
              <Circle weight="fill" size={8} />
              Payroll Periods
            </p>
          </NavLink>
        </li>
      </ul>
    </Accordion.Body>
  </Accordion.Item>
);

export default PayrollAccordion;
