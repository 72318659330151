import React, {useEffect, useState} from 'react';

import TableWraper from 'components/common/table-wrapper';

// import {isAdministrator, isBiller, isPayRoll} from 'utils/common';
import {Eye} from 'phosphor-react';
import {createdAtFormat, DEBOUNCE_DELAY, isAdministrator, isPayRoll} from 'utils/common';
import {initialMetaForTable, textStatus} from 'constants/common';
import {getProviderData} from 'containers/looker-import/api';
import {useParams} from 'react-router-dom';
import ShowProviderData from './ShowProviderDataModal';

let timeout;

const RecordsListing = ({refreshByParent, filters}) => {
  const {id} = useParams();
  const [allListSelected, setAllListSelected] = useState(false);
  const [lookerList, setLookerList] = useState([]);
  const [refreshPage, setRefreshPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState(initialMetaForTable);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isShow, setIsShow] = useState(false);

  async function fetchData() {
    try {
      setLoading(true);
      const result = await getProviderData(id, {...meta, status: filters});
      if (result['provider_data']) {
        const data = result?.provider_data.map(item => ({...item, isSelected: allListSelected}));
        setLookerList(data);
        setTotalCount(result?.meta.total_count);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage, refreshByParent]);

  const handleRefreshPage = () => {
    setRefreshPage(pre => !pre);
  };
  const handleSetPerPage = obj => {
    setMeta(pre => ({...pre, perPage: obj.value}));
    handleRefreshPage();
  };
  const debounceFn = (callback, delay) => {
    clearTimeout(timeout);
    timeout = setTimeout(callback, delay);
  };
  const handleSetSearchQuery = value => {
    setMeta(pre => ({...pre, search: value}));
    debounceFn(handleRefreshPage, DEBOUNCE_DELAY);
  };
  const handlePageChange = value => {
    setMeta(pre => ({...pre, page: value}));
    handleRefreshPage();
  };

  const setAllListCheck = value => {
    setLookerList(pre => pre.map(item => ({...item, isSelected: value})));
  };
  const setSingleItemCheck = (value, id) => {
    setLookerList(pre => pre.map(item => (item.id === id ? {...item, isSelected: value} : item)));
  };
  const handleShowVisible = () => {
    setIsShow(true);
  };
  const handleShowClose = () => {
    setIsShow(false);
    setSelectedRow(null);
  };
  return (
    <>
      <TableWraper
        setPerPage={handleSetPerPage}
        setSearchQuery={handleSetSearchQuery}
        searchValue={meta.search}
        totalListCount={totalCount}
        pageSize={meta.perPage}
        currentPage={meta.page}
        onPageChange={handlePageChange}
      >
        {loading ? (
          <h3 style={{textAlign: 'center'}}>Loading</h3>
        ) : (
          <table className="table">
            <thead>
              <tr>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">
                      <input
                        className="form-check-input"
                        onChange={e => {
                          setAllListSelected(e.target.checked);
                          setAllListCheck(e.target.checked);
                        }}
                        type="checkbox"
                        checked={allListSelected}
                      />
                    </p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Date</p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Provider</p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Procedure</p>
                  </div>
                </th>
                {/* <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Unit</p>
                  </div>
                </th> */}
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Status</p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Error Message</p>
                  </div>
                </th>
                {(isAdministrator() || isPayRoll()) && (
                  <th scope="col" className="action-column">
                    <div className="header-text-otr">
                      <p className="table-name heading-xsb">Actions</p>
                    </div>
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {lookerList.map(item => {
                return (
                  <tr key={item.id}>
                    <th scope="col">
                      <div className="header-text-otr">
                        <p className="table-name heading-xsb">
                          <input
                            className="form-check-input"
                            value={item.isSelected}
                            onChange={e => {
                              setSingleItemCheck(e.target.checked, item.id);
                            }}
                            type="checkbox"
                            checked={item.isSelected}
                          />
                        </p>
                      </div>
                    </th>

                    <td>
                      <div className="">
                        <p className="table-text ">
                          <>{item.service_date || ''}</>
                        </p>
                      </div>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <p className="table-text ">
                          {item?.provider?.first_name + ' ' + item?.provider?.last_name || ''}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <p className="table-text ">{item?.earning_code?.name || ''}</p>
                      </div>
                    </td>

                    {/* <td>
                      <div className="table-text-otr">
                        <div className="table-text ">
                          <span className="mx-1 total-count">{item?.total_count || 0}</span>
                          <span className="mx-1 total-matched">{item?.record_matched_count || 0}</span>
                          <span className="not-matched">{item?.record_not_found_count || 0}</span>
                        </div>
                      </div>
                    </td> */}
                    <td>
                      <div className="table-text-otr">
                        <p className={`table-text ${textStatus[item.status]}`}>
                          {item?.status === 'error'
                            ? 'Failed'
                            : item?.status === 'in_process'
                            ? 'In Progress'
                            : 'Completed'}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <p className="table-text ">
                        {item?.message || ''}
                        </p>
                      </div>
                    </td>
                    {(isAdministrator() || isPayRoll()) && (
                      <td className="action-column">
                        <div className="table-icon-otr">
                          <div className="icon-otr">
                            <Eye
                              size={24}
                              onClick={() => {
                                setSelectedRow(item);
                                handleShowVisible();
                              }}
                            />
                          </div>
                        </div>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </TableWraper>
      {isShow && <ShowProviderData selectedRow={selectedRow} handleCloseModal={handleShowClose} />}
    </>
  );
};

export default RecordsListing;
