import Input from 'components/common/input';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './datepicker.scss';
import {CaretLeft, CaretRight} from 'phosphor-react';

const CustomDatePicker = ({name = 'Date', label = 'Date', selected, minDate = null, onDateChange, ...rest}) => {
  const setDateHandler = date => {
    let formattedDate = '';
    if (date) {
      formattedDate = moment(date).format('MM/DD/YYYY');
    }
    onDateChange(formattedDate);
  };

  return (
    <DatePicker
      selected={selected}
      renderCustomHeader={({monthDate, decreaseMonth, increaseMonth}) => (
        <div className="d-flex justify-content-between align-items-center">
          <div
            aria-label="Previous Month"
            className={'datepicker__navigation react-datepicker__navigation--previous'}
            onClick={decreaseMonth}
          >
            <CaretLeft size={18} />
          </div>
          <span className="react-datepicker__current-month">
            {monthDate.toLocaleString('en-US', {
              month: 'long',
              year: 'numeric',
            })}
          </span>
          <div
            aria-label="Next Month"
            className={'datepicker__navigation react-datepicker__navigation--next'}
            onClick={increaseMonth}
          >
            <CaretRight size={18} />
          </div>
        </div>
      )}
      minDate={minDate}
      onChange={setDateHandler}
      placeholderText="2022-02-01"
      customInput={<Input name={name} label={label !== null ? label : false} type="text" {...rest} />}
      disabled={rest.disabled}
    />
  );
};

export default CustomDatePicker;
