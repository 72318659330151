import React, {useState, useEffect} from 'react';
import Input from 'components/common/input';
import SelectComponent from 'components/common/select';
import {ErrorMessage} from 'formik';
import {Row, Col} from 'react-bootstrap';
import Toggle from 'components/common/togglebutton';
import './provider-rates.scss';
import AsyncSelectComponent from 'components/common/async-select';
import CustomDatePicker from 'components/common/custom-datepicker';
import moment from 'moment';

const ProviderRatesForm = ({
  isCreating = false,
  formik,
  providers,
  earningCodesNames,
  earningCodes,
  isUpdateFlow = false,
  dropdownLoading,
  loadProviderDropdownOptions,
  loadEarningNamesDropdownOptions,
  loadEarningCodesDropdownOptions,
}) => {
  const [earningCodeId, setEarningCodeId] = useState('');

  useEffect(() => {
    setEarningCodeId(formik.values.earning_code_id);
  }, []);

  const handleChangeForProvider = obj => {
    formik.setFieldValue('provider_id', obj.value);
  };

  const handleChangeForEarningName = obj => {
    formik.setFieldValue('earning_code_id', obj.value);
    setEarningCodeId(obj.value);
  };

  const handleChangeForEarningCode = obj => {
    formik.setFieldValue('earning_code_id', obj.value);
    setEarningCodeId(obj.value);
  };
  console.log('formik is', formik);
  return (
    <form className={'w-100 provider-ratess-form'}>
      <Row>
        <div className="wrapper mb-4 mt-4">
          <Toggle label={'Active'} name={'active'} value={formik.values.active} handleChange={formik.setFieldValue} />
        </div>

        <Col md={4}>
          <div className=" input-otr" style={{alignItems: 'inherit'}}>
            <label htmlFor="provider_id" className="input-label">
              Provider Name
            </label>
            <AsyncSelectComponent
              isLoading={dropdownLoading.providerName}
              selectedValue={formik.values.provider_id}
              defaultOptions={providers}
              loadOptions={loadProviderDropdownOptions}
              onChange={obj => handleChangeForProvider(obj)}
              placeholder="Search and select"
            />

            <ErrorMessage className="error-text" component="p" name="provider_id" />
          </div>
        </Col>
        <Col md={4}>
          <div className=" input-otr" style={{alignItems: 'inherit'}}>
            <label htmlFor="email" className="input-label">
              Earning Code
            </label>
            <AsyncSelectComponent
              isLoading={dropdownLoading.earningCode}
              selectedValue={formik.values.earning_code_id}
              defaultOptions={earningCodes}
              loadOptions={loadEarningCodesDropdownOptions}
              onChange={obj => handleChangeForEarningCode(obj)}
              placeholder="Search and select"
            />

            <ErrorMessage className="error-text" component="p" name="document_delivery_type" />
          </div>
        </Col>
        <Col md={4}>
          <div className=" input-otr" style={{alignItems: 'inherit'}}>
            <label htmlFor="earning_code_id" className="input-label">
              Earning Name
            </label>
            <AsyncSelectComponent
              isLoading={dropdownLoading.providerName}
              selectedValue={formik.values.earning_code_id}
              defaultOptions={earningCodesNames}
              loadOptions={loadEarningNamesDropdownOptions}
              onChange={obj => handleChangeForEarningName(obj)}
              placeholder="Search and select"
            />

            <ErrorMessage className="error-text" component="p" name="earning_code_id" />
          </div>
        </Col>

        <Col md={4}>
          <Input
            handleChange={formik.handleChange}
            name={'initial_unit_charge_amount'}
            value={formik.values.initial_unit_charge_amount}
            placeholder={'e.g. 50.00'}
            label={'Initial Unit Charge Amount'}
            type="number"
          />
          <ErrorMessage className="error-text" component="p" name="initial_unit_charge_amount" />
        </Col>

        <Col md={4}>
          <Input
            handleChange={formik.handleChange}
            name={'unit_cov_by_initial_charge'}
            value={formik.values.unit_cov_by_initial_charge}
            placeholder={'e.g. 1'}
            label={'Unit Covered By Initial Charge'}
            type="number"
          />
          <ErrorMessage className="error-text" component="p" name="unit_cov_by_initial_charge" />
        </Col>
        <Col md={4}>
          <Input
            handleChange={formik.handleChange}
            name={'unit_multiplier'}
            value={formik.values.unit_multiplier}
            placeholder={'e.g. 1.00'}
            label={'RVU Value'}
            type="number"
          />
          <ErrorMessage className="error-text" component="p" name="unit_multiplier" />
        </Col>
        <Col md={4}>
          <CustomDatePicker
            name="effective_date"
            label={'Effective Date'}
            selected={formik.values.effective_date ? new Date(moment(formik.values.effective_date)) : ''}
            onDateChange={date => formik.setFieldValue('effective_date', date)}
          />
          <ErrorMessage className="error-text" component="p" name="effective_date" />
        </Col>
      </Row>
    </form>
  );
};

export default ProviderRatesForm;
