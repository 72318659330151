import React from 'react';

import BreadcrumbTrail from 'components/common/breadcrumb-trail';
import 'components/facilities/facilities.scss';
import RecordsListing from 'components/adp-export/adp-export-search-listing';
import {useLocation} from 'react-router-dom';

const AdpExportSearchListing = () => {
  const location = useLocation();
  return (
    <>
      <div className="apd-search-results">
        <div className="container-fluid">
          <div className="heading-path-otr">
            <h3 className="heading heading-h3">Search Results</h3>
            <BreadcrumbTrail />
          </div>
          <RecordsListing records={location?.state?.result || []} searchValues={location?.state?.data} />
        </div>
      </div>
    </>
  );
};

export default AdpExportSearchListing;
