import React, {useEffect, useState} from 'react';
import SelectComponent from 'components/common/select';
import {Row, Col} from 'react-bootstrap';
import {statusOptions} from 'constants/common';
import Input from 'components/common/input';
import {ErrorMessage} from 'formik';

const EmailFilter = ({handleSubmit}) => {
  const [list, setList] = useState([]);
  const [value, setValue] = useState('');
  useEffect(() => {
    const fetchUsers = async () => {
      //   const result = await getUsers();
      //   if (result?.users) {
      //     const data = result.users.map(item => ({value: item.id, label: item.full_name}));
      //     setList(data);
      //   }
      setList(statusOptions);
    };
    fetchUsers();
  }, []);
  const handleChangeOnDropdown = item => {
    setValue(item.value);
  };

  return (
    <div className="providers-filter">
      <h5>Filters</h5>
      <Row>
        <Col md={4}>
          <Input
            // handleChange={formik.handleChange}
            name={'start_date'}
            // value={formik.values.start_date}
            value=""
            placeholder={'e.g. 10'}
            label={'From'}
            type="date"
          />
        </Col>
        <Col md={4}>
          <Input
            // handleChange={formik.handleChange}
            name={'end_date'}
            // value={formik.values.end_date}
            value=""
            placeholder={'DD/MM/YYYY'}
            label={'To'}
            type="date"
            format="DD/MM/YYYY"
          />
        </Col>
        <Col md={4} className="mt-2">
          <p>Status</p>
          <SelectComponent
            options={list}
            selectedValue={value}
            handleChange={handleChangeOnDropdown}
            placeholder="Select Status"
          />
        </Col>
      </Row>
      <div className="filter-search-otr">
        {/* <SearchInput /> */}
        <div
          className={`action ${value ? '' : 'disabled'}`}
          onClick={() => {
            handleSubmit(value);
          }}
        >
          <button className="primary-btn-small record-btn">Apply</button>
        </div>
      </div>
    </div>
  );
};

export default EmailFilter;
