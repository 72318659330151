import {CLIENT_ID, CLIENT_SECRET, GRANT_TYPE} from 'constants/common';
import {AUTH_ENDPOINT, REVOKE_ENDPOINT} from 'constants/endpoints';
import {post} from 'services/network';
import {getAccessToken} from 'utils/common';

export const getAuthToken = ({email, password}) => {
  const data = {
    grant_type: GRANT_TYPE,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
    email,
    password,
  };

  return post(AUTH_ENDPOINT, data);
};

export const revokeAuthToken = () => {
  const data = {
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
    token: getAccessToken(),
  };

  return post(REVOKE_ENDPOINT, data);
};
