import * as yup from 'yup';

export const emailSendBy = {
  TITLE: 'by_title',
  ENTITY: 'by_entity',
  PAYROLL_GROUP: 'by_payroll_group',
  CATEGORY: 'by_category',
  PROVIDERS: 'by_providers',
  ACTIVE_PROVIDERS: 'by_active_providers',
};

export const sendMailInitialValues = {
  sender: '',
  replyToEmail: '',
  templateId: '',
  subject: '',
  body: '',
  emailSendBy: emailSendBy.TITLE,
  ids:[]
};

export const sendMailValidationSchema = yup.object().shape({
  sender: yup.string().required('Required *'),
  replyToEmail: yup.string().required('Required *'),
  templateId: yup.string().required('Required *'),
  subject: yup.string().required('Required *'),
  body: yup.string().required('Required *'),
});

export const templateInitialValues = {
  name: '',
  template_id: '',
  subject: '',
};

export const templateValidationSchema = yup.object().shape({
  name: yup.string().required('Required *'),
  template_id: yup.string().required('Required *'),
  subject: yup.string().required('Required *'),
});
