import CustomToast from 'components/common/custom-toast';
import {alertTypes} from 'constants/common';
import {
  CLOSE_AND_START_NEW_PAYROLL_PERIOD_ENDPOINT,
  COMING_PAST_PAYROLL_PERIOD_ENDPOINT,
  PAYROLL_PERIOD_ENDPOINT,
} from 'constants/endpoints';
import {toast} from 'react-toastify';

import {get, post, put} from 'services/network';

export const getComingPastPayrollPeriod = async () => {
  try {
    const result = await get(`${COMING_PAST_PAYROLL_PERIOD_ENDPOINT}`, true);
    return result;
  } catch (e) {
    console.log(e);
  }
};
export const updateCloseAndStartNew = async () => {
  try {
    const result = await post(CLOSE_AND_START_NEW_PAYROLL_PERIOD_ENDPOINT, null, true);
    return result;
  } catch (error) {
    console.log(error);
  }
};
export const updateClosePeriod = async id => {
  try {
    const result = await put(PAYROLL_PERIOD_ENDPOINT + `/${id}/close_period`, null, true);
    toast(<CustomToast variant={alertTypes.SUCCESS} message="Payroll period has been updated!" />);
    return result;
  } catch (error) {
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={error?.response?.data?.message || error?.response?.statusText}
      />,
    );
    console.log(error);
  }
};
export const updateOpenPeriod = async id => {
  try {
    const result = await put(PAYROLL_PERIOD_ENDPOINT + `/${id}/open_period`, null, true);
    toast(<CustomToast variant={alertTypes.SUCCESS} message="Payroll period has been updated!" />);
    return result;
  } catch (error) {
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={error?.response?.data?.message || error?.response?.statusText}
      />,
    );
    console.log(error);
  }
};
