import React, {useEffect, useState} from 'react';
import {CalendarBlank, ClockCounterClockwise, CurrencyDollar, FirstAidKit, Gear, Wrench} from 'phosphor-react';
import {Link, NavLink, useLocation} from 'react-router-dom';
import {PROVIDERS_PATH, PROVIDER_TABS} from 'constants/routePaths';
import {getUserInfoFromStorage} from 'utils/common';
import {getSingleUser} from 'containers/users/api';
import {times} from 'lodash';
import classNames from 'classnames';

const {INFORMATION, PAYROLL, FACILITIES, RATES, ADJUSTMENT, CALENDAR} = PROVIDER_TABS;

const ProviderPermittedView = () => {
  const user = getUserInfoFromStorage();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [providerId, setProviderId] = useState(null);

  useEffect(() => {
    const fetchEditUser = async () => {
      setLoading(true);
      const result = await getSingleUser(user.id);
      if (result?.user?.provider) {
        result.user.status = result.user.active ? 1 : 0;
        setProviderId(result.user.provider.id);
        setLoading(false);
      }
    };
    fetchEditUser();
  }, [user.id]);

  const isLinkActive = tabName => {
    return location.search.includes(tabName);
  };

  return loading ? (
    times(6, i => <div key={i} className="loading-link" />)
  ) : (
    <>
      <Link
        className={classNames({active: isLinkActive(INFORMATION)})}
        to={PROVIDERS_PATH + `/${providerId}/edit?tab=${INFORMATION}`}
      >
        <p className="menu-linkk">
          <FirstAidKit size={24} /> <span className="heading-smb">My Information</span>
        </p>
      </Link>
      <Link
        className={classNames({active: isLinkActive(PAYROLL)})}
        to={PROVIDERS_PATH + `/${providerId}/edit?tab=${PAYROLL}`}
      >
        <p className="menu-linkk">
          <CurrencyDollar size={24} /> <span className="heading-smb">Pay</span>
        </p>
      </Link>
      <Link
        className={classNames({active: isLinkActive(RATES)})}
        to={PROVIDERS_PATH + `/${providerId}/edit?tab=${RATES}`}
      >
        <p className="menu-linkk">
          <FirstAidKit size={24} /> <span className="heading-smb">Rates</span>
        </p>
      </Link>
      <Link
        className={classNames({active: isLinkActive(FACILITIES)})}
        to={PROVIDERS_PATH + `/${providerId}/edit?tab=${FACILITIES}`}
      >
        <p className="menu-linkk">
          <Wrench size={24} /> <span className="heading-smb">Facilities</span>
        </p>
      </Link>
      <Link
        className={classNames({active: isLinkActive(ADJUSTMENT)})}
        to={PROVIDERS_PATH + `/${providerId}/edit?tab=${ADJUSTMENT}`}
      >
        <p className="menu-linkk">
          <ClockCounterClockwise size={24} /> <span className="heading-smb">Session History</span>
        </p>
      </Link>
      <Link
        className={classNames({active: isLinkActive(CALENDAR)})}
        to={PROVIDERS_PATH + `/${providerId}/edit?tab=${CALENDAR}`}
      >
        <p className="menu-linkk">
          <CalendarBlank size={24} /> <span className="heading-smb">Calendar</span>
        </p>
      </Link>
      <NavLink to="/settings">
        {' '}
        <p className="menu-linkk">
          <Gear size={24} /> <span className="heading-smb">Settings</span>
        </p>
      </NavLink>
    </>
  );
};

export default ProviderPermittedView;
