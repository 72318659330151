const getEarningCodeInitialValues = selectedValues => {
  if (selectedValues !== null) {
    return {
      // active: selectedValues?.active || 0,
      code: selectedValues?.code || '',
      name: selectedValues?.name || '',
      billable: selectedValues?.billable || false,
      status: selectedValues?.status === 'active' ? 1 : 0,
      //   calculation_type: selectedValues?.calculation_type || '',
      max_duration: selectedValues?.max_duration || 0,
      min_duration: selectedValues?.min_duration || 0,
      unit_in_minutes: selectedValues?.unit_in_minutes || '',
      note_required: selectedValues?.note_required || false,
      pto_required: selectedValues?.pto_required || false,
      required_medical_patient: selectedValues?.required_medical_patient || false,
      required_patient: selectedValues?.required_patient || false,
      required_supervision: selectedValues?.required_supervision || false,
      round_units: selectedValues?.round_units || false,
      time_off: selectedValues?.time_off || false,
    };
  }
  return {
    // active: 1,
    code: '',
    name: '',
    billable: false,
    status: 1,
    // calculation_type: '',
    max_duration: 0,
    min_duration: 0,
    unit_in_minutes: '',
    note_required: false,
    pto_required: false,
    required_medical_patient: false,
    required_patient: false,
    required_supervision: false,
    round_units: false,
    time_off: false,
  };
};

export {getEarningCodeInitialValues};
