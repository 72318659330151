import React, {useEffect, useState, useCallback} from 'react';
import TableWraper from 'components/common/table-wrapper';
import debounce from 'lodash/debounce';

import {Eye} from 'phosphor-react';

import {getEmailQueueList} from 'containers/mail/api';
import {DEBOUNCE_DELAY, isAdministrator, isBiller, isPayRoll} from 'utils/common';
import {initialMetaForTable} from 'constants/common';
import {EMAIL_DETAILS_PATH} from 'constants/routePaths';
import {useNavigate} from 'react-router-dom';
import {getNameInitials} from 'utils/common';
import '../mail.scss';

const EmailQueueListing = () => {
  const navigate = useNavigate();
  const [allListSelected, setAllListSelected] = useState(false);
  const [emailQueueList, setEmailQueueList] = useState([]);
  const [refreshPage, setRefreshPage] = useState(false);
  const [loading, setLoading] = useState(true);
  const [meta, setMeta] = useState(initialMetaForTable);
  const [totalCount, setTotalCount] = useState(0);

  const fetchData = useCallback(
    async function () {
      try {
        const result = await getEmailQueueList(meta);

        if (result['emails']) {
          const data = result?.emails.map(item => ({...item, isSelected: allListSelected}));
          setEmailQueueList(data);
          setTotalCount(result?.meta.total_count);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
        setRefreshPage(false);
      }
    },
    [loading, refreshPage],
  );

  useEffect(() => {
    if (loading || refreshPage) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, refreshPage]);

  const handleRefreshPage = () => {
    setRefreshPage(pre => !pre);
  };
  const handleSetPerPage = obj => {
    setMeta(pre => ({...pre, perPage: obj.value}));
    handleRefreshPage();
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(debounce(handleRefreshPage, DEBOUNCE_DELAY), [meta.search]);
  const handleSetSearchQuery = value => {
    setMeta(pre => ({...pre, search: value}));
    debounceFn();
  };
  const handlePageChange = value => {
    setMeta(pre => ({...pre, page: value}));
    handleRefreshPage();
    setLoading(true);
  };

  const setAllListCheck = value => {
    setEmailQueueList(pre => pre.map(item => ({...item, isSelected: value})));
  };
  const setSingleItemCheck = (value, id) => {
    setEmailQueueList(pre => pre.map(item => (item.id === id ? {...item, isSelected: value} : item)));
  };

  return (
    <>
      <div className="email-queue">
        <TableWraper
          setPerPage={handleSetPerPage}
          setSearchQuery={handleSetSearchQuery}
          searchValue={meta.search}
          totalListCount={totalCount}
          pageSize={meta.perPage}
          currentPage={meta.page}
          onPageChange={handlePageChange}
        >
          {loading || refreshPage ? (
            <h3 style={{textAlign: 'center'}}>Loading</h3>
          ) : (
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">
                    <div className="header-text-otr">
                      <p className="table-name heading-xsb">
                        <input
                          className="form-check-input"
                          onChange={e => {
                            setAllListSelected(e.target.checked);
                            setAllListCheck(e.target.checked);
                          }}
                          type="checkbox"
                          checked={allListSelected}
                        />
                      </p>
                    </div>
                  </th>
                  <th scope="col">
                    <div className="header-text-otr">
                      <p className="table-name heading-xsb">Send Date</p>
                    </div>
                  </th>
                  <th scope="col">
                    <div className="header-text-otr">
                      <p className="table-name heading-xsb">Sent By</p>
                    </div>
                  </th>
                  <th scope="col">
                    <div className="header-text-otr">
                      <p className="table-name heading-xsb">To Email</p>
                    </div>
                  </th>
                  <th scope="col">
                    <div className="header-text-otr">
                      <p className="table-name heading-xsb">Subject</p>
                    </div>
                  </th>

                  {(isAdministrator() || isPayRoll() || isBiller()) && (
                    <th scope="col">
                      <div className="header-text-otr">
                        <p className="table-name heading-xsb">Action</p>
                      </div>
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {emailQueueList.map(item => {
                  return (
                    <tr key={item.id}>
                      <th scope="col">
                        <div className="header-text-otr">
                          <p className="table-name heading-xsb">
                            <input
                              className="form-check-input"
                              value={item.isSelected}
                              onChange={e => {
                                setSingleItemCheck(e.target.checked, item.id);
                              }}
                              type="checkbox"
                              checked={item.isSelected}
                            />
                          </p>
                        </div>
                      </th>
                      <td>
                        <div className="table-text-otr">
                          <p className="table-text ">{item.send_date || ''}</p>
                        </div>
                      </td>
                      <td>
                        <div className="profile-otr" title={item.insert_by}>
                          <div className="named-avatar">{getNameInitials(item.insert_by)}</div>
                          {item.insert_by}
                        </div>
                      </td>
                      <td>
                        <div className="table-text-otr">
                          <p className="table-text ">{item.to_email || ''}</p>
                        </div>
                      </td>
                      <td>
                        <div className="table-text-otr">
                          <p className="table-text ">{item.subject || '-'}</p>
                        </div>
                      </td>
                      {(isAdministrator() || isPayRoll() || isBiller()) && (
                        <td className="action-column">
                          <div className="table-icon-otr">
                            <div className="icon-otr">
                              <Eye
                                size={24}
                                onClick={() => {
                                  navigate(`${EMAIL_DETAILS_PATH}?id=${item.id}`);
                                }}
                              />
                            </div>
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </TableWraper>
      </div>
    </>
  );
};

export default EmailQueueListing;
