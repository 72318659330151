import {ReactComponent as AlertPrimaryImg} from 'assets/images/alert-primary.svg';
import {ReactComponent as AlertSecondaryImg} from 'assets/images/alert-secondary.svg';
import {ReactComponent as AlertInfoImg} from 'assets/images/alert-info.svg';
import {ReactComponent as AlertSuccessImg} from 'assets/images/alert-success.svg';
import {ReactComponent as AlertDangerImg} from 'assets/images/alert-danger.svg';
import {ReactComponent as AlertWarningImg} from 'assets/images/alert-warning.svg';

export const BASE_URL = process.env.REACT_APP_API_URL;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;
export const GRANT_TYPE = 'password';

export const roleTypes = {
  ADMINISTRATOR: 0,
  PAYROLL: 1,
  DIRECTOR: 2,
  VIEW_ONLY: 3,
  PROVIDER: 4,
  BILLER: 5,
};

export const roleNamesByType = {
  [roleTypes.ADMINISTRATOR]: 'Administrator',
  [roleTypes.PAYROLL]: 'Payroll',
  [roleTypes.DIRECTOR]: 'Director',
  [roleTypes.VIEW_ONLY]: 'View Only',
  [roleTypes.PROVIDER]: 'Provider',
  [roleTypes.BILLER]: 'Biller',
};

export const states = [
  {value: 'Alabama', label: 'AL'},
  {value: 'Alaska', label: 'AK'},
  {value: 'Arizona', label: 'AZ'},
  {value: 'Arkansas', label: 'AR'},
  {value: 'California', label: 'CA'},
  {value: 'Colorado', label: 'CO'},
  {value: 'Colorado', label: 'CO'},
  {value: 'Connecticut', label: 'CT'},
  {value: 'District of Columbia', label: 'DC'},
  {value: 'Delaware', label: 'DE'},
  {value: 'Florida', label: 'FL'},
  {value: 'Georgia', label: 'GA'},
  {value: 'Hawaii', label: 'HI'},
  {value: 'Idaho', label: 'ID'},
  {value: 'Illinois', label: 'IL'},
  {value: 'Indiana', label: 'IN'},
  {value: 'Iowa', label: 'IA'},
  {value: 'Kansas', label: 'KS'},
  {value: 'Kentucky', label: 'KY'},
  {value: 'Louisiana', label: 'LA'},
  {value: 'Maine', label: 'ME'},
  {value: 'Maryland', label: 'MD'},
  {value: 'Massachusetts', label: 'MA'},
  {value: 'Michigan', label: 'MI'},
  {value: 'Minnesota', label: 'MN'},
  {value: 'Mississippi', label: 'MS'},
  {value: 'Missouri', label: 'MO'},
  {value: 'Montana', label: 'MT'},
  {value: 'Nebraska', label: 'NE'},
  {value: 'Nevada', label: 'NV'},
  {value: 'New Hampshire', label: 'NH'},
  {value: 'New Jersey', label: 'NJ'},
  {value: 'New Mexico', label: 'NM'},
  {value: 'New York', label: 'NY'},
  {value: 'North Carolina', label: 'NC'},
  {value: 'North Dakota', label: 'ND'},
  {value: 'Ohio', label: 'OH'},
  {value: 'Oklahoma', label: 'OK'},
  {value: 'Oregon', label: 'OR'},
  {value: 'Pennsylvania', label: 'PA'},
  {value: 'Puerto Rico', label: 'PR'},
  {value: 'Rhode Island', label: 'RI'},
  {value: 'South Carolina', label: 'SC'},
  {value: 'South Dakota', label: 'SD'},
  {value: 'Tennessee', label: 'TN'},
  {value: 'Texas', label: 'TX'},
  {value: 'Utah', label: 'UT'},
  {value: 'Virgin Islands', label: 'VI'},
  {value: 'Vermont', label: 'VT'},
  {value: 'Virginia', label: 'VA'},
  {value: 'Washington', label: 'WA'},
  {value: 'West Virginia', label: 'WV'},
  {value: 'Wisconsin', label: 'WI'},
  {value: 'Wyoming', label: 'WY'},
];

export const initialMetaForTable = {
  page: 1,
  perPage: 50,
  search: '',
  sort: '',
  order: 'asc',
};

export const initialMetaForPayrollPeriodTable = {
  page: 1,
  perPage: 50,
  search: '',
  sort: '',
  order: 'desc',
};
export const orderStratergy = {
  asc: 'desc',
  desc: 'asc',
};

export const entriesOptions = [
  {value: 10, label: '10'},
  {value: 20, label: '20'},
  {value: 30, label: '30'},
  {value: 50, label: '50'},
];

export const toastContainerProps = {
  hideProgressBar: true,
  closeButton: false,
  autoClose: 5000,
  limit: 2,
  position: 'bottom-right',
  closeOnClick: false,
};

export const alertTypes = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  DANGER: 'danger',
};

export const alertIcons = {
  [alertTypes.INFO]: <AlertInfoImg />,
  [alertTypes.DANGER]: <AlertDangerImg />,
  [alertTypes.WARNING]: <AlertWarningImg />,
  [alertTypes.SUCCESS]: <AlertSuccessImg />,
  [alertTypes.PRIMARY]: <AlertPrimaryImg />,
  [alertTypes.SECONDARY]: <AlertSecondaryImg />,
};

export const notableTypes = {
  PROVIDER: 'Provider',
  FACILITY: 'Facility',
};

export const statusOptions = [
  {value: 'success', label: 'SUCCESS'},
  {value: 'error', label: 'ERROR'},
];

export const lookerImportStatusOPtions = [
  {value: 'complete', label: 'SUCCESS'},
  {value: 'error', label: 'ERROR'},
  {value: 'in_process', label: 'IN PROCESS'}
];

export const statusAmountOptions = [
  {value: 'paid', label: 'Paid'},
  {value: 'unpaid', label: 'Unpaid'},
];

export const textStatus = {
  error: 'in-active',
  in_process: 'in-process',
  success: 'active',
};

export const paidTypeStatus = {
  erroneous: 'in-active',
  unpaid: 'in-process',
  paid: 'active',
};

export const filterOptions = [
  {value: 'active', label: 'ACTIVE'},
  {value: 'inactive', label: 'INACTIVE'},
];

export const appendForMail = '@cheservices.com';

export const PAID_TYPES = [
  {label: 'Paid', value: 'paid'},
  {label: 'Unpaid', value: 'unpaid'},
  {label: 'Erroneous', value: 'erroneous'},
];
