import React, {useEffect, useRef, useState} from 'react';

import TableWraper from 'components/common/table-wrapper';

import {getActionButtonProps, isAdministrator, isBiller, isPayRoll} from 'utils/common';
import {PencilSimple, Eye, ArrowsDownUp} from 'phosphor-react';
import {createProviderAndPayrollInformation, getProvidersList, getProvidersListBlob} from 'containers/adp-missing/api';
import {DEBOUNCE_DELAY} from 'utils/common';
import {alertTypes, appendForMail, initialMetaForTable, orderStratergy} from 'constants/common';
import {PROVIDERS_PATH, PROVIDER_TABS} from 'constants/routePaths';
import {useNavigate} from 'react-router-dom';

import CreateProviderModal from './CreateProviderModal';
import ShowProvider from './ShowProvider';
import {tabsList} from 'constants/providers';
import {Dropdown} from 'react-bootstrap';
import {orderBy} from 'lodash';
import moment from 'moment';
import {CSVLink} from 'react-csv';
import {toast} from 'react-toastify';
import CustomToast from 'components/common/custom-toast';
import {Tooltip, OverlayTrigger} from 'components/common/tooltip';

let timeout;
const headers = [
  {label: 'Npi Code', key: 'npi_code'},
  {label: 'Provider Name', key: 'name'},
  {label: 'Email', key: 'company_email'},
];
const renderTooltip = props => (
  <Tooltip id="button-tooltip" {...props}>
    Edit
  </Tooltip>
);

const ProvidersListing = ({refreshByParent, filters}) => {
  const exportRef = useRef(null);
  const navigate = useNavigate();
  const [allListSelected, setAllListSelected] = useState(false);
  const [providersList, setProvidersList] = useState([]);
  const [refreshPage, setRefreshPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState(initialMetaForTable);
  const [totalCount, setTotalCount] = useState(0);
  const [createModalShow, setCreateModalShow] = useState(false);
  const [createModalLoading, setCreateModalLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [providerInformation, setProviderInformation] = useState(false);
  const [isSorted, setSorted] = useState(false);
  const [csvData, setCsvData] = useState([]);

  async function fetchData() {
    try {
      setLoading(true);
      const result = await getProvidersList({...meta, ...filters});
      if (result['providers']) {
        const data = result?.providers.map(item => ({...item, isSelected: allListSelected}));
        setProvidersList(data);
        setTotalCount(result?.meta.total_count);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage, refreshByParent]);
  useEffect(() => {
    setCsvData(providersList.filter(item => item.isSelected));
  }, [providersList]);
  const handleRefreshPage = () => {
    setRefreshPage(pre => !pre);
  };
  const handleSetPerPage = obj => {
    setMeta(pre => ({...pre, perPage: obj.value}));
    handleRefreshPage();
  };
  const debounceFn = (callback, delay) => {
    clearTimeout(timeout);
    timeout = setTimeout(callback, delay);
  };
  const handleSetSearchQuery = value => {
    setMeta(pre => ({...pre, search: value}));
    debounceFn(handleRefreshPage, DEBOUNCE_DELAY);
  };
  const handlePageChange = value => {
    setMeta(pre => ({...pre, page: value}));
    handleRefreshPage();
  };

  const setAllListCheck = value => {
    setProvidersList(pre => pre.map(item => ({...item, isSelected: value})));
  };
  const setSingleItemCheck = (value, id) => {
    setProvidersList(pre => pre.map(item => (item.id === id ? {...item, isSelected: value} : item)));
  };
  const handleClickAddProvider = () => {
    setCreateModalShow(true);
  };
  const handleCreateProvider = async (values, setErrors) => {
    setCreateModalLoading(true);
    if (!values.payroll_information_attributes.provider_category_label.toLowerCase().includes('salary')) {
      delete values.payroll_information_attributes.base_salary;
      delete values.payroll_information_attributes.min_units_per_day;
      delete values.payroll_information_attributes.unit_rate;
    }

    // if (values.regional_directors_attributes.length > 0) {
    //   values.regional_director_attribute = values.regional_director_attribute.map(rda => rda.id);
    // }

    delete values.payroll_information_attributes.last_dos;
    delete values.payroll_information_attributes.pay_roll_end_date;
    const data = {
      provider: {
        ...values,
        company_email: values.company_email,
        phone_no: values.phone_no.replaceAll(' ', ''),
        active: values.active ? true : false,
        provider_and_titles_attributes: values.provider_and_titles_attributes.map(provider_title => ({
          provider_title_id: provider_title.value,
        })),
        payroll_information_attributes: {
          ...values.payroll_information_attributes,
          ...(values.payroll_information_attributes.first_dos && {
            first_dos: moment(values.payroll_information_attributes.first_dos).format('YYYY-MM-DD'),
          }),
          // last_dos: moment(values.payroll_information_attributes.last_dos).format('YYYY-MM-DD'),
          // pay_roll_end_date: moment(values.payroll_information_attributes.pay_roll_end_date).format('YYYY-MM-DD'),
        },
      },
    };
    try {
      const result = await createProviderAndPayrollInformation(data, setErrors);
      if (result.meta.status === 200) {
        handleRefreshPage();
      }
    } catch (e) {
      console.log(e);
    } finally {
      setCreateModalLoading(false);
      setCreateModalShow(false);
    }
  };
  const handleCloseProviderInformation = () => {
    setProviderInformation(false);
    setSelectedRow(null);
  };

  const sortedDataByColumn = (columnName, isAlreadySorted) => {
    if (providersList.length > 0) {
      // const orderByColumn = orderBy(providersList, [columnName], [isAlreadySorted ? 'desc' : 'asc']);
      // setProvidersList(orderByColumn);
      // setSorted(!isAlreadySorted);
      setMeta(pre => ({...pre, sort: columnName, order: orderStratergy[pre.order]}));
      handleRefreshPage();
    }
  };
  const handleExport = done => {
    exportRef.current.link.click();
    if (csvData.length) {
      done(true);
    } else {
      toast(<CustomToast variant={alertTypes.DANGER} message={'Please Select Record!'} />);
      done(false);
    }
  };

  const handleExportWithApi = async () => {
    try {
      const result = await getProvidersListBlob({...meta, ...filters});
      console.log('data is ', result);
      if (result) {
        const encodedData = result.providers_encoded_data;
        const decodedData = atob(encodedData);

        const blob = new Blob([decodedData], {type: 'text/csv'});

        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'provider-data.csv');

        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const AddressComponent = ({address}) => {
    const addressParts = address.split(',');
    const street = addressParts[0];
    const cityStateZip = addressParts.slice(1).join(', ');

    return (
      <div>
        <p>{street}</p>
        <p>{cityStateZip}</p>
      </div>
    );
  };

  return (
    <>
      <TableWraper
        setPerPage={handleSetPerPage}
        setSearchQuery={handleSetSearchQuery}
        searchValue={meta.search}
        totalListCount={totalCount}
        pageSize={meta.perPage}
        currentPage={meta.page}
        onPageChange={handlePageChange}
        actionButtons={
          (isAdministrator() || isPayRoll() || isBiller()) && [
            // ...getActionButtonProps('Create Provider', handleClickAddProvider),
            ...getActionButtonProps('Export', handleExportWithApi),
          ]
        }
      >
        {loading ? (
          <h3 style={{textAlign: 'center'}}>Loading</h3>
        ) : (
          <table className="table">
            <thead>
              <tr>
                {/* <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">
                      <input
                        className="form-check-input"
                        onChange={e => {
                          setAllListSelected(e.target.checked);
                          setAllListCheck(e.target.checked);
                        }}
                        type="checkbox"
                        checked={allListSelected}
                      />
                    </p>
                  </div>
                </th> */}
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">NPI Code</p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p
                      className="table-name heading-xsb cursor-pointer c-hand"
                      onClick={() => sortedDataByColumn('name', isSorted)}
                    >
                      Provider Name
                    </p>
                  </div>
                </th>
                {/* <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Provider Title</p>
                  </div>
                </th> */}
                <th scope="col">
                  <div className="header-text-otr">
                    <p
                      className="table-name heading-xsb"
                      onClick={() => sortedDataByColumn('personal_email', isSorted)}
                    >
                      Contact
                      
                    </p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p
                      className="table-name heading-xsb"
                      onClick={() => sortedDataByColumn('office_location', isSorted)}
                    >
                      Address
                      
                    </p>
                  </div>
                </th>
                {(isAdministrator() || isPayRoll() || isBiller()) && (
                  <th scope="col" className="action-column">
                    <div className="header-text-otr">
                      <p className="table-name heading-xsb">Actions</p>
                    </div>
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {providersList.map(item => {
                return (
                  <tr key={item.id}>
                    {/* <th scope="col">
                      <div className="header-text-otr">
                        <p className="table-name heading-xsb">
                          <input
                            className="form-check-input"
                            value={item.isSelected}
                            onChange={e => {
                              setSingleItemCheck(e.target.checked, item.id);
                            }}
                            type="checkbox"
                            checked={item.isSelected}
                          />
                        </p>
                      </div>
                    </th> */}

                    <th scope="col">
                      <div className="table-text-otr">
                        <p className="table-text ">{item?.npi_code || ''}</p>
                      </div>
                    </th>
                    <td>
                      <div className="">
                        <p className="table-text ">
                          {item.last_name}, {item.first_name} {item?.provider_title?.name}
                        </p>
                      </div>
                    </td>
                    {/* <td>
                      <div className="table-text-otr">
                        <p className="table-text ">{item?.provider_title?.name || '-'}</p>
                      </div>
                    </td> */}

                    <td>
                      <div className="">
                        <p className="table-text-no-wrap">
                          Phone: {item?.phone_no || ''} <br /> Email: {item?.company_email || ''}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div className="">
                        <p className="table-text-no-wrap">{<AddressComponent address={item.office_location} />}</p>
                      </div>
                    </td>
                    {(isAdministrator() || isPayRoll() || isBiller()) && (
                      <td className="action-column">
                        <div className="table-icon-otr">
                          {/* <Dropdown>
                            <Dropdown.Toggle variant="white" id="dropdown-basic">
                              ...
                            </Dropdown.Toggle>

                            <Dropdown.Menu> */}
                          {/* <Dropdown.Item
                                onClick={() => {
                                  setSelectedRow(item);
                                  setProviderInformation(true);
                                }}
                               >
                                <div className="icon-otr">
                                  <span className="me-2">
                                    <Eye size={24} />
                                  </span>
                                  <span>Details</span>
                                </div>
                              </Dropdown.Item> */}
                          {/* <Dropdown.Item
                                onClick={() => {
                                  navigate(
                                    PROVIDERS_PATH +
                                      `/${item.id}/edit?tab=${tabsList[PROVIDER_TABS.INFORMATION].value}`,
                                  );
                                }}
                              > */}
                          <OverlayTrigger placement="bottom" delay={{show: 250, hide: 400}} overlay={renderTooltip}>
                            <div
                              className="icon-otr"
                              onClick={() => {
                                navigate(
                                  PROVIDERS_PATH + `/${item.id}/edit?tab=${tabsList[PROVIDER_TABS.INFORMATION].value}`,
                                );
                              }}
                            >
                              <span className="me-2">
                                <PencilSimple size={24} />
                              </span>
                              {/* <span>Edit</span> */}
                            </div>
                          </OverlayTrigger>
                          {/* </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown> */}

                          {/* <div className="icon-otr">
                            <Eye
                              size={24}
                              onClick={() => {
                                setSelectedRow(item);
                                setProviderInformation(true);
                              }}
                            />
                          </div>
                          <div className="icon-otr">
                            <PencilSimple
                              size={24}
                              onClick={() => {
                                navigate(
                                  PROVIDERS_PATH + `/${item.id}/edit?tab=${tabsList[PROVIDER_TABS.INFORMATION].value}`,
                                );
                              }}
                            />
                          </div> */}
                        </div>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </TableWraper>
      <CSVLink
        ref={exportRef}
        data={csvData}
        headers={headers}
        asyncOnClick={true}
        onClick={(event, done) => {
          handleExport(done);
        }}
        filename={'my-file.csv'}
        className="btn btn-primary opacity-0 w-0 h-0 position-absolute top-0"
        target="_blank"
      />
      {createModalShow && (
        <CreateProviderModal
          open={true}
          close={() => {
            setCreateModalShow(false);
          }}
          handleSubmit={handleCreateProvider}
          loading={createModalLoading}
        />
      )}
      {providerInformation && (
        <ShowProvider
          isModalVisible={true}
          handleCloseModal={handleCloseProviderInformation}
          selectedRow={selectedRow}
        />
      )}
    </>
  );
};

export default ProvidersListing;
