import React from 'react';
import CustomModal from 'components/common/modal';
import {Accordion, Col, Row} from 'react-bootstrap';

const ShowList = ({selectedRow, handleCloseModal, isModalVisible}) => {
  return (
    <CustomModal size="md" show={isModalVisible} onHide={() => handleCloseModal()} heading={`System Audit`}>
      <Row className="gap-5">
        <Col md={3}>
          <h5>Name</h5>
          <p>{selectedRow?.meta?.updated_by_jsonb?.name || ''}</p>
        </Col>
        <Col md={3}>
          <h5>Date</h5>
          <p>{selectedRow?.created_at || ''}</p>
        </Col>
        <Col md={3}>
          <h5>Email</h5>
          <p>{selectedRow?.updated_by || ''}</p>
        </Col>
        <Col md={3}>
          <h5>Event</h5>
          <p>{selectedRow?.event || ''}</p>
        </Col>
        <Col md={3}>
          <h5>UDP</h5>
          <p>{selectedRow?.item_type || ''}</p>
        </Col>
        <Col md={12}>
          <h5>Description</h5>
          <div className="description-wrapper">
            <p>{selectedRow?.attribute_changed_message || ''}</p>
          </div>
        </Col>
      </Row>
      <Accordion defaultActiveKey={0} className="mt-2">
        {Object.keys(selectedRow.attribute_changed_hash).map((item, index) => {
          return (
            <Accordion.Item eventKey={index}>
              <Accordion.Header>{item.toUpperCase()}</Accordion.Header>
              <Accordion.Body>
                {Object.keys(selectedRow.attribute_changed_hash[item]).map(ele => {
                  return (
                    <>
                      {' '}
                      <h5>{ele.toUpperCase()}</h5>
                      <p>{selectedRow.attribute_changed_hash[item][ele]}</p>
                    </>
                  );
                })}
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </CustomModal>
  );
};

export default ShowList;
