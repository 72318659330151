import React, {useEffect, useState} from 'react';

import TableWraper from 'components/common/table-wrapper';

import {DEBOUNCE_DELAY, useQuery} from 'utils/common';
import {initialMetaForTable} from 'constants/common';
import {getFacilityUnitsReport} from 'containers/faclity-units/api';

let timeout;

const FacilityUnitsListing = () => {
  const query = useQuery();
  const startDate = query.get('startDate');
  const endDate = query.get('endDate');
  const entities = query.get('entities');

  const [facilityUnitsList, setFacilityUnitsList] = useState([]);
  const [refreshPage, setRefreshPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState(initialMetaForTable);
  const [totalCount, setTotalCount] = useState(0);

  async function fetchData() {
    try {
      setLoading(true);
      const result = await getFacilityUnitsReport({startDate, endDate, entities}, meta.search);
      if (result) {
        setFacilityUnitsList(result);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage]);

  const handleRefreshPage = () => {
    setRefreshPage(pre => !pre);
  };
  const handleSetPerPage = obj => {
    setMeta(pre => ({...pre, perPage: obj.value}));
    handleRefreshPage();
  };
  const debounceFn = (callback, delay) => {
    clearTimeout(timeout);
    timeout = setTimeout(callback, delay);
  };
  const handleSetSearchQuery = value => {
    setMeta(pre => ({...pre, search: value}));
    debounceFn(handleRefreshPage, DEBOUNCE_DELAY);
  };
  const handlePageChange = value => {
    setMeta(pre => ({...pre, page: value}));
    handleRefreshPage();
  };
  return (
    <>
      <TableWraper
        setPerPage={handleSetPerPage}
        setSearchQuery={handleSetSearchQuery}
        searchValue={meta.search}
        totalListCount={totalCount}
        pageSize={meta.perPage}
        currentPage={meta.page}
        onPageChange={handlePageChange}
      >
        {loading ? (
          <h3 style={{textAlign: 'center'}}>Loading</h3>
        ) : (
          <table className="table">
            <thead>
              <tr>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Entity</p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Facility</p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Beds</p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Units</p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Hours</p>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {facilityUnitsList.map((item, i) => {
                return (
                  <tr key={i}>
                    <th scope="row">
                      <div className="table-text-otr ">
                        <span className="table-text">{item?.entity_name || ''}</span>
                      </div>
                    </th>
                    <td>
                      <div className="table-text-otr">
                        <p className="table-text">{item?.facility_name || ''}</p>
                      </div>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <p className="table-text">{item?.total_beds || ''}</p>
                      </div>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <p className="table-text">{item?.total_unit || ''}</p>
                      </div>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <p className="table-text">{item?.total_hours || ''}</p>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </TableWraper>
    </>
  );
};

export default FacilityUnitsListing;
