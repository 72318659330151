import React, {useEffect, useRef, useState} from 'react';

import TableWraper from 'components/common/table-wrapper';

import {ArrowsDownUp, Pencil} from 'phosphor-react';
import {DEBOUNCE_DELAY, getActionButtonProps, isAdministrator, isBiller, isPayRoll} from 'utils/common';
import {alertTypes, initialMetaForTable, orderStratergy, paidTypeStatus} from 'constants/common';
import {getProviderDataByFilter, updateAdjustment} from 'containers/provider-adjustments/api';
import {startCase} from 'lodash';
import EditModal from './EditModal';
import moment from 'moment';
import {CSVLink} from 'react-csv';
import {toast} from 'react-toastify';
import CustomToast from 'components/common/custom-toast';

let timeout;
const headers = [
  {label: 'Effective Date', key: 'effective_date'},
  {label: 'Service Date', key: 'service_date'},
  {label: 'Provider', key: 'provider.first_name'},
  {label: 'Facility', key: 'facility.name'},
  {label: 'Amount', key: 'reg_amount'},
  {label: 'Paid', key: 'status'},
];

const RecordsListing = ({refreshByParent, filters}) => {
  const exportRef = useRef(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [adjustmentsList, setAdjustmentsList] = useState([]);
  const [allListSelected, setAllListSelected] = useState(false);
  const [isModalVisible, setModalVisibility] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState(initialMetaForTable);
  const [totalCount, setTotalCount] = useState(0);
  const [isSorted, setSorted] = useState(false);

  const [csvData, setCsvData] = useState([]);
  async function fetchData() {
    try {
      setLoading(true);
      const result = await getProviderDataByFilter({...meta, ...filters});
      if (result['provider_data']) {
        setAdjustmentsList(result?.provider_data?.map(item => ({...item, isSelected: allListSelected})));
        setTotalCount(result?.meta.total_count);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage, refreshByParent]);
  useEffect(() => {
    setCsvData(adjustmentsList.filter(item => item.isSelected));
  }, [adjustmentsList]);

  const handleRefreshPage = () => {
    setRefreshPage(pre => !pre);
  };
  const handleSetPerPage = obj => {
    setMeta(pre => ({...pre, perPage: obj.value}));
    handleRefreshPage();
  };
  const debounceFn = (callback, delay) => {
    clearTimeout(timeout);
    timeout = setTimeout(callback, delay);
  };
  const handleSetSearchQuery = value => {
    setMeta(pre => ({...pre, search: value}));
    debounceFn(handleRefreshPage, DEBOUNCE_DELAY);
  };
  const handlePageChange = value => {
    setMeta(pre => ({...pre, page: value}));
    handleRefreshPage();
  };
  const handleCloseModal = () => {
    setModalVisibility(false);
    setSelectedRow(null);
  };
  const handleOpenModal = () => {
    setModalVisibility(true);
  };

  const setAllListCheck = value => {
    setAdjustmentsList(pre => pre.map(item => ({...item, isSelected: value})));
  };
  const setSingleItemCheck = (value, id) => {
    setAdjustmentsList(pre => pre.map(item => (item.id === id ? {...item, isSelected: value} : item)));
  };
  const handleSubmit = async values => {
    const {id, ...rest} = values;
    const payload = {
      provider_datum: {
        ...rest,
        documentation_received_date: moment(rest.documentation_received_date).format('YYYY-MM-DD'),
        effective_date: moment(rest.effective_date).format('YYYY-MM-DD'),
        service_date: moment(rest.service_date).format('YYYY-MM-DD'),
      },
      id,
    };
    const result = await updateAdjustment(payload);
    if (result?.meta?.status === 200) {
      handleCloseModal();
      handleRefreshPage();
    }
  };
  const handleExport = done => {
    exportRef.current.link.click();
    if (csvData.length) {
      done(true);
    } else {
      toast(<CustomToast variant={alertTypes.DANGER} message={'Please Select Record!'} />);
      done(false);
    }
  };
  const sortedDataByColumn = (columnName, isAlreadySorted) => {
    if (adjustmentsList.length > 0) {
      setMeta(pre => ({...pre, sort: columnName, order: orderStratergy[pre.order]}));
      handleRefreshPage();
    }
  };
  return (
    <>
      <TableWraper
        setPerPage={handleSetPerPage}
        setSearchQuery={handleSetSearchQuery}
        searchValue={meta.search}
        totalListCount={totalCount}
        pageSize={meta.perPage}
        currentPage={meta.page}
        onPageChange={handlePageChange}
        actionButtons={
          (isAdministrator() || isPayRoll() || isBiller()) && [
            ...getActionButtonProps('Export', () => {
              exportRef.current.link.click();
            }),
          ]
        }
      >
        {loading ? (
          <h3 style={{textAlign: 'center'}}>Loading</h3>
        ) : (
          <table className="table">
            <thead>
              <tr>
                {/* <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">
                      <input
                        className="form-check-input"
                        onChange={e => {
                          setAllListSelected(e.target.checked);
                          setAllListCheck(e.target.checked);
                        }}
                        type="checkbox"
                        checked={allListSelected}
                      />
                    </p>
                  </div>
                </th> */}
                <th scope="col">
                  <div className="header-text-otr">
                    <p
                      className="table-name heading-xsb cursor-pointer c-hand"
                      onClick={() => sortedDataByColumn('effective_date', isSorted)}
                    >
                      Effective Date 
                    </p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p
                      className="table-name heading-xsb cursor-pointer c-hand"
                      onClick={() => sortedDataByColumn('service_date', isSorted)}
                    >
                      Service Date 
                    </p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p
                      className="table-name heading-xsb cursor-pointer c-hand"
                      onClick={() => sortedDataByColumn('provider.name', isSorted)}
                    >
                      Provider 
                    </p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p
                      className="table-name heading-xsb cursor-pointer c-hand"
                      onClick={() => sortedDataByColumn('facility.name', isSorted)}
                    >
                      Facility 
                    </p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p
                      className="table-name heading-xsb cursor-pointer c-hand"
                      onClick={() => sortedDataByColumn('reg_amount', isSorted)}
                    >
                      Amount 
                    </p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Paid</p>
                  </div>
                </th>

                {(isAdministrator() || isPayRoll() || isBiller()) && (
                  <th scope="col" className="action-column">
                    <div className="header-text-otr">
                      <p className="table-name heading-xsb">Actions</p>
                    </div>
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {adjustmentsList?.map(item => {
                return (
                  <tr key={item.id}>
                    {/* <th scope="col">
                      <div className="header-text-otr">
                        <p className="table-name heading-xsb">
                          <input
                            className="form-check-input"
                            value={item.isSelected}
                            onChange={e => {
                              setSingleItemCheck(e.target.checked, item.id);
                            }}
                            type="checkbox"
                            checked={item.isSelected}
                          />
                        </p>
                      </div>
                    </th> */}

                    <th scope="col">
                      <div className="table-text-otr ">
                        <span className="table-text">{item?.effective_date || ''}</span>
                      </div>
                    </th>
                    <td>
                      <div className="table-text-otr">
                        <p className="table-text">{item?.service_date || ''}</p>
                      </div>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <p className="table-text">
                          {item?.provider?.first_name + ' ' + item?.provider?.last_name || ''}
                        </p>
                      </div>
                    </td>
                    <td style={{width: '250px'}}>
                      <div className="table-text-otr">
                        <p className="table-text">{item?.facility?.name || ''}</p>
                      </div>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <p className="table-text">{item?.reg_amount || ''}</p>
                      </div>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <p className={`table-text heading-xsb ${paidTypeStatus[item?.paid_type]}`}>
                          {startCase(item.paid_type)}
                        </p>
                      </div>
                    </td>
                    {(isAdministrator() || isPayRoll() || isBiller()) && (
                      <td className="action-column">
                        <div className="table-icon-otr">
                          <div className="icon-otr">
                            <Pencil
                              size={24}
                              onClick={() => {
                                handleOpenModal();
                                setSelectedRow({...item});
                              }}
                            />
                          </div>
                        </div>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </TableWraper>
      <CSVLink
        ref={exportRef}
        data={csvData}
        headers={headers}
        asyncOnClick={true}
        onClick={(event, done) => {
          handleExport(done);
        }}
        filename={'my-file.csv'}
        className="btn btn-primary opacity-0 w-0 h-0 position-absolute top-0"
        target="_blank"
      />
      {isModalVisible && (
        <EditModal
          selectedRow={{
            ...selectedRow,
            provider_id: selectedRow?.provider?.id,
            facility_id: selectedRow?.facility?.id || '',
            earning_code_id: selectedRow?.earning_code?.id || '',
            serviced_unit: selectedRow?.serviced_unit || '',
            service_date: selectedRow?.service_date || '',
            effective_date: selectedRow?.effective_date || '',
            unit_in_minutes: selectedRow?.unit_in_minutes || '',
            patient_seen: selectedRow?.patient_seen || '',
            note: selectedRow?.note || {message: ''},
          }}
          handleCloseModal={handleCloseModal}
          handleSubmit={handleSubmit}
        />
      )}
    </>
  );
};

export default RecordsListing;
