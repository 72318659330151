import '../mail.scss';
import React, {useEffect, useState, useCallback} from 'react';
import TableWraper from 'components/common/table-wrapper';
import debounce from 'lodash/debounce';

import {getTemplatesList, createTemplate, updateTemplate, deleteTemplate} from 'containers/mail/api';
import {getActionButtonProps, DEBOUNCE_DELAY, isAdministrator, isBiller, isPayRoll} from 'utils/common';
import {initialMetaForTable} from 'constants/common';
import {Trash, PencilSimple} from 'phosphor-react';
import CreateTemplateModal from 'components/mail/templates/CreateTemplateModal';

const EmailTemplatesListing = () => {
  const [allListSelected, setAllListSelected] = useState(false);
  const [templatesList, setTemplatesList] = useState([]);
  const [refreshPage, setRefreshPage] = useState(false);
  const [loading, setLoading] = useState(true);
  const [meta, setMeta] = useState(initialMetaForTable);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalVisible, setModalVisibility] = useState(false);

  const fetchData = useCallback(
    async function () {
      try {
        const result = await getTemplatesList(meta);

        if (result['email_templates']) {
          const data = result.email_templates.map(item => ({...item, isSelected: allListSelected}));
          setTemplatesList(data);
          setTotalCount(result?.meta.total_count);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
        setRefreshPage(false);
      }
    },
    [loading, refreshPage],
  );

  useEffect(() => {
    if (loading || refreshPage) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, refreshPage]);

  const deleteSingleTemplate = async id => {
    await deleteTemplate(id);
    setLoading(true);
  };

  const handleRefreshPage = () => {
    setRefreshPage(pre => !pre);
  };

  const handleSetPerPage = obj => {
    setMeta(pre => ({...pre, perPage: obj.value}));
    handleRefreshPage();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(debounce(handleRefreshPage, DEBOUNCE_DELAY), [meta.search]);
  const handleSetSearchQuery = value => {
    setMeta(pre => ({...pre, search: value}));
    debounceFn();
  };

  const handlePageChange = value => {
    setMeta(pre => ({...pre, page: value}));
    handleRefreshPage();
    setLoading(true);
  };

  const setAllListCheck = value => {
    setTemplatesList(pre => pre.map(item => ({...item, isSelected: value})));
  };

  const setSingleItemCheck = (value, id) => {
    setTemplatesList(pre => pre.map(item => (item.id === id ? {...item, isSelected: value} : item)));
  };

  const handleCloseModal = () => {
    setModalVisibility(false);
    setSelectedRow(null);
  };

  const handleEditModal = item => {
    setSelectedRow(item);
    setModalVisibility(true);
  };

  const handleSubmit = async (values, setErrors, setSubmitting) => {
    setSubmitting(true);
    try {
      const result = selectedRow ? await updateTemplate(values, setErrors) : await createTemplate(values, setErrors);
      if (result?.meta?.status === 200) {
        handleCloseModal();
        handleRefreshPage();
        setSubmitting(false);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="email-queue email-template">
      <TableWraper
        setPerPage={handleSetPerPage}
        setSearchQuery={handleSetSearchQuery}
        searchValue={meta.search}
        totalListCount={totalCount}
        pageSize={meta.perPage}
        currentPage={meta.page}
        onPageChange={handlePageChange}
        actionButtons={
          (isAdministrator() || isPayRoll() || isBiller()) &&
          getActionButtonProps('Add Template', () => setModalVisibility(true))
        }
      >
        {loading || refreshPage ? (
          <h3 style={{textAlign: 'center'}}>Loading</h3>
        ) : (
          <table className="table">
            <thead>
              <tr>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">
                      <input
                        className="form-check-input"
                        onChange={e => {
                          setAllListSelected(e.target.checked);
                          setAllListCheck(e.target.checked);
                        }}
                        type="checkbox"
                        checked={allListSelected}
                      />
                    </p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Template Name</p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Subject</p>
                  </div>
                </th>
                {(isAdministrator() || isPayRoll() || isBiller()) && (
                  <th scope="col">
                    <div className="header-text-otr actions">
                      <p className="table-name heading-xsb">Actions</p>
                    </div>
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {templatesList.map(item => {
                return (
                  <tr key={item.id}>
                    <th scope="col">
                      <div className="header-text-otr">
                        <p className="table-name heading-xsb">
                          <input
                            className="form-check-input"
                            value={item.isSelected}
                            onChange={e => {
                              setSingleItemCheck(e.target.checked, item.id);
                            }}
                            type="checkbox"
                            checked={item.isSelected}
                          />
                        </p>
                      </div>
                    </th>
                    <td>
                      <div className="table-text-otr">
                        <p className="table-text ">{item.name}</p>
                      </div>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <p className="table-text ">{item.subject || '-'}</p>
                      </div>
                    </td>
                    {(isAdministrator() || isPayRoll() || isBiller()) && (
                      <td>
                        <div className="d-flex actions">
                          <div className="table-icon-otr">
                            <div className="icon-otr">
                              <PencilSimple size={24} onClick={() => handleEditModal(item)} />
                            </div>
                            <div className="icon-otr" onClick={() => deleteSingleTemplate(item.id)}>
                              <Trash size={24} />
                            </div>
                          </div>
                        </div>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </TableWraper>
      <CreateTemplateModal
        open={isModalVisible}
        selectedRow={selectedRow}
        close={handleCloseModal}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

export default EmailTemplatesListing;
