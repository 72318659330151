import {get, post} from 'services/network';
import {ROLES_ENDPOINT} from 'constants/endpoints';
import {toast} from 'react-toastify';
import CustomToast from 'components/common/custom-toast';
import {alertTypes} from 'constants/common';

export const getAdminRoles = async obj => {
  const params = {
    sort: obj?.sort ? obj.sort : '',
    order: obj?.order ? obj.order : 'asc',
    page: obj?.page ? obj.page : 1,
    perPage: obj?.perPage ? obj.perPage : 10,
    search: obj?.search ? obj.search : '',
  };
  try {
    const result = await get(
      `${ROLES_ENDPOINT}?page=${params.page}&sort=${params.sort}&order=${params.order}&per=${params.perPage}&query=${params.search}`,
      true,
    );
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const createAdminRole = async (data, setErrors) => {
  try {
    const result = await post(ROLES_ENDPOINT, data, true);
    toast(<CustomToast variant={alertTypes.SUCCESS} message="Role created successfully!" />);
    return result;
  } catch (error) {
    if (error?.response?.data) {
      setErrors(error?.response?.data.errors);
    }
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={error?.response?.data?.message || error?.response?.statusText}
      />,
    );
    console.log(error);
  }
};

export const getUsersByRoleId = async id => {
  try {
    const result = await get(`${ROLES_ENDPOINT}/${id}/users`, true);
    return result;
  } catch (e) {
    console.log(e);
  }
};
