import React from 'react';
import AsyncSelect from 'react-select/async';
import {isArray} from 'lodash';
import Check from 'components/common/check';
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#F3F3F3' : 'white',
    color: state.isSelected ? '#00598B' : '#636363',
    padding: '6px 12px',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '28px',
  }),
  control: () => ({
    display: 'flex',
    // none of react-select's styles are passed to <Control />
    width: '100%',
    background: '#F3F3F3',
    boxShadow: '0 0 0 2px var(--nextui-colors-border)',
    borderRadius: '8px',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: '6px 12px',

    // none of react-select's styles are passed to <Control />
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none',
  }),
  indicatorsContainer: (provided, state, isFocused) => ({
    ...provided,
    padding: '0px 12px',
    transform: isFocused ? '90deg' : '180deg',
    svg: {
      width: '24px',
      height: '24px',
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    padding: '8px 0',
    background: '#FFFFFF',
    boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.1)',
    borderRadius: '16px',
    zIndex: 1000,
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return {...provided, opacity, transition};
  },
  multiValue: (provided, state) => {
    return {...provided, borderRadius: '8px'};
  },
};

const CustomOptionComponent = ({innerProps, innerRef, children}) => (
  <div ref={innerRef} {...innerProps}>
    {children}
  </div>
);

function AsyncSelectComponent({isLoading, defaultOptions, loadOptions, selectedValue, ...rest}) {
  let value;
  if (isArray(selectedValue)) {
    value = selectedValue;
  } else {
    value = defaultOptions.find(item => item.value === selectedValue) || '';
  }
  return (
    <div className="async-select">
      <AsyncSelect
        width="200px"
        styles={customStyles}
        isLoading={isLoading}
        loadOptions={loadOptions}
        defaultOptions={defaultOptions}
        value={value}
        closeMenuOnSelect={!rest.isMulti}
        hideSelectedOptions={!rest.isMulti}
        components={
          rest.isMulti
            ? {
                Option: ({children, ...rest}) => (
                  <CustomOptionComponent {...rest}>
                    <div className="px-4 py-2 d-flex align-items-center">
                      <Check checked={rest.isSelected} name="check" />
                      {children}
                    </div>
                  </CustomOptionComponent>
                ),
              }
            : {}
        }
        {...rest}
      />
    </div>
  );
}

export default AsyncSelectComponent;
