import React from 'react';

import BreadcrumbTrail from 'components/common/breadcrumb-trail';
import SendMailForm from 'components/mail/send-mail/send-mail-form';
import EmailForm from 'components/mail/send-mail/email-form';
import {Formik} from 'formik';
import {sendMailInitialValues, sendMailValidationSchema} from 'constants/mail';
import {appendForMail} from 'constants/common';
import {sendMail} from 'containers/mail/api';

const SendMail = () => {
  const handleSendEmail = async (values, setErrors, setSubmitting) => {
    const data = {
      subject: values.subject,
      body: values.body,
      sender: `${values.sender + appendForMail}`,
      reply_to_email: `${values.replyToEmail + appendForMail}`,
      template_id: values.templateId,
      filters: {
        [values.emailSendBy]: true,
        ids:values.ids
      },
    };

    try {
      setSubmitting(true);
      const result = await sendMail(data, setErrors);
      if (result.meta.status === 200) {
        setSubmitting(false);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="backend-tables">
      <div className="container-fluid">
        <div className="heading-path-otr">
          <h3 className="heading heading-h3">Send Mail</h3>
          <BreadcrumbTrail />
        </div>

        <Formik
          initialValues={sendMailInitialValues}
          enableReinitialize
          validationSchema={sendMailValidationSchema}
          onSubmit={(values, {setSubmitting, setErrors}) => handleSendEmail(values, setErrors, setSubmitting)}
        >
          {({handleChange, values, handleSubmit, setFieldValue, isSubmitting}) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className="table-main facility-padding">
                  <h4>Send Mail</h4>
                  <SendMailForm values={values} handleChange={handleChange} setFieldValue={setFieldValue} />
                </div>

                <div className="mt-4">
                  <div className="table-main facility-padding">
                    <h4>Email</h4>
                    <EmailForm values={values} handleChange={handleChange} setFieldValue={setFieldValue} />

                    <button type="submit" className={`primary-btn-small record-btn mt-3 `} disabled={isSubmitting}>
                      {isSubmitting ? (
                        <>
                          <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                          <span className="visually-hidden">Sending...</span>
                        </>
                      ) : (
                        <span>Send</span>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default SendMail;
