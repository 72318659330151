import React from 'react';

import CustomModal from 'components/common/modal';
import Input from 'components/common/input';
import {Formik, ErrorMessage} from 'formik';
import {templateInitialValues, templateValidationSchema} from 'constants/mail';
import {isEmpty} from 'lodash';

const CreateTemplateModal = ({open, close, handleSubmit, selectedRow}) => {
  return (
    <CustomModal
      size="sm"
      show={open}
      onHide={() => close()}
      heading={`${isEmpty(selectedRow) ? 'Create' : 'Edit'} Template`}
    >
      <Formik
        initialValues={isEmpty(selectedRow) ? templateInitialValues : selectedRow}
        validationSchema={templateValidationSchema}
        onSubmit={(values, {setErrors, setSubmitting}) => {
          handleSubmit(values, setErrors, setSubmitting);
        }}
      >
        {({handleChange, values, handleSubmit, isSubmitting}) => {
          return (
            <div className="col-login-inr roles-modal">
              <form className="form-main" onSubmit={handleSubmit}>
                <div className="wrapper">
                  <Input
                    handleChange={handleChange}
                    placeholder="Enter Template Name"
                    type="text"
                    label="Name"
                    value={values.name}
                    name="name"
                  />
                  <ErrorMessage component="p" name="name" />
                </div>
                <div className="wrapper">
                  <Input
                    handleChange={handleChange}
                    placeholder="Enter Template Subject"
                    type="text"
                    label="Subject"
                    value={values.subject}
                    name="subject"
                  />
                  <ErrorMessage component="p" name="subject" />
                </div>
                <div className="wrapper">
                  <Input
                    handleChange={handleChange}
                    placeholder="Enter Template ID"
                    type="text"
                    label="Template ID"
                    value={values.template_id}
                    name="template_id"
                  />
                  <ErrorMessage component="p" name="template_id" />
                </div>
                <div className="action">
                  <button type="submit" className="primary-btn login-btn" disabled={isSubmitting}>
                    {isSubmitting ? (
                      <>
                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        <span className="visually-hidden">Loading...</span>
                      </>
                    ) : (
                      <span>{`${selectedRow === null ? 'Create' : 'Update'} Template`}</span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          );
        }}
      </Formik>
    </CustomModal>
  );
};

export default CreateTemplateModal;
