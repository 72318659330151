import React, {useEffect, useState} from 'react';

import TableWraper from 'components/common/table-wrapper';

import {ArrowsDownUp, Eye} from 'phosphor-react';
import {getSystemAuditList} from 'containers/system-audit/api';
import {DEBOUNCE_DELAY} from 'utils/common';
import ShowList from './ShowList';
import {initialMetaForTable, orderStratergy} from 'constants/common';
let timeout;

const RecordsListing = ({filters}) => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [systemAuditList, setsystemAuditList] = useState([]);
  const [isModalVisible, setModalVisibility] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState(initialMetaForTable);
  const [totalCount, setTotalCount] = useState(0);
  async function fetchData() {
    try {
      setLoading(true);
      const result = await getSystemAuditList({...meta, ...filters});
      if (result['paper_trail/versions']) {
        setsystemAuditList(result['paper_trail/versions']);
        setTotalCount(result?.meta.total_count);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage, filters]);

  const handleRefreshPage = () => {
    setRefreshPage(pre => !pre);
  };
  const handleSetPerPage = obj => {
    setMeta(pre => ({...pre, perPage: obj.value}));
    handleRefreshPage();
  };
  const debounceFn = (callback, delay) => {
    clearTimeout(timeout);
    timeout = setTimeout(callback, delay);
  };
  const handleSetSearchQuery = value => {
    setMeta(pre => ({...pre, search: value}));
    debounceFn(handleRefreshPage, DEBOUNCE_DELAY);
  };
  const handlePageChange = value => {
    console.log('HANDLE PAGE CHANGE', value);
    setMeta(pre => ({...pre, page: value}));
    handleRefreshPage();
  };
  const handleCloseModal = () => {
    setModalVisibility(false);
    setSelectedRow(null);
  };
  const handleOpenModal = () => {
    setModalVisibility(true);
  };
  const sortedDataByColumn = columnName => {
    if (systemAuditList.length > 0) {
      setMeta(pre => ({...pre, sort: columnName, order: orderStratergy[pre.order]}));
      handleRefreshPage();
    }
  };
  return (
    <>
      <TableWraper
        setPerPage={handleSetPerPage}
        setSearchQuery={handleSetSearchQuery}
        searchValue={meta.search}
        totalListCount={totalCount}
        pageSize={meta.perPage}
        currentPage={meta.page}
        onPageChange={handlePageChange}
      >
        {loading ? (
          <h3 style={{textAlign: 'center'}}>Loading</h3>
        ) : (
          <table className="table">
            <thead>
              <tr>
                <th scope="col">
                  <div className="header-text-otr">
                    <p
                      className="table-name heading-xsb cursor-pointer c-hand"
                      onClick={() => sortedDataByColumn('name')}
                    >
                      Name 
                    </p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p
                      className="table-name heading-xsb cursor-pointer c-hand"
                      onClick={() => sortedDataByColumn('email')}
                    >
                      Email 
                    </p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p
                      className="table-name heading-xsb cursor-pointer c-hand"
                      onClick={() => sortedDataByColumn('event')}
                    >
                      Event 
                    </p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Description</p>
                  </div>
                </th>
                <th scope="col" className="action-column">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Actions</p>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {systemAuditList.map(item => {
                return (
                  <tr key={item.id}>
                    <th scope="row" style={{width: '250px'}}>
                      <div className="table-text-otr ">
                        <span className="table-text">{item?.meta?.updated_by_jsonb?.name || ''}</span>
                      </div>
                    </th>
                    <td>
                      <div className="">
                        <p className="table-text">{item?.meta?.updated_by_jsonb?.email || ''}</p>
                      </div>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <p className="table-text">{item?.event || ''}</p>
                      </div>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <p className="table-text">{item?.attribute_changed_message || ''}</p>
                      </div>
                    </td>
                    <td className="action-column">
                      <div className="table-icon-otr d-flex justify-content-center">
                        <div className="icon-otr">
                          <Eye
                            size={24}
                            onClick={() => {
                              handleOpenModal();
                              setSelectedRow({...item});
                            }}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </TableWraper>
      {isModalVisible && (
        <ShowList selectedRow={selectedRow} handleCloseModal={handleCloseModal} isModalVisible={isModalVisible} />
      )}
    </>
  );
};

export default RecordsListing;
