import React from 'react';

import BreadcrumbTrail from 'components/common/breadcrumb-trail';
import EarningCodesListing from 'components/earning-codes/RecordListing';

const EarningCodes = () => {
  return (
    <>
      <div className="system-audit-main">
        <div className="container-fluid">
          <div className="heading-path-otr">
            <h3 className="heading heading-h3">Earning Codes</h3>
            <BreadcrumbTrail />
          </div>
          <EarningCodesListing />
        </div>
      </div>
    </>
  );
};

export default EarningCodes;
