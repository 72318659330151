import React from 'react';

import CustomModal from 'components/common/modal';
import Input from 'components/common/input';
import {Formik, ErrorMessage} from 'formik';
import {notesInitialValues as initialValues, notesValidationSchema as validationSchema} from 'constants/providers';

const NotesModal = ({show, close, isNotesDetailVisible = false, handleSubmit, selectedRow}) => {
  return (
    <CustomModal
      size="lg"
      show={show}
      onHide={close}
      heading={`${isNotesDetailVisible ? 'Note Detail' : selectedRow ? 'Edit Note' : 'Create Note'} `}
    >
      <Formik
        initialValues={selectedRow ? {notes: selectedRow.message} : initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, {setErrors}) => {
          handleSubmit(values, setErrors);
        }}
      >
        {({handleChange, values, handleSubmit}) => {
          return (
            <div className="col-login-inr roles-modal">
              <form className="form-main" onSubmit={handleSubmit}>
                <div className="wrapper">
                  <Input
                    handleChange={handleChange}
                    placeholder="Enter Notes"
                    type="textarea"
                    label="Notes"
                    value={values.notes}
                    name="notes"
                    rows="4"
                    disabled={isNotesDetailVisible}
                  />
                  <ErrorMessage component="p" name="notes" />
                </div>
                {!isNotesDetailVisible && (
                  <div className="action">
                    <input className="primary-btn login-btn" type="submit" name="submit" value={'Save'} />
                  </div>
                )}
              </form>
            </div>
          );
        }}
      </Formik>
    </CustomModal>
  );
};

export default NotesModal;
