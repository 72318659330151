import React, {useEffect, useState} from 'react';

import SelectComponent from 'components/common/select';

import {Row, Col} from 'react-bootstrap';
import {statusOptions} from 'constants/common';

const Filter = ({handleSubmit, filters}) => {
  const [list, setList] = useState([]);
  const [value, setValue] = useState(filters);
  useEffect(() => {
    const fetchUsers = async () => {
      setList(statusOptions);
    };
    fetchUsers();
  }, []);
  const handleChangeOnDropdown = item => {
    setValue(item.value);
  };

  return (
    <div className="looker-filter" style={{width: '100%'}}>
      <h5>Filters</h5>
      <Row>
        <Col md={12} className="mt-4">
          <p style={{margin: '10px 0px'}}>Status</p>
          <SelectComponent options={list} selectedValue={value} handleChange={handleChangeOnDropdown} />
        </Col>
      </Row>
      <div className="filter-search-otr">
        {/* <SearchInput /> */}
        <div
          onClick={() => {
            handleSubmit(value);
          }}
        >
          <button className="primary-btn-small record-btn">Apply</button>
        </div>
      </div>
    </div>
  );
};

export default Filter;
