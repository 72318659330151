import CustomToast from 'components/common/custom-toast';
import {alertTypes} from 'constants/common';
import {toast} from 'react-toastify';
import {get, put, post, deleteApi} from 'services/network';
import {NOTES_ENDPOINT} from 'constants/endpoints';

export const getNotes = async (notableType, noteableId, page, perPage, search) => {
  try {
    const result = await get(
      `${NOTES_ENDPOINT}?filter[noteable_type]=${notableType}&filter[noteable_id]=${noteableId}&per=${perPage}&page=${page}&query=${search}`,
      true,
    );
    return result;
  } catch (error) {
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={error?.response?.data?.message || error?.response?.statusText}
      />,
    );
    console.log('error');
  }
};

export const createNotes = async data => {
  try {
    const result = await post(NOTES_ENDPOINT, data, true);
    toast(<CustomToast variant={alertTypes.SUCCESS} message="Notes created successfully!" />);
    return result;
  } catch (error) {
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={error?.response?.data?.message || error?.response?.statusText}
      />,
    );
    console.log('error');
  }
};
export const updateNotes = async data => {
  const id = data.id;
  delete data.id;
  try {
    const result = await put(NOTES_ENDPOINT + `/${id}`, data, true);
    toast(<CustomToast variant={alertTypes.SUCCESS} message="Note updated successfully!" />);
    return result;
  } catch (error) {
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={error?.response?.data?.message || error?.response?.statusText}
      />,
    );
    console.log('error');
  }
};

export const deleteNotes = async id => {
  try {
    const response = await deleteApi(`${NOTES_ENDPOINT}/${id}/`, true);
    toast(<CustomToast variant={alertTypes.SUCCESS} message="Note deleted successfully!" />);
    return response;
  } catch (error) {
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={error?.response?.data?.message || error?.response?.statusText}
      />,
    );
    console.error(error);
  }
};
