import SelectComponent from 'components/common/select';
import {states} from 'constants/common';
import {ErrorMessage} from 'formik';
import {Col} from 'react-bootstrap';

const StateField = ({column = 4, name, selectedValue, setFieldValue, isDisabled = false, ...rest}) => {
  return (
    <Col md={column}>
      <div className=" input-otr" style={{alignItems: 'inherit'}}>
        <label htmlFor={name} className="input-label">
          State *
        </label>
        <SelectComponent
          name={name}
          options={states}
          isDisabled={isDisabled}
          placeholder="Select state"
          selectedValue={selectedValue}
          handleChange={obj => setFieldValue(name, obj.value)}
          {...rest}
        />
        <ErrorMessage className="error-text" component="p" name={name} />
      </div>
    </Col>
  );
};

export default StateField;
