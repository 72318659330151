/* eslint-disable prefer-destructuring */
import axios from 'axios';
import {LOGIN_PATH} from 'constants/routePaths';
import {getAccessToken, removeAccessToken, removeUserInfoFromStorage} from 'utils/common';

const hookRequestInterceptorsWithAxiosInstance = instance =>
  instance.interceptors.request.use(config => {
    return config;
  });

const hookResponseInterceptorsWithAxiosInstance = instance =>
  instance.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      const {status} = error.response;
      if (status === 401) {
        removeAccessToken();
        removeUserInfoFromStorage();
        window.location.href = LOGIN_PATH;
      }
      return Promise.reject(error);
    },
  );

function getAxios(tokenizeInstance, accessToken = null) {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  hookRequestInterceptorsWithAxiosInstance(instance);
  hookResponseInterceptorsWithAxiosInstance(instance);

  if (tokenizeInstance) {
    const bearer = accessToken || getAccessToken();

    if (bearer) instance.defaults.headers.common.Authorization = `Bearer ${bearer}`;
  }

  return instance;
}

export const get = (url, tokenizeInstance = false, accessToken = null) =>
  getAxios(tokenizeInstance, accessToken)
    .get(url)
    .then(res => {
      return res.data;
    });

export const getBlob = (url, tokenizeInstance = false, accessToken = null) =>
  getAxios(tokenizeInstance, accessToken)
    .get(url, {
      responseType: 'blob', // Set responseType to blob for binary data
    })
    .then(res => {
      return res.data;
    });

export const post = (url, data, tokenizeInstance = false) =>
  getAxios(tokenizeInstance)
    .post(url, data)
    .then(res => {
      return res.data;
    });

export const deleteApi = (url, tokenizeInstance = false, accessToken = null) =>
  getAxios(tokenizeInstance, accessToken)
    .delete(url)
    .then(res => {
      return res.data;
    });

export const put = (url, data, tokenizeInstance = false) =>
  getAxios(tokenizeInstance)
    .put(url, data)
    .then(res => {
      return res.data;
    });
