import React from 'react';
import CustomModal from 'components/common/modal';

const ConfirmationModal = ({ open, userName, onClose, onConfirm }) => {
  return (
    <CustomModal size="sm" show={open} onHide={onClose} heading="Confirm Deactivation">
      <div className="col-login-inr confirm-modal">
        <div className="modal-body">
          <p>Are you sure you want to deactivate {userName}?</p>
        </div>
        <div className="modal-footer">
          <button className="primary-btn" onClick={onConfirm}>Yes</button>
          <button className="secondary-btn" onClick={onClose}>No</button>
        </div>
      </div>
    </CustomModal>
  );
};

export default ConfirmationModal;
