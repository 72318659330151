import React, {useState, useEffect} from 'react';
import 'components/common/table-wrapper/table.scss';
import 'components/facilities/facilities.scss';
import AsyncSelectComponent from 'components/common/async-select';

const AssignedProviders = ({
  actionButtons,
  children,
  providersList,
  setDropDownId,
  dropDownId,
  isDisabled,
  isLoading,
  loadDropdownOptions,
}) => {
  const [selected, setSelected] = useState(null);
  useEffect(() => {
    if (dropDownId) {
      setSelected(providersList.find(item => item.value === dropDownId));
    } else {
      setSelected(null);
    }
  }, [dropDownId, providersList]);

  return (
    <>
      <div className="card-wrapper">
        <div className="table-head-content">
          <div className="search-otr">
            <AsyncSelectComponent
              isLoading={isLoading}
              selectedValue={selected?.value}
              defaultOptions={providersList}
              loadOptions={loadDropdownOptions}
              onChange={obj => setDropDownId(obj.value)}
              placeholder="Search and select"
            />

            {actionButtons?.length > 0 &&
              actionButtons.map((item, index) => {
                return (
                  <button
                    key={item.label + index}
                    index={index}
                    className={isDisabled ? `btn btn-disable btn-border` : item.classes}
                    onClick={() => {
                      item.handleClick();
                      setSelected([]);
                    }}
                    disabled={isDisabled}
                  >
                    {item.label}
                  </button>
                );
              })}
          </div>
        </div>
        <div className="table-otr">{children}</div>
      </div>
    </>
  );
};

export default AssignedProviders;
