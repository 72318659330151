import React, {useState} from 'react';
import 'components/facilities/facilities.scss';

import FilterWrapper from 'components/common/filter-wrapper';
import FacilityFilter from 'components/facilities/filter';
import BreadcrumbTrail from 'components/common/breadcrumb-trail';
import FacilitiesListing from 'components/facilities/facilities-listing';
import {filterOptions} from 'constants/common';

const Facilities = () => {
  const [filters, setFilters] = useState({
    status: filterOptions[0].value,
    entity_id: '',
  });
  const [loading, setLoading] = useState(false);
  const onFilterClick = filter => {
    setFilters(filter);
    setLoading(true);
  };

  return (
    <>
      <div className="providers-main">
        <div className="container-fluid">
          <div className="heading-path-otr">
            <h3 className="heading heading-h3">Facilities</h3>
            <BreadcrumbTrail />
          </div>
          {/* <TableComponent /> */}
          <FilterWrapper>
            <FacilityFilter filters={filters} handleSubmit={onFilterClick} />
          </FilterWrapper>
          <FacilitiesListing filters={filters} refresh={loading} handleRefresh={setLoading} />
        </div>
      </div>
    </>
  );
};

export default Facilities;
