import React from 'react';
import Upload from 'rc-upload';
import 'components/common/file-upload/file.scss';
import {UploadSimple} from 'phosphor-react';

const FileUpload = props => {
  return (
    <div
      style={{
        margin: '30px 0px',
        width: '100%',
      }}
    >
      <div>
        <Upload {...props} className="custom-upload-lg">
          <UploadSimple size={20} />
          <span className="ms-2">Drag and Drop or Browse to Upload</span>
        </Upload>
      </div>
    </div>
  );
};

export default FileUpload;
