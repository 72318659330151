import React, {useEffect, useState} from 'react';

import TableWraper from 'components/common/table-wrapper';

import {Minus, Plus} from 'phosphor-react';
import {DEBOUNCE_DELAY} from 'utils/common';
import {initialMetaForTable} from 'constants/common';
import _ from 'lodash';

let timeout;

const RecordsListing = ({data}) => {
  const [recordsListing, setRecordsLiting] = useState(
    data?.map(item => ({...item, isNested: false, recordId: item.name + Math.random()})),
  );
  const [refreshPage, setRefreshPage] = useState(false);
  const [totalCount, setTotalCount] = useState(data.length);
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState(initialMetaForTable);

  const handleRefreshPage = () => {
    setRefreshPage(pre => !pre);
  };
  const handleSetPerPage = obj => {
    setMeta(pre => ({...pre, perPage: obj.value}));
    handleRefreshPage();
  };
  const debounceFn = (callback, delay) => {
    clearTimeout(timeout);
    timeout = setTimeout(callback, delay);
  };
  const handleSetSearchQuery = value => {
    setMeta(pre => ({...pre, search: value}));
    debounceFn(handleRefreshPage, DEBOUNCE_DELAY);
  };
  const handlePageChange = value => {
    setMeta(pre => ({...pre, page: value}));
    handleRefreshPage();
  };
  return (
    <>
      <TableWraper
        setPerPage={handleSetPerPage}
        setSearchQuery={handleSetSearchQuery}
        searchValue={meta.search}
        totalListCount={totalCount}
        pageSize={meta.perPage}
        currentPage={meta.page}
        onPageChange={handlePageChange}
      >
        {loading ? (
          <p style={{textAlign: 'center'}}>Loading</p>
        ) : (
          <table className="table main-nested">
            <thead>
              <tr>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Clinician Name</p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">NPI Code</p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">From Date</p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">To Date</p>
                  </div>
                </th>

                <th scope="col" className="action-column">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Actions</p>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {recordsListing.map(item => {
                return (
                  <>
                    <tr key={item.id} className={`${item.isNested ? 'nested-table table-active' : 'table-active'}`}>
                      <th scope="row">
                        <div className="table-text-otr ">
                          <span className="table-text">{item?.name || ''}</span>
                        </div>
                      </th>
                      <td>
                        <div className="table-text-otr">
                          <p className="table-text">{item?.npi_code || ''}</p>
                        </div>
                      </td>
                      <td>
                        <div className="table-text-otr">
                          <p className="table-text">{item?.start_date || ''}</p>
                        </div>
                      </td>
                      <td>
                        <div className="table-text-otr">
                          <p className="table-text">{item?.end_date || ''}</p>
                        </div>
                      </td>

                      <td className="action-column">
                        <div className="table-icon-otr">
                          <div className="icon-otr">
                            {item.isNested ? (
                              <Minus
                                size={24}
                                onClick={() => {
                                  setRecordsLiting(pre =>
                                    pre.map(elem => {
                                      if (elem.recordId === item.recordId) {
                                        return {...elem, isNested: false};
                                      }
                                      return elem;
                                    }),
                                  );
                                }}
                              />
                            ) : (
                              <Plus
                                size={24}
                                onClick={() => {
                                  setRecordsLiting(pre =>
                                    pre.map(elem => {
                                      if (elem.recordId === item.recordId) {
                                        return {...elem, isNested: true};
                                      }
                                      return elem;
                                    }),
                                  );
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                    {item.isNested && item?.service_data?.length > 0 && (
                      <tr className="nested-parent-tr">
                        <td colSpan={6} className="nested-parent-td">
                          <div className="nested-table-wrapper app-scroller ">
                            <table
                              className={`table nested-table ${
                                Object.keys(item.service_data[0]).length > 6 ? 'scroll-table' : ''
                              }`}
                            >
                              <thead>
                                <tr>
                                  {Object.keys(item.service_data[0])
                                    ?.reverse()
                                    .map(key => {
                                      return (
                                        <th scope="col">
                                          <div className="header-text-otr">
                                            <p className="table-name heading-xsb">
                                              {key.replace('_', ' ').toUpperCase()}
                                            </p>
                                          </div>
                                        </th>
                                      );
                                    })}
                                </tr>
                              </thead>
                              <tbody>
                                {item?.service_data?.map(elem => {
                                  return (
                                    <>
                                      <tr key={item.id} className={`${item.isNested ? 'nested-table' : ''}`}>
                                        {Object.keys(elem)
                                          ?.reverse()
                                          .map((elemKey, index) => {
                                            return index === 0 ? (
                                              <th scope="row">
                                                <div className="table-text-otr ">
                                                  <span className="table-text">{elem[elemKey] || ''}</span>
                                                </div>
                                              </th>
                                            ) : (
                                              <td>
                                                <div className="table-text-otr">
                                                  <p className="table-text">{elem[elemKey] || ''}</p>
                                                </div>
                                              </td>
                                            );
                                          })}
                                      </tr>
                                    </>
                                  );
                                })}
                              </tbody>
                              <tfoot>
                                <tr className="nested-table-footer">
                                  <th colSpan={2}>
                                    <div className="table-text-otr ">
                                      <span className="table-name heading-xsb">{'Total' || ''}</span>
                                    </div>
                                  </th>
                                  {Object.keys(item.service_data_total)
                                    ?.reverse()
                                    .map((elemKey, index) => {
                                      return (
                                        <td key={index}>
                                          <div className="table-text-otr">
                                            <p className="table-name heading-xsb">
                                              {item.service_data_total[elemKey] || ''}
                                            </p>
                                          </div>
                                        </td>
                                      );
                                    })}
                                </tr>
                                <tr className="nested-table-footer">
                                  <th colSpan={2}>
                                    <div className="table-text-otr ">
                                      <span className="table-name heading-xsb">{'Total Charges' || ''}</span>
                                    </div>
                                  </th>
                                  {Object.keys(item.service_data_total_amount)
                                    ?.reverse()
                                    .map((elemKey, index) => {
                                      return (
                                        <td key={index}>
                                          <div className="table-text-otr">
                                            <p className="table-name heading-xsb">
                                              {item.service_data_total_amount[elemKey] || ''}
                                            </p>
                                          </div>
                                        </td>
                                      );
                                    })}
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </td>
                      </tr>
                    )}

                    {/* second nested table  */}

                    {item.isNested && item?.service_data_by_facility_entity?.length > 0 && (
                      <tr className="nested-parent-tr">
                        <td colSpan={5}>
                          <table className="table nested-table">
                            <thead>
                              <tr>
                                <th scope="col">
                                  <div className="header-text-otr">
                                    <p className="table-name heading-xsb">Entity</p>
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className="header-text-otr">
                                    <p className="table-name heading-xsb">Facility</p>
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className="header-text-otr">
                                    <p className="table-name heading-xsb">Location</p>
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className="header-text-otr">
                                    <p className="table-name heading-xsb">Total</p>
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className="header-text-otr">
                                    <p className="table-name heading-xsb">Units</p>
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className="header-text-otr">
                                    <p className="table-name heading-xsb">Total Amount</p>
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {item?.service_data_by_facility_entity?.map(elem => {
                                return (
                                  <>
                                    <tr key={item.id} className={`${item.isNested ? 'nested-table' : ''}`}>
                                      <th scope="row">
                                        <div className="table-text-otr ">
                                          <span className="table-text">{elem?.entity_name || ''}</span>
                                        </div>
                                      </th>

                                      <td>
                                        <div className="table-text-otr">
                                          <p className="table-text">{elem?.facility_name || ''}</p>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="table-text-otr">
                                          <p className="table-text">{elem?.location || ''}</p>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="table-text-otr">
                                          <p className="table-text">{elem?.total_hours || ''}</p>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="table-text-otr">
                                          <p className="table-text">{elem?.total_serviced_units || ''}</p>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="table-text-otr">
                                          <p className="table-text">{elem?.total_amount || ''}</p>
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                );
                              })}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    )}

                    {/* third nested table  */}

                    {item.isNested && item?.service_data_adjustments?.length > 0 && (
                      <>
                        <tr className="nested-parent-tr">
                          <td colSpan={12}>
                            <p className="table-name heading-xsb adjustment-table-header">Adjustment</p>
                          </td>
                        </tr>
                        <tr className="nested-parent-tr" style={{borderRadius: '0px'}}>
                          <td colSpan={5}>
                            <table className="table nested-table adjustment-table-body">
                              <thead>
                                <tr>
                                  <th scope="col">
                                    <div className="header-text-otr">
                                      <p className="table-name heading-xsb">Effective Date</p>
                                    </div>
                                  </th>
                                  <th scope="col">
                                    <div className="header-text-otr">
                                      <p className="table-name heading-xsb">Service Date</p>
                                    </div>
                                  </th>
                                  <th scope="col">
                                    <div className="header-text-otr">
                                      <p className="table-name heading-xsb">Facility</p>
                                    </div>
                                  </th>
                                  <th scope="col">
                                    <div className="header-text-otr">
                                      <p className="table-name heading-xsb">Code</p>
                                    </div>
                                  </th>
                                  <th scope="col">
                                    <div className="header-text-otr">
                                      <p className="table-name heading-xsb">Units</p>
                                    </div>
                                  </th>
                                  <th scope="col">
                                    <div className="header-text-otr">
                                      <p className="table-name heading-xsb">Charge Amount</p>
                                    </div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {item?.service_data_adjustments?.map(elem => {
                                  return (
                                    <>
                                      <tr key={item.id} className={`${item.isNested ? 'nested-table' : ''}`}>
                                        <th scope="row">
                                          <div className="table-text-otr ">
                                            <span className="table-text">{elem?.effective_date || ''}</span>
                                          </div>
                                        </th>

                                        <td>
                                          <div className="table-text-otr">
                                            <p className="table-text">{elem?.service_date || ''}</p>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="table-text-otr">
                                            <p className="table-text">{elem?.facility_name || ''}</p>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="table-text-otr">
                                            <p className="table-text">{elem?.earning_code || ''}</p>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="table-text-otr">
                                            <p className="table-text">{elem?.service_units || ''}</p>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="table-text-otr">
                                            <p className="table-text">{elem?.charge_amount || ''}</p>
                                          </div>
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })}
                                <tr className="nested-table-footer">
                                  <th colSpan={5}>
                                    {' '}
                                    <div className="table-text-otr ">
                                      <span className="table-name heading-xsb">{'Total Adj.' || ''}</span>
                                    </div>
                                  </th>
                                  <td>
                                    <div className="table-text-otr">
                                      <p className="table-name heading-xsb">
                                        {'$ ' +
                                          item?.service_data_adjustments.reduce(
                                            (pre, current) =>
                                              Number(pre) + Number(current['charge_amount'].replace('$', '')),
                                            0,
                                          ) || ''}
                                      </p>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </>
                    )}

                    {item.isNested &&
                      _.isObject(item?.salary_contract) &&
                      Object.keys(item?.salary_contract)?.length > 0 && (
                        <tr className="nested-parent-tr">
                          <td colSpan={5}>
                            <table className="table nested-table">
                              <thead>
                                <tr>
                                  <th scope="col">
                                    <div className="header-text-otr">
                                      <p className="table-name heading-xsb">Salary contract</p>
                                    </div>
                                  </th>
                                  <th scope="col">
                                    <div className="header-text-otr">
                                      <p className="table-name heading-xsb"></p>
                                    </div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {Object.keys(item?.salary_contract).map(elem => {
                                  return (
                                    <>
                                      <tr key={item.id} className={`${item.isNested ? '' : ''}`}>
                                        <th scope="row" style={{padding: '10px'}}>
                                          <div className="table-text-otr ">
                                            <span className="table-text">{elem || ''}</span>
                                          </div>
                                        </th>
                                        <td>
                                          <div>
                                            <p className="table-text">{item?.salary_contract[elem] || '-'}</p>
                                          </div>
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })}
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      )}
                    {/* fourth table  */}

                    {item.isNested && item?.service_data_by_earning_code?.length > 0 && (
                      <tr className="nested-parent-tr">
                        <td colSpan={5}>
                          <table className="table nested-table">
                            <thead>
                              <tr>
                                <th scope="col">
                                  <div className="header-text-otr">
                                    <p className="table-name heading-xsb">Procedure Code</p>
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className="header-text-otr">
                                    <p className="table-name heading-xsb">Procedure Name</p>
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className="header-text-otr">
                                    <p className="table-name heading-xsb">Charge Unit Code</p>
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className="header-text-otr">
                                    <p className="table-name heading-xsb">Unit Amount</p>
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {item?.service_data_by_earning_code?.map(elem => {
                                return (
                                  <>
                                    <tr key={item.id} className={`${item.isNested ? 'nested-table' : ''}`}>
                                      <th scope="row">
                                        <div className="table-text-otr ">
                                          <span className="table-text">{elem?.earning_code || ''}</span>
                                        </div>
                                      </th>

                                      <td>
                                        <div className="table-text-otr">
                                          <p className="table-text">{elem?.earning_code_name || ''}</p>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="table-text-otr">
                                          <p className="table-text">{elem?.earning_code || ''}</p>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="table-text-otr">
                                          <p className="table-text">{elem?.initial_unit_charge_amount || ''}</p>
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                );
                              })}
                              <tr className="nested-table-footer">
                                <th colSpan={3}>
                                  {' '}
                                  <div className="table-text-otr ">
                                    <span className="table-name heading-xsb">{'Gross Total' || ''}</span>
                                  </div>
                                </th>
                                <td>
                                  <div className="table-text-otr">
                                    <p className="table-name heading-xsb">{item?.gross_total || ''}</p>
                                  </div>
                                </td>
                              </tr>
                              <tr className="nested-table-footer">
                                <th colSpan={3}>
                                  {' '}
                                  <div className="table-text-otr ">
                                    <span className="table-name heading-xsb">{'Rollover Total' || ''}</span>
                                  </div>
                                </th>
                                <td>
                                  <div className="table-text-otr">
                                    <p className="table-name heading-xsb">{item?.rollover_total || ''}</p>
                                  </div>
                                </td>
                              </tr>
                              <tr className="nested-table-footer">
                                <th colSpan={3}>
                                  {' '}
                                  <div className="table-text-otr ">
                                    <span className="table-name heading-xsb">{'Net Total' || ''}</span>
                                  </div>
                                </th>
                                <td>
                                  <div className="table-text-otr">
                                    <p className="table-name heading-xsb">{item?.net_total || ''}</p>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    )}
                  </>
                );
              })}
            </tbody>
          </table>
        )}
      </TableWraper>
    </>
  );
};

export default RecordsListing;
