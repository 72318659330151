import React from 'react';
import './search-input.scss';
import {MagnifyingGlass} from 'phosphor-react';

function SearchInput({handleChange, value}) {
  return (
    <div className="search-input-otr">
      <MagnifyingGlass size={20} />
      <input
        className="theme-input2 search-input2"
        type="text"
        name="search"
        value={value}
        placeholder="Search"
        onChange={e => {
          e.preventDefault();
          const value = e.target.value || '';
          handleChange(value);
        }}
      />
    </div>
  );
}

export default SearchInput;
