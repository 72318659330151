import React, {useEffect, useState} from 'react';

import TableWraper from 'components/common/table-wrapper';
import ShowList from './Show';

import {ArrowsDownUp, Eye} from 'phosphor-react';
import {DEBOUNCE_DELAY} from 'utils/common';
import {initialMetaForTable, orderStratergy} from 'constants/common';
import {getProvidersList} from 'containers/providers/api';
import {isEmpty} from 'lodash';

let timeout;

const RecordsListing = ({refreshByParent, filters}) => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [providerListing, setProviderListing] = useState([]);
  const [isModalVisible, setModalVisibility] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState(initialMetaForTable);
  const [totalCount, setTotalCount] = useState(0);

  async function fetchData() {
    try {
      setLoading(true);
      const result = await getProvidersList({...meta, ...filters});
      if (result['providers']) {
        setProviderListing(result['providers']);
        setTotalCount(result?.meta.total_count);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage, refreshByParent]);

  const handleRefreshPage = () => {
    setRefreshPage(pre => !pre);
  };
  const handleSetPerPage = obj => {
    setMeta(pre => ({...pre, perPage: obj.value}));
    handleRefreshPage();
  };
  const debounceFn = (callback, delay) => {
    clearTimeout(timeout);
    timeout = setTimeout(callback, delay);
  };
  const handleSetSearchQuery = value => {
    setMeta(pre => ({...pre, search: value}));
    debounceFn(handleRefreshPage, DEBOUNCE_DELAY);
  };
  const handlePageChange = value => {
    setMeta(pre => ({...pre, page: value}));
    handleRefreshPage();
  };
  const handleCloseModal = () => {
    setModalVisibility(false);
    setSelectedRow(null);
  };
  const handleOpenModal = () => {
    setModalVisibility(true);
  };

  const getRegionalDirectorsText = regionalDirectors => {
    let text = '';
    if (!isEmpty(regionalDirectors)) {
      regionalDirectors.forEach((rd, i) => {
        text += rd.user.first_name + rd.user.last_name;
        if (i !== regionalDirectors.length - 1) {
          text += ', ';
        }
      });
    }

    return text;
  };

  const sortedDataByColumn = columnName => {
    if (providerListing.length > 0) {
      setMeta(pre => ({...pre, sort: columnName, order: orderStratergy[pre.order]}));
      handleRefreshPage();
    }
  };

  return (
    <>
      <TableWraper
        setPerPage={handleSetPerPage}
        setSearchQuery={handleSetSearchQuery}
        searchValue={meta.search}
        totalListCount={totalCount}
        pageSize={meta.perPage}
        currentPage={meta.page}
        onPageChange={handlePageChange}
      >
        {loading ? (
          <h3 style={{textAlign: 'center'}}>Loading</h3>
        ) : (
          <table className="table">
            <thead>
              <tr>
                <th scope="col">
                  <div className="header-text-otr">
                    <p
                      className="table-name heading-xsb cursor-pointer c-hand"
                      onClick={() => sortedDataByColumn('npi_code')}
                    >
                      Npi 
                    </p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p
                      className="table-name heading-xsb cursor-pointer c-hand"
                      onClick={() => sortedDataByColumn('name')}
                    >
                      Provider 
                    </p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p
                      className="table-name heading-xsb cursor-pointer c-hand"
                      onClick={() => sortedDataByColumn('category_name')}
                    >
                      Category 
                    </p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p
                      className="table-name heading-xsb cursor-pointer c-hand"
                      onClick={() => sortedDataByColumn('office_location')}
                    >
                      Back Office 
                    </p>
                  </div>
                </th>

                <th scope="col" className="action-column">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Actions</p>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {providerListing?.map(item => {
                return (
                  <tr key={item.id}>
                    <th scope="row">
                      <div className="table-text-otr ">
                        <span className="table-text">{item?.npi_code || ''}</span>
                      </div>
                    </th>
                    <td>
                      <div className="table-text-otr">
                        <p className="table-text-black">
                          {item?.first_name ? `${item.first_name} ${item.last_name}` : ''}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <p className="table-text">{item?.category_name || ''}</p>
                      </div>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <p className="table-text">{item?.office_location || ''}</p>
                      </div>
                    </td>
                    <td className="action-column">
                      <div className="table-icon-otr">
                        <div className="icon-otr">
                          <Eye
                            size={24}
                            onClick={() => {
                              handleOpenModal();
                              setSelectedRow({...item});
                            }}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </TableWraper>
      {isModalVisible && (
        <ShowList selectedRow={selectedRow} handleCloseModal={handleCloseModal} isModalVisible={isModalVisible} />
      )}
    </>
  );
};

export default RecordsListing;
