import React, {useEffect, useState} from 'react';

import TableWraper from 'components/common/table-wrapper';
import {Pencil, Trash} from 'phosphor-react';
import {getProviderAdjustmentHistory, getProviderData} from 'containers/providers/api';
import {DEBOUNCE_DELAY} from 'utils/common';
import {initialMetaForTable, paidTypeStatus} from 'constants/common';
import {useParams} from 'react-router-dom';
import FilterWrapper from 'components/common/filter-wrapper';
import AdjustmentHistoryFilter from '../AdjustmentHistoryFilter';
import EditAdjustmentModal from '../forms/EditAdjustmentModal';
import {updateAdjustment} from 'containers/provider-adjustments/api';

import {startCase} from 'lodash';

import moment from 'moment';

let timeout;

const AdjustmentHistory = () => {
  const {id} = useParams();
  const [selectedRow, setSelectedRow] = useState(null);
  const [adjustmentsList, setAdjustmentsList] = useState([]);
  const [allListSelected, setAllListSelected] = useState(false);
  const [isModalVisible, setModalVisibility] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState(initialMetaForTable);
  const [totalCount, setTotalCount] = useState(0);
  const [date, setDate] = useState({
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').format('YYYY-MM-DD'),
  });
  const [status, setStatus] = useState('');
  const [earningCode, setEarningCode] = useState('');

  async function fetchData() {
    try {
      setLoading(true);
      const result = await getProviderAdjustmentHistory({
        id,
        start_date: date.startDate,
        end_date: date.endDate,
        paid_type: status,
        earning_code_id: earningCode,
        ...meta,
      });

      if (result?.provider_data?.length) {
        setAdjustmentsList(result?.provider_data?.map(item => ({...item, isSelected: allListSelected})));
        setTotalCount(result?.meta.total_count);
      } else {
        setAdjustmentsList([]);
        setTotalCount(result?.meta.total_count);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage]);

  const handleRefreshPage = () => {
    setRefreshPage(pre => !pre);
  };
  const handleSetPerPage = obj => {
    setMeta(pre => ({...pre, perPage: obj.value}));
    handleRefreshPage();
  };
  const debounceFn = (callback, delay) => {
    clearTimeout(timeout);
    timeout = setTimeout(callback, delay);
  };
  const handleSetSearchQuery = value => {
    setMeta(pre => ({...pre, search: value}));
    debounceFn(handleRefreshPage, DEBOUNCE_DELAY);
  };
  const handlePageChange = value => {
    setMeta(pre => ({...pre, page: value}));
    handleRefreshPage();
  };
  const handleCloseModal = () => {
    setModalVisibility(false);
    setSelectedRow(null);
  };
  const handleOpenModal = () => {
    setModalVisibility(true);
  };

  const setAllListCheck = value => {
    setAdjustmentsList(pre => pre.map(item => ({...item, isSelected: value})));
  };
  const setSingleItemCheck = (value, id) => {
    setAdjustmentsList(pre => pre.map(item => (item.id === id ? {...item, isSelected: value} : item)));
  };
  const handleSubmit = async values => {
    const {id, ...rest} = values;
    const payload = {
      provider_datum: {
        ...rest,
      },
      id,
    };
    const result = await updateAdjustment(payload);
    if (result?.meta?.status === 200) {
      handleCloseModal();
      handleRefreshPage();
    }
  };
  const handleDelete = () => {};
  const changeFilter = (startDate, endDate, filterStatus = '', earningCodeId = '') => {
    if (startDate !== null || endDate !== null)
      setDate({
        startDate: startDate === null ? date.startDate : moment(startDate).format('YYYY-MM-DD'),
        endDate: endDate === null ? date.endDate : moment(endDate).format('YYYY-MM-DD'),
      });
    setStatus(filterStatus);
    setEarningCode(earningCodeId);
  };

  return (
    <>
      <div className="providers-main">
        {/* <TableComponent /> */}
        <FilterWrapper>
          <AdjustmentHistoryFilter
            handleSubmit={handleRefreshPage}
            changeFilter={changeFilter}
            startDate={date.startDate}
            endDate={date.endDate}
          />
        </FilterWrapper>

        <TableWraper
          setPerPage={handleSetPerPage}
          setSearchQuery={handleSetSearchQuery}
          searchValue={meta.search}
          totalListCount={totalCount}
          pageSize={meta.perPage}
          currentPage={meta.page}
          onPageChange={handlePageChange}
        >
          {loading ? (
            <h3 style={{textAlign: 'center'}}>Loading</h3>
          ) : (
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">
                    <div className="header-text-otr">
                      <p className="table-name heading-xsb">
                        <input
                          className="form-check-input"
                          onChange={e => {
                            setAllListSelected(e.target.checked);
                            setAllListCheck(e.target.checked);
                          }}
                          type="checkbox"
                          checked={allListSelected}
                        />
                      </p>
                    </div>
                  </th>
                  <th scope="col" style={{width: '105px'}}>
                    <div className="header-text-otr">
                      <p className="table-name heading-xsb">Service Date</p>
                    </div>
                  </th>
                  <th scope="col">
                    <div className="header-text-otr">
                      <p className="table-name heading-xsb">Effective Date</p>
                    </div>
                  </th>
                  <th scope="col">
                    <div className="header-text-otr">
                      <p className="table-name heading-xsb">Note ID</p>
                    </div>
                  </th>
                  <th scope="col">
                    <div className="header-text-otr">
                      <p className="table-name heading-xsb">Earning Code</p>
                    </div>
                  </th>

                  <th scope="col">
                    <div className="header-text-otr">
                      <p className="table-name heading-xsb">Facility</p>
                    </div>
                  </th>
                  <th scope="col">
                    <div className="header-text-otr">
                      <p className="table-name heading-xsb">Paid Date</p>
                    </div>
                  </th>
                  <th scope="col">
                    <div className="header-text-otr">
                      <p className="table-name heading-xsb">Paid Status</p>
                    </div>
                  </th>
                  {/* <th scope="col">
                    <div className="header-text-otr">
                      <p className="table-name heading-xsb">Amount</p>
                    </div>
                  </th>
                  <th scope="col">
                    <div className="header-text-otr">
                      <p className="table-name heading-xsb">Paid</p>
                    </div>
                  </th> */}

                  <th scope="col" className="action-column">
                    <div className="header-text-otr">
                      <p className="table-name heading-xsb">Actions</p>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {adjustmentsList?.map(item => {
                  return (
                    <tr key={item.id}>
                      <th scope="col">
                        <div className="header-text-otr">
                          <p className="table-name heading-xsb">
                            <input
                              className="form-check-input"
                              value={item.isSelected}
                              onChange={e => {
                                setSingleItemCheck(e.target.checked, item.id);
                              }}
                              type="checkbox"
                              checked={item.isSelected}
                            />
                          </p>
                        </div>
                      </th>

                      <td>
                        <div className="table-text-otr">
                          <p className="table-text-black">{item?.service_date || ''}</p>
                        </div>
                      </td>
                      <td>
                        <div className="table-text-otr ">
                          <span className="table-text">{item?.effective_date || ''}</span>
                        </div>
                      </td>
                      <td>
                        <div className="table-text-otr">
                          <p className="table-text">{item?.note_id || ''}</p>
                        </div>
                      </td>
                      <td>
                        <div className="table-text-otr">
                          <p className="table-text-black">{item?.earning_code?.code || ''}</p>
                        </div>
                      </td>

                      <td>
                        <div className="table-text-otr">
                          <p className="table-text-black">{item?.facility?.name || ''}</p>
                        </div>
                      </td>
                      <td>
                        <div className="table-text-otr">
                          <p className="table-text-black">{item?.paid_date || ''}</p>
                        </div>
                      </td>
                      <td>
                        <div className="table-text-otr">
                          <p className={`table-text heading-xsb ${paidTypeStatus[item?.paid_type]}`}>
                            {startCase(item.paid_type)}
                          </p>
                        </div>
                      </td>
                      {/* <td>
                        <div className="table-text-otr">
                          <p className="table-text">{item?.reg_amount || ''}</p>
                        </div>
                      </td>
                     
                      <td>
                        <div className="table-text-otr">
                          <p className={`table-text heading-xsb ${textStatus[item?.status]}`}>
                            {startCase(item.status)}
                          </p>
                        </div>
                      </td>
                       */}
                      <td className="action-column">
                        <div className="table-icon-otr">
                          <div className="icon-otr">
                            <Pencil
                              size={24}
                              onClick={() => {
                                handleOpenModal();
                                setSelectedRow({...item});
                              }}
                            />
                          </div>
                          {/* <div className="icon-otr">
                            <Trash
                              size={24}
                              onClick={() => {
                                handleDelete();
                              }}
                            />
                          </div> */}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </TableWraper>
        {isModalVisible && (
          <EditAdjustmentModal
            selectedRow={{
              ...selectedRow,
              provider_id: id,
              facility_id: selectedRow?.facility?.id || '',
              earning_code_id: selectedRow?.earning_code?.id || '',
              serviced_unit: selectedRow?.serviced_unit || '',
              service_date: selectedRow?.service_date || '',
              effective_date: selectedRow?.effective_date || '',
              unit_in_minutes: selectedRow?.unit_in_minutes || '',
              note: selectedRow?.note || {message: ''},
            }}
            handleCloseModal={handleCloseModal}
            handleSubmit={handleSubmit}
          />
        )}
      </div>
    </>
  );
};

export default AdjustmentHistory;
