import React, {useEffect, useState} from 'react';

import FilterWrapper from 'components/common/filter-wrapper';
import ProviderRatesFilter from 'components/provider-rates/filter';
import BreadcrumbTrail from 'components/common/breadcrumb-trail';
import 'components/provider-rates/provider-rates.scss';
import ProviderRateHistoryListing from 'components/provider-rates/provider-rate-history-listing';
import {Col, Row} from 'react-bootstrap';
import {getProviderHistory} from './api';
import {useParams} from 'react-router-dom';

const ProviderRateHistory = () => {
  const {id} = useParams();
  const [refresh, setRefresh] = useState(false);
  const [providerHistory, setProviderHistory] = useState('');
  const handleRefresh = () => {
    setRefresh(true);
  };
  const fetchProviderHistory = async () => {
    const result = await getProviderHistory(id);
    if (result) {
      setProviderHistory(result);
    }
    console.log('get result ', result);
  };
  useEffect(() => {
    fetchProviderHistory();
  }, []);
  return (
    <>
      <div className="providers-main">
        <div className="container-fluid">
          <div className="heading-path-otr">
            <h3 className="heading heading-h3">Provider Rate History</h3>
            <BreadcrumbTrail />
          </div>

          <FilterWrapper>
            <div className="w-100">
              <h5>Current</h5>
              <br />
              <Row>
                <Col md={3}>
                  <p className="text-small">Provider</p>
                  <p className="text-large">{providerHistory?.current_values?.provider_name || '-'}</p>
                </Col>
                <Col md={3}>
                  <p className="text-small">Earning</p>
                  <p className="text-large">{providerHistory?.current_values?.earning_code || '-'}</p>
                </Col>
                <Col md={3}>
                  <p className="text-small">Unit in Minutes</p>
                  <p className="text-large">{providerHistory?.current_values?.unit_in_minutes || '-'}</p>
                </Col>
                <Col md={3}>
                  <p className="text-small">Unit Rate</p>
                  <p className="text-large">{providerHistory?.current_values?.initial_unit_charge_amount || '-'}</p>
                </Col>
              </Row>
            </div>
          </FilterWrapper>
          {/* <TableComponent /> */}

          <ProviderRateHistoryListing
            records={providerHistory?.versions || []}
            refreshByParent={refresh}
            setRefreshByParent={setRefresh}
          />
        </div>
      </div>
    </>
  );
};

export default ProviderRateHistory;
