import {useSelector} from 'react-redux';
import React from 'react';
import AppRoutes from 'routes';
import {checkUserLoggedInStatus} from 'utils/common';
import {toastContainerProps} from 'constants/common';
import {ToastContainer} from 'react-toastify';

const App = () => {
  const {loginData} = useSelector(state => state.login);

  return (
    <>
      <AppRoutes isLoggedIn={checkUserLoggedInStatus(loginData)} />
      <ToastContainer {...toastContainerProps} />
    </>
  );
};

export default App;
