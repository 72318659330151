import React, {useEffect, useState} from 'react';

import moment from 'moment';

import {useParams} from 'react-router-dom';
import MyCalendar from 'components/common/calendar';
import {getProviderData} from 'containers/providers/api';

const ProviderCalender = () => {
  const {id} = useParams();
  const [dataEvents, setDataEvents] = useState([]);
  const [date, setDate] = useState({
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').format('YYYY-MM-DD'),
  });
  const [refresh, setRefresh] = useState(false);
  async function fetchData() {
    const result = await getProviderData({
      id,
      start_date: moment(date.startDate).format('YYYY-MM-DD'),
      end_date: moment(date.endDate).format('YYYY-MM-DD'),
      facility_id: ''
    });
    console.log('result is ', result);
    if (result?.length) {
      setDataEvents(result);
    }
  }
  useEffect(() => {
    fetchData();
  }, [refresh]);
  const handleChangeDate = (changedDate, type) => {
    setDate({
      startDate: moment(changedDate).startOf(type).format('YYYY-MM-DD'),
      endDate: moment(changedDate).endOf(type).format('YYYY-MM-DD'),
    });
    handleRefresh();
  };
  const handleRefresh = () => {
    setRefresh(pre => !pre);
  };
  return (
    <MyCalendar
      eventsData={dataEvents}
      handleChangeDate={handleChangeDate}
      provider_id={id}
      handleRefresh={handleRefresh}
      isProviderView
    />
  );
};

export default ProviderCalender;
