export const getPayrollGroupInitialValues = selectedRow => {
  if (selectedRow === null)
    return {
      status: 0,
      name: '',
      payroll_entities_attributes: [],
    };
  return {
    name: selectedRow.name,
    payroll_entities_attributes: selectedRow.payroll_entities.map(item => ({
      value: item?.entity.id,
      label: item?.entity.name,
      id: item.id,
    })),
    status: selectedRow.active ? 1 : 0,
  };
};
