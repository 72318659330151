import React, {useState, useEffect} from 'react';
import AssignedProviders from 'components/common/assigne-facilities-providers/assigned-facility-provider';
import {DEBOUNCE_DELAY, getActionButtonProps} from 'utils/common';
import {Trash} from 'phosphor-react';
import {getProvidersList} from 'containers/providers/api';
import {initialMetaForTable} from 'constants/common';
import {getFacilitiesList} from 'containers/facilities/api';
import debounce from 'debounce-promise';
import {Link, useLocation} from 'react-router-dom';

const AssignFaciltyProvider = ({
  btnText,
  btnClickHandler,
  data,
  setDropDownId,
  facilityProviderDeleteHandler,
  isDisabled,
  dropDownId,
  isProviderView,
}) => {
  const [list, setList] = useState(data);
  const [allListSelected, setAllListSelected] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isDropdownLoading, setDropdownLoading] = useState(false);
  const [dropDownList, setDropDownList] = useState([]);
  const {pathname} = useLocation();

  const isAtProvidersTab = pathname.includes('providers');
  const fetchData = debounce(async (search = '') => {
    setDropdownLoading(true);
    try {
      const reqPayload = {...initialMetaForTable, status: 'active', search};
      if (isProviderView) {
        const result = await getFacilitiesList(reqPayload);
        if (result['facilities']) {
          const data = result?.facilities.map(item => ({value: item.id, label: item.name}));
          setDropDownList(data);
          return data;
        }
      } else {
        const result = await getProvidersList(reqPayload);
        if (result['providers']) {
          const data = result?.providers.map(item => ({value: item.id, label: item.name}));
          setDropDownList(data);
          return data;
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading(false);
    }
  }, DEBOUNCE_DELAY);

  const loadDropdownOptions = search => fetchData(search);

  useEffect(() => {
    fetchData();
  }, []);

  const setAllListCheck = value => {
    setList(pre => pre.map(item => ({...item, isSelected: value})));
  };
  const setSingleItemCheck = (value, id) => {
    setList(pre => pre.map(item => (item.id === id ? {...item, isSelected: value} : item)));
  };
  useEffect(() => {
    if (data) {
      setList(data);
      setLoading(false);
    }
  }, [data]);
  return (
    <div className="provider-padding">
      <div className="table-assigned-provider">
        <AssignedProviders
          isLoading={isDropdownLoading}
          loadDropdownOptions={loadDropdownOptions}
          providersList={dropDownList}
          actionButtons={getActionButtonProps(btnText, btnClickHandler)}
          setDropDownId={setDropDownId}
          isDisabled={isDisabled}
          dropDownId={dropDownId}
        >
          {loading ? (
            <h3 style={{textAlign: 'center'}}>Loading</h3>
          ) : (
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">
                    <div className="header-text-otr">
                      <p className="table-name heading-xsb">
                        <input
                          className="form-check-input"
                          onChange={e => {
                            setAllListSelected(e.target.checked);
                            setAllListCheck(e.target.checked);
                          }}
                          type="checkbox"
                          checked={allListSelected}
                        />
                      </p>
                    </div>
                  </th>
                  <th scope="col">
                    <div className="header-text-otr">
                      <p className="table-name heading-xsb">NPI</p>
                    </div>
                  </th>
                  <th scope="col">
                    <div className="header-text-otr">
                      <p className="table-name heading-xsb">Name</p>
                    </div>
                  </th>
                  <th scope="col">
                    <div className="header-text-otr">
                      <p className="table-name heading-xsb">Assigned Date</p>
                    </div>
                  </th>
                  <th scope="col" className="action-btn">
                    <div className="header-text-otr d-contents">
                      <p className="table-name heading-xsb">Actions</p>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {list.length > 0 &&
                  list.map(item => {
                    return (
                      <tr key={item.id}>
                        {console.log('item', item)}
                        <th scope="col">
                          <div className="header-text-otr">
                            <p className="table-name heading-xsb">
                              <input
                                className="form-check-input"
                                value={item.isSelected}
                                onChange={e => {
                                  setSingleItemCheck(e.target.checked, item.id);
                                }}
                                type="checkbox"
                                checked={item.isSelected}
                              />
                            </p>
                          </div>
                        </th>
                        <td>
                          <div className="table-text-otr w-100">
                            <p className="table-text  ">
                              {!isProviderView ? item?.provider?.npi_code : item?.facility?.npi || ''}
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="table-text-otr">
                            <Link
                              to={
                                isAtProvidersTab
                                  ? item?.facility?.id
                                    ? `/facilities/${item?.facility?.id}/edit`
                                    : '/'
                                  : item?.provider?.id
                                  ? `/providers/${item?.provider?.id}/edit?tab=provider-information`
                                  : '/'
                              }
                            >
                              <p className="table-text ">
                                {!isProviderView
                                  ? item?.provider?.first_name + ' ' + item?.provider?.last_name
                                  : item?.facility?.name || ''}
                              </p>
                            </Link>
                          </div>
                        </td>
                        <td>
                          <div className="table-text-otr">
                            <p className="table-text ">{!isProviderView ? item?.created_at : item?.created_at || ''}</p>
                          </div>
                        </td>
                        <td className="action-column">
                          <div className="table-icon-otr d-flex justify-content-center">
                            <div className="icon-otr">
                              <Trash size={24} onClick={() => facilityProviderDeleteHandler(item.id)} />
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
        </AssignedProviders>
      </div>
    </div>
  );
};

export default AssignFaciltyProvider;
