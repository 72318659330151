import {get} from 'services/network';
import {USERS_ENDPOINT, SYSTEM_AUDIT_ENDPOINT} from 'constants/endpoints';
import moment from 'moment';

export const getUsers = async (search = '') => {
  try {
    const response = await get(`${USERS_ENDPOINT}?query=${search}`, true);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const getSystemAuditList = async obj => {
  const params = {
    sort: obj?.sort ? obj.sort : '',
    order: obj?.order ? obj.order : 'asc',
    page: obj?.page ? obj.page : 1,
    perPage: obj?.perPage ? obj.perPage : 10,
    search: obj?.search ? obj.search : '',
    startDate: obj?.startDate ? moment(obj.startDate).format('YYYY-MM-DD') : '',
    endDate: obj?.endDate ? moment(obj.endDate).format('YYYY-MM-DD') : '',
    user: obj?.user ? obj.user : '',
  };
  try {
    const result = await get(
      `${SYSTEM_AUDIT_ENDPOINT}?page=${params.page}&sort=${params.sort}&order=${params.order}&per=${
        params.perPage
      }&search=${params.search}${params.startDate ? `&from=${params.startDate}` : ''}${
        params.endDate ? `&to=${params.endDate}` : ''
      }${params.user ? `&user=${params.user}` : ''}`,
      true,
    );
    return result;
  } catch (e) {
    console.log(e);
  }
};
