import React, {useState, useEffect} from 'react';

import TableWraper from 'components/common/table-wrapper';
import CreateRoleModal from 'components/roles/CreateRoleModal';
import UserCountModal from 'components/roles/UsersCountModal';
import BreadcrumbTrail from 'components/common/breadcrumb-trail';

import {getAdminRoles, createAdminRole} from 'containers/roles/api';
import './roles.scss';
import {DEBOUNCE_DELAY, getActionButtonProps, isAdministrator} from 'utils/common';
import {initialMetaForTable, orderStratergy} from 'constants/common';
import {ArrowsDownUp} from 'phosphor-react';

let timeout;
function AdminRoles() {
  const [rolesList, setRolesList] = useState([]);
  const [isModalVisible, setModalVisibility] = useState(false);
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState(initialMetaForTable);
  const [totalCount, setTotalCount] = useState(0);
  const [refreshPage, setRefreshPage] = useState(false);
  const [userCountModal, setUserCountModal] = useState(false);
  const [roleId, setRoleId] = useState(null);
  async function fetchData() {
    setLoading(true);
    try {
      const result = await getAdminRoles(meta);
      if (result?.roles) {
        setRolesList(result.roles);
        setTotalCount(result?.meta.total_count);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
    setLoading(false);
  }
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage]);

  const handleRefreshPage = () => {
    setRefreshPage(pre => !pre);
  };
  const handleSetPerPage = obj => {
    setMeta(pre => ({...pre, perPage: obj.value}));
    handleRefreshPage();
  };
  const debounceFn = (callback, delay) => {
    clearTimeout(timeout);
    timeout = setTimeout(callback, delay);
  };
  const handleSetSearchQuery = value => {
    setMeta(pre => ({...pre, search: value}));
    debounceFn(handleRefreshPage, DEBOUNCE_DELAY);
  };
  const handlePageChange = value => {
    setMeta(pre => ({...pre, page: value}));
    handleRefreshPage();
  };
  const handleClickOnAddRole = () => {
    setModalVisibility(true);
  };
  const handleCreateRole = async (values, setErrors) => {
    const payload = {
      roles: {
        ...values,
      },
    };
    await createAdminRole(payload, setErrors);
    setModalVisibility(false);
    handleRefreshPage();
  };
  const handleCloseCrateModal = () => {
    setModalVisibility(false);
  };
  const handleOpenUserModal = () => {
    setUserCountModal(true);
  };
  const handleCloseUserModal = () => {
    setUserCountModal(false);
    setRoleId(null);
  };
  const sortedDataByColumn = columnName => {
    if (rolesList.length > 0) {
      setMeta(pre => ({...pre, sort: columnName, order: orderStratergy[pre.order]}));
      handleRefreshPage();
    }
  };
  return (
    <>
      <div className="roles-main">
        <div className="container-fluid">
          <div className="heading-path-otr">
            <h3 className="heading heading-h3">Roles</h3>
            <BreadcrumbTrail />
          </div>
          <TableWraper
            setPerPage={handleSetPerPage}
            setSearchQuery={handleSetSearchQuery}
            searchValue={meta.search}
            totalListCount={totalCount}
            pageSize={meta.perPage}
            currentPage={meta.page}
            onPageChange={handlePageChange}
            actionButtons={isAdministrator() && getActionButtonProps('Add Role', handleClickOnAddRole)}
          >
            {loading ? (
              <p style={{textAlign: 'center'}}>Loading</p>
            ) : (
              <table className="table fixed-layout">
                <thead>
                  <tr>
                    <th scope="col">
                      <div className="header-text-otr">
                        <p
                          className="table-name heading-xsb cursor-pointer c-hand"
                          onClick={() => sortedDataByColumn('name')}
                        >
                          Role Name 
                        </p>
                      </div>
                    </th>
                    <th scope="col">
                      <div className="header-text-otr">
                        <p
                          className="table-name heading-xsb cursor-pointer c-hand"
                          onClick={() => sortedDataByColumn('users_count')}
                        >
                          User Count 
                        </p>
                      </div>
                    </th>
                    <th scope="col">
                      <div className="header-text-otr">
                        <p className="table-name heading-xsb">Description</p>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {rolesList.map(item => {
                    return (
                      <tr key={item.id}>
                        <th scope="row">
                          <p className="table-text">{item.name}</p>
                        </th>
                        <td>
                          <p className="table-text">
                            <span
                              onClick={() => {
                                handleOpenUserModal();
                                setRoleId(item.id);
                              }}
                              className={` ${item.users_count ? 'clickable' : 'not-clickable'}`}
                            >
                              {item.users_count}
                            </span>
                          </p>
                        </td>
                        <td>
                          <p className="table-text">
                            <span
                              onClick={() => {
                                handleOpenUserModal();
                                setRoleId(item.id);
                              }}
                              className={` ${item.users_count ? 'clickable' : 'not-clickable'}`}
                            >
                              {item.description}
                            </span>
                          </p>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </TableWraper>
        </div>
      </div>

      {isModalVisible && (
        <CreateRoleModal open={isModalVisible} close={handleCloseCrateModal} handleSubmit={handleCreateRole} />
      )}
      {userCountModal && <UserCountModal open={userCountModal} close={handleCloseUserModal} id={roleId} />}
    </>
  );
}

export default AdminRoles;
