import React from 'react';

import BreadcrumbTrail from 'components/common/breadcrumb-trail';
import 'components/facilities/facilities.scss';
import EmailTemplatesListing from 'components/mail/templates/email-templates-listing';

const EmailTemplates = () => {
  return (
    <>
      <div className="providers-main">
        <div className="container-fluid">
          <div className="heading-path-otr">
            <h3 className="heading heading-h3">Templates</h3>
            <BreadcrumbTrail />
          </div>

          <EmailTemplatesListing />
        </div>
      </div>
    </>
  );
};

export default EmailTemplates;
