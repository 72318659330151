import CustomToast from 'components/common/custom-toast';
import {alertTypes} from 'constants/common';
import {ACTIVE_ANALYSIS_ENDPOINT, ACTIVE_ANALYSIS_EMAIL_ENDPOINT} from 'constants/endpoints';
import {toast} from 'react-toastify';
import {post} from 'services/network';

export const createActiveAnalysis = async data => {
  // const params = {sort: meta?.name || '', order: meta?.order || 'asc'};
  // &sort=${params.sort}&order=${params.order}

  try {
    const result = await post(`${ACTIVE_ANALYSIS_ENDPOINT}?search_type=active_analysis_unpaid`, data, true);
    return result;
  } catch (error) {
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={error?.response?.data?.message || error?.response?.statusText}
      />,
    );
    console.log('error');
  }
};
export const createActiveAnalysisEmail = async data => {
  try {
    const result = await post(`${ACTIVE_ANALYSIS_EMAIL_ENDPOINT}`, data, true);
    toast(<CustomToast variant={alertTypes.INFO} message={result} />);
    return result;
  } catch (error) {
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={error?.response?.data?.message || error?.response?.statusText}
      />,
    );
    console.log('error');
  }
};
