import CustomToast from 'components/common/custom-toast';
import {alertTypes} from 'constants/common';
import {EARNING_CODES_ENDPOINT, PROVIDERS_ENDPOINT} from 'constants/endpoints';
import {toast} from 'react-toastify';
import {get, put, post, deleteApi} from 'services/network';

export const getEarningCodes = async obj => {
  const params = {
    sort: obj?.sort ? obj.sort : '',
    order: obj?.order ? obj.order : 'asc',
    page: obj?.page ? obj.page : 1,
    perPage: obj?.perPage ? obj.perPage : 10,
    search: obj?.search ? obj.search : '',
    status: obj?.status || '',
    active: obj?.active || false,
  };
  try {
    const result = await get(
      `${EARNING_CODES_ENDPOINT}?page=${params.page}&sort=${params.sort}&order=${params.order}&per=${
        params.perPage
      }&query=${params.search}${params.status ? `&filter[status]=${params.status}` : ''}`,
      true,
    );
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const updateEarningCodes = async (data, setErrors) => {
  const id = data.id;
  delete data.id;
  try {
    const result = await put(EARNING_CODES_ENDPOINT + `/${id}`, data, true);
    toast(<CustomToast variant={alertTypes.SUCCESS} message="Eearning code updated successfully!" />);
    return result;
  } catch (error) {
    if (error?.response?.data) {
      setErrors(error?.response?.data.errors);
    }
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={error?.response?.data?.message || error?.response?.statusText}
      />,
    );
    console.log('error');
  }
};
export const createEarningCodes = async (data, setErrors) => {
  try {
    const result = await post(EARNING_CODES_ENDPOINT, data, true);
    toast(<CustomToast variant={alertTypes.SUCCESS} message="Provider created successfully!" />);
    return result;
  } catch (error) {
    if (error?.response?.data) {
      setErrors(error?.response?.data.errors);
    }
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={error?.response?.data?.message || error?.response?.statusText}
      />,
    );
    console.log('error', error);
  }
};
export const showEarningCodes = async id => {
  try {
    const result = await get(`${EARNING_CODES_ENDPOINT}/${id}`, true);
    return result;
  } catch (e) {
    console.log(e);
  }
};
export const deactivateEarningCode = async id => {
  try {
    const response = await deleteApi(`${EARNING_CODES_ENDPOINT}/${id}/`, true);
    toast(<CustomToast variant={alertTypes.SUCCESS} message="User deactivated successfully!" />);
    return response;
  } catch (error) {
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={error?.response?.data?.message || error?.response?.statusText}
      />,
    );
    console.error(error);
  }
};
export const getEarningCodesByProvider = async id => {
  try {
    const result = await get(`${PROVIDERS_ENDPOINT}/${id}/earning_codes`, true);
    return result;
  } catch (e) {
    console.log(e);
  }
};
