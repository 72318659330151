import React, {useEffect, useState} from 'react';
import Input from 'components/common/input';
import SelectComponent from 'components/common/select';
import {ErrorMessage} from 'formik';
import {Row, Col} from 'react-bootstrap';
import {getUsersByFilter} from 'containers/providers/api';
import AsyncSelectComponent from 'components/common/async-select';
import CustomDatePicker from 'components/common/custom-datepicker';
import moment from 'moment';

const CreatePayrollInformation = ({
  formik,
  categories,
  payMethods,
  loadPayMethodDropdownOptions,
  loadProviderCategoryDropdownOptions,
  dropdownLoading,
}) => {
  const [users, setUsers] = useState([]);
  async function fetchUsers() {
    const result = await getUsersByFilter('biller', 'active');
    if (result['users']) {
      setUsers(result['users'].map(item => ({value: item.id, label: item.full_name})));
    }
  }
  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <form className={'w-100'}>
      <Row>
        <Col md={4}>
          <div className=" input-otr" style={{alignItems: 'inherit'}}>
            <label htmlFor="email" className="input-label">
              Provider Category
            </label>
            <AsyncSelectComponent
              isLoading={dropdownLoading.providerCategory}
              selectedValue={formik.values.payroll_information_attributes.provider_category_id}
              defaultOptions={categories}
              loadOptions={loadProviderCategoryDropdownOptions}
              onChange={obj => {
                formik.setFieldValue('payroll_information_attributes.provider_category_id', obj.value);
                formik.setFieldValue('payroll_information_attributes.provider_category_label', obj.label);
                if (!obj.label.toLowerCase().includes('salary')) {
                  formik.setFieldValue('payroll_information_attributes.base_salary', 0);
                  formik.setFieldValue('payroll_information_attributes.min_units_per_day', 0);
                  formik.setFieldValue('payroll_information_attributes.unit_rate', 0);
                }
              }}
              placeholder="Search and select"
            />
            <ErrorMessage
              className="error-text"
              component="p"
              name="payroll_information_attributes.provider_category_id"
            />
          </div>
        </Col>
        <Col md={4}>
          <div className=" input-otr" style={{alignItems: 'inherit'}}>
            <label htmlFor="email" className="input-label">
              Pay Method
            </label>
            <AsyncSelectComponent
              name="payroll_information_attributes.pay_method_id"
              isLoading={dropdownLoading.payMehod}
              selectedValue={formik.values.payroll_information_attributes.pay_method_id}
              defaultOptions={payMethods}
              loadOptions={loadPayMethodDropdownOptions}
              onChange={obj => {
                formik.setFieldValue('payroll_information_attributes.pay_method_id', obj.value);
              }}
              placeholder="Search and select"
            />

            <ErrorMessage className="error-text" component="p" name="payroll_information_attributes.pay_method_id" />
          </div>
        </Col>
        <Col md={4}>
          <div className=" input-otr" style={{alignItems: 'inherit'}}>
            <label htmlFor="email" className="input-label">
              Billers
            </label>
            <SelectComponent
              name="payroll_information_attributes.user_id"
              options={users}
              selectedValue={formik.values.payroll_information_attributes.user_id}
              handleChange={obj => {
                formik.setFieldValue('payroll_information_attributes.user_id', obj.value);
              }}
            />
            <ErrorMessage className="error-text" component="p" name="payroll_information_attributes.user_id" />
          </div>
        </Col>
        <Col md={4}>
          <Input
            handleChange={formik.handleChange}
            name={'payroll_information_attributes.weekly_rvu_commitment'}
            value={formik.values.payroll_information_attributes.weekly_rvu_commitment}
            placeholder={'e.g 10'}
            label={'Weekly RVU Commitment'}
            type="number"
          />
          <ErrorMessage
            className="error-text"
            component="p"
            name="payroll_information_attributes.weekly_rvu_commitment"
          />
        </Col>
        {formik.values.payroll_information_attributes.provider_category_label?.toLowerCase().includes('salary') && (
          <>
            <Col md={4}>
              <Input
                handleChange={formik.handleChange}
                name={'payroll_information_attributes.base_salary'}
                value={formik.values.payroll_information_attributes.base_salary}
                placeholder={'e.g 10'}
                label={'Base Salary Pay Per Period'}
                type="number"
              />
              <ErrorMessage className="error-text" component="p" name="payroll_information_attributes.base_salary" />
            </Col>
            <Col md={4}>
              <Input
                handleChange={formik.handleChange}
                name={'payroll_information_attributes.min_units_per_day'}
                value={formik.values.payroll_information_attributes.min_units_per_day}
                placeholder={'e.g 10'}
                label={'Minimum Units Required Per Day'}
                type="number"
              />
              <ErrorMessage
                className="error-text"
                component="p"
                name="payroll_information_attributes.min_units_per_day"
              />
            </Col>
            <Col md={4}>
              <Input
                handleChange={formik.handleChange}
                name={'payroll_information_attributes.unit_rate'}
                value={formik.values.payroll_information_attributes.unit_rate}
                placeholder={'e.g 150'}
                label={'Rate for Unit Overage'}
                type="number"
              />
              <ErrorMessage className="error-text" component="p" name="payroll_information_attributes.unit_rate" />
            </Col>
          </>
        )}
        <Col md={4}>
          <CustomDatePicker
            name="payroll_information_attributes.first_dos"
            label="First DOS"
            selected={
              formik.values.payroll_information_attributes.first_dos
                ? new Date(moment(formik.values.payroll_information_attributes.first_dos))
                : ''
            }
            onDateChange={date => formik.setFieldValue('payroll_information_attributes.first_dos', date)}
          />
          <ErrorMessage className="error-text" component="p" name="payroll_information_attributes.first_dos" />
        </Col>
        {/* <Col md={4}>
          <CustomDatePicker
            name="payroll_information_attributes.last_dos"
            label="Last DOS"
            selected={
              formik.values.payroll_information_attributes.last_dos
                ? new Date(moment(formik.values.payroll_information_attributes.last_dos))
                : ''
            }
            onDateChange={date => formik.setFieldValue('payroll_information_attributes.last_dos', date)}
          />
          <ErrorMessage className="error-text" component="p" name="payroll_information_attributes.last_dos" />
        </Col>
        <Col md={4}>
          <CustomDatePicker
            name="payroll_information_attributes.pay_roll_end_date"
            label="Payroll End Date"
            selected={
              formik.values.payroll_information_attributes.pay_roll_end_date
                ? new Date(moment(formik.values.payroll_information_attributes.pay_roll_end_date))
                : ''
            }
            onDateChange={date => formik.setFieldValue('payroll_information_attributes.pay_roll_end_date', date)}
          />
          <ErrorMessage className="error-text" component="p" name="payroll_information_attributes.pay_roll_end_date" />
        </Col> */}
      </Row>
    </form>
  );
};

export default CreatePayrollInformation;
