// for event we have to passs correct complete timestamp of date object like as passsing by moment
//start: moment().toDate(),
//end: moment().add(1, 'hours').toDate(),
import './calendar.scss';
import React, {useState, useEffect} from 'react';
import events from './events';
import BigCalendar from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Toolbar from './toolbar';
import EditEventModal from './edit-event-modal/edit-event-modal';
import CreateEventModal from './create-event-modal/create-event-modal';
import MonthEventsView from 'components/common/calendar/MonthEventsView';
import {classNameWithKeys, matchingWithKeys} from 'constants/calendar';

const MyCalendar = ({eventsData = null, handleChangeDate, providerId, handleRefresh, isProviderView = false}) => {
  const [viewState, setViewState] = useState('month');
  const [eventsList, setEventList] = useState([]);
  const [showEditEventModal, setShowEditEventModal] = useState(false);
  const [showCreateEventModal, setShowCreateEventModal] = useState(false);
  const [currentDate, setCurrentDate] = useState(
    `${new Date().toLocaleString('default', {month: 'long', year: 'numeric'})} `,
  );
  const [eventDate, setEventDate] = useState('');
  const [format, setFormat] = useState({});
  const [date, setDate] = useState(`${new Date().toLocaleString('default', {month: 'long', year: 'numeric'})} `);
  const [selectedData, setSelectedData] = useState(null);
  const [editSelectedData, setEditSelectedData] = useState(null);

  useEffect(() => {
    if (viewState === 'month') {
      setFormat({
        weekdayFormat: (date, culture, localizer) => localizer.format(date, 'dddd', culture),
      });
    }
    if (viewState === 'week') {
      setFormat({
        dayFormat: (date, culture, localizer) => localizer.format(date, 'dddd MM/DD', culture),
      });
    }
    if (viewState === 'day') {
      setFormat({
        dayFormat: (date, culture, localizer) => localizer.format(date, 'dddd', culture),
      });
    }
    let result = Array.from(
      new Set(
        eventsData.map(s => {
          let {provider_data_by_earning_code, ...props} = s;
          return props;
        }),
      ),
    ).map(lab => {
      return {
        ...lab,
        provider_data_by_earning_code: [
          ...eventsData
            .filter(s => s.facility_id === lab.facility_id && s.serviced_date === lab.serviced_date)
            .map(edition => {
              const {provider_data_by_earning_code} = edition;

              return provider_data_by_earning_code[0];
            }),
        ],
      };
    });
    result = Array.from(new Set(result.map(JSON.stringify))).map(JSON.parse);

    if (result?.length) {
      setEventList(
        result.map(item => ({
          title: (
            <div className="calendar-card">
              <div className="card-title">{`${!isProviderView ? `${item.name}, ` : ''}${item.facility_name}`}</div>
              <div className="card-body">
                {viewState === 'month' ? (
                  <MonthEventsView events={item.provider_data_by_earning_code} />
                ) : (
                  <>
                    {item.provider_data_by_earning_code.map(elem => {
                      let objectHasvalue = null;

                      Object.keys(elem).forEach(keyName => {
                        const index = matchingWithKeys.findIndex(item => item === keyName);
                        if (index !== -1) {
                          if (elem[matchingWithKeys[index]][0]?.provider_data?.length) {
                            objectHasvalue = matchingWithKeys[index];
                          }
                        }
                      });

                      return objectHasvalue !== null && objectHasvalue !== 'erroneous_provider_data' ? (
                        <p className={objectHasvalue !== null ? classNameWithKeys[objectHasvalue] : ''}>{`${
                          elem.earning_code
                        } ${elem[objectHasvalue][0]?.total_price_and_unit?.total_units?.toFixed(2)}U ${
                          elem[objectHasvalue][0]?.total_price_and_unit?.total_amount
                        }`}</p>
                      ) : (
                        ''
                      );
                    })}
                  </>
                )}
                {viewState === 'month' && item.provider_data_by_earning_code?.length > 2 && (
                  <span>{`+${item.provider_data_by_earning_code?.length - 2} more`}</span>
                )}
              </div>
            </div>
          ),
          allDay: true,
          start: moment(item.serviced_date),
          end: moment(item.serviced_date),
          data: item,
        })),
      );
    }
  }, [viewState, eventsData]);
  const getCustomToolbar = toolbar => {
    const toolbarDate = toolbar.date;
    if (toolbar.view === 'day') {
      toolbar.onViewChange('day');
      setViewState('day');
      const dateRange = toolbar.date.toLocaleString('default', {month: 'long', day: 'numeric', year: 'numeric'});
      setDate(dateRange);
    } else if (toolbar.view === 'week') {
      toolbar.onViewChange('week');
      setViewState('week');

      const dateRange = `
        ${toolbarDate.toLocaleString('default', {month: 'long'})} 
        ${moment(toolbarDate).startOf('week')._d.getDate()} - 
        ${moment(toolbarDate).endOf('week')._d.getDate()}, ${toolbarDate.getFullYear()}`;
      setDate(dateRange);
    } else if (toolbar.view === 'month') {
      toolbar.onViewChange('month');
      setViewState('month');
      const dateRange = `
        ${toolbarDate.toLocaleString('default', {month: 'long'})} ${toolbarDate.getFullYear()}`;
      setDate(dateRange);
    }
    const goToDayView = () => {
      toolbar.onViewChange('day');
      setViewState('day');
      const dateRange = toolbar.date.toLocaleString('default', {month: 'long', day: 'numeric', year: 'numeric'});
      setDate(dateRange);
    };
    const goToWeekView = () => {
      toolbar.onViewChange('week');
      setViewState('week');

      const dateRange = `
        ${toolbarDate.toLocaleString('default', {month: 'long'})}
        ${moment(toolbarDate).startOf('week')._d.getDate()} -
        ${moment(toolbarDate).endOf('week')._d.getDate()}, ${toolbarDate.getFullYear()}`;
      setDate(dateRange);
    };
    const goToMonthView = () => {
      toolbar.onViewChange('month');
      setViewState('month');
      const dateRange = `
        ${toolbarDate.toLocaleString('default', {month: 'long'})} ${toolbarDate.getFullYear()}`;
      setDate(dateRange);
    };
    const goToBack = () => {
      let view = viewState;
      let mDate = toolbar.date;
      let newDate;
      if (view === 'month') {
        newDate = new Date(mDate.getFullYear(), mDate.getMonth() - 1, 1);
        const dateRange = newDate.toLocaleString('default', {month: 'long', year: 'numeric'});
        handleChangeDate(dateRange, 'month');
        setCurrentDate(dateRange);
        setDate(dateRange);
      } else if (view === 'week') {
        newDate = new Date(mDate.getFullYear(), mDate.getMonth(), mDate.getDate() - 7, 1);
        const dateRange = `
          ${newDate.toLocaleString('default', {month: 'long'})} 
          ${moment(newDate).startOf('week')._d.getDate()} - 
          ${moment(newDate).endOf('week')._d.getDate()}, ${newDate.getFullYear()}`;
        handleChangeDate(newDate, 'week');
        setCurrentDate(newDate);
        setDate(dateRange);
      } else {
        newDate = new Date(mDate.getFullYear(), mDate.getMonth(), mDate.getDate() - 1, 1);
        const dateRange = newDate.toLocaleString('default', {month: 'long', day: 'numeric', year: 'numeric'});
        handleChangeDate(dateRange, 'day');
        setCurrentDate(dateRange);
        setDate(dateRange);
      }
      toolbar.onNavigate('prev', newDate);
    };
    const goToNext = () => {
      let view = viewState;
      let mDate = toolbar.date;
      let newDate;
      if (view === 'month') {
        newDate = new Date(mDate.getFullYear(), mDate.getMonth() + 1, 1);
        const dateRange = newDate.toLocaleString('default', {month: 'long', year: 'numeric'});
        handleChangeDate(dateRange, 'month');
        setCurrentDate(dateRange);
        setDate(dateRange);
      } else if (view === 'week') {
        newDate = new Date(mDate.getFullYear(), mDate.getMonth(), mDate.getDate() + 7, 1);
        const dateRange = `
          ${newDate.toLocaleString('default', {month: 'long'})} 
          ${moment(newDate).startOf('week')._d.getDate()} - 
          ${moment(newDate).endOf('week')._d.getDate()}, ${newDate.getFullYear()}`;
        handleChangeDate(newDate, 'week');
        setCurrentDate(newDate);
        setDate(dateRange);
      } else {
        newDate = new Date(mDate.getFullYear(), mDate.getMonth(), mDate.getDate() + 1, 1);
        const dateRange = newDate.toLocaleString('default', {month: 'long', day: 'numeric', year: 'numeric'});
        handleChangeDate(dateRange, 'day');
        setCurrentDate(dateRange);
        setDate(dateRange);
      }
      toolbar.onNavigate('next', newDate);
    };

    const goToToday = () => {
      const now = new Date();
      toolbar.date.setMonth(now.getMonth());
      toolbar.date.setYear(now.getFullYear());
      toolbar.date.setDate(now.getDate());
      toolbar.onNavigate('current');
      if (viewState === 'month') {
        const dateRange = `
        ${toolbar.date.toLocaleString('default', {month: 'long'})} ${toolbarDate.getFullYear()}`;
        handleChangeDate(now, 'month');
        setCurrentDate(now);
        setDate(dateRange);
      }
      if (viewState === 'week') {
        const dateRange = `
        ${toolbar.date.toLocaleString('default', {month: 'long'})} 
        ${moment(toolbar.date).startOf('week')._d.getDate()} - 
        ${moment(toolbar.date).endOf('week')._d.getDate()}, ${toolbar.date.getFullYear()}`;
        handleChangeDate(now, 'week');
        setCurrentDate(now);
        setDate(dateRange);
      }
      if (viewState === 'day') {
        const dateRange = toolbar.date.toLocaleString('default', {month: 'long', day: 'numeric', year: 'numeric'});
        handleChangeDate(now, 'day');
        setCurrentDate(now);
        setDate(dateRange);
      }
    };

    return (
      <div className="toolbar-header radius">
        <Toolbar
          date={date}
          goToNext={goToNext}
          goToBack={goToBack}
          goToDayView={goToDayView}
          goToMonthView={goToMonthView}
          goToWeekView={goToWeekView}
          goToToday={goToToday}
          viewState={viewState}
          setShowCreateEventModal={setShowCreateEventModal}
        />
      </div>
    );
  };
  const addEvent = ({event, start, end, allDay}) => {
    const newEvent = {
      id: events.length,
      title: 'here is my new  event',
      start: new Date(new Date(start).setHours(new Date().getHours() - 3)),
      end: new Date(new Date(end).setHours(new Date().getHours() + 3)),
      desc: 'This is a new event',
    };

    setEventList(prevState => [...prevState, newEvent]);
  };
  const handleClickOnEdit = item => {
    const payload = {
      provider_id: providerId || '',
      facility_id: item?.facility_id ? item.facility_id : '',
      service_date: item.serviced_date || '',
      provider_name: item?.name || '',
      facility_name: item?.facility_name || '',
      provider_data:
        item?.provider_data?.length > 0
          ? item?.provider_data?.map(elem => ({
              ...elem,
              note: typeof elem?.note === 'object' ? {...elem.note} : {message: ''},
            }))
          : [
              {
                id: item.id,
                earning_code_id: item?.earning_code_id,
                // patient_seen: item?.patient_seen || '',
                note_id: item?.note_id || '',
                serviced_unit: item?.serviced_unit || '',
                effective_date: item?.effective_date || '',
                note: {message: item?.note?.message || ''},
              },
            ],
    };
    setEditSelectedData(payload);
    setShowCreateEventModal(true);
  };
  const localizer = BigCalendar.momentLocalizer(moment); // or globalizeLocalizer
  return (
    <div className="mb-4 padding-conatiner react-calendar">
      <div style={{height: '100vh', width: '100%'}} className="radius">
        <BigCalendar
          localizer={localizer}
          // selectable
          events={eventsList}
          showAllEvents
          startAccessor="start"
          endAccessor="end"
          popup
          style={{height: 1000}}
          formats={format}
          components={{
            toolbar: getCustomToolbar,
          }}
          onSelectEvent={event => {
            console.log('event is ', event);
            setEventDate(moment(event.start).format('dddd,MMMM YY'));
            setSelectedData(event?.data);
            setShowEditEventModal(true);
          }}
          views={['month', 'week', 'day']}
        />
        <div className="footer">All times are in EST.</div>
      </div>
      {showEditEventModal && (
        <EditEventModal
          show={true}
          onHide={() => {
            setShowEditEventModal(false);
            setSelectedData(null);
          }}
          data={selectedData}
          handleClickOnEdit={handleClickOnEdit}
        />
      )}
      {showCreateEventModal && (
        <CreateEventModal
          show={true}
          onHide={() => {
            setShowCreateEventModal(false);
            setEditSelectedData(null);
          }}
          date={moment(new Date()).format('dddd,MMMM YY')}
          selectedData={editSelectedData}
          handleRefresh={handleRefresh}
          currentStartDate={moment(currentDate).startOf(viewState).format('YYYY-MM-DD')}
          currentEndDate={moment(currentDate).endOf(viewState).format('YYYY-MM-DD')}
        />
      )}
    </div>
  );
};

export default MyCalendar;
