import React, {useState, useEffect} from 'react';
import CustomModal from 'components/common/modal';
import ProviderForm from './forms/ProviderForm';
import CreatePayrollInformation from './forms/CreatePayrollInformation';
import debounce from 'debounce-promise';

import {Formik} from 'formik';
import {
  createProviderInitialValues as initialValues,
  createProviderValidationSchema as validationSchema,
} from 'constants/providers';
import {
  getProviderTitle,
  getEntitiesList,
  getProviderCategoies,
  getUsersByFilter,
  getPayMethodList,
  checkProviderInformation,
} from 'containers/providers/api';
import {Spinner} from 'react-bootstrap';
import {DEBOUNCE_DELAY} from 'utils/common';
import {appendForMail} from 'constants/common';

const CreateProviderModal = ({open, close, handleSubmit, loading}) => {
  const [providerTitles, setProviderTitles] = useState([]);
  const [entities, setEntities] = useState([]);
  const [categories, setCategories] = useState([]);
  const [payMethodList, setPayMethodList] = useState([]);
  const [current, setCurrent] = useState(0);
  const [users, setUsers] = useState([]);
  const [dropdownLoading, setDropdownLoading] = useState({
    prviderCategory: false,
    payMethod: false,
    entity: false,
    providerTitle: false,
    users: false,
  });

  async function fetchUsers() {
    const result = await getUsersByFilter('director');
    if (result['users']) {
      setUsers(result['users'].map(item => ({user_id: item.id, value: item.id, label: item.full_name})));
    }
  }

  async function fetchEntities() {
    const result = await getEntitiesList();
    if (result?.entities) {
      setEntities(result['entities']?.map(item => ({value: item.id, label: item.name})));
    }
  }
  const fetchProviderTitle = async () => {
    const result = await getProviderTitle();

    if (result?.provider_titles) {
      setProviderTitles(result.provider_titles.map(item => ({value: item.id, label: item.name})));
    }
  };
  async function fetchCategories() {
    const result = await getProviderCategoies();
    if (result['provider_categories']) {
      setCategories(result['provider_categories'].map(item => ({value: item.id, label: item.name})));
    }
  }

  async function fetchPayMethodList() {
    const result = await getPayMethodList();
    if (result['pay_methods']) {
      setPayMethodList(result['pay_methods'].map(item => ({value: item.id, label: item.name})));
    }
  }

  useEffect(() => {
    fetchProviderTitle();
    // fetchEntities();
    fetchPayMethodList();
    fetchCategories();
    fetchUsers();
  }, []);

  const fetchProviderCategory = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, prviderCategory: true});
    try {
      const result = await getProviderCategoies('active', search);
      if (result['provider_categories']) {
        const data = result?.provider_categories.map(item => ({value: item.id, label: item.name}));
        setCategories(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, prviderCategory: false});
    }
  }, DEBOUNCE_DELAY);
  const fetchPayMethod = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, payMethod: true});
    try {
      const result = await getPayMethodList('active', search);
      if (result['pay_methods']) {
        const data = result?.pay_methods.map(item => ({value: item.id, label: item.name}));
        setPayMethodList(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, payMethod: false});
    }
  }, DEBOUNCE_DELAY);
  const fetchEntityData = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, entity: true});
    try {
      const result = await getEntitiesList(search);
      if (result['entities']) {
        const data = result?.entities.map(item => ({value: item.id, label: item.name}));
        setEntities(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, entity: false});
    }
  }, DEBOUNCE_DELAY);

  const fetchProvidersTitleData = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, providerTitle: true});
    try {
      const result = await getProviderTitle('active', search);
      if (result['provider_titles']) {
        const data = result?.provider_titles.map(item => ({value: item.id, label: item.name}));
        setProviderTitles(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, providerTitle: false});
    }
  }, DEBOUNCE_DELAY);
  const fetchUsersData = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, users: true});
    try {
      const result = await getUsersByFilter('director', 'active', search);
      if (result['users']) {
        const data = result?.users.map(item => ({
          id: item.id,
          user_id: item.id,
          value: item.id,
          label: item.full_name,
        }));
        setUsers(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, users: false});
    }
  }, DEBOUNCE_DELAY);

  const loadProviderCategoryDropdownOptions = search => fetchProviderCategory(search);
  const loadPayMethodDropdownOptions = search => fetchPayMethod(search);
  const loadEntityDropdownOptions = search => fetchEntityData(search);
  const loadProvidersTitleDropdownOptions = search => fetchProvidersTitleData(search);
  const loadUsersDropdownOptions = search => fetchUsersData(search);
  const validateProviderInformation = async (values, setErrors) => {
    // if (
    //   !formik.errors.npi_code &&
    //   !formik.errors.first_name &&
    //   !formik.errors.last_name &&
    //   !formik.errors.company_email &&
    //   !formik.errors.personal_email &&
    //   !formik.errors.name &&
    //   !formik.errors.phone_no &&
    //   !formik.errors.provider_and_titles_attributes &&
    //   !formik.errors.entity_id &&
    //   !formik.errors.address_attributes &&
    //   formik.dirty
    // ) {
    const data = {
      provider: {
        npi_code: values.npi_code,
        first_name: values.first_name,
        last_name: values.last_name,
        company_email: values.company_email,
        personal_email: values.personal_email,
        name: values.name,
        phone_no: values.phone_no.replaceAll(' ', ''),
        provider_and_titles_attributes: values.provider_and_titles_attributes.map(provider_title => ({
          provider_title_id: provider_title.value,
        })),
        entity_id: values.entity_id,
        address_attributes: values.address_attributes,
      },
    };
    try {
      const result = await checkProviderInformation(data, setErrors);
      if (result.status === 200) {
        setCurrent(pre => ++pre);
      }
    } catch (e) {
      console.log(e);
    }
    // }
  };
  return (
    <CustomModal
      show={open}
      onHide={() => close()}
      heading={`Create ${current === 0 ? 'Provider' : 'Payroll Information'}`}
    >
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values, {setErrors}) => {
          current === 0 ? validateProviderInformation(values, setErrors) : handleSubmit(values, setErrors);
        }}
      >
        {formik => {
          return (
            <>
              {current === 0 ? (
                <ProviderForm
                  formik={formik}
                  users={users}
                  providerTitles={providerTitles}
                  entities={entities}
                  dropdownLoading={dropdownLoading}
                  loadEntityDropdownOptions={loadEntityDropdownOptions}
                  loadProvidersTitleDropdownOptions={loadProvidersTitleDropdownOptions}
                  loadUsersDropdownOptions={loadUsersDropdownOptions}
                />
              ) : (
                <CreatePayrollInformation
                  formik={formik}
                  categories={categories}
                  payMethods={payMethodList}
                  dropdownLoading={dropdownLoading}
                  loadProviderCategoryDropdownOptions={loadProviderCategoryDropdownOptions}
                  loadPayMethodDropdownOptions={loadPayMethodDropdownOptions}
                />
              )}
              {loading ? (
                <button className={`primary-btn-small record-btn mt-3`}>
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                </button>
              ) : (
                <div
                  style={{paddingTop: '55px', paddingBottom: '10px'}}
                  className={`d-flex ${current === 0 ? 'justify-content-end' : 'justify-content-between'}`}
                >
                  {current === 0 ? (
                    <button type="button" className={`primary-btn-small record-btn mt-3`} onClick={formik.handleSubmit}>
                      Next
                    </button>
                  ) : (
                    <>
                      <button
                        type="button"
                        className={`ms-3 secondary-btn-small record-btn mt-3 `}
                        onClick={() => setCurrent(0)}
                      >
                        Back
                      </button>
                      <button
                        type="button"
                        className={`primary-btn-small record-btn mt-3 `}
                        onClick={formik.handleSubmit}
                      >
                        Save
                      </button>
                    </>
                  )}
                </div>
              )}
            </>
          );
        }}
      </Formik>
    </CustomModal>
  );
};

export default CreateProviderModal;
