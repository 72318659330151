import React, {useEffect, useState} from 'react';

import TableWraper from 'components/common/table-wrapper';
import CustomModal from 'components/common/modal';
import Input from 'components/common/input';
import EntityModal from './modals/payroll-group-entity';

import {Formik, ErrorMessage} from 'formik';
import {ArrowsDownUp, PencilSimple} from 'phosphor-react';
import {
  getPayrollGroupsList,
  createPayrollGroup,
  updatePayrollGroups,
  getEntitesList,
  deletePayrollGroupEntity,
} from 'containers/backend-tables/api';
import {payrollGroupValidationSchema as validationSchema} from 'constants/backend-tables';
import {getNameInitials, DEBOUNCE_DELAY, getActionButtonProps, isAdministrator} from 'utils/common';
import {initialMetaForTable, orderStratergy} from 'constants/common';
import ToggleButton from 'components/common/togglebutton';
import SelectComponent from 'components/common/select';
import {getPayrollGroupInitialValues as getInitialValues} from 'utils/backend-tables';
import {filter} from 'lodash';
import debounce from 'debounce-promise';
import AsyncSelectComponent from 'components/common/async-select';
let timeout;

const PayrollGroups = () => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [facilityTypeList, setFacilityTypeList] = useState([]);
  const [isModalVisible, setModalVisibility] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState(initialMetaForTable);
  const [entities, setEntities] = useState([]);
  const [isEntitesVisible, setEntitiesVisible] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [multiSelectPayload, setMultiselectPayload] = useState([]);
  const [dropdownLoading, setDropdownLoading] = useState({
    entities: false,
  });

  async function fetchEntites() {
    try {
      const result = await getEntitesList('', 'active', meta.sort, meta.order);
      if (result?.entities) {
        setEntities(result.entities.map(item => ({value: item.id, label: item.name})));
      }
    } catch (e) {
      console.log(e);
    }
  }
  const fetchEntitiessData = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, entities: true});
    try {
      const result = await getEntitesList(search, 'active');
      if (result['entities']) {
        const data = result?.entities.map(item => ({value: item.id, label: item.name}));
        setEntities(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, entities: false});
    }
  }, DEBOUNCE_DELAY);
  const loadEntitiesDropdownOptions = search => fetchEntitiessData(search);
  async function fetchData() {
    setLoading(true);
    try {
      const result = await getPayrollGroupsList(meta);
      if (result?.payroll_groups) {
        setFacilityTypeList(result.payroll_groups);
        setTotalCount(result?.meta.total_count);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchEntites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalVisible]);
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage]);

  const handleRefreshPage = () => {
    setRefreshPage(pre => !pre);
  };
  const handleSetPerPage = obj => {
    setMeta(pre => ({...pre, perPage: obj.value}));
    handleRefreshPage();
  };
  const debounceFn = (callback, delay) => {
    clearTimeout(timeout);
    timeout = setTimeout(callback, delay);
  };
  const handleSetSearchQuery = value => {
    setMeta(pre => ({...pre, search: value}));
    debounceFn(handleRefreshPage, DEBOUNCE_DELAY);
  };
  const handlePageChange = value => {
    setMeta(pre => ({...pre, page: value}));
    handleRefreshPage();
  };
  const handleClickOnCreate = () => {
    handleOpenModal();
  };
  const handleCloseModal = () => {
    setModalVisibility(false);
    setSelectedRow(null);
    setMultiselectPayload(null);
  };
  const handleOpenModal = () => {
    setModalVisibility(true);
  };
  const handleCreatePayrollGroup = async (data, setErrors) => {
    try {
      const result = await createPayrollGroup(data, setErrors);
      if (result?.meta?.status === 200) {
        handleCloseModal();
        handleRefreshPage();
      }
    } catch (e) {
      console.log(e);
    }
  };
  const handleUpdatePayrollGroup = async (data, setErrors) => {
    try {
      const result = await updatePayrollGroups(data, setErrors);
      if (result?.meta?.status === 200) {
        handleCloseModal();
        handleRefreshPage();
      }
    } catch (e) {
      console.log(e);
    }
  };
  const handleHideEntityModal = () => {
    setEntitiesVisible(false);
  };
  const handleShowEntityModal = () => {
    setEntitiesVisible(true);
  };

  const handleDeleteEntity = async id => {
    const result = await deletePayrollGroupEntity(id);
    if (result?.status === 200) {
      handleHideEntityModal();
      handleRefreshPage();
    }
  };
  const _onChange = (newTags, selectedValue, name, setFieldValue) => {
    // split on whatever your delimiter is
    const newTagsArray = newTags;

    // is this a tag addition or deletion? find which array is longer to find out
    const isDeletion = newTagsArray.length < selectedValue.length;

    // note brackets are just es6 array destructuring
    const [alteredValue] = isDeletion
      ? // if deletion
        selectedValue.filter(tag => !newTagsArray.includes(tag))
      : // if addition
        newTagsArray.filter(tag => !selectedValue.includes(tag));
    if (isDeletion) {
      if (alteredValue?.id) {
        setMultiselectPayload(array => [
          ...array.map(item => {
            if (alteredValue.value === item?.value) {
              return {...item, _destroy: true};
            }
            return item;
          }),
        ]);
      } else {
        setMultiselectPayload(pre => [
          ...filter(pre, function (e) {
            return e.value !== alteredValue.value;
          }),
        ]);
      }
    } else {
      multiSelectPayload
        ? setMultiselectPayload([...multiSelectPayload, alteredValue])
        : setMultiselectPayload([alteredValue]);
    }
    setFieldValue(name, newTags);
  };
  const sortedDataByColumn = columnName => {
    if (facilityTypeList.length > 0) {
      setMeta(pre => ({...pre, sort: columnName, order: orderStratergy[pre.order]}));
      handleRefreshPage();
    }
  };
  return (
    <>
      <TableWraper
        setPerPage={handleSetPerPage}
        setSearchQuery={handleSetSearchQuery}
        searchValue={meta.search}
        totalListCount={totalCount}
        pageSize={meta.perPage}
        currentPage={meta.page}
        onPageChange={handlePageChange}
        actionButtons={isAdministrator() && getActionButtonProps('Add Payroll Group', handleClickOnCreate)}
      >
        {loading ? (
          <p style={{textAlign: 'center'}}>Loading</p>
        ) : (
          <table className="table">
            <thead>
              <tr>
                <th scope="col">
                  <div className="header-text-otr">
                    <p
                      className="table-name heading-xsb cursor-pointer c-hand"
                      onClick={() => sortedDataByColumn('name')}
                    >
                      Group Name 
                    </p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Entites Count</p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Active</p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Updated Date</p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Updated By</p>
                  </div>
                </th>
                {isAdministrator() && (
                  <th scope="col" className="action-column">
                    <div className="header-text-otr">
                      <p className="table-name heading-xsb">Actions</p>
                    </div>
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {facilityTypeList.map(item => {
                return (
                  <tr key={item.id}>
                    <th scope="row">
                      <div className="table-text-otr ">
                        <span className="table-text">{item?.name || ''}</span>
                      </div>
                    </th>
                    <td>
                      <p className="table-text">
                        <span
                          onClick={() => {
                            setSelectedEntity(item.payroll_entities);
                            handleShowEntityModal();
                          }}
                          className={` ${item?.payroll_entities?.length < 1 ? 'disable-click' : 'clickable'}`}
                        >
                          {item.payroll_entities?.length || '0'}
                        </span>
                      </p>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <p className={`table-text ${item?.active ? 'active' : 'in-active'}`} title="Title here ">
                          {item?.active ? 'true' : 'false'}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <p className="table-text">{item?.updated_at || ''}</p>
                      </div>
                    </td>
                    <td>
                      <div className="profile-otr">
                        <div className="named-avatar">{getNameInitials(item?.update_by)}</div>
                        <p className="user-name heading-xs" title={getNameInitials(item?.update_by)}>
                          {item?.update_by || ''}
                        </p>
                      </div>
                    </td>
                    {isAdministrator() && (
                      <td className="action-column">
                        <div className="table-icon-otr">
                          <div className="icon-otr">
                            <PencilSimple
                              size={24}
                              onClick={() => {
                                handleOpenModal();
                                setSelectedRow({...item});
                                setMultiselectPayload(
                                  item.payroll_entities.map(item => ({
                                    value: item?.entity.id,
                                    label: item?.entity.name,
                                    id: item.id,
                                  })),
                                );
                              }}
                            />
                          </div>
                        </div>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </TableWraper>

      {isModalVisible && (
        <CustomModal
          size="sm"
          show={isModalVisible}
          onHide={() => handleCloseModal()}
          heading={`${selectedRow === null ? 'Create' : 'Edit'} Payroll Group`}
        >
          <Formik
            initialValues={getInitialValues(selectedRow)}
            validationSchema={validationSchema}
            onSubmit={(values, {setErrors}) => {
              if (selectedRow === null) {
                const payload = {
                  payroll_group: {
                    ...values,
                    payroll_entities_attributes: values?.payroll_entities_attributes.map(item => ({
                      entity_id: item.value,
                    })),
                    status: values.status ? 'active' : 'inactive',
                  },
                };
                handleCreatePayrollGroup(payload, setErrors);
              } else {
                const payload = {
                  id: selectedRow.id,
                  payroll_group: {
                    ...values,
                    payroll_entities_attributes: multiSelectPayload.map(item => ({
                      id: item?.id ? item.id : null,
                      entity_id: item.value,
                      _destroy: item?._destroy ? item._destroy : false,
                    })),
                    status: values.status ? 'active' : 'inactive',
                  },
                };
                handleUpdatePayrollGroup(payload, setErrors);
              }
            }}
          >
            {({handleChange, values, handleSubmit, setFieldValue}) => {
              return (
                <div className="col-login-inr backend-tables-modal">
                  <form className="form-main" onSubmit={handleSubmit}>
                    <div className="wrapper">
                      <Input
                        handleChange={handleChange}
                        placeholder="Name"
                        type="text"
                        label="Name"
                        value={values.name}
                        name="name"
                        maxLength={50}
                      />
                      <ErrorMessage component="p" name="name" />
                    </div>
                    <div className="wrapper">
                      <div className=" input-otr" style={{alignItems: 'inherit'}}>
                        <label htmlFor="email" className="input-label">
                          Entites
                        </label>
                        <AsyncSelectComponent
                          isLoading={dropdownLoading.entities}
                          selectedValue={values.payroll_entities_attributes}
                          defaultOptions={entities}
                          loadOptions={loadEntitiesDropdownOptions}
                          onChange={obj => {
                            _onChange(
                              obj,
                              values.payroll_entities_attributes,
                              'payroll_entities_attributes',
                              setFieldValue,
                            );
                          }}
                          placeholder="Search and select"
                          isMulti
                        />

                        <ErrorMessage className="error-text" component="p" name="payroll_entities_attributes" />
                      </div>
                    </div>
                    <div className="wrapper">
                      <ToggleButton
                        label="Active Payroll Group"
                        handleChange={(name, value) => {
                          setFieldValue(name, value);
                        }}
                        value={values.status}
                        name="status"
                      />
                    </div>
                    <div className="action">
                      <input
                        className="primary-btn login-btn"
                        type="submit"
                        name="submit"
                        value={`${selectedRow === null ? 'Create' : 'Edit'} Payroll Group`}
                      />
                    </div>
                  </form>
                </div>
              );
            }}
          </Formik>
        </CustomModal>
      )}
      {isEntitesVisible && (
        <EntityModal close={handleHideEntityModal} entities={selectedEntity} handleDeleteEntity={handleDeleteEntity} />
      )}
    </>
  );
};

export default PayrollGroups;
