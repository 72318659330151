import Input from 'components/common/input';
import {ErrorMessage} from 'formik';
import {Col} from 'react-bootstrap';

const ZipCodeField = ({column = 4, name, value, handleChange, isDisabled = false, ...rest}) => {
  return (
    <Col md={column}>
      <Input
        value={value}
        label={'Zip Code *'}
        placeholder={'e.g 10011'}
        isDisabled={isDisabled}
        handleChange={handleChange}
        name={name}
        type="number"
        {...rest}
      />
      <ErrorMessage className="error-text" component="p" name={name} />
    </Col>
  );
};

export default ZipCodeField;
