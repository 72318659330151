import React, {useCallback, useEffect, useState} from 'react';

import Form from '../forms/EditPayrollInformation';
import debounce from 'debounce-promise';

import {ErrorMessage, Formik} from 'formik';
import {
  payrollInitialValues,
  payrollInformationValidationSchema,
  paymentEntityValidationSchema,
} from 'constants/providers';
import {useLocation, useParams, useNavigate} from 'react-router-dom';
import {
  getUsersByFilter,
  getProviderCategoies,
  updatePayrollInformation,
  getPayMethodList,
  createPaymentEntity,
  getPaymentEntitiesList,
  deletePaymentEntity,
} from 'containers/providers/api';
import {Col, Row} from 'react-bootstrap';
import {getEntitesList} from 'containers/backend-tables/api';
import SelectComponent from 'components/common/select';
import Input from 'components/common/input';
import PaymentEntitiesListing from 'components/providers/tabs/PaymentEntitiesListing';
import {initialMetaForTable} from 'constants/common';
import {debounce as lodashDebounce, isEmpty, toLength} from 'lodash';
import {appendObjectOnEditDropdown, DEBOUNCE_DELAY, isAdministrator} from 'utils/common';
import AsyncSelectComponent from 'components/common/async-select';
import moment from 'moment';

const PayrollInformation = ({provider}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {id} = useParams();
  const [categories, setCategories] = useState([]);
  const [users, setUsers] = useState([]);
  const [payMethodList, setPayMethodList] = useState([]);
  const [payrollInformation, setPayrollInformation] = useState({});
  const [initialValues, setInitialValues] = useState(payrollInitialValues);
  const [entities, setEntities] = useState([]);
  const [paymentEntitiesList, setPaymentEntitiesList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [meta, setMeta] = useState(initialMetaForTable);
  const [totalCount, setTotalCount] = useState(0);
  const [dropdownLoading, setDropdownLoading] = useState({
    prviderCategory: false,
    payMethod: false,
    users: false,
    entity: false,
  });

  async function fetchCategories() {
    const result = await getProviderCategoies('active');
    if (result['provider_categories']) {
      setCategories(result['provider_categories'].map(item => ({value: item.id, label: item.name})));
    }
  }
  async function fetchUsers() {
    const result = await getUsersByFilter('biller', 'active');
    if (result['users']) {
      setUsers(result['users'].map(item => ({value: item.id, label: item.full_name})));
    }
  }
  async function fetchPayMethodList() {
    const result = await getPayMethodList('active');
    if (result['pay_methods']) {
      setPayMethodList(result['pay_methods'].map(item => ({value: item.id, label: item.name})));
    }
  }
  async function fetchEntites() {
    const result = await getEntitesList('', 'active');
    if (result['entities']) {
      setEntities(result['entities'].map(item => ({value: item.id, label: item.name})));
    }
  }

  const fetchPaymentEntitiesList = useCallback(async () => {
    const result = await getPaymentEntitiesList(id, meta.page, meta.perPage, meta.search);
    if (result['payment_entities']) {
      setPaymentEntitiesList(result['payment_entities']);
      setTotalCount(result?.meta.total_count);
      setLoading(false);
    }
  }, [id, meta.page, meta.perPage, meta.search]);

  const handleSetPerPage = obj => {
    setMeta(pre => ({...pre, perPage: obj.value}));
    setLoading(true);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(
    lodashDebounce(() => {
      setLoading(true);
    }, DEBOUNCE_DELAY),
    [meta.search],
  );
  const handleSetSearchQuery = value => {
    setMeta(pre => ({...pre, search: value}));
    debounceFn();
  };
  const handlePageChange = value => {
    setMeta(pre => ({...pre, page: value}));
    setLoading(true);
  };

  useEffect(() => {
    if (id && !isEmpty(provider) && !isEmpty(provider?.payroll_information)) {
      setInitialValues({
        provider_category: provider?.payroll_information?.provider_category_id,
        provider_category_label: provider.payroll_information.provider_category_label,
        base_salary: provider.payroll_information.base_salary,
        weekly_rvu_commitment: provider.payroll_information.weekly_rvu_commitment,
        min_units_per_day: provider.payroll_information.min_units_per_day,
        unit_rate: provider.payroll_information.unit_rate,
        paid_time_off_per_year: provider.payroll_information.paid_time_off_per_year,
        paid_time_off_balance: provider.payroll_information.paid_time_off_balance,
        first_dos: provider.payroll_information.first_dos,
        last_dos: provider.payroll_information.last_dos,
        pay_roll_end_date: provider.payroll_information.pay_roll_end_date,
        pay_method: provider.payroll_information.pay_method_id,
        user: provider.payroll_information.user_id,
        provider: provider.payroll_information.provider_id,
        pto_required: provider.payroll_information.pto_required,
        units_per_day: provider?.payroll_information?.units_per_day,
        days_per_year: provider?.payroll_information?.days_per_year,
        total_units_per_year: provider?.payroll_information?.total_units_per_year,
        units_per_pay_period: provider?.payroll_information?.units_per_pay_period,
        pto_balance: provider?.payroll_information?.pto_balance,
      });
      setPayrollInformation(provider.payroll_information);
      setCategories(
        appendObjectOnEditDropdown(categories, {
          value: provider.payroll_information.provider_category_id,
          label: provider.payroll_information.provider_category_label,
        }),
      );
      setPayMethodList(
        appendObjectOnEditDropdown(payMethodList, {
          value: provider.payroll_information.pay_method_id,
          label: provider.payroll_information?.pay_method_label || '',
        }),
      );
      setUsers(
        appendObjectOnEditDropdown(users, {
          value: provider.payroll_information.user_id,
          label: provider.payroll_information?.user_label || '',
        }),
      );
    }
  }, [id, provider]);

  useEffect(() => {
    fetchCategories();
    fetchUsers();
    fetchPayMethodList();
    fetchEntites();
  }, []);

  useEffect(() => {
    if (loading) {
      fetchPaymentEntitiesList();
    }
  }, [fetchPaymentEntitiesList, loading]);

  const fetchProviderCategory = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, prviderCategory: true});
    try {
      const result = await getProviderCategoies('active', search);
      if (result['provider_categories']) {
        const data = result?.provider_categories.map(item => ({value: item.id, label: item.name}));
        setCategories(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, prviderCategory: false});
    }
  }, DEBOUNCE_DELAY);
  const fetchPayMethod = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, payMethod: true});
    try {
      const result = await getPayMethodList('active', search);
      if (result['pay_methods']) {
        const data = result?.pay_methods.map(item => ({value: item.id, label: item.name}));
        setPayMethodList(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, payMethod: false});
    }
  }, DEBOUNCE_DELAY);
  const fetchUsersData = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, users: true});
    try {
      const result = await getUsersByFilter('director', 'active', search);
      if (result['users']) {
        const data = result?.users.map(item => ({value: item.id, label: item.full_name}));
        setUsers(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, users: false});
    }
  }, DEBOUNCE_DELAY);
  const fetchEntityData = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, entity: true});
    try {
      const result = await getEntitesList(search, 'active');
      if (result['entities']) {
        const data = result?.entities.map(item => ({value: item.id, label: item.name}));
        setEntities(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, entity: false});
    }
  }, DEBOUNCE_DELAY);

  const loadProviderCategoryDropdownOptions = search => fetchProviderCategory(search);
  const loadPayMethodDropdownOptions = search => fetchPayMethod(search);
  const loadUsersDropdownOptions = search => fetchUsersData(search);
  const loadEntityDropdownOptions = search => fetchEntityData(search);

  const handleUpdatePayrollInformation = async (values, setErrors) => {
    let data = {
      first_dos: moment(values.first_dos).format('YYYY-MM-DD'),
      pay_roll_end_date: moment(values.pay_roll_end_date).format('YYYY-MM-DD'),
      last_dos: moment(values.last_dos).format('YYYY-MM-DD'),
      provider_category_id: values.provider_category,
      pay_method_id: values.pay_method,
      user_id: values.user,
      provider_id: values.provider,
      pto_required: values.pto_required,
      weekly_rvu_commitment: values.weekly_rvu_commitment,
    };
    if (values.pto_required) {
      data = {
        ...data,
        units_per_day: values?.units_per_day,
        days_per_year: values?.days_per_year,
        total_units_per_year: values?.total_units_per_year,
        units_per_pay_period: values?.units_per_pay_period,
        pto_balance: values?.pto_balance,
      };
    }
    if (values.provider_category_label.toLowerCase().includes('salary')) {
      data.base_salary = values.base_salary;
      data.min_units_per_day = values.min_units_per_day;
      data.unit_rate = values.unit_rate;
      data.paid_time_off_per_year = values.paid_time_off_per_year;
      data.paid_time_off_balance = values.paid_time_off_balance;
    }

    const result = await updatePayrollInformation({id: payrollInformation.id, payroll_information: data}, setErrors);
    if (result?.meta?.status === 200) {
      window.location = location.pathname + location.search;
    }
  };

  const handleCreatePaymentEntity = async (values, resetForm) => {
    const payload = {
      ...values,
      provider_id: id,
    };
    const result = await createPaymentEntity(payload);
    if (result?.meta?.status === 200) {
      setLoading(true);
      resetForm();
    }
  };

  const handleDeletePaymentEntity = async selectedPaymentEntityId => {
    const result = await deletePaymentEntity(selectedPaymentEntityId);

    if (result?.status === 200) {
      setLoading(true);
    }
  };

  return (
    <div className="user-main">
      <div className="card-wrapper">
        <div className="table-head-content">
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={payrollInformationValidationSchema}
            onSubmit={(values, {setErrors}) => {
              handleUpdatePayrollInformation(values, setErrors);
            }}
          >
            {formik => {
              return (
                <Form
                  formik={formik}
                  users={users}
                  categories={categories}
                  payMethods={payMethodList}
                  dropdownLoading={dropdownLoading}
                  loadUsersDropdownOptions={loadUsersDropdownOptions}
                  loadPayMethodDropdownOptions={loadPayMethodDropdownOptions}
                  loadProviderCategoryDropdownOptions={loadProviderCategoryDropdownOptions}
                />
              );
            }}
          </Formik>
        </div>
      </div>
      {isAdministrator() && (
        <div className="card-wrapper my-4 ">
          <div className="heading-path-otr">
            <h6 className="heading heading-h6 px-4">Add Payment Entity</h6>
          </div>
          <Formik
            initialValues={{
              entity_id: '',
              adp_number: '',
            }}
            enableReinitialize={true}
            validationSchema={paymentEntityValidationSchema}
            onSubmit={(values, {resetForm}) => handleCreatePaymentEntity(values, resetForm)}
          >
            {formik => {
              return (
                <form onSubmit={formik.handleSubmit} className={'w-100 px-4'}>
                  <Row>
                    <Col md={8}>
                      <div className=" input-otr" style={{alignItems: 'inherit'}}>
                        <label htmlFor="email" className="input-label">
                          Entity Name
                        </label>

                        <AsyncSelectComponent
                          isLoading={dropdownLoading.entity}
                          selectedValue={formik.values.entity_id}
                          defaultOptions={entities}
                          loadOptions={loadEntityDropdownOptions}
                          onChange={obj => {
                            formik.setFieldValue('entity_id', obj.value);
                          }}
                          placeholder="Search and select"
                        />

                        <ErrorMessage className="error-text" component="p" name="entity_id" />
                      </div>
                    </Col>
                    <Col md={4}>
                      <Row>
                        <Col md={8}>
                          <Input
                            handleChange={formik.handleChange}
                            name={'adp_number'}
                            value={formik.values.adp_number}
                            placeholder={'e.g 10'}
                            label={'ADP File Number'}
                            type="text"
                          />
                          <ErrorMessage className="error-text" component="p" name="adp_number" />
                        </Col>
                        <Col md={4} className="d-flex  justify-content-end">
                          <button
                            type="submit"
                            className="primary-btn-small h-50 record-btn"
                            style={{marginTop: '41px'}}
                          >
                            Add Entity
                          </button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </form>
              );
            }}
          </Formik>
          <PaymentEntitiesListing
            meta={meta}
            loading={loading}
            totalCount={totalCount}
            paymentEntitiesList={paymentEntitiesList}
            handleSetPerPage={handleSetPerPage}
            handlePageChange={handlePageChange}
            handleSetSearchQuery={handleSetSearchQuery}
            handleDeletePaymentEntity={handleDeletePaymentEntity}
          />
        </div>
      )}
    </div>
  );
};

export default PayrollInformation;
