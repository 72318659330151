import React from 'react';
import EmailFilter from 'components/mail/email-queue/email-filter';

import FilterWrapper from 'components/common/filter-wrapper';
import BreadcrumbTrail from 'components/common/breadcrumb-trail';
import 'components/facilities/facilities.scss';
import EmailQueueListing from 'components/mail/email-queue/email-queue-listing';

const EmailQueue = () => {
  return (
    <>
      <div className="providers-main">
        <div className="container-fluid">
          <div className="heading-path-otr">
            <h3 className="heading heading-h3">Email Queue</h3>
            <BreadcrumbTrail />
          </div>
          <FilterWrapper>
            <EmailFilter />
          </FilterWrapper>
          <EmailQueueListing />
        </div>
      </div>
    </>
  );
};

export default EmailQueue;
