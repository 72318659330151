import * as yup from 'yup';

const createFacilityInitialValues = {
  status: 1,
  name: '',
  npi: '',
  ads_name: '',
  facility_id: '',
  regional_managers_attributes: [],
  entity_id: '',
  document_delivery_type: '',
  email: '',
  fax: '',
  phone_no: '',

  state: '',
  zip_code: '',
  street: '',
  city: '',
  county: '',
  country: 'US',
  facility_type_id: '',
  number_of_bed: '',
  patient_estimate: '',
  visit_estimate: '',
  start_date: '', // moment(new Date()).format('YYYY-MM-DD')
  end_date: '', //moment(new Date()).format('YYYY-MM-DD'),
};

const FacilityValidationSchema = yup.object().shape({
  status: yup.string(),
  name: yup.string().required('Required *'),
  ads_name: yup.string().required('Required *'),
  npi: yup.number(),
  // .test('len', 'Must be exactly 10 characters *', val => val?.toString()?.length === 10),
  facility_id: yup
    .string()
    .required('Required *')
    .matches(/^[a-zA-Z0-9]+$/, 'Only alphabets and numbers are allowed '),
  entity_id: yup.string().required('Required *'),
  document_delivery_type: yup.string().required('Required *'),
  email: yup.string().email('Invalid email format').required('Required *'),
  fax: yup.string().matches(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, 'Fax number is not valid'),

  phone_no: yup
    .string()
    .required('Required *')
    .matches(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, 'Phone number is not valid *'),
  street: yup.string().required('Required *'),
  city: yup.string().required('Required *'),
  county: yup.string().required('Required *'),
  country: yup.string().required('Required *'),
  state: yup.string().required('Required *'),
  facility_type_id: yup.string().required('Required *'),
  number_of_bed: yup.string().max(3, 'Must be 3 characters *').required('Required *'),
  patient_estimate: yup.string().max(3, 'Must be 3 characters *').required('Required *'),
  visit_estimate: yup.string().max(3, 'Must be 3 characters *').required('Required *'),
  zip_code: yup
    .number()
    .required('Required *')
    .test('len', 'Must be exactly 5 characters *', val => val?.toString()?.length === 5),
});

const documentDeliveryTypeOptions = [
  {value: 'Email', label: 'Email'},
  {value: 'Fax', label: 'Fax'},
];

export {createFacilityInitialValues, FacilityValidationSchema, documentDeliveryTypeOptions};
