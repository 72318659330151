import CustomToast from 'components/common/custom-toast';
import {alertTypes} from 'constants/common';
import {toast} from 'react-toastify';
import {get, put, post, deleteApi, getBlob} from 'services/network';
import {
  CHECK_PROVIDERS_ENDPOINT,
  ENTITIES_ENDPOINT,
  PAYMENT_ENTITY_ENDPOINT,
  PAYROLL_INFORMATION_ENDPOINT,
  PAY_METHOD_LIST,
  PROVIDERS_ENDPOINT,
  PROVIDER_CATEGORY_ENDPOINT,
  PROVIDER_DATA_ENDPOINT,
  PROVIDER_DATA_UC_ENDPOINT,
  PROVIDER_TITLES_ENDPOINT,
  USERS_ENDPOINT,
  PROVIDER_RATES_ENDPOINT,
  PROVIDERS_ENDPOINT_BLOB,
} from 'constants/endpoints';
import {isArray} from 'lodash';
import moment from 'moment';

export const getProvidersList = async obj => {
  const params = {
    sort: obj?.sort ? obj.sort : '',
    order: obj?.order ? obj.order : 'asc',
    page: obj?.page ? obj.page : 1,
    perPage: obj?.perPage ? obj.perPage : 10,
    search: obj?.search ? obj.search : '',
    status: obj?.status ? (isArray(obj.status) ? obj.status.map(item => item.value) : obj.status) : '',
    users: obj?.users ? (isArray(obj.users) ? obj.users.map(item => item.value) : obj.users) : '',
    states: obj?.states ? (isArray(obj.states) ? obj.states.map(item => item.value) : obj.states) : '',
    entity_id: obj?.entity_id ? (isArray(obj.entity_id) ? obj.entity_id.map(item => item.value) : obj.entity_id) : '',
    facility_id: obj?.facility_id ? obj.facility_id : '',
    provider_id: obj?.provider_id ? obj.provider_id : '',
  };
  try {
    const result = await get(
      `${PROVIDERS_ENDPOINT}?adp_number=false&page=${params.page}&sort=${params.sort}&order=${params.order}&per=${
        params.perPage
      }&query=${params.search}&filter[status]=${params.status}${
        params.states ? `&filter[state]=${params.states}` : ''
      }${params.entity_id ? `&filter[entity_id]=${params.entity_id}` : ''}${
        params.provider_id ? `&filter[provider_id]=${params.provider_id}` : ''
      }${params.facility_id ? `&filter[facility_id]=${params.facility_id}` : ''}${
        params.users ? `&filter[type]=${params.users}` : ''
      }`,
      true,
    );
    return result;
  } catch (e) {
    console.log(e);
  }
};
export const getProvidersListBlob = async obj => {
  const params = {
    page: obj?.page ? obj.page : 1,
    perPage: obj?.perPage ? obj.perPage : 10,
    search: obj?.search ? obj.search : '',
    status: obj?.status ? (isArray(obj.status) ? obj.status.map(item => item.value) : obj.status) : '',
    users: obj?.users ? (isArray(obj.users) ? obj.users.map(item => item.value) : obj.users) : '',
    states: obj?.states ? (isArray(obj.states) ? obj.states.map(item => item.value) : obj.states) : '',
    entity_id: obj?.entity_id ? (isArray(obj.entity_id) ? obj.entity_id.map(item => item.value) : obj.entity_id) : '',
    facility_id: obj?.facility_id ? obj.facility_id : '',
    provider_id: obj?.provider_id ? obj.provider_id : '',
  };
  try {
    const result = await get(
      `${PROVIDERS_ENDPOINT_BLOB}?page=${params.page}&per=${params.perPage}&query=${params.search}&filter[status]=${
        params.status
      }${params.states ? `&filter[state]=${params.states}` : ''}${
        params.entity_id ? `&filter[entity_id]=${params.entity_id}` : ''
      }${params.provider_id ? `&filter[provider_id]=${params.provider_id}` : ''}${
        params.facility_id ? `&filter[facility_id]=${params.facility_id}` : ''
      }${params.users ? `&filter[type]=${params.users}` : ''}`,
      true,
    );
    return result;
  } catch (e) {
    console.log(e);
  }
};
export const getEntitiesList = async (status = '', search = '') => {
  try {
    const result = await get(`${ENTITIES_ENDPOINT}?filter[status]=${status}&query=${search}`, true);
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const getProviderIndex = async (id, obj) => {
  const params = {
    page: obj?.page ? obj.page : 1,
    perPage: obj?.perPage ? obj.perPage : 10,
    search: obj?.search ? obj.search : '',
  };
  try {
    const result = await get(
      `${PROVIDER_RATES_ENDPOINT}?filter[provider_id]=${id}&page=${params.page}&per=${params.perPage}&query=${params.search}`,
      true,
    );
    return result;
  } catch (e) {
    console.log(e);
  }
};
export const getEditProvider = async (id, obj) => {
  const params = {
    page: obj?.page ? obj.page : 1,
    perPage: obj?.perPage ? obj.perPage : 10,
  };
  try {
    const result = await get(`${PROVIDERS_ENDPOINT}/${id}`, true);
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const getProviderTitle = async (status = '', query = '') => {
  try {
    const result = await get(`${PROVIDER_TITLES_ENDPOINT}?filter[status]=${status}&query=${query}`, true);
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const updateProvider = async (data, setErrors) => {
  const id = data.id;
  delete data.id;
  try {
    const result = await put(PROVIDERS_ENDPOINT + `/${id}`, data, true);
    toast(<CustomToast variant={alertTypes.SUCCESS} message="Provider updated successfully!" />);
    return result;
  } catch (error) {
    if (error?.response?.data) {
      setErrors(error?.response?.data.errors);
    }
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={error?.response?.data?.message || error?.response?.statusText}
      />,
    );
    console.log('error');
  }
};

export const getProviderCategoies = async (status = '', query = '') => {
  try {
    const result = await get(`${PROVIDER_CATEGORY_ENDPOINT}?filter[status]=${status}&query=${query}`, true);
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const getUsersByFilter = async (filter, status = null, query = '') => {
  try {
    const result = await get(
      `${USERS_ENDPOINT}?filter[role_type]=${filter}${status !== null ? `&filter[status]=${status}` : ''}${
        query ? `&query=${query}` : ''
      }`,
      true,
    );
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const getPayrollInformation = async id => {
  try {
    const result = await get(`${PAYROLL_INFORMATION_ENDPOINT}/${id}`, true);
    return result;
  } catch (e) {
    console.log(e);
  }
};
export const updatePayrollInformation = async (data, setErrors) => {
  const id = data.id;
  delete data.id;
  try {
    const result = await put(PAYROLL_INFORMATION_ENDPOINT + `/${id}`, data, true);
    toast(<CustomToast variant={alertTypes.SUCCESS} message="Payroll Information updated successfully!" />);
    return result;
  } catch (error) {
    console.log('errror is ', error);
    if (error?.response?.data) {
      setErrors(error?.response?.data.errors);
    }
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={error?.response?.data?.message || error?.response?.statusText}
      />,
    );
    console.log('error');
  }
};
export const getPayMethodList = async (status = '', query = '') => {
  try {
    const result = await get(`${PAY_METHOD_LIST}?filter[status]=${status}&query=${query}`, true);
    return result;
  } catch (e) {
    console.log(e);
  }
};
export const createProviderAndPayrollInformation = async (data, setErrors) => {
  try {
    const result = await post(PROVIDERS_ENDPOINT, data, true);
    toast(<CustomToast variant={alertTypes.SUCCESS} message="Provider created successfully!" />);
    return result;
  } catch (error) {
    if (error?.response?.data) {
      setErrors(error?.response?.data.errors);
    }
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={error?.response?.data?.message || error?.response?.statusText}
      />,
    );
    console.log('error');
  }
};
export const checkProviderInformation = async (data, setErrors) => {
  try {
    const result = await post(CHECK_PROVIDERS_ENDPOINT, data, true);
    // toast(<CustomToast variant={alertTypes.SUCCESS} message="Provider created successfully!" />);
    return result;
  } catch (error) {
    if (error?.response?.data) {
      setErrors(error?.response?.data.errors);
    }
    // toast(<CustomToast variant={alertTypes.DANGER} message={error?.response?.data?.message || error?.response?.statusText} />);
    console.log('error');
  }
};
export const createPaymentEntity = async data => {
  try {
    const result = await post(PAYMENT_ENTITY_ENDPOINT, data, true);
    toast(<CustomToast variant={alertTypes.SUCCESS} message="Payment entity created successfully!" />);
    return result;
  } catch (error) {
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={error?.response?.data?.message || error?.response?.statusText}
      />,
    );
    console.log('error', error);
  }
};

export const deletePaymentEntity = async id => {
  try {
    const result = await deleteApi(`${PAYMENT_ENTITY_ENDPOINT}/${id}`, true);
    toast(<CustomToast variant={alertTypes.SUCCESS} message="Payment entity deleted successfully!" />);
    return result;
  } catch (error) {
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={error?.response?.data?.message || error?.response?.statusText}
      />,
    );
    console.log('error');
  }
};

export const getPaymentEntitiesList = async (id, page, perPage, search) => {
  try {
    const result = await get(
      `${PROVIDERS_ENDPOINT}/${id}/payment_entities?per=${perPage}&page=${page}&query=${search}`,
      true,
    );
    return result;
  } catch (e) {
    console.log(e);
  }
};
export const getProviderData = async params => {
  try {
    const result = await get(
      `${PROVIDERS_ENDPOINT}/${params.id}/provider_data?facility_id=${params.facility_id}&start_date=${moment(
        params.start_date,
      ).format('YYYY-MM-DD')}&end_date=${moment(params.end_date).format('YYYY-MM-DD')}`,
      true,
    );
    return result;
  } catch (e) {
    toast(<CustomToast variant={alertTypes.DANGER} message={e?.message} />);
    console.log(e);
  }
};
export const getProviderAdjustmentHistory = async params => {
  try {
    const result = await get(
      `${PROVIDERS_ENDPOINT}/${params.id}/adjustment_history?page=${params?.page || 1}&per=${
        params?.perPage || 10
      }&query=${params?.search || ''}&start_date=${moment(params.start_date).format('YYYY-MM-DD')}&end_date=${moment(
        params.end_date,
      ).format('YYYY-MM-DD')}${
        params.paid_type && params.paid_type !== 'all' ? '&filter[paid_type]=' + params.paid_type : ''
      }`,
      true,
    );
    return result;
  } catch (e) {
    toast(<CustomToast variant={alertTypes.DANGER} message={e?.message} />);
    console.log(e);
  }
};

export const createProviderData = async data => {
  try {
    const submittedData = {
      ...data,
      effective_date: moment(data.effective_date).format('YYYY-MM-DD'),
      service_date: moment(data.service_date).format('YYYY-MM-DD'),
    };
    const result = await put(PROVIDER_DATA_UC_ENDPOINT, submittedData, true);
    toast(<CustomToast variant={alertTypes.SUCCESS} message="Provider data created successfully!" />);
    return result;
  } catch (error) {
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={error?.response?.data?.message || error?.response?.statusText}
      />,
    );
    console.log('error');
  }
};

export const updateProviderData = async data => {
  const id = data.id;
  delete data.id;
  try {
    const result = await put(PROVIDER_DATA_UC_ENDPOINT + `/${id}`, data, true);
    toast(<CustomToast variant={alertTypes.SUCCESS} message="Provider data updated successfully!" />);
    return result;
  } catch (error) {
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={error?.response?.data?.message || error?.response?.statusText}
      />,
    );
    console.log('error');
  }
};
