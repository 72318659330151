import React, {useEffect, useState, useCallback} from 'react';
import TableWraper from 'components/common/table-wrapper';
import debounce from 'lodash/debounce';

import {getActionButtonProps, getNameInitials, getPaginatedItems} from 'utils/common';
import {DEBOUNCE_DELAY} from 'utils/common';
import {initialMetaForTable} from 'constants/common';

const ProviderRateHistoryListing = ({records, refreshByParent, setRefreshByParent}) => {
  const [allListSelected, setAllListSelected] = useState(false);
  const [providerRatesList, setProviderRatesList] = useState([]);
  const [refreshPage, setRefreshPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState(initialMetaForTable);
  const [totalCount, setTotalCount] = useState(records.length);

  function fetchData() {
    const result = getPaginatedItems(records, meta.page, meta.perPage)?.data?.map((item, index) => ({
      ...item,
      isSelected: allListSelected,
      id: index,
    }));
    setProviderRatesList(result);
    setLoading(false);
  }
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setLoading(true);
    fetchData();
  }, [refreshPage, records]);
  const handleRefreshPage = () => {
    setRefreshPage(pre => !pre);
  };
  const handleSetPerPage = obj => {
    setMeta(pre => ({...pre, perPage: obj.value}));
    handleRefreshPage();
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(debounce(handleRefreshPage, DEBOUNCE_DELAY), [meta.search]);
  const handleSetSearchQuery = value => {
    setMeta(pre => ({...pre, search: value}));
    debounceFn();
  };
  const handlePageChange = value => {
    setMeta(pre => ({...pre, page: value}));
    handleRefreshPage();
    handleRefreshPage();
  };

  const setAllListCheck = value => {
    setProviderRatesList(pre => pre.map(item => ({...item, isSelected: value})));
  };
  const setSingleItemCheck = (value, id) => {
    setProviderRatesList(pre => pre.map(item => (item.id === id ? {...item, isSelected: value} : item)));
  };

  return (
    <>
      <TableWraper
        setPerPage={handleSetPerPage}
        setSearchQuery={handleSetSearchQuery}
        searchValue={meta.search}
        totalListCount={totalCount}
        pageSize={meta.perPage}
        currentPage={meta.page}
        onPageChange={handlePageChange}
        actionButtons={getActionButtonProps('Export', () => {})}
      >
        {loading ? (
          <h3 style={{textAlign: 'center'}}>Loading</h3>
        ) : (
          <table className="table provider-rate">
            <thead>
              <tr>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">
                      <input
                        className="form-check-input"
                        onChange={e => {
                          setAllListSelected(e.target.checked);
                          setAllListCheck(e.target.checked);
                        }}
                        type="checkbox"
                        checked={allListSelected}
                      />
                    </p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Effective Date</p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Rate</p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Multiplier</p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Updated Date</p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Updated by</p>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {providerRatesList.map(item => {
                return (
                  <tr key={item.id}>
                    <th scope="col">
                      <div className="header-text-otr">
                        <p className="table-name heading-xsb">
                          <input
                            className="form-check-input"
                            value={item.isSelected}
                            onChange={e => {
                              setSingleItemCheck(e.target.checked, item.id);
                            }}
                            type="checkbox"
                            checked={item.isSelected}
                          />
                        </p>
                      </div>
                    </th>
                    <td>
                      <div className="">
                        <p className="table-text ">{item?.effective_date || ''}</p>
                      </div>
                    </td>
                    <td>
                      <div className="">
                        <p className="table-text ">{item?.initial_unit_charge_amount || ''}</p>
                      </div>
                    </td>

                    <td>
                      <div className="table-text-otr">
                        <p className="table-text ">{item?.unit_multiplier || ''}</p>
                      </div>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <p className="table-text ">{item?.updated_date || ''}</p>
                      </div>
                    </td>
                    <td>
                      <div className="profile-otr">
                        {item?.updated_by && <div className="named-avatar">{getNameInitials(item?.updated_by)}</div>}
                        <p className="table-text" title={getNameInitials(item?.updated_by)}>
                          {item?.updated_by || ''}
                        </p>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </TableWraper>
    </>
  );
};

export default ProviderRateHistoryListing;
