import React, {useEffect, useState} from 'react';
import SelectComponent from 'components/common/select';
import {Row, Col} from 'react-bootstrap';
import {PAID_TYPES} from 'constants/common';
import {getProvidersList} from 'containers/providers/api';
import {getEarningCodes} from 'containers/earning-codes/api';

import Input from 'components/common/input';
import debounce from 'debounce-promise';
import {DEBOUNCE_DELAY} from 'utils/common';
import AsyncSelectComponent from 'components/common/async-select';
import CustomDatePicker from 'components/common/custom-datepicker';
import moment from 'moment';

const AdjustmentsFilter = ({handleSubmit, filters}) => {
  const [providers, setProviders] = useState([]);
  const [filterState, setFilterState] = useState(filters);
  const [earningCodes, setEarningCodes] = useState([]);
  const [dropdownLoading, setDropdownLoading] = useState({
    providers: false,
    earningCodes: false,
  });

  async function fetchProviders() {
    const result = await getProvidersList();
    if (result['providers']) {
      setProviders(result['providers'].map(item => ({value: item.id, label: item.first_name + ' ' + item.last_name})));
    }
  }

  const fetchProvidersData = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, providers: true});
    try {
      const result = await getProvidersList({page: 1, perPage: 10, status: 'active', search});
      if (result['providers']) {
        const data = result?.providers.map(item => ({value: item.id, label: item.first_name + ' ' + item.last_name}));
        setProviders(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, providers: false});
    }
  }, DEBOUNCE_DELAY);
  async function fetchEarningCodes() {
    try {
      const result = await getEarningCodes({page: 1, perPage: 10, status: 'active'});
      if (result['earning_codes']) {
        const codes = result?.earning_codes.map(item => ({value: item.id, label: item.code}));
        setEarningCodes(codes);
      }
    } catch (e) {
      console.log(e);
    } finally {
    }
  }
  const fetchEarningCodesData = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, earningCodes: true});
    try {
      const result = await getEarningCodes({page: 1, perPage: 10, search, status: 'active'});
      if (result['earning_codes']) {
        const data = result?.earning_codes.map(item => ({value: item.id, label: item.code}));
        setEarningCodes(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, earningCodes: false});
    }
  }, DEBOUNCE_DELAY);
  useEffect(() => {
    fetchProviders();
    fetchEarningCodes();
  }, []);
  const loadProviderDropdownOptions = search => fetchProvidersData(search);
  const loadEarningCodesDropdownOptions = search => fetchEarningCodesData(search);
  return (
    <div className="adjustments-filter">
      <h5>Filters</h5>
      <Row>
        <Col sm={6} lg={4} className="mt-4">
          <p className="mb-2">Paid Status</p>
          <SelectComponent
            options={PAID_TYPES}
            selectedValue={filterState.paid_type}
            handleChange={obj => {
              setFilterState(pre => ({...pre, paid_type: obj.value}));
            }}
            placeholder="Select"
          />
        </Col>
        <Col sm={6} lg={4} className="mt-4">
          <p className="mb-2">Provider</p>
          <AsyncSelectComponent
            isLoading={dropdownLoading.providers}
            selectedValue={filterState.provider_id}
            defaultOptions={providers}
            loadOptions={loadProviderDropdownOptions}
            onChange={obj => {
              setFilterState(pre => ({...pre, provider_id: obj.value}));
            }}
            placeholder="Search and select"
          />
        </Col>
        <Col sm={12} lg={4} className="mt-4">
          <p className="mb-2">Earning Code</p>
          <AsyncSelectComponent
            isLoading={dropdownLoading.earningCodes}
            selectedValue={filterState.earning_code_id}
            defaultOptions={earningCodes}
            loadOptions={loadEarningCodesDropdownOptions}
            onChange={obj => {
              setFilterState(pre => ({...pre, earning_code_id: obj.value}));
            }}
            placeholder="Search and select"
          />
        </Col>
        <Col sm={6} lg={4} style={{marginTop: '12px'}}>
          <CustomDatePicker
            name="start_date"
            label="From"
            selected={filterState.from ? new Date(moment(filterState.from)) : ''}
            onDateChange={date => setFilterState(pre => ({...pre, from: date}))}
          />
        </Col>
        <Col sm={6} lg={4} style={{marginTop: '12px'}}>
          <CustomDatePicker
            name="end_date"
            label="To"
            selected={filterState.to ? new Date(moment(filterState.to)) : ''}
            onDateChange={date => setFilterState(pre => ({...pre, to: date}))}
          />
        </Col>
      </Row>
      <div className="filter-search-otr mt-3">
        {/* <SearchInput /> */}
        <div
          className={`action`}
          onClick={() => {
            handleSubmit(filterState);
          }}
        >
          <p className="primary-btn-small record-btn">Apply</p>
        </div>
      </div>
    </div>
  );
};

export default AdjustmentsFilter;
