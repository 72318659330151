import CustomToast from 'components/common/custom-toast';
import {alertTypes} from 'constants/common';
import {ROLES_ENDPOINT, USERS_ENDPOINT, FORGOT_PASSWORD_ENDPOINT} from 'constants/endpoints';
import {toast} from 'react-toastify';
import {get, post, put, deleteApi} from 'services/network';

export const getUsers = async (page, perPage, search, sort, order) => {
  try {
    const response = await get(
      `${USERS_ENDPOINT}?per=${perPage}&page=${page}&sort=${sort}&order=${order}&query=${search}`,
      true,
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const getSingleUser = async id => {
  try {
    const response = await get(`${USERS_ENDPOINT}/${id}`, true);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const getRoles = async () => {
  try {
    const response = await get(ROLES_ENDPOINT, true);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const createUser = async (values, setErrors) => {
  try {
    const response = await post(USERS_ENDPOINT, {users: values}, true);
    toast(<CustomToast variant={alertTypes.SUCCESS} message="User created successfully!" />);
    return response;
  } catch (error) {
    if (error?.response?.data) {
      setErrors(error?.response?.data.errors);
    }
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={error?.response?.data?.message || error?.response?.statusText}
      />,
    );
    console.error(error);
  }
};

export const forgetUserPassword = async (values, setErrors) => {
  try {
    const response = await post(FORGOT_PASSWORD_ENDPOINT, {user: values}, true);
    toast(<CustomToast variant={alertTypes.SUCCESS} message="Confirmation email has been sent!" />);
    return response;
  } catch (error) {
    if (error?.response?.data) {
      setErrors(error?.response?.data.errors);
    }
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={error?.response?.data?.message || error?.response?.statusText}
      />,
    );
    console.error(error);
  }
};

export const resetUserPassword = async (values, setErrors) => {
  try {
    const response = await put(FORGOT_PASSWORD_ENDPOINT, {user: values}, true);
    toast(<CustomToast variant={alertTypes.SUCCESS} message="Password has been reset successfully" />);
    return response;
  } catch (error) {
    if (error?.response?.data) {
      setErrors(error?.response?.data.errors);
    }
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={error?.response?.data?.message || error?.response?.statusText}
      />,
    );
    console.error(error);
  }
};

export const updateUser = async (id, values, setErrors) => {
  try {
    const response = await put(`${USERS_ENDPOINT}/${id}/`, {users: values}, true);
    toast(<CustomToast variant={alertTypes.SUCCESS} message="User updated successfully!" />);
    return response;
  } catch (error) {
    if (error?.response?.data) {
      setErrors(error?.response?.data.errors);
    }
    toast(<CustomToast variant={alertTypes.DANGER} message="Something went wrong!" />);
    console.error(error);
  }
};

export const deactivateUser = async id => {
  try {
    const response = await deleteApi(`${USERS_ENDPOINT}/${id}/`, true);
    toast(<CustomToast variant={alertTypes.SUCCESS} message="User deactivated successfully!" />);
    return response;
  } catch (error) {
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={error?.response?.data?.message || error?.response?.statusText}
      />,
    );
    console.error(error);
  }
};
