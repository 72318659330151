import React from 'react';
import {ButtonGroup, ButtonToolbar} from 'react-bootstrap';
import Next from 'assets/images/Next.png';
import Back from 'assets/images/back.png';
import AddEvent from 'assets/images/Add-icon.png';

import './calendar.scss';
import {isAdministrator, isPayRoll} from 'utils/common';

const Toolbar = ({
  date,
  goToNext,
  goToBack,
  goToDayView,
  goToWeekView,
  goToMonthView,
  goToToday,
  viewState,
  setShowCreateEventModal,
}) => {
  return (
    <div className="toolbar radius">
      <div>
        <span>
          <button className="border-0 btn-bg" type="button" onClick={() => goToBack()}>
            <img src={Back} alt="back" />
          </button>
          <button className="border-0 mr-2 btn-bg" type="button" onClick={() => goToNext()}>
            <img src={Next} alt="next" />
          </button>
          <button className="btn-today" onClick={() => goToToday()}>
            Today
          </button>
        </span>
      </div>
      <div className="date-text">{date}</div>
      <ButtonToolbar aria-label="Toolbar with button groups">
        <ButtonGroup className="me-0" aria-label="First group">
          <button
            className={
              viewState === 'month' ? 'toolbar-views-btn border-month active' : 'toolbar-views-btn border-month'
            }
            onClick={() => goToMonthView()}
          >
            Month
          </button>
          <button
            className={viewState === 'week' ? 'toolbar-views-btn active' : 'toolbar-views-btn'}
            onClick={() => goToWeekView()}
          >
            Week
          </button>{' '}
          <button
            className={viewState === 'day' ? 'toolbar-views-btn border-day active' : 'toolbar-views-btn border-day'}
            onClick={() => goToDayView()}
          >
            Day
          </button>
          {(isAdministrator() || isPayRoll()) && (
            <img src={AddEvent} alt="add-event" className="add-event" onClick={() => setShowCreateEventModal(true)} />
          )}
        </ButtonGroup>
      </ButtonToolbar>{' '}
    </div>
  );
};
export default Toolbar;
