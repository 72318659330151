import TableWraper from 'components/common/table-wrapper';
import {Eye, PencilSimple, Trash} from 'phosphor-react';
import {DEBOUNCE_DELAY, getActionButtonProps, getNameInitials} from 'utils/common';
import {initialMetaForTable} from 'constants/common';

import NotesModal from 'components/common/notes-modal';
import {useCallback, useEffect, useState} from 'react';
import {getNotes, createNotes, updateNotes, deleteNotes} from './api';
import React from 'react';

let timeout;

const NotesListing = ({noteableId, notableType}) => {
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [allListSelected, setAllListSelected] = useState(false);
  const [isNotesModalVisible, setNotesModalVisibility] = useState(false);
  const [isNotesDetailVisible, setNotesDetailVisibility] = useState(false);
  const [selectedNotesRow, setSelectedNotesRow] = useState(null);
  const [meta, setMeta] = useState(initialMetaForTable);

  const fetchNotes = useCallback(async () => {
    const result = await getNotes(notableType, noteableId, meta.page, meta.perPage, meta.search);
    if (result?.meta) {
      const resultMeta = result?.meta;
      setMeta(pre => ({
        ...pre,
        totalCount: resultMeta.total_count,
        totalPages: resultMeta.total_pages,
      }));
    }
    if (result?.notes) {
      setNotes(result.notes);
      setLoading(false);
    }
  }, [meta.page, meta.perPage, meta.search, notableType]);

  useEffect(() => {
    if (loading) {
      fetchNotes();
    }
  }, [fetchNotes, loading]);

  const handleSetPerPage = obj => {
    setMeta(pre => ({...pre, perPage: obj.value}));
    setLoading(true);
  };

  const debounceFn = (callback, delay) => {
    clearTimeout(timeout);
    timeout = setTimeout(callback, delay);
  };
  const handleSetSearchQuery = value => {
    setMeta(pre => ({...pre, search: value}));
    debounceFn(() => {
      setLoading(true);
    }, DEBOUNCE_DELAY);
  };

  const handlePageChange = value => {
    setMeta(pre => ({...pre, page: value}));
    setLoading(true);
  };

  const handleAddRecord = () => {
    handleOpenNotesModal();
  };

  const setAllListCheck = value => {
    setNotes(pre => pre.map(item => ({...item, isSelected: value})));
  };

  const setSingleItemCheck = (value, id) => {
    setNotes(pre => pre.map(item => (item.id === id ? {...item, isSelected: value} : item)));
  };

  const handleSubmitNotes = async values => {
    let result;
    if (selectedNotesRow) {
      const payload = {
        id: selectedNotesRow.id,
        note: {
          message: values.notes,
        },
      };
      result = await updateNotes(payload);
    } else {
      const payload = {
        noteable_type: notableType,
        noteable_id: noteableId,
        message: values.notes,
      };
      result = await createNotes(payload);
    }
    if (result?.meta?.status === 200) {
      handleCloseNotesModal();
      setLoading(true);
    }
  };

  const handleDeleteNotes = async id => {
    const result = await deleteNotes(id);
    if (result?.status === 200) {
      setLoading(true);
    }
  };

  const handleOpenNotesModal = selectedNote => {
    setSelectedNotesRow(selectedNote);
    setNotesModalVisibility(true);
  };

  const handleCloseNotesModal = () => {
    setNotesDetailVisibility(false);
    setNotesModalVisibility(false);
    setSelectedNotesRow(null);
  };

  const viewNoteHandler = item => {
    setNotesDetailVisibility(true);
    handleOpenNotesModal(item);
  };

  return (
    <div className="mt-5">
      <div className="card-wrapper px-4">
        <div className="heading-path-otr">
          <h6 className="heading heading-h6">Notes</h6>
        </div>
        <TableWraper
          setPerPage={handleSetPerPage}
          setSearchQuery={handleSetSearchQuery}
          searchValue={meta.search}
          totalListCount={notes.length}
          pageSize={meta.perPage}
          currentPage={meta.page}
          onPageChange={handlePageChange}
          actionButtons={getActionButtonProps('Add Record', handleAddRecord)}
        >
          {loading ? (
            <h3 style={{textAlign: 'center'}}>Loading</h3>
          ) : (
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">
                    <div className="header-text-otr">
                      <p className="table-name heading-xsb">
                        <input
                          className="form-check-input"
                          onChange={e => {
                            setAllListSelected(e.target.checked);
                            setAllListCheck(e.target.checked);
                          }}
                          type="checkbox"
                          checked={allListSelected}
                        />
                      </p>
                    </div>
                  </th>
                  <th scope="col">
                    <div className="header-text-otr">
                      <p className="table-name heading-xsb">Note</p>
                    </div>
                  </th>
                  <th scope="col">
                    <div className="header-text-otr">
                      <p className="table-name heading-xsb">Created At</p>
                    </div>
                  </th>
                  <th scope="col">
                    <div className="header-text-otr">
                      <p className="table-name heading-xsb">Added By</p>
                    </div>
                  </th>
                  <th scope="col" className="action-column">
                    <div className="header-text-otr">
                      <p className="table-name heading-xsb">Actions</p>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {notes.map(item => {
                  return (
                    <tr key={item.id}>
                      <th scope="col">
                        <div className="header-text-otr">
                          <input
                            className="form-check-input"
                            value={item.isSelected}
                            onChange={e => {
                              setSingleItemCheck(e.target.checked, item.id);
                            }}
                            type="checkbox"
                            checked={item.isSelected}
                          />
                        </div>
                      </th>
                      <td className="">
                        <div>
                          <p className="table-text">{item?.message || ''}</p>
                        </div>
                      </td>
                      <td>
                        <div>
                          <p className="table-text">{item?.created_at || ''}</p>
                        </div>
                      </td>
                      <td>
                        <div className="profile-otr">
                          <div className="named-avatar">{getNameInitials(item?.updated_by)}</div>
                          <p className="user-name heading-xs">{item?.updated_by || ''}</p>
                        </div>
                      </td>
                      <td className="action-column">
                        <div className="table-icon-otr justify-content-start">
                          <div className="icon-otr">
                            <Eye size={24} onClick={() => viewNoteHandler(item)} />
                          </div>
                          <div className="icon-otr">
                            <PencilSimple size={24} onClick={() => handleOpenNotesModal(item)} />
                          </div>
                          <div className="icon-otr">
                            <Trash
                              size={24}
                              onClick={() => {
                                handleDeleteNotes(item.id);
                              }}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </TableWraper>
      </div>
      <NotesModal
        show={isNotesModalVisible}
        isNotesDetailVisible={isNotesDetailVisible}
        close={handleCloseNotesModal}
        handleSubmit={handleSubmitNotes}
        selectedRow={selectedNotesRow}
      />
    </div>
  );
};

export default NotesListing;
