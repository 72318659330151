import React, {useCallback, useEffect, useState} from 'react';
import {ArrowsDownUp, PencilSimple, Trash} from 'phosphor-react';
import {useNavigate} from 'react-router-dom';
import TableWraper from 'components/common/table-wrapper';
import {getEntities, deleteEntity} from 'containers/entities/api';
import {ENTITIES_CREATE_PATH} from 'constants/routePaths';
import BreadcrumbTrail from 'components/common/breadcrumb-trail';
import {initialMetaForTable, orderStratergy} from 'constants/common';
import {getActionButtonProps, isAdministrator} from 'utils/common';

function EntitiesListing() {
  const navigate = useNavigate();

  const [entitiesList, setEntitiesList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [meta, setMeta] = useState({
    ...initialMetaForTable,
    totalCount: 0,
    totalPages: 0,
  });

  const handleSetPerPage = obj => {
    setLoading(true);
    setMeta(pre => ({...pre, perPage: obj.value}));
  };
  const handleSetSearchQuery = value => {
    setLoading(true);
    setMeta(pre => ({...pre, search: value}));
  };
  const handlePageChange = value => {
    setLoading(true);
    setMeta(pre => ({...pre, page: value}));
  };

  const fetchEntities = useCallback(async () => {
    const result = await getEntities(meta.page, meta.perPage, meta.search, meta.sort, meta.order);
    if (result?.meta) {
      const meta = result?.meta;
      setMeta(pre => ({
        ...pre,
        totalCount: meta.total_count,
        totalPages: meta.total_pages,
      }));
    }
    if (result?.entities) {
      setEntitiesList(result.entities);
    }
    setLoading(false);
  }, [meta.page, meta.perPage, meta.search]);

  useEffect(() => {
    if (loading) {
      fetchEntities();
    }
  }, [fetchEntities, loading]);

  const deleteSingleEntity = async id => {
    await deleteEntity(id);
    setLoading(true);
  };
  const sortedDataByColumn = columnName => {
    if (entitiesList.length > 0) {
      setMeta(pre => ({...pre, sort: columnName, order: orderStratergy[pre.order]}));
      setLoading(true);
    }
  };

  return (
    <div className="entities-main">
      <div className="container-fluid">
        <div className="heading-path-otr">
          <h3 className="heading heading-h3">Entities</h3>
          <BreadcrumbTrail />
        </div>

        <TableWraper
          setPerPage={handleSetPerPage}
          setSearchQuery={handleSetSearchQuery}
          totalListCount={meta.totalCount}
          pageSize={meta.perPage}
          currentPage={meta.page}
          onPageChange={handlePageChange}
          actionButtons={
            isAdministrator() && getActionButtonProps('Add New Entity', () => navigate(ENTITIES_CREATE_PATH))
          }
        >
          {loading ? (
            <p style={{textAlign: 'center'}}>Loading</p>
          ) : (
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">
                    <div className="header-text-otr">
                      <p
                        className="table-name heading-xsb cursor-pointer c-hand"
                        onClick={() => sortedDataByColumn('name')}
                      >
                        Entity 
                      </p>
                    </div>
                  </th>
                  <th scope="col">
                    <div className="header-text-otr">
                      <p
                        className="table-name heading-xsb cursor-pointer c-hand"
                        onClick={() => sortedDataByColumn('time_sheet_email')}
                      >
                        TimeSheet Email 
                      </p>
                    </div>
                  </th>
                  <th scope="col">
                    <div className="header-text-otr">
                      <p className="table-name heading-xsb">Status</p>
                    </div>
                  </th>
                  {isAdministrator() && (
                    <th scope="col" className="action-column">
                      <div className="header-text-otr">
                        <p className="table-name heading-xsb">Actions</p>
                      </div>
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {entitiesList?.map(entity => (
                  <tr key={entity.id}>
                    <th scope="col" style={{width: '400px'}}>
                      <div className="table-text-otr" style={{width: '400px'}}>
                        <p className="table-text" title={entity.name}>
                          {entity.name}
                        </p>
                      </div>
                    </th>
                    <td className="">
                      <div className="">
                        <p className="table-text" title={entity.time_sheet_email}>
                          {entity.time_sheet_email}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <p className={`table-text ${entity?.active ? 'active' : 'in-active'}`}>
                          {entity.active ? 'Active' : 'Inactive'}
                        </p>
                      </div>
                    </td>
                    {isAdministrator() && (
                      <td className="action-column">
                        <div className="table-icon-otr justify-content-start">
                          <div className="icon-otr" onClick={() => navigate(`/admin/entities/${entity.id}/edit`)}>
                            <PencilSimple size={24} />
                          </div>
                          <div className="icon-otr" onClick={() => deleteSingleEntity(entity.id)}>
                            <Trash size={24} />
                          </div>
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </TableWraper>
      </div>
    </div>
  );
}

export default EntitiesListing;
