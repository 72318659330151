import React from 'react';
import Input from 'components/common/input';
import SelectComponent from 'components/common/select';
import {ErrorMessage} from 'formik';
import {Row, Col} from 'react-bootstrap';
import Toggle from 'components/common/togglebutton';
import CustomDatePicker from 'components/common/custom-datepicker';
import AsyncSelectComponent from 'components/common/async-select';
import moment from 'moment';

const AdpSearchForm = ({
  formik,
  entities,
  providerCategories,
  providers,
  payRolls,
  isUpdateFlow = false,
  loadPayrollDropdownOptions,
  loadProviderCategoryDropdownOptions,
  loadProvidersDropdownOptions,
  loadEntitiesDropdownOptions,
  dropdownLoading,
}) => {
  const handleChangeForProvider = obj => {
    formik.setFieldValue('provider_id', obj.value);
  };

  const handleChangeForPayRoll = obj => {
    formik.setFieldValue('payroll_group_id', obj.value);
  };

  const handleChangeForProviderCategories = obj => {
    formik.setFieldValue('provider_categories_attributes', obj.value);
  };

  const handleChangeForEntity = obj => {
    formik.setFieldValue('entity_id', obj.value);
  };

  return (
    <form className={'w-100'}>
      <Row>
        <Col md={6}>
          <CustomDatePicker
            name="start_date"
            label={'Pay Period Start Date'}
            selected={formik.values.start_date ? new Date(moment(formik.values.start_date)) : ''}
            onDateChange={date => formik.setFieldValue('start_date', date)}
          />
          <ErrorMessage className="error-text" component="p" name="start_date" />
        </Col>
        <Col md={6}>
          <CustomDatePicker
            name="end_date"
            label="Pay Period End Date"
            selected={formik.values.end_date ? new Date(moment(formik.values.end_date)) : ''}
            onDateChange={date => formik.setFieldValue('end_date', date)}
          />
          <ErrorMessage className="error-text" component="p" name="end_date" />
        </Col>

        {/* <div className="wrapper mb-4 mt-4 d-flex">
          <div className="mr-4">Include All Unpaid Entries</div>
          <Toggle
            // label={'Include All Unpaid Entries'}
            name={'status'}
            value={formik.values.status}
            handleChange={formik.setFieldValue}
          />
        </div> */}

        <Col md={12}>
          <div className=" input-otr" style={{alignItems: 'inherit'}}>
            <label htmlFor="email" className="input-label">
              Payroll Group
            </label>
            <AsyncSelectComponent
              name="payroll_group_id"
              isLoading={dropdownLoading.payroll}
              selectedValue={formik.values.payroll_group_id}
              defaultOptions={payRolls}
              loadOptions={loadPayrollDropdownOptions}
              onChange={obj => handleChangeForPayRoll(obj)}
              placeholder="Search and select"
            />
            <ErrorMessage className="error-text" component="p" name="payroll_group_id" />
          </div>
        </Col>
        {/* <Col md={6}>
          <div className=" input-otr" style={{alignItems: 'inherit'}}>
            <label htmlFor="email" className="input-label">
              Provider
            </label>
            <AsyncSelectComponent
              name="provider_id"
              isLoading={dropdownLoading.providers}
              selectedValue={formik.values.provider_id}
              defaultOptions={providers}
              loadOptions={loadProvidersDropdownOptions}
              onChange={obj => handleChangeForProvider(obj)}
              placeholder="Search and select"
            />
            <ErrorMessage className="error-text" component="p" name="provider_id" />
          </div>
        </Col>

        <Col md={6}>
          <div className=" input-otr" style={{alignItems: 'inherit'}}>
            <label htmlFor="email" className="input-label">
              Provider Categories
            </label>
            <AsyncSelectComponent
              name="provider_categories_attributes"
              isLoading={dropdownLoading.providerCategory}
              selectedValue={formik.values.provider_categories_attributes}
              defaultOptions={providerCategories}
              loadOptions={loadProviderCategoryDropdownOptions}
              onChange={obj => handleChangeForProviderCategories(obj)}
              placeholder="Search and select"
            />

            <ErrorMessage className="error-text" component="p" name="provider_categories_attributes" />
          </div>
        </Col>

        <Col md={12}>
          <div className=" input-otr" style={{alignItems: 'inherit'}}>
            <label htmlFor="email" className="input-label">
              Entity
            </label>
            <AsyncSelectComponent
              name="entity_id"
              isLoading={dropdownLoading.entities}
              selectedValue={formik.values?.entity_id}
              defaultOptions={entities}
              loadOptions={loadEntitiesDropdownOptions}
              onChange={obj => handleChangeForEntity(obj)}
              placeholder="Search and select"
            />
            <ErrorMessage className="error-text" component="p" name="entity_id" />
          </div>
        </Col> */}
      </Row>
    </form>
  );
};

export default AdpSearchForm;
