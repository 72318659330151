import {PAYROLL_PERIOD_ENDPOINT} from 'constants/endpoints';
import {get} from 'services/network';

export const getPayrollPeriods = async obj => {
  const params = {
    sort: obj?.sort ? obj.sort : '',
    order: obj?.order ? obj.order : '',
    page: obj?.page ? obj.page : 1,
    perPage: obj?.perPage ? obj.perPage : 10,
    search: obj?.search ? obj.search : '',
    filter: obj?.filter ? obj.filter : '',
  };
  try {
    const result = await get(
      `${PAYROLL_PERIOD_ENDPOINT}?page=${params.page}&per=${params.perPage}&sort=${params.sort}&order=${params.order}&query=${params.search}&filter[period_status]=${params.filter}`,
      true,
    );
    return result;
  } catch (e) {
    console.log(e);
  }
};
