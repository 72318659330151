import React from 'react';

import FilterWrapper from 'components/common/filter-wrapper';
import BreadcrumbTrail from 'components/common/breadcrumb-trail';
import Filter from 'components/activity-analysis-unpaid/UnpaidFilter';
import {createActiveAnalysis} from './api';
import {useNavigate} from 'react-router-dom';
import {ACTIVITY_ANALYSIS_LIST_PATH} from 'constants/routePaths';
import moment from 'moment';

const ActivityAnalysis = () => {
  const navigate = useNavigate();
  const handleClickOnFilter = async values => {
    const apiPayload = {
      start_date: moment(values.start_date).format('YYYY-MM-DD'),
      end_date: moment(values.end_date).format('YYYY-MM-DD'),
      include_unit_overage_bonus: values.include_unit_overage_bonus,
      unit_overage_start_date: moment(values.unit_overage_start_date).format('YYYY-MM-DD'),
      unit_overage_close_date: moment(values.unit_overage_close_date).format('YYYY-MM-DD'),
      unpaid: values.unpaid,
      filter_by: values.filter_by,
      ...(values.filter_by === 'entity' && {entities: [values.entity?.map(item => item.value)]}),
      ...(values.filter_by === 'provider' && {providers: [values.provider?.map(item => item.value)]}),
      ...(values.filter_by === 'payroll_group' && {payroll_groups: [values.payroll_group?.map(item => item.value)]}),
      ...(values.filter_by === 'all-providers' && {show_by_all_providers: true}),
    };
    const result = await createActiveAnalysis(apiPayload);
    if (result) {
      navigate(ACTIVITY_ANALYSIS_LIST_PATH, {
        state: {
          data: result,
          payload: {
            start_date: values.start_date,
            end_date: values.end_date,
            unpaid: values.unpaid,
            filter_by: values.filter_by,
            ...(values.filter_by === 'entity' && {entities: [values.entity?.map(item => item.value)]}),
            ...(values.filter_by === 'provider' && {providers: [values.provider?.map(item => item.value)]}),
            ...(values.filter_by === 'payroll_group' && {
              payroll_groups: [values.payroll_group?.map(item => item.value)],
            }),
            ...(values.filter_by === 'all-providers' && {show_by_all_providers: true}),
          },
          apiPayload,
        },
      });
    }
  };

  return (
    <>
      <div className="provider-assignment">
        <div className="container-fluid">
          <div className="heading-path-otr">
            <h3 className="heading heading-h3">Activity Analysis Unpaid</h3>
            <BreadcrumbTrail />
          </div>

          <FilterWrapper>
            <Filter handleSubmit={handleClickOnFilter} />
          </FilterWrapper>
        </div>
      </div>
    </>
  );
};

export default ActivityAnalysis;
