import React from 'react';
import {Accordion} from 'react-bootstrap';
import {NavLink} from 'react-router-dom';
import {EARNING_CODES_PATH, ROLES_PATH, BACKEND_TABLES_PATH, USERS_PATH, SYSTEM_AUDIT_PATH, ENTITIES_PATH} from 'constants/routePaths';
import {User, Circle} from 'phosphor-react';

const AdminAccordion = () => {
  return (
    <Accordion.Item eventKey="4">
      <Accordion.Header>
        <User size={24} /> <span className="heading-smb">Admin</span>
      </Accordion.Header>
      <Accordion.Body>
        <ul className="menu-ul">
          <li className="menu-li">
            <NavLink to={USERS_PATH}>
              <p className="menu-a heading-smb">
                <Circle weight="fill" size={8} />
                Users
              </p>
            </NavLink>
          </li>
          <li className="menu-li">
            <NavLink to={EARNING_CODES_PATH}>
              <p className="menu-a heading-smb">
                <Circle weight="fill" size={8} />
                Earning Codes
              </p>
            </NavLink>
          </li>
          <li className="menu-li">
            <NavLink to={ROLES_PATH}>
              <p className="menu-a heading-smb">
                <Circle weight="fill" size={8} />
                Roles
              </p>
            </NavLink>
          </li>
          <li className="menu-li">
            <NavLink to={ENTITIES_PATH}>
              <p className="menu-a heading-smb">
                <Circle weight="fill" size={8} />
                Entities
              </p>
            </NavLink>
          </li>
          <li className="menu-li">
            <NavLink to={SYSTEM_AUDIT_PATH}>
              <p className="menu-a heading-smb">
                <Circle weight="fill" size={8} />
                System Audit
              </p>
            </NavLink>
          </li>
          <li className="menu-li">
            <NavLink to={BACKEND_TABLES_PATH}>
              <p className="menu-a heading-smb">
                <Circle weight="fill" size={8} />
                Back End Tables
              </p>
            </NavLink>
          </li>
        </ul>
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default AdminAccordion;
