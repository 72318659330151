import React, {useState, useEffect} from 'react';
import Input from 'components/common/input';
import {ErrorMessage} from 'formik';
import '../mail.scss';
import {Row, Col} from 'react-bootstrap';
import {map, startCase} from 'lodash';
import {emailSendBy} from 'constants/mail';
import Radio from 'components/common/radio';
import AsyncSelectComponent from 'components/common/async-select';
import {DEBOUNCE_DELAY} from 'utils/common';
import {getEntitesList, getPayrollGroupsList} from 'containers/backend-tables/api';
import {getProvidersList} from 'containers/providers/api';
import {getProviderTitleList, getProviderCategoryList} from 'containers/backend-tables/api';
import debounce from 'debounce-promise';

const SendMailForm = ({values, handleChange, setFieldValue}) => {
  const [activeProvidersList, setActiveProvidersList] = useState([]);
  const [providersList, setProvidersList] = useState([]);
  const [entitiesList, setEntitiesList] = useState([]);
  const [payrollGroupList, setPayrollGroupList] = useState([]);
  const [providerCategoryList, setProviderCategoryList] = useState([]);
  const [providerTitleList, setProviderTitleList] = useState([]);

  const [dropdownLoading, setDropdownLoading] = useState({
    entity: false,
    payroll_group: false,
    providers: false,
    title: false,
    category: false,
    active_providers: false,
  });

  const fetchEntitiesData = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, entity: true});
    try {
      const result = await getEntitesList(search, 'active');
      if (result['entities']) {
        const data = result?.entities.map(item => ({label: item.name, value: item.id}));
        setEntitiesList(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, entity: false});
    }
  }, DEBOUNCE_DELAY);

  const fetchPayrollData = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, payroll_group: true});
    try {
      const result = await getPayrollGroupsList({search});
      if (result['payroll_groups']) {
        const data = result?.payroll_groups.map(item => ({label: item.name, value: item.id}));
        setPayrollGroupList(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, payroll_group: false});
    }
  }, DEBOUNCE_DELAY);

  const fetchProvidersData = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, providers: true});
    try {
      const result = await getProvidersList({search: search});
      if (result['providers']) {
        const data = result?.providers.map(item => ({label: item.name, value: item.id}));
        setProvidersList(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, providers: false});
    }
  }, DEBOUNCE_DELAY);

  const fetchActiveProvidersData = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, active_providers: true});
    try {
      const result = await getProvidersList({search: search, status: 'active'});
      if (result['providers']) {
        const data = result?.providers.map(item => ({label: item.name, value: item.id}));
        setActiveProvidersList(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, active_providers: false});
    }
  }, DEBOUNCE_DELAY);

  const fetchProvidersTitleData = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, title: true});
    try {
      const result = await getProviderTitleList({search: search, status: 'active'});
      if (result['provider_titles']) {
        const data = result?.provider_titles.map(item => ({label: item.name, value: item.id}));
        setProviderTitleList(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, title: false});
    }
  }, DEBOUNCE_DELAY);

  const fetchProvidersCategoryData = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, category: true});
    try {
      const result = await getProviderCategoryList({search: search});
      if (result['provider_categories']) {
        const data = result?.provider_categories.map(item => ({label: item.name, value: item.id}));
        setProviderCategoryList(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, category: false});
    }
  }, DEBOUNCE_DELAY);

  const loadEntitiesDropdownOptions = search => fetchEntitiesData(search);
  const loadPayrollDropdownOptions = search => fetchPayrollData(search);
  const loadProvidersDropdownOptions = search => fetchProvidersData(search);
  const loadActiveProvidersDropdownOptions = search => fetchActiveProvidersData(search);
  const loadProvidersTitleDropdownOptions = search => fetchProvidersTitleData(search);
  const loadProvidersCategoryDropdownOptions = search => fetchProvidersCategoryData(search);

  async function fetchEntities() {
    const result = await getEntitesList('', 'active');
    if (result?.entities) {
      setEntitiesList(result.entities.map(item => ({label: item.name, value: item.id})));
    }
  }
  async function fetchActiveProviders() {
    const result = await getProvidersList({status: 'active'});
    if (result?.providers) {
      setActiveProvidersList(result.providers.map(item => ({label: item.name, value: item.id})));
    }
  }
  async function fetchProviders() {
    const result = await getProvidersList();
    if (result?.providers) {
      setProvidersList(result.providers.map(item => ({label: item.name, value: item.id})));
    }
  }
  async function fetchPayroll() {
    const result = await getPayrollGroupsList();
    if (result?.payroll_groups) {
      setPayrollGroupList(result.payroll_groups?.map(item => ({label: item.name, value: item.id})));
    }
  }

  async function fetchTitles() {
    const result = await getProviderTitleList();
    if (result?.provider_titles) {
      setProviderTitleList(result.provider_titles?.map(item => ({label: item.name, value: item.id})));
    }
  }

  async function fetchCategories() {
    const result = await getProviderCategoryList();
    if (result?.provider_categories) {
      setProviderCategoryList(result.provider_categories?.map(item => ({label: item.name, value: item.id})));
    }
  }

  useEffect(() => {
    fetchEntities();
    fetchPayroll();
    fetchProviders();
    fetchActiveProviders();
    fetchTitles();
    fetchCategories();
  }, []);

  const getDefaultOptions = value => {
    switch (value) {
      case 'entity':
        return entitiesList;
      case 'providers':
        return providersList;
      case 'payroll_group':
        return payrollGroupList;
      case 'title':
        return providerTitleList;
      case 'category':
        return providerCategoryList;
      case 'active_providers':
        return activeProvidersList;

      default:
        return [];
    }
  };

  const getLoadDropdownOptions = (search, value) => {
    switch (value) {
      case 'entity':
        return loadEntitiesDropdownOptions(search);
      case 'providers':
        return loadProvidersDropdownOptions(search);
      case 'payroll_group':
        return loadPayrollDropdownOptions(search);
      case 'title':
        return loadProvidersTitleDropdownOptions(search);
      case 'category':
        return loadProvidersCategoryDropdownOptions(search);
      case 'active_providers':
        return loadActiveProvidersDropdownOptions(search);

      default:
        return [];
    }
  };

  const resetDropDownValues = value => {
    setFieldValue('emailSendBy', value);
    setFieldValue('providers', []);
    setFieldValue('entity', []);
    setFieldValue('payroll_group', []);
    setFieldValue('title', []);
    setFieldValue('category', []);
    setFieldValue('active_providers', []);
  };

  return (
    <div className="w-100 send-mail">
      <Row>
        <Col md={6} className="position-relative">
          <Input
            handleChange={handleChange}
            name="sender"
            value={values.sender}
            placeholder={'e.g. name'}
            label="Sender Email"
            type="text"
            className="company-email"
          />
          <ErrorMessage className="error-text" component="p" name="sender" />
        </Col>
        <Col md={6} className="position-relative">
          <Input
            handleChange={handleChange}
            name="replyToEmail"
            value={values.replyToEmail}
            placeholder="e.g. name@example.com"
            label="Reply-to Email"
            type="text"
            className="company-email"
          />
          <ErrorMessage className="error-text" component="p" name="replyToEmail" />
        </Col>

        {map(emailSendBy, (sendBy, key) => (
          <Col md={6} className="my-3" key={key}>
            <Radio
              key={key}
              handleChange={() => resetDropDownValues(sendBy)}
              label={`Send ${startCase(sendBy)}`}
              value={values.emailSendBy}
              fieldValue={sendBy}
              name="emailSendBy"
            />
            {values.emailSendBy === sendBy && (
              <Row>
                <div style={{paddingLeft: '40px'}}>
                  <AsyncSelectComponent
                    isLoading={dropdownLoading[sendBy.split('by_')[1]]}
                    selectedValue={values[sendBy.split('by_')[1]]}
                    defaultOptions={getDefaultOptions(sendBy.split('by_')[1])}
                    loadOptions={search => getLoadDropdownOptions(search, sendBy.split('by_')[1])}
                    onChange={obj => {
                      let ids = [];
                      obj.forEach(item => ids.push(item.value));
                      setFieldValue('ids', ids);
                      setFieldValue(sendBy.split('by_')[1], obj);
                    }}
                    isDisabled={values.emailSendBy !== sendBy}
                    placeholder="Search and select"
                    isMulti
                    menuPortalTarget={document.body}
                  />
                  <ErrorMessage className="error-text mt-2" component="p" name={`${sendBy.split('by_')[1]}`} />
                </div>
              </Row>
            )}
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default SendMailForm;
