import React from 'react';
import './radio.scss';

function Radio({label, handleChange, value, name, fieldValue}) {
  return (
    <div className="form-check">
      <input
        className="form-check-input"
        name={name}
        id={name}
        value={value}
        onChange={() => handleChange(name, fieldValue)}
        type="radio"
        checked={value == fieldValue}
      />
      <label className="form-check-label heading-sm" htmlFor={name}>
        {label}
      </label>
    </div>
  );
}

export default Radio;
