import React from 'react';

import {Trash} from 'phosphor-react';

import CustomModal from 'components/common/modal';
import {deletePayrollGroupEntity} from 'containers/backend-tables/api';

const EntityModal = ({entities, close, handleDeleteEntity}) => {
  return (
    <CustomModal size="sm" show={true} onHide={() => close()} heading="Entites">
      <div className="table-otr">
        <table className="table secondary-table">
          <thead className="secondary-head">
            <tr>
              <th scope="col">
                <div className="header-text-otr">
                  <p className="table-name heading-xsb">Name</p>
                </div>
              </th>
              <th scope="col" className="action-column">
                <div className="header-text-otr text-end">
                  <p className="table-name heading-xsb" >
                    Actions
                  </p>
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="secondary-body">
            {entities?.map(item => {
              return (
                <tr key={item.id}>
                  <th scope="row">
                    <p className="table-text">{item?.entity?.name || ''}</p>
                  </th>
                  <td className="action-column">
                    <div className="table-icon-otr">
                      <div className="icon-otr">
                        <Trash
                          size={24}
                          onClick={() => {
                            handleDeleteEntity(item.id);
                          }}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </CustomModal>
  );
};

export default EntityModal;
