import React from 'react';

import BreadcrumbTrail from 'components/common/breadcrumb-trail';
import RecordsListing from 'components/service-analysis/RecordsListing';
import {useLocation} from 'react-router-dom';

const ServiceAnalysisListing = () => {
  const {
    state: {data},
  } = useLocation();
  return (
    <>
      <div className="provider-assignment">
        <div className="container-fluid">
          <div className="heading-path-otr">
            <h3 className="heading heading-h3">Search Results</h3>
            <BreadcrumbTrail />
          </div>

          <RecordsListing data={data} />
        </div>
      </div>
    </>
  );
};

export default ServiceAnalysisListing;
