import React, {useEffect, useState, useCallback, useRef} from 'react';
import TableWraper from 'components/common/table-wrapper';
import debounce from 'lodash/debounce';
import {CSVDownload, CSVLink} from 'react-csv';
import {alertTypes, orderStratergy} from 'constants/common';
import {toast} from 'react-toastify';
import CustomToast from 'components/common/custom-toast';

import {getActionButtonProps, isAdministrator, isPayRoll} from 'utils/common';
import {ArrowsDownUp, PencilSimple, Trash} from 'phosphor-react';
import {getFacilitiesList, deleteFacility} from 'containers/facilities/api';
import {DEBOUNCE_DELAY} from 'utils/common';
import {initialMetaForTable} from 'constants/common';
import {FACILITIES_PATH} from 'constants/routePaths';
import {useNavigate} from 'react-router-dom';
import {Dropdown} from 'react-bootstrap';
import ConfirmationModal from 'components/common/confirmation-modal';
import CreateFacilityModal from 'components/facilities/CreateFacilityModal';

const headers = [
  {label: 'Npi Code', key: 'npi'},
  {label: 'Facility Name', key: 'name'},
  {label: 'Email', key: 'email'},
  {label: 'Phone', key: 'phone_no'},
  {label: 'Fax', key: 'fax'},
  {label: 'Adress', key: 'address.street'},
];
const FacilitiesListing = ({filters, refresh, handleRefresh}) => {
  const exportRef = useRef(null);
  const navigate = useNavigate();
  const [allListSelected, setAllListSelected] = useState(false);
  const [facilitiesList, setFacilitiesList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [meta, setMeta] = useState(initialMetaForTable);
  const [totalCount, setTotalCount] = useState(0);
  const [deletableItem, setDeleteableItem] = useState(null);
  const [isDeleting, setDeleting] = useState(false);
  const [isCreateFacilityModalVisble, setCreateFacilityModalVisibility] = useState(false);
  const [csvData, setCsvData] = useState([]);
  useEffect(() => {
    if (refresh) {
      setLoading(true);
    }
  }, [refresh]);
  const fetchData = useCallback(async () => {
    try {
      const result = await getFacilitiesList({...meta, ...filters});

      if (result['facilities']) {
        const data = result?.facilities.map(item => ({...item, isSelected: allListSelected}));
        setFacilitiesList(data);
        setCsvData(data);
        setTotalCount(result?.meta.total_count);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
      handleRefresh(false);
    }
  }, [allListSelected, meta, filters]);

  useEffect(() => {
    if (loading) {
      fetchData();
    }
  }, [fetchData, loading]);

  const handleConfirmModalToggle = ({itemId = null}) => {
    setDeleteableItem(itemId);
  };

  const deleteSingleFacility = async () => {
    setDeleting(true);
    const res = await deleteFacility(deletableItem);

    if (res) {
      setDeleteableItem(null);
      setDeleting(false);
      setLoading(true);
    }
  };

  const handleSetPerPage = obj => {
    setMeta(pre => ({...pre, perPage: obj.value}));
    setLoading(true);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(
    debounce(() => {
      setLoading(true);
    }, DEBOUNCE_DELAY),
    [meta.search],
  );

  const handleSetSearchQuery = value => {
    setMeta(pre => ({...pre, search: value}));
    debounceFn();
  };

  const handlePageChange = value => {
    setMeta(pre => ({...pre, page: value}));
    setLoading(true);
  };

  const setAllListCheck = value => {
    setFacilitiesList(pre => pre.map(item => ({...item, isSelected: value})));
  };
  const setSingleItemCheck = (value, id) => {
    setFacilitiesList(pre => pre.map(item => (item.id === id ? {...item, isSelected: value} : item)));
  };
  const handleClickAddProvider = () => {
    setCreateFacilityModalVisibility(true);
  };

  const handleExport = done => {
    exportRef.current.link.click();
    if (csvData.length) {
      done(true);
    } else {
      toast(<CustomToast variant={alertTypes.DANGER} message={'Please Select Record!'} />);
      done(false);
    }
  };

  // useEffect(() => {
  //   setCsvData(facilitiesList.filter(item => item.isSelected));
  // }, [facilitiesList]);
  const sortedDataByColumn = columnName => {
    if (facilitiesList.length > 0) {
      setMeta(pre => ({...pre, sort: columnName, order: orderStratergy[pre.order]}));
      setLoading(true);
    }
  };
  return (
    <>
      <TableWraper
        setPerPage={handleSetPerPage}
        setSearchQuery={handleSetSearchQuery}
        searchValue={meta.search}
        totalListCount={totalCount}
        pageSize={meta.perPage}
        currentPage={meta.page}
        onPageChange={handlePageChange}
        actionButtons={
          (isAdministrator() || isPayRoll()) && [
            ...getActionButtonProps('Add Facility', handleClickAddProvider),
            ...getActionButtonProps('Export', () => {
              exportRef.current.link.click();
            }),
          ]
        }
      >
        {loading ? (
          <h3 style={{textAlign: 'center'}}>Loading</h3>
        ) : (
          <table className="table">
            <thead>
              <tr>
                {/* <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">
                      <input
                        className="form-check-input"
                        onChange={e => {
                          setAllListSelected(e.target.checked);
                          setAllListCheck(e.target.checked);
                        }}
                        type="checkbox"
                        checked={allListSelected}
                      />
                    </p>
                  </div>
                </th> */}
                <th scope="col">
                  <div className="header-text-otr">
                    <p
                      className="table-name heading-xsb cursor-pointer c-hand"
                      onClick={() => sortedDataByColumn('npi')}
                    >
                      NPI Code 
                    </p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p
                      className="table-name heading-xsb cursor-pointer c-hand"
                      onClick={() => sortedDataByColumn('name')}
                    >
                      Facility Name 
                    </p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p
                      className="table-name heading-xsb cursor-pointer c-hand"
                      onClick={() => sortedDataByColumn('email')}
                    >
                      Contact 
                    </p>
                  </div>
                </th>
                {/* <th scope="col">
                  <div className="header-text-otr">
                    <p
                      className="table-name heading-xsb cursor-pointer c-hand"
                      onClick={() => sortedDataByColumn('phone_no')}
                    >
                      Phone 
                    </p>
                  </div>
                </th> */}
                {/* <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Fax</p>
                  </div>
                </th> */}
                <th scope="col">
                  <div className="header-text-otr">
                    <p
                      className="table-name heading-xsb cursor-pointer c-hand"
                      onClick={() => sortedDataByColumn('address')}
                    >
                      Address 
                    </p>
                  </div>
                </th>
                {(isAdministrator() || isPayRoll()) && (
                  <th scope="col">
                    <div className="header-text-otr">
                      <p className="table-name heading-xsb">Actions</p>
                    </div>
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {facilitiesList.map(item => {
                return (
                  <tr key={item.id}>
                    {/* <th scope="col">
                      <div className="header-text-otr">
                        <p className="table-name heading-xsb">
                          <input
                            className="form-check-input"
                            value={item.isSelected}
                            onChange={e => {
                              setSingleItemCheck(e.target.checked, item.id);
                            }}
                            type="checkbox"
                            checked={item.isSelected}
                          />
                        </p>
                      </div>
                    </th> */}

                    <th scope="col">
                      <div className="table-text-otr w-100">
                        <p className="table-text ">{item?.npi || ''}</p>
                      </div>
                    </th>
                    <td>
                      <div className="table-text-otr w-100">
                        <p className="table-text-black">{item?.name || ''}</p>
                      </div>
                    </td>
                    {/* <td>
                      <div className="table-text-otr">
                        <p className="table-text ">{item?.email || ''}</p>
                      </div>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <p className="table-text-black ">{item?.phone_no || ''}</p>
                      </div>
                    </td> */}
                    <td>
                      <div className="">
                        <p className="table-text-no-wrap">
                          Phone: {item?.phone_no || ''} <br /> Email: {item?.email || ''}
                        </p>
                      </div>
                    </td>
                    {/* <td>
                      <div className="table-text-otr">
                        <p className="table-text ">{item?.fax || ''}</p>
                      </div>
                    </td> */}
                    <td>
                      <div className="table-text-otr" style={{maxWidth: '300px'}}>
                        <p className="">
                          {item?.address?.street || ''}
                          <br /> {item?.address?.city + ' ' + item?.address?.state + ' ' + item?.address?.zip_code}
                        </p>
                      </div>
                    </td>

                    {(isAdministrator() || isPayRoll()) && (
                      <td>
                        <div className="d-flex justify-content-start">
                          <div className="table-icon-otr">
                            <Dropdown>
                              <Dropdown.Toggle variant="white" id="dropdown-basic">
                                ...
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => {
                                    navigate(FACILITIES_PATH + `/${item.id}/edit`);
                                  }}
                                >
                                  <div className="icon-otr">
                                    <span className="me-2">
                                      <PencilSimple size={24} />
                                    </span>
                                    <span>Edit</span>
                                  </div>
                                </Dropdown.Item>
                                <Dropdown.Item onClick={e => handleConfirmModalToggle({...e, itemId: item.id})}>
                                  <div className="icon-otr">
                                    <span className="me-2">
                                      <Trash size={24} />
                                    </span>
                                    <span>Delete</span>
                                  </div>
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </TableWraper>
      {isCreateFacilityModalVisble && (
        <CreateFacilityModal
          open={isCreateFacilityModalVisble}
          close={() => setCreateFacilityModalVisibility(false)}
          refetchFacilitites={() => setLoading(true)}
        />
      )}
      <ConfirmationModal
        isDeleting={isDeleting}
        isModalVisible={deletableItem}
        handleCloseModal={handleConfirmModalToggle}
        handleConfirmClose={deleteSingleFacility}
      />
      <CSVLink
        ref={exportRef}
        data={csvData}
        headers={headers}
        asyncOnClick={true}
        onClick={(event, done) => {
          handleExport(done);
        }}
        filename={'my-file.csv'}
        className="btn btn-primary opacity-0 w-0 h-0 position-absolute top-0"
        target="_blank"
      />
    </>
  );
};

export default FacilitiesListing;
