import React, {useRef, useState} from 'react';

import FilterWrapper from 'components/common/filter-wrapper';
import BreadcrumbTrail from 'components/common/breadcrumb-trail';
import Filter from 'components/looker-imports/Filter';
import FileUpload from 'components/common/file-upload';
import CustomToast from 'components/common/custom-toast';
import LookerListing from 'components/looker-imports/LookerListing';

import {alertTypes} from 'constants/common';
import {toast} from 'react-toastify';
import {createLookerImport} from './api';
import {checkCsvFormate} from 'utils/common';

import 'containers/looker-import/looker.scss';
import {CSVLink} from 'react-csv';

const LookerImports = () => {
  const exportRef = useRef(null);
  const [refresh, setRefresh] = useState(false);
  const [filters, setFilters] = useState(null);
  const [csvData] = useState([
    {
      serviceDate: '',
      facilityName: '',
      billingFacilityNpiCode: '',
      billingProviderName: '',
      billingProviderNpiCode: '',
      billingProcedureCode: '',
      billingNoteID: '',
      billingAmountEntered: '',
      billingUnitsCount: '',
      effectiveDate: '',
      numberOfPatientSeen: '',
    },
  ]);
  const handleRefreshPage = () => {
    setRefresh(pre => !pre);
  };
  const handleFileUpload = async file => {
    const extension = file.file.name.split('.').pop().toLowerCase();
    const isSupported = ['xlsx', 'csv'].includes(extension);
    const fileSizeLimit = 1024 * 1024; // 1MB limit

    if (isSupported) {
      // Check file size
      if (file.file.size > fileSizeLimit) {
        toast(<CustomToast variant={alertTypes.DANGER} message={'File size exceeds the limit (1MB).'} />);
        return;
      }

      const check = await checkCsvFormate(
        file,
        [
          'ServiceDate',
          'FacilityName',
          'BillingFacilityNPICode',
          'BillingProviderSeenByName',
          'BillingProviderNPICode',
          'BillingProcedureCode',
          'BillingNoteID',
          'BillingAmountEntered',
          'BillingUnitsCount',
          'EffectiveDate',
          'NumberOfPatientSeen',
        ],
        extension,
      );

      if (check) {
        let formData = new FormData();
        formData.append('document', file.file);
        const result = await createLookerImport(formData);
        handleRefreshPage();
      } else {
        toast(<CustomToast variant={alertTypes.DANGER} message={'Please choose the correct file.'} />);
      }
    }
  };

  const handleClickOnFilter = values => {
    setFilters(values);
    handleRefreshPage();
  };

  const headers = [
    {label: 'ServiceDate', key: 'serviceDate'},
    {label: 'FacilityName', key: 'facilityName'},
    {label: 'BillingFacilityNPICode', key: 'billingFacilityNpiCode'},
    {label: 'BillingProviderSeenByName', key: 'billingProviderName'},
    {label: 'BillingProviderNPICode', key: 'billingProviderNpiCode'},
    {label: 'BillingProcedureCode', key: 'billingProcedureCode'},
    {label: 'BillingNoteID', key: 'billingNoteID'},
    {label: 'BillingAmountEntered', key: 'billingAmountEntered'},
    {label: 'BillingUnitsCount', key: 'billingUnitsCount'},
    {label: 'EffectiveDate', key: 'effectiveDate'},
    {label: 'NumberOfPatientSeen', key: 'numberOfPatientSeen'},
  ];
  const handleExport = done => {
    exportRef.current.link.click();
    if (csvData.length) {
      done(true);
    } else {
      toast(<CustomToast variant={alertTypes.DANGER} message={'Please Select Record!'} />);
      done(false);
    }
  };
  return (
    <>
      <div className="looker-main">
        <div className="container-fluid">
          <div className="drag">
            <div className="wrapper">
              <FileUpload type="drag" accept={['.xlsx', '.csv']} customRequest={handleFileUpload} />
              <button
                className="primary-btn-small record-btn"
                onClick={() => {
                  exportRef.current.link.click();
                }}
              >
                Download template
              </button>
            </div>
          </div>

          <div className="heading-path-otr">
            <h3 className="heading heading-h3">Looker Import</h3>
            <BreadcrumbTrail />
          </div>

          <FilterWrapper>
            <Filter handleSubmit={handleClickOnFilter} />
          </FilterWrapper>
          <LookerListing refreshByParent={refresh} filters={filters} />
        </div>
      </div>
      <CSVLink
        ref={exportRef}
        data={csvData}
        headers={headers}
        asyncOnClick={true}
        onClick={(event, done) => {
          handleExport(done);
        }}
        filename={'template.csv'}
        className="btn btn-primary opacity-0 w-0 h-0 position-absolute top-0"
        target="_blank"
      />
    </>
  );
};

export default LookerImports;
