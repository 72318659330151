import Modal from 'react-bootstrap/Modal';
import moment from 'moment';

import './edit-event-modal.scss';
import {PencilSimple} from 'phosphor-react';
import {isAdministrator, isPayRoll} from 'utils/common';
import {classNameWithKeys, matchingWithKeys} from 'constants/calendar';

const EditEventModal = props => {
  const getCommonEventValue = (event, key = null) => {
    return key !== null
      ? `${event?.earning_code} ${event[key][0]?.total_price_and_unit?.total_units?.toFixed(2)}U ${
          event[key][0]?.total_price_and_unit?.total_amount
        }`
      : '';
  };

  return (
    <Modal {...props} className="edit-event-modal" size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {moment(props?.data?.serviced_date).format('dddd, MMM DD')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-card">
          <div className="title">{`${props?.data?.name}, ${props?.data?.facility_name}`}</div>
          <div className="body">
            {props?.data?.provider_data_by_earning_code?.map(elem => {
              let objectHasvalue = null;
              Object.keys(elem).forEach(keyName => {
                const index = matchingWithKeys.findIndex(item => item === keyName);
                if (index !== -1) {
                  if (elem[matchingWithKeys[index]][0]?.provider_data?.length) {
                    objectHasvalue = matchingWithKeys[index];
                  }
                }
              });
              console.log('elem[objectHasvalue][0].provider_data,', elem[objectHasvalue][0].provider_data);
              return objectHasvalue === 'erroneous_provider_data' ? null : (
                <div className="d-flex justify-content-between align-items-center">
                  {/* <p className={objectHasvalue !== null ? classNameWithKeys[objectHasvalue] : ''}>{`${
                          entireEle.earning_code
                        } ${entireEle.serviced_unit.toFixed(2)}U ${
                          entireEle.reg_amount.includes('$') ? entireEle.reg_amount : `$${entireEle.reg_amount}`
                        }`}</p> */}
                  <p
                    key={elem.earning_code}
                    className={objectHasvalue !== null ? classNameWithKeys[objectHasvalue] : ''}
                  >
                    {getCommonEventValue(elem, objectHasvalue)}
                  </p>
                  {(isAdministrator() || isPayRoll()) && (
                    <PencilSimple
                      className="edit-icon"
                      size={18}
                      onClick={() => {
                        props.handleClickOnEdit({
                          provider_data: elem[objectHasvalue][0].provider_data,
                          serviced_date: props?.data?.serviced_date,
                          facility_id: props?.data?.facility_id,
                        });
                        props.onHide();
                      }}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default EditEventModal;
