import React, {useEffect, useState} from 'react';
import {ErrorMessage, Formik} from 'formik';
import Input from 'components/common/input';
import {useNavigate, useParams} from 'react-router-dom';
import Toggle from 'components/common/togglebutton';
import {createEntity, updateEntity, getSingleEntity} from 'containers/entities/api';
import {ENTITIES_PATH} from 'constants/routePaths';
import BreadcrumbTrail from 'components/common/breadcrumb-trail';
import {toast} from 'react-toastify';
import CustomToast from 'components/common/custom-toast';
import {alertTypes} from 'constants/common';
import {entityFormInitialValues, entitySchema} from 'constants/entities';
import {Col, Row} from 'react-bootstrap';

const EntitiesForm = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(entityFormInitialValues);

  const fetchEditEntity = async id => {
    setLoading(true);
    const result = await getSingleEntity(id);
    if (result?.entity) {
      result.entity.status = result.entity.active ? 1 : 0;
      setInitialValues(result.entity);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (params?.id) {
      fetchEditEntity(params?.id);
    }
  }, []);

  const submitHandler = async (values, setErrors) => {
    if (params?.id) {
      await updateEntity(params.id, values, setErrors);
      navigate(ENTITIES_PATH);
      toast(<CustomToast variant={alertTypes.SUCCESS} message="Entity updated successfully!" />);
    } else {
      await createEntity(values, setErrors);
      navigate(ENTITIES_PATH);
      toast(<CustomToast variant={alertTypes.SUCCESS} message="Entity created successfully!" />);
    }
  };

  const entityFlow = params?.id ? 'Update' : 'Create';

  return (
    <>
      {loading ? (
        <p style={{textAlign: 'center'}}>Loading</p>
      ) : (
        <div className="entities-main">
          <div className="container-fluid">
            <div className="heading-path-otr">
              <h3 className="heading heading-h3">{entityFlow}</h3>
              <BreadcrumbTrail />
            </div>

            <div className="table-main">
              <div className="table-head-content">
                <Formik
                  initialValues={initialValues}
                  enableReinitialize={true}
                  validationSchema={entitySchema}
                  onSubmit={(values, {setErrors}) => {
                    submitHandler(values, setErrors);
                  }}
                >
                  {({values, handleChange, setFieldValue, handleSubmit, errors}) => {
                    return (
                      <form onSubmit={handleSubmit} className={'w-100'}>
                        <h3 className="heading heading-h3 mb-3">Entity</h3>
                        <Row>
                          <Col md={12}>
                            <div className="wrapper">
                              <Toggle
                                label="Active"
                                name="status"
                                value={values.status}
                                handleChange={values.adp_w2.length>=3 && setFieldValue}
                                // disabled
                              />
                              <ErrorMessage className="error-text" component="p" name="status" />
                            </div>
                          </Col>
                          <Col md={12}>
                            <div className="wrapper">
                              <Input
                                handleChange={handleChange}
                                name="name"
                                value={values.name}
                                placeholder={'Entity'}
                                label={'Entity Name'}
                              />
                              <ErrorMessage className="error-text" component="p" name="name" />
                            </div>
                          </Col>
                          <Col lg={3} md={12}>
                            <div className="wrapper">
                              <Input
                                handleChange={e => {
                                  if (e.target.value?.length>=3) {
                                    setFieldValue('status', 1);
                                  } else {
                                    setFieldValue('status', 0);
                                  }
                                  setFieldValue('adp_w2', e.target.value);
                                }}
                                name={'adp_w2'}
                                value={values.adp_w2}
                                placeholder={'ADP code For W2'}
                                label={'ADP code For W2'}
                                maxlength="3"
                              />
                              <ErrorMessage className="error-text" component="p" name="adp_w2" />
                            </div>
                          </Col>
                          <Col lg={3} md={12}>
                            <div className="wrapper">
                              <Input
                                handleChange={(e)=>{setFieldValue('adp_1099',e.target.value.trim())}}
                                name="adp_1099"
                                value={values.adp_1099}
                                placeholder={'ADP code For 1099'}
                                label={'ADP code For 1099'}
                                maxlength="3"
                                
                              />
                              <ErrorMessage className="error-text" component="p" name="adp_1099" />
                            </div>
                          </Col>
                          <Col lg={6} md={12}>
                            <div className="wrapper">
                              <Input
                                handleChange={(e)=>{setFieldValue('time_sheet_email',e.target.value.trim())}}
                                name={'time_sheet_email'}
                                value={values.time_sheet_email}
                                placeholder={'Default Timesheet Email'}
                                label={'Default Timesheet Email'}
                              />
                              <ErrorMessage className="error-text" component="p" name="time_sheet_email" />
                            </div>
                          </Col>
                          <Col lg={4} md={12} className="mt-4">
                            <button type="submit" className="primary-btn-small record-btn">
                              {entityFlow}
                            </button>
                          </Col>
                        </Row>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EntitiesForm;
