import React from 'react';

import {Accordion} from 'react-bootstrap';
import {House, List, Wrench, SignOut} from 'phosphor-react';
import ProviderAccordion from 'components/layout/sidebar/ProviderAccordion';
import PayrollAccordion from 'components/layout/sidebar/PayrollAccordion';
import ReportsAccordion from 'components/layout/sidebar/ReportsAccordion';
import MailAccordion from 'components/layout/sidebar/MailAccordion';
import AdminAccordion from 'components/layout/sidebar/AdminAccordion';
import ProviderPermittedView from 'components/layout/sidebar/ProviderPermittedView';

import './sidebar.scss';
import {isAdministrator, isPayRoll, isProvider} from 'utils/common';
import {NavLink} from 'react-router-dom';
import {DASHBOARD_PATH, FACILITIES_PATH} from 'constants/routePaths';
import {ReactComponent as MiniLogo} from 'assets/images/logo.svg';

function Sidebar({logoutHandler, toggleSidebarHandler}) {
  return (
    <div className="App sidebar-main">
      <div className="logo-menu-otr">
        <div className="logo-otr">
          <NavLink to={DASHBOARD_PATH}>
            <MiniLogo />
          </NavLink>
        </div>
        <div className="menu-icon-otr" onClick={toggleSidebarHandler}>
          <List size={24} />
        </div>
      </div>
      <Accordion defaultActiveKey="4">
        <NavLink to={DASHBOARD_PATH}>
          <p className="menu-linkk">
            <House size={24} /> <span className="heading-smb">Home</span>
          </p>
        </NavLink>
        {isProvider() ? (
          <ProviderPermittedView />
        ) : (
          <>
            <ProviderAccordion />
            <NavLink to={FACILITIES_PATH}>
              <p className="menu-linkk">
                <Wrench size={24} /> <span className="heading-smb">Facilities</span>
              </p>
            </NavLink>
            <PayrollAccordion />
            <ReportsAccordion />
            <MailAccordion />
            {(isAdministrator() || isPayRoll()) && <AdminAccordion />}
          </>
        )}
        <p className="menu-linkk" onClick={logoutHandler}>
          <SignOut size={24} /> <span className="heading-smb">Sign Out</span>
        </p>
      </Accordion>
    </div>
  );
}

export default Sidebar;
