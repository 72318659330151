import React from 'react';

import FilterWrapper from 'components/common/filter-wrapper';
import BreadcrumbTrail from 'components/common/breadcrumb-trail';
import Filter from 'components/tsm-raw-data/Filter';
import {useNavigate} from 'react-router-dom';
import {RAW_DATA_SEARCH_PATH} from 'constants/routePaths';

const TSMRawData = () => {
  const navigate = useNavigate();

  const handleClickOnFilter = values => {
    navigate(
      RAW_DATA_SEARCH_PATH +
        `?startDate=${values.startDate}&endDate=${values.endDate}&entities=${values.entities}&providers=${values.providers}`,
    );
  };

  return (
    <>
      <div className="provider-assignment">
        <div className="container-fluid">
          <div className="heading-path-otr">
            <h3 className="heading heading-h3">TSM Raw Data Report</h3>
            <BreadcrumbTrail />
          </div>

          <FilterWrapper>
            <Filter handleSubmit={handleClickOnFilter} />
          </FilterWrapper>
        </div>
      </div>
    </>
  );
};

export default TSMRawData;
