import React, {useState, useEffect} from 'react';
import AdpSearchFrom from 'components/adp-export/adp-export-search-form';
import CustomToast from 'components/common/custom-toast';
import AsyncSelectComponent from 'components/common/async-select';
import debounce from 'debounce-promise';

import {DEBOUNCE_DELAY} from 'utils/common';
import {Formik} from 'formik';

import 'components/facilities/facilities.scss';

import {
  adpExportInitialValues as initialValues,
  adpExportValidationSchema as validationSchema,
} from 'constants/adp-export';
import {getEntitiesList} from 'containers/providers/api';
import {getProviderCategoies} from 'containers/providers/api';
import {getProvidersList} from 'containers/providers/api';
import {getPayrollGroupsList} from 'containers/backend-tables/api';
import {getAdpExportSearch} from './api';
import {useNavigate} from 'react-router-dom';
import {Spinner} from 'react-bootstrap';
import {ADP_EXPORT_SEARCH_LISTING_PATH} from 'constants/routePaths';
import {alertTypes} from 'constants/common';
import {toast} from 'react-toastify';

const AdpExportSerach = ({loading}) => {
  const navigate = useNavigate();
  const [entities, setEntities] = useState([]);
  const [providerCategories, setProviderCategories] = useState([]);
  const [providers, setProviders] = useState([]);
  const [payRolls, setPayRolls] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dropdownLoading, setDropdownLoading] = useState({
    entities: false,
    providers: false,
    providerCategory: false,
    payroll: false,
  });

  const handleSearch = async (values, setErrors) => {
    const data = {
      ...values,
      provider_categories_attributes: [{id: values.provider_categories_attributes}],
    };
    try {
      setIsLoading(true);
      const result = await getAdpExportSearch(data, setErrors);
      if (result?.length) {
        navigate(ADP_EXPORT_SEARCH_LISTING_PATH, {state: {result, data}});
      } else {
        toast(<CustomToast variant={alertTypes.DANGER} message={'No Records Found!'} />);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  async function fetchEntities() {
    const result = await getEntitiesList();
    if (result?.entities) {
      setEntities(result['entities']?.map(item => ({value: item.id, label: item.name})));
    }
  }

  async function fetchProvidersList() {
    const result = await getProvidersList();
    if (result['providers']) {
      setProviders(result['providers'].map(item => ({value: item.id, label: item.name})));
    }
  }

  async function fetchProviderCategories() {
    const result = await getProviderCategoies();
    if (result['provider_categories']) {
      setProviderCategories(result['provider_categories'].map(item => ({value: item.id, label: item.name})));
    }
  }

  async function fetchPayRolls() {
    const result = await getPayrollGroupsList();
    if (result['payroll_groups']) {
      setPayRolls(result['payroll_groups'].map(item => ({value: item.id, label: item.name})));
    }
  }

  useEffect(() => {
    // fetchEntities();
    // fetchProviderCategories();
    // fetchProvidersList();
    fetchPayRolls();
  }, []);

  const fetchEntitiesData = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, entities: true});
    try {
      const result = await getEntitiesList('', search);
      if (result['entities']) {
        const data = result?.entities.map(item => ({label: item.name, value: item.id}));
        setEntities(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, entities: false});
    }
  }, DEBOUNCE_DELAY);

  const fetchProvidersData = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, providers: true});
    try {
      const result = await getProvidersList({search});
      if (result['providers']) {
        const data = result?.providers.map(item => ({
          value: item.id,
          label: `${item.first_name} ${item.last_name}` || item.name,
        }));
        setProviders(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, providers: false});
    }
  }, DEBOUNCE_DELAY);

  const fetchProviderCategoryData = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, providerCategory: true});
    try {
      const result = await getProviderCategoies('', search);
      if (result['provider_categories']) {
        const data = result?.provider_categories.map(item => ({
          value: item.id,
          label: item.name,
        }));
        setProviderCategories(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, providerCategory: false});
    }
  }, DEBOUNCE_DELAY);
  const fetchPayrollGroupData = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, payroll: true});
    try {
      const result = await getPayrollGroupsList({search});
      if (result['payroll_groups']) {
        const data = result?.payroll_groups.map(item => ({
          value: item.id,
          label: item.name,
        }));
        setPayRolls(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, payroll: false});
    }
  }, DEBOUNCE_DELAY);
  const loadEntitiesDropdownOptions = search => fetchEntitiesData(search);
  const loadProvidersDropdownOptions = search => fetchProvidersData(search);
  const loadProviderCategoryDropdownOptions = search => fetchProviderCategoryData(search);
  const loadPayrollDropdownOptions = search => fetchPayrollGroupData(search);

  return (
    <div>
      <div className="card-wrapper facility-padding">
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={(values, {setErrors}) => handleSearch(values, setErrors)}
        >
          {formik => {
            return (
              <>
                <h4>Search</h4>
                <AdpSearchFrom
                  formik={formik}
                  entities={entities}
                  providerCategories={providerCategories}
                  providers={providers}
                  payRolls={payRolls}
                  dropdownLoading={dropdownLoading}
                  loadEntitiesDropdownOptions={loadEntitiesDropdownOptions}
                  loadProvidersDropdownOptions={loadProvidersDropdownOptions}
                  loadProviderCategoryDropdownOptions={loadProviderCategoryDropdownOptions}
                  loadPayrollDropdownOptions={loadPayrollDropdownOptions}
                />

                {loading ? (
                  <button className={`primary-btn-small record-btn mt-3`}>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                  </button>
                ) : (
                  <button
                    type="button"
                    className={`primary-btn-small record-btn mt-3 `}
                    onClick={formik.handleSubmit}
                    disabled={isLoading}
                  >
                    Search
                  </button>
                )}
              </>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default AdpExportSerach;
