import React, {useEffect, useState} from 'react';

import FilterWrapper from 'components/common/filter-wrapper';
import ProviderRatesFilter from 'components/provider-rates/filter';
import BreadcrumbTrail from 'components/common/breadcrumb-trail';
import 'components/provider-rates/provider-rates.scss';
import ProviderRatesListing from 'components/provider-rates/provider-rates-listing';
import {Col, Row} from 'react-bootstrap';
import {getProviderHistory} from './api';

const ProviderRates = () => {
  const [refresh, setRefresh] = useState(false);
  const [filters, setFilters] = useState({
    provider_id: '',
    earning_code_id: '',
  });

  const handleRefresh = () => {
    setRefresh(true);
  };
  const onFilterClick = value => {
    setFilters(value);
    handleRefresh();
  };

  return (
    <>
      <div className="providers-main">
        <div className="container-fluid">
          <div className="heading-path-otr">
            <h3 className="heading heading-h3">Provider Rates</h3>
            <BreadcrumbTrail />
          </div>
          <FilterWrapper>
            <ProviderRatesFilter handleSubmit={onFilterClick} filters={filters} />
          </FilterWrapper>
          {/* <TableComponent /> */}

          <ProviderRatesListing refreshByParent={refresh} filters={filters} setRefreshByParent={setRefresh} />
        </div>
      </div>
    </>
  );
};

export default ProviderRates;
