import React, {useState, useEffect} from 'react';
import Input from 'components/common/input';
import SelectComponent from 'components/common/select';
import {ErrorMessage} from 'formik';
import {Row, Col} from 'react-bootstrap';
import {normalizeInput} from 'utils/common';
import Toggle from 'components/common/togglebutton';
import StateField from 'components/common/state-field';
import {documentDeliveryTypeOptions} from 'constants/facilities';
import ZipCodeField from 'components/common/zip-code-field';
import CityField from 'components/common/city-field';
import AsyncSelectComponent from 'components/common/async-select';
import moment from 'moment';
import CustomDatePicker from 'components/common/custom-datepicker';

const FacilityForm = ({
  formik,
  entities,
  users,
  facilitiesTypes,
  isUpdateFlow = false,
  dropdownLoading,
  loadEntitiesDropdownOptions,
  loadUsersDropdownOptions,
  loadFacilitiesTypeDropdownOptions,
}) => {
  const [defaultValue, setDefaultValue] = useState([]);

  useEffect(() => {
    const list = [];
    users.forEach((option, index) => {
      const obj = formik.values.regional_managers_attributes.find(item => item.user_id === option.value);
      if (obj) {
        list.push(option);
      } else {
        return false;
      }
    });
    setDefaultValue(list);
  }, [formik.values.regional_managers_attributes, users]);

  const handleChangeForManagers = selectedUsers => {
    console.log('directors are ', selectedUsers);
    formik.setFieldValue('regional_managers_attributes', selectedUsers);
  };

  const handleChangeForDocumentType = obj => {
    formik.setFieldValue('document_delivery_type', obj.value);
  };

  const handleChangeForEntity = obj => {
    formik.setFieldValue('entity_id', obj.value);
  };

  const handleChangeForFacility = obj => {
    formik.setFieldValue('facility_type_id', obj.value);
  };

  return (
    <form className={'w-100'}>
      <Row>
        <div className="wrapper mb-4 mt-4">
          <Toggle label={'Active'} name={'status'} value={formik.values.status} handleChange={formik.setFieldValue} />
        </div>
        <Col md={4}>
          <Input
            handleChange={formik.handleChange}
            name={'name'}
            value={formik.values.name}
            placeholder={'Facility Name'}
            label={'Facilty Name *'}
            maxLength="50"
            type="text"
          />
          <ErrorMessage className="error-text" component="p" name="name" />
        </Col>
        <Col md={4}>
          <Input
            handleChange={e => {
              formik.setFieldValue('npi', e.target.value);
            }}
            name={'npi'}
            value={formik.values.npi}
            placeholder={'NPI Number'}
            label={'NPI Number'}
            type="number"
            // maxLength={10}
          />
          <ErrorMessage className="error-text" component="p" name="npi" />
        </Col>
        <Col md={4}>
          <Input
            handleChange={formik.handleChange}
            name={'facility_id'}
            value={formik.values.facility_id}
            placeholder={'Facility Id'}
            label={'Facility Id *'}
            type="text"
            // maxLength={10}
          />
          <ErrorMessage className="error-text" component="p" name="facility_id" />
        </Col>
        {/* {(defaultValue.length > 0 || !isUpdateFlow) && ( */}
        <Col md={4}>
          <Input
            handleChange={formik.handleChange}
            name={'ads_name'}
            value={formik.values.ads_name}
            placeholder={'ADS Facility Name *'}
            label={'ADS Facility Name *'}
            maxLength="50"
            type="text"
          />
          <ErrorMessage className="error-text" component="p" name="ads_name" />
        </Col>
        <Col md={4}>
          <div className=" input-otr" style={{alignItems: 'inherit'}}>
            <label htmlFor="email" className="input-label">
              Regional Director
            </label>

            <AsyncSelectComponent
              isLoading={dropdownLoading.users}
              selectedValue={formik.values.regional_managers_attributes}
              defaultOptions={users}
              loadOptions={loadUsersDropdownOptions}
              onChange={obj => handleChangeForManagers(obj)}
              placeholder="Search and select"
              isMulti
            />

            <ErrorMessage className="error-text" component="p" name="regional_managers_attributes" />
          </div>
        </Col>
        {/* )} */}
        <Col md={4}>
          <div className=" input-otr" style={{alignItems: 'inherit'}}>
            <label htmlFor="email" className="input-label">
              Entity Name *
            </label>

            <AsyncSelectComponent
              isLoading={dropdownLoading.entities}
              selectedValue={formik.values.entity_id}
              defaultOptions={entities}
              loadOptions={loadEntitiesDropdownOptions}
              onChange={obj => handleChangeForEntity(obj)}
              placeholder="Search and select"
            />

            <ErrorMessage className="error-text" component="p" name="entity_id" />
          </div>
        </Col>
        <Col md={4}>
          <div className=" input-otr" style={{alignItems: 'inherit'}}>
            <label htmlFor="email" className="input-label">
              Document Delivery Type *
            </label>
            <SelectComponent
              name="document_delivery_type"
              options={documentDeliveryTypeOptions}
              placeholder="Select"
              selectedValue={formik.values.document_delivery_type}
              handleChange={obj => handleChangeForDocumentType(obj)}
            />
            <ErrorMessage className="error-text" component="p" name="document_delivery_type" />
          </div>
        </Col>

        <Col md={4}>
          <Input
            handleChange={formik.handleChange}
            name={'email'}
            value={formik.values.email}
            placeholder={'e.g. name@example.com'}
            label={'Email *'}
            type="text"
          />
          <ErrorMessage className="error-text" component="p" name="email" />
        </Col>
        <Col md={4}>
          <Input
            handleChange={e => {
              // formik.setFieldValue('fax', e.target.value);
              formik.setFieldValue('fax', normalizeInput(e.target.value, formik.values.fax));
            }}
            name={'fax'}
            value={normalizeInput(formik.values.fax, 0)}
            placeholder={'(858)625-8700'}
            label={'Fax'}
            type="text"
          />
          <ErrorMessage className="error-text" component="p" name="fax" />
        </Col>
        <Col md={4}>
          <Input
            handleChange={e => {
              formik.setFieldValue('phone_no', normalizeInput(e.target.value, formik.values.phone_no));
            }}
            name={'phone_no'}
            value={normalizeInput(formik.values.phone_no, 0)}
            placeholder={'(858)625-8700'}
            label={'Phone Number *'}
            type="text"
          />
          <ErrorMessage className="error-text" component="p" name="phone_no" />
        </Col>
        <Col md={4}>
          <Input
            handleChange={formik.handleChange}
            name={'street'}
            value={formik.values.street}
            placeholder={'e.g. 100 Main Road'}
            label={'Street *'}
            maxLength="50"
            type="text"
          />
          <ErrorMessage className="error-text" component="p" name="street" />
        </Col>
        <CityField name="city" value={formik.values.city} handleChange={formik.handleChange} />
        <Col md={4}>
          <Input
            handleChange={formik.handleChange}
            name={'county'}
            value={formik.values.county}
            placeholder={'e.g. Orange County'}
            label={'County *'}
            maxLength="50"
            type="text"
          />
          <ErrorMessage className="error-text" component="p" name="county" />
        </Col>
        <StateField
          name="state"
          selectedValue={formik.values.state}
          setFieldValue={formik.setFieldValue}
          onBlur={formik.handleBlur}
          menuPortalTarget={document.body}
        />

        <ZipCodeField
          name="zip_code"
          value={formik.values.zip_code}
          handleChange={e => {
            if (e.target.value.length <= 5) {
              formik.setFieldValue('zip_code', e.target.value);
            }
          }}
        />
        <Col md={4}>
          <Input
            handleChange={formik.handleChange}
            name={'country'}
            value={formik.values.country}
            placeholder={'e.g. U.S.'}
            label={'Country *'}
            maxLength="50"
            type="text"
          />
          <ErrorMessage className="error-text" component="p" name="country" />
        </Col>

        <Col md={4}>
          <div className=" input-otr" style={{alignItems: 'inherit'}}>
            <label htmlFor="email" className="input-label">
              Facility Type *
            </label>
            <AsyncSelectComponent
              isLoading={dropdownLoading.facilitiesTypes}
              selectedValue={formik.values.facility_type_id}
              defaultOptions={facilitiesTypes}
              loadOptions={loadFacilitiesTypeDropdownOptions}
              onChange={obj => handleChangeForFacility(obj)}
              placeholder="Search and select"
            />

            <ErrorMessage className="error-text" component="p" name="facility_type_id" />
          </div>
        </Col>
        <Col md={4}>
          <Input
            handleChange={formik.handleChange}
            name={'number_of_bed'}
            value={formik.values.number_of_bed}
            placeholder={'e.g. 59 '}
            label={'Number of Beds *'}
            type="number"
          />
          <ErrorMessage className="error-text" component="p" name="number_of_bed" />
        </Col>
        <Col md={4}>
          <Input
            handleChange={formik.handleChange}
            name={'patient_estimate'}
            value={formik.values.patient_estimate}
            placeholder={'e.g. 10'}
            label={'Estimated Clinical Need-Patient *'}
            type="number"
          />
          <ErrorMessage className="error-text" component="p" name="patient_estimate" />
        </Col>
        <Col md={4}>
          <Input
            handleChange={formik.handleChange}
            name={'visit_estimate'}
            value={formik.values.visit_estimate}
            placeholder={'e.g. 10'}
            label={'Estimated Clinical Need-Visits *'}
            type="number"
          />
          <ErrorMessage className="error-text" component="p" name="visit_estimate" />
        </Col>
        <Col md={4}>
          <CustomDatePicker
            name="start_date"
            label="Start Date"
            selected={formik.values.start_date ? new Date(moment(formik.values.start_date)) : ''}
            onDateChange={date => formik.setFieldValue('start_date', date)}
          />
          <ErrorMessage className="error-text" component="p" name="start_date" />
        </Col>
        <Col md={4}>
          <CustomDatePicker
            name="end_date"
            label="End Date"
            selected={formik.values.end_date ? new Date(moment(formik.values.end_date)) : ''}
            onDateChange={date => formik.setFieldValue('end_date', date)}
          />
          <ErrorMessage className="error-text" component="p" name="end_date" />
        </Col>
      </Row>
    </form>
  );
};

export default FacilityForm;
