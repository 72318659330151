import React, {useEffect, useState, useCallback} from 'react';
import TableWraper from 'components/common/table-wrapper';
import debounce from 'lodash/debounce';

import {ArrowSquareOut} from 'phosphor-react';
import {DEBOUNCE_DELAY, isAdministrator, isPayRoll} from 'utils/common';
import {initialMetaForTable} from 'constants/common';
import {getAdpExport} from 'containers/adp-export/api';

const AdpExportHistory = () => {
  const [allListSelected, setAllListSelected] = useState(false);
  const [adpExportsList, setAdpExportsList] = useState([]);
  const [refreshPage, setRefreshPage] = useState(false);
  const [loading, setLoading] = useState(true);
  const [meta, setMeta] = useState(initialMetaForTable);
  const [totalCount, setTotalCount] = useState(0);

  const fetchData = useCallback(
    async function () {
      try {
        const result = await getAdpExport(meta);

        if (result['adp_exports']) {
          const data = result?.adp_exports.map(item => ({...item, isSelected: allListSelected}));
          setAdpExportsList(data);
          setTotalCount(result?.meta.total_count);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
        setRefreshPage(false);
      }
    },
    [loading, refreshPage],
  );
  useEffect(() => {
    if (loading || refreshPage) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, refreshPage]);

  const handleRefreshPage = () => {
    setRefreshPage(pre => !pre);
  };
  const handleSetPerPage = obj => {
    setMeta(pre => ({...pre, perPage: obj.value}));
    handleRefreshPage();
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(debounce(handleRefreshPage, DEBOUNCE_DELAY), [meta.search]);
  const handleSetSearchQuery = value => {
    setMeta(pre => ({...pre, search: value}));
    debounceFn();
  };
  const handlePageChange = value => {
    setMeta(pre => ({...pre, page: value}));
    handleRefreshPage();
    setLoading(true);
  };

  const setAllListCheck = value => {
    setAdpExportsList(pre => pre.map(item => ({...item, isSelected: value})));
  };
  const setSingleItemCheck = (value, id) => {
    setAdpExportsList(pre => pre.map(item => (item.id === id ? {...item, isSelected: value} : item)));
  };

  return (
    <>
      <TableWraper
        setPerPage={handleSetPerPage}
        setSearchQuery={handleSetSearchQuery}
        searchValue={meta.search}
        totalListCount={totalCount}
        pageSize={meta.perPage}
        currentPage={meta.page}
        onPageChange={handlePageChange}
      >
        {loading || refreshPage ? (
          <h3 style={{textAlign: 'center'}}>Loading</h3>
        ) : (
          <table className="table">
            <thead>
              <tr>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">
                      <input
                        className="form-check-input"
                        onChange={e => {
                          setAllListSelected(e.target.checked);
                          setAllListCheck(e.target.checked);
                        }}
                        type="checkbox"
                        checked={allListSelected}
                      />
                    </p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Filename</p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Sent Date</p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Date Range</p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Status</p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Number of Records</p>
                  </div>
                </th>

                {(isAdministrator() || isPayRoll()) && (
                  <th scope="col">
                    <div className="header-text-otr">
                      <p className="table-name heading-xsb">Actions</p>
                    </div>
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {adpExportsList.map(item => {
                return (
                  <tr key={item.id}>
                    <th scope="col">
                      <div className="header-text-otr">
                        <p className="table-name heading-xsb">
                          <input
                            className="form-check-input"
                            value={item.isSelected}
                            onChange={e => {
                              setSingleItemCheck(e.target.checked, item.id);
                            }}
                            type="checkbox"
                            checked={item.isSelected}
                          />
                        </p>
                      </div>
                    </th>

                    <td>
                      <div className="table-text-otr">
                        <p className="table-text">{item?.file_name || ''}</p>
                      </div>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <p className="table-text">{item?.sent_date || ''}</p>
                      </div>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <p className="table-text">
                          {item.start_date} to {item.end_date}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <p className="table-text">{item.status}</p>
                      </div>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <p className="table-text">{item.total_record}</p>
                      </div>
                    </td>

                    {(isAdministrator() || isPayRoll()) && (
                      <td>
                        <div className="d-flex justify-space-around">
                          <div className="table-icon-otr">
                            <div className="icon-otr">
                              <ArrowSquareOut
                                size={24}
                                onClick={() => {
                                  // navigate(FACILITIES_PATH + `/${item.id}/edit`);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </TableWraper>
    </>
  );
};

export default AdpExportHistory;
