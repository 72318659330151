import * as yup from 'yup';
import moment from 'moment';

export const adjustmentsInitialValues = {
  provider_id: '',
  facility_id: '',
  earning_code_id: '',
  serviced_unit: '',
  service_date: moment(new Date()).format('YYYY-MM-DD'),
  effective_date: moment(new Date()).format('YYYY-MM-DD'),
  documentation_received_date: moment(new Date()).format('YYYY-MM-DD'),
  unit_in_minutes: '',
  initial_unit_charge_amount: '',
  patient_seen: '',
  paid_type: '',
  note: {
    message: '',
  },
};
export const adjustmentsValidationSchema = yup.object().shape({
  provider_id: yup.string().required('Required *'),
  facility_id: yup.string().required('Required *'),
  earning_code_id: yup.string().required('Required *'),
  serviced_unit: yup.string().required('Required *'),
  service_date: yup.date().required('Required *'),
  paid_type: yup.string().required('Required *'),
  // effective_date: yup.string().required('Required *'),
});
